// export const boothesArr = [
//   {
//     boothImg: "Product1.png",
//     logo: "logo1.png",
//     name: "Rawae3 Sweets",
//     sellerName: "Ola Ziadeh",
//     url: "609/1",
//   },
//   {
//     boothImg: "Product2.png",
//     logo: "logo2.png",
//     name: "فن وموهبة",
//     sellerName: "Shatha ajjawi",
//     url: "498/1",
//   },
//   {
//     boothImg: "Product3.png",
//     logo: "logo3.png",
//     name: "Walaa Nsser",
//     sellerName: "Walaa Nsser",
//     url: "478/1",
//   },
//   {
//     boothImg: "Product4.png",
//     logo: "logo4.png",
//     name: "Linda Habib",
//     sellerName: "Linda Habib",
//     url: "592/1",
//   },
//   // {
//   //   boothImg: 'Product5.png',
//   //   logo: 'logo5.png',
//   //   name: 'Khitan w alwan',
//   //   sellerName: 'Khitan w alwan',
//   //   url: '623/1',
//   // },
//   {
//     boothImg: "Product7.png",
//     logo: "logo7.png",
//     name: "W Designs",
//     sellerName: "Rawan Alkayyali",
//     url: "466/1",
//   },
//   {
//     boothImg: "Product6.png",
//     logo: "logo6.png",
//     name: "Sondos.n.art",
//     sellerName: "Sondos Qasem ",
//     url: "597/1",
//   },
// ];

export const CategoryImgs = [
  {
    id: 32,
    name: "Health & Beauty",
    catImg: "/assets/images/cat/Healthy & Beauty.png",
  },
  {
    id: 2,
    name: "Books",
    catImg: "/assets/images/cat/books.png",
  },
  {
    id: 25,
    name: "Graduation",
    catImg: "/assets/images/cat/Graduation.png",
  },
  {
    id: 24,
    name: "New Baby",
    catImg: "/assets/images/cat/New Baby.png",
  },
  {
    id: 21,
    name: "Bags & Pursus",
    catImg: "/assets/images/cat/Bags & Purses.png",
  },
  {
    id: 31,
    name: "Services",
    catImg: "/assets/images/cat/Services.png",
  },
  {
    id: 14,
    name: "Food ",
    catImg: "/assets/images/cat/Food.png",
  },
  {
    id: 13,
    name: "Medical",
    catImg: "/assets/images/cat/Medical.png",
  },
  {
    id: 22,
    name: "Home & Living",
    catImg: "/assets/images/cat/Home & Living.png",
  },
  {
    id: 11,
    name: "Electronics",
    catImg: "/assets/images/cat/Electronics.png",
  },
  {
    id: 20,
    name: "Art & Collectibles",
    catImg: "/assets/images/cat/Art & Collectibles.png",
  },
  {
    id: 33,
    name: "Memorial Gifts",
    catImg: "/assets/images/cat/Memorial Gifts.png",
  },
  {
    id: 30,
    name: "Clothes & Shoes",
    catImg: "/assets/images/cat/Clothes & Shoes.png",
  },
  {
    id: 29,
    name: "Toys & Games",
    catImg: "/assets/images/cat/Toys & Games.png",
  },
  {
    id: 28,
    name: "Accessories & Jewelry",
    catImg: "/assets/images/cat/Accessories & jewelry.png",
  },
  {
    id: 26,
    name: "Weddings",
    catImg: "/assets/images/cat/Wedding.png",
  },
  {
    id: 27,
    name: "Birthday",
    catImg: "/assets/images/cat/Birthday.png",
  },
  {
    id: 23,
    name: "Craft Supplies & Tools",
    catImg: "/assets/images/cat/Craft Supplies & Tools.png",
  },
];
