import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import { format } from "date-fns";

import { Box, makeStyles, Grid } from "@material-ui/core";
import tableIcons from "./../../styles/materialTableIcons";
import { colors } from "../../styles/commonCss";

import OrganizerBazarsDropdown from "./../../components/OrganizerBazarsDropdown/index";
import { getOrganizerOrdersAsync } from "../../redux/organizer/api";
import { useDispatch, useSelector } from "react-redux";
import BuyerInfoModal from "./../../components/BuyerInfoModal/index";
import OrderItemsModal from "./partials/OrderItemsModal/index";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { orderStatusIndicator } from "./../App/constants";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import OrderItemsModal from './../../components/ManageOrdersSection/partials/OrderItemsModal/index'
import { getBazarHome } from "../../redux/bazar/actions";
import { Link, Typography, Breadcrumbs } from "@material-ui/core";
import ShippmentAdressModal from "../../components/ShippmentAddress";
import axios from "axios";
import { handleLogout } from "../../utils/setLocalStorage";

const useStyles = makeStyles((theme) => ({
  disabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
  upgrade: {
    marginLeft: "10px",
    color: colors.link,
    textDecoration: "underline",
    cursor: "pointer",
  },
  dis: {
    display: "flex",
    justifyContent: "center",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
  chartSize: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    margin: "15px 40px 60px 40px",
  },
}));
const headerStyle = {
  textAlign: "center",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};

const OrganizerManageOrdersTable = (props) => {
  const dispatch = useDispatch();
  const tableRef = React.createRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const paramsBazarId = useParams().bazarId;

  const [filterBazarId, setFilterBazarId] = useState(paramsBazarId || "");
  const [open, setOpen] = useState(false);
  const [dataa, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [earning_sales, setEarning_Sales] = useState([]);

  const handleFilterChange = (e) => {
    setFilterBazarId(e.target.value);
    tableRef.current.onQueryChange();
  };
  const handleOpen = (bazarId) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const bazar = useSelector((state) => state.bazarReducer.bazarHome);
  useEffect(() => {
    dispatch(getBazarHome(filterBazarId || paramsBazarId));
  }, [filterBazarId]);
  useEffect(() => {
    axios
      .get(`Organizer/GetEarningSalesToOrganizer?BazarId=${filterBazarId}`)
      .then((response) => {
        setEarning_Sales(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filterBazarId]);
  const body = (
    <Grid container className={classes.dis} xs={12}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img
            src="/assets/images/empty/order.PNG"
            alt="emptyBazar"
            style={{ width: "150px" }}
          />
        </Grid>
        <Grid
          container
          item
          justify="center"
          style={{ margin: "1px 0", fontSize: ".8rem" }}
        >
          <span style={{ textAlign: "center", margin: "1rem" }}>
            {t("donotorders")}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
  // let sum = 0;
  // let x = dataa?.map((itm) => {
  //   sum = sum + itm?.earning;
  //   return sum;
  // });
  return (
    <>
      {filterBazarId ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" className={"margin-top-50"}>
              {t("stats")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="center"
            style={{ margin: "50px 0" }}
            spacing={4}
          >
            <Grid item>
              <div className={classes.chartSize}>
                <img src="/assets/images/charts/selles.svg" width="100%" />
                <div style={{ fontSize: "16px", marginTop: "-10%" }}>
                  {t("sales")}
                </div>
                <div style={{ textAlign: "center" }}>
                  {earning_sales?.selles}
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.chartSize}>
                <img src="/assets/images/charts/earnings.svg" width="100%" />
                <div style={{ fontSize: "16px" }}>{t("earning")}</div>
                <div style={{ textAlign: "center" }}>
                  {earning_sales?.earning}
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.chartSize}>
                <img src="/assets/images/charts/order.svg" width="100%" />
                <div style={{ fontSize: "16px", marginTop: "-10%" }}>
                  {t("order.orders")}
                </div>
                <div style={{ textAlign: "center" }}>
                  {/* {bazar.numOfOrders} */}
                  {count}
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
      {props.location.pathname === "/Organizer/manageOrders/:bazarId" ? (
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
          <Link
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() =>
              props.history.push(`/Organizer/bazarHome/${paramsBazarId}`)
            }
          >
            {bazar.name}
          </Link>
          <Typography className="special">
            {t("buttons.manageOrders")}
          </Typography>
        </Breadcrumbs>
      ) : (
        ""
      )}
      {!paramsBazarId && (
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <OrganizerBazarsDropdown
            handleChange={handleFilterChange}
            variant="outlined"
            value={filterBazarId}
            styleObj={{ width: "200px" }}
          />
          {console.log(filterBazarId, "filterBazarId")}
        </Box>
      )}
      <MaterialTable
        tableRef={tableRef}
        localization={{
          toolbar: {
            searchPlaceholder: t("searchForOrder"),
          },
          body: {
            emptyDataSourceMessage: body,
          },
        }}
        style={{
          fontSize: "15px",
          // overflowX: 'auto',
          minWidth: "650px",
          display: "block",
          width: "100%",
          overflowX: "scroll",
          // borderCollapse: 'collapse',
          // width: '100%',
        }}
        icons={tableIcons}
        title={t("buttons.manageOrders")}
        columns={[
          {
            title: t("order.orderNo"),
            field: "orderNumber",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return (
                row.orderNumber && (
                  <OrderItemsModal
                    orderNo={row.orderNumber}
                    orderItems={row.orderItems}
                    shippingPrice={row.shippingPrice}
                    subTotal={row.subTotal}
                    total={row.total}
                  />
                )
              );
            },
          },
          {
            title: t("order.orderTotal"),
            field: "total",
            type: "numeric",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <div>
                {row.currency} {row.total}
              </div>
            ),
          },
          {
            title: t("earning"),
            field: "earning",
            type: "numeric",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <div>
                {row.currency} {row.earning}
              </div>
            ),
          },

          {
            title: t("bazarName"),
            field: "bazarName",
            cellStyle: { width: "fit-content", textAlign: "center" },
          },

          {
            title: t("date"),
            field: "orderDate",
            render: (row) => (
              <div>{format(new Date(row.orderDate), "MMMM dd, yyyy ")}</div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("status"),
            // field: orderStatusIndicator('status',
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <Box display="flex" alignItems="center" justifyContent="center">
                <FiberManualRecordIcon
                  // fontSize="small"
                  style={{
                    color: orderStatusIndicator[row.status]?.color,
                    // marginRight: '10px',
                    fontSize: "12px",
                  }}
                />
                <div style={{ margin: "0 10px" }}>
                  {t(
                    "productStatus." + orderStatusIndicator[row.status]?.title
                  ) || row.status}
                </div>
              </Box>
            ),
          },
          {
            title: t("cartPage.shipmentAddress"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) =>
              row.buyer && (
                <ShippmentAdressModal
                  label={t("links.view")}
                  user={row}
                  email={row.buyer.email}
                />
              ),
          },
          {
            title: t("buyerInfo"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) =>
              row.buyer && (
                <BuyerInfoModal label={t("links.view")} user={row.buyer} />
              ),
          },
        ]}
        options={{
          search: true,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle,
          doubleHorizontalScroll: true,
          tableLayout: "auto",
          sorting: false,
        }}
        data={(query) => {
          // debugger
          return new Promise(async (resolve, reject) => {
            const { page, pageSize, search } = query;
            try {
              const result = await getOrganizerOrdersAsync(
                page + 1,
                pageSize,
                search,
                filterBazarId
              );
              console.log(result, "uuuu");
              resolve({
                data: result.data,
                page: query.page,
                totalCount: result.count || 1,
              });
              setCount(result.count);
              // const x = await getOrganizerOrdersAsync(
              //   1,
              //   1000,
              //   search,
              //   filterBazarId
              // );
              // setData(x?.data);
            } catch (error) {
              resolve({
                data: [],
                page: query.page,
                totalCount: 1,
              });
              if (error?.response?.status === 401) {
                handleLogout();
                window.location.href = "/login";
              }
            }
          });
        }}
      />
    </>
  );
};

export default OrganizerManageOrdersTable;
