import React, { useEffect, useState } from "react";
import PackageCard from "./../../PackageCard/index";

import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { choosePackage } from "../../../redux/bazar/actions";
// import { packages } from './constants'
import packageEnum from "../../../enums/Package";
import { useTranslation } from "react-i18next";

const StepFourChoosePackage = (props) => {
  const { bazarInfo } = props;
  const { t } = useTranslation();
  const [selectedPackage, setSelectedPackage] = useState(bazarInfo.packagePlan);
  console.log(props.bazarInfo.id, "step 4");

  useEffect(() => {
    setSelectedPackage(bazarInfo.packagePlan);
  }, [bazarInfo]);

  const handleSelectedPackageChange = (id, type) => {
    setSelectedPackage(type);
    const packageInfo = { bazarId: +props.bazarId, packagePlan: +id };
    props.addPackage(packageInfo, +id, type);
    console.log(packageInfo);
  };
  const packages = [
    {
      title: t("createBazar.packages.free"),
      content: {
        price: "0 JD",
        description: t("createBazar.packages.freeDescription"),
        max: t("createBazar.packages.freeSellers"),
      },
      type: "FreePlan",
      id: packageEnum.free,
    },
    {
      title: t("createBazar.packages.pro"),
      content: {
        price: "1000 JD",
        description: t("createBazar.packages.proDescription"),
        max: t("createBazar.packages.proSellers"),
      },
      type: "ProPlan",
      id: packageEnum.pro,
    },
    {
      title: t("contactUs"),
      content: {
        price: "?",
        description: t("createBazar.packages.contactUsDescription"),
        max: t("createBazar.packages.contactUsSellers"),
      },
      type: "ContactUsPlan",
      id: packageEnum.contactUs,
    },
  ];
  return (
    <Grid container spacing={2}>
      {packages.map((pack) => (
        <PackageCard
          {...pack}
          key={pack.id}
          handleSelectedPackageChange={handleSelectedPackageChange}
          selectedPackage={selectedPackage}
        />
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return { bazarInfo: state.bazarReducer.bazarInfo };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addPackage: (packageInfo, packageId, type) =>
      dispatch(choosePackage(packageInfo, packageId, type)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepFourChoosePackage);
