import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import SellerBoothCard from "../../components/SellerBoothCard";
import ProductRequestCard from "./../../components/ProductRequestCard/index";
import AppPagination from "../../components/Pagination";
import {
  getSellerProductAsync,
  searchInsellerProductsByNameAsync,
  getSellerProductsFilteredByBoothCategoryAsync,
} from "./../../redux/seller/api";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  disp: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
  flex: {
    justifyContent: "flex-end",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
  },
  sellerProductsAlert: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#dcd9d9",
    padding: "20px 10px",
  },
}));
const SellerProducts = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const PageRef = useRef();
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = React.useState("");
  const [count, setCount] = useState(0);

  const boothId = useParams().boothId;
  const pageSize = 12;
  const sellerId = localStorage.userId;

  useEffect(() => {
    fetchData(page, search);
  }, []);
  const fetchData = async (currentPage, searchValue) => {
    try {
      let response = "";
      debugger;
      if (!boothId) {
        response = !searchValue
          ? await getSellerProductAsync(
              localStorage.userId,
              currentPage,
              pageSize
            )
          : await searchInsellerProductsByNameAsync(
              localStorage.userId,
              searchValue,
              currentPage,
              pageSize
            );
      } else {
        response = await getSellerProductsFilteredByBoothCategoryAsync(
          sellerId,
          searchValue,
          boothId,
          currentPage,
          pageSize
        );
      }
      // debugger
      //   const { data, count } = response
      console.log(response.data, "pppppp");
      const products = response.data;
      console.log(products);
      setProducts(products);
      setProducts(response.data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
    setLoading(false);
  };
  const handleSearchChange = (event, value) => {
    fetchData(1, event.target.value);
    setPage(1);
    setSearch(event.target.value);
  };
  const scrollPage = () => PageRef?.current?.scrollIntoView();
  const handlePageChange = (event, newPage) => {
    console.log(newPage);
    fetchData(newPage, search);
    setPage(newPage);
    setTimeout(() => scrollPage(), 1000);
  };
  const handleDeleteProduct = async (productId) => {
    // alert('Deleteeeeeeeeeeeeeeeeee')
    try {
      let newProducts = products.filter((product) => product.id !== productId);
      console.log(newProducts);
      const response = await axios.delete(
        `Seller/DeleteProduct?ProductId=${productId}`
      );
      setProducts(newProducts);
      dispatch(
        setSnackbar(true, "success", t("messages.productDeletedSuccessfuly"))
      );
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      if (error?.response?.status === 400)
        dispatch(
          setSnackbar(
            true,
            "error",
            t("messages.cannotDeletedCause") + error?.response?.data.message
          )
        );
    }
  };
  const productsExists = !loading ? (products.length ? true : false) : true;
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        {!boothId && (
          <div
            variant="filled"
            // style={{
            //   width: '100%',
            //   background: '#dcdbdb',
            //   padding: '20px 10px',
            // }}
            className={classes.sellerProductsAlert}
          >
            <Box width="30px" margin="0 10px">
              <img
                src="/assets/images/SVG/lamp.svg"
                width="100%"
                height="100%"
                // style={{ color: '#f8b334' }}
              />
            </Box>
            <Typography>
              {t("infoMessages.sellerProductsDescription")}
            </Typography>
          </div>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <h3 ref={PageRef}>{t("products")}</h3>
          </Grid>
          <div className={classes.disp}>
            {productsExists && !boothId && (
              <Grid container item xs={12} md={6} style={{ margin: "1rem 0" }}>
                <TextField
                  onChange={handleSearchChange}
                  placeholder={t("SearchforProduct")}
                  value={search}
                  id="input-with-icon-textfield"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {console.log(
              "productsExists",
              products,
              props.history.location.pathname
            )}
            {props.history.location.pathname === "/Seller/products" &&
              productsExists &&
              !loading && (
                <Grid
                  item
                  md={6}
                  xs={12}
                  container
                  style={{ margin: "1rem 0" }}
                  className={classes.flex}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.history.push("/Seller/AddProduct")}
                  >
                    + {t("buttons.addProduct")}
                  </Button>
                </Grid>
              )}
          </div>
        </Grid>

        <Grid container item xs={12}>
          {productsExists &&
            products.map((product) => (
              <Grid
                container
                item
                xs={12}
                // sm={6}
                key={product.id}
                lg={4}
                justify="center"
              >
                <ProductRequestCard
                  {...product}
                  status="active"
                  boothId={boothId}
                  handleDeleteProduct={handleDeleteProduct}
                  role="Seller"
                />
              </Grid>
            ))}
          {/* <ProductRequestCard status="active" />{' '} */}
        </Grid>
        <Grid container item xs={12} justify="center">
          {productsExists ? (
            !loading && (
              <AppPagination
                count={Math.ceil(count / pageSize)}
                page={page}
                handlePageChange={handlePageChange}
              />
            )
          ) : boothId ? (
            <Alert severity="error">
              You Don't have Products of the same booth category
            </Alert>
          ) : (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                height: "max-content",
              }}
            >
              <Box width="180px">
                <img
                  src="/assets/images/SVG/shipping.svg"
                  width="100%"
                  height="100%"
                />
              </Box>
              <div>{t("infoMessages.noProductAddFirstOne")}</div>
              <Button
                style={{ marginTop: "40px" }}
                variant="contained"
                color="primary"
                onClick={() => props.history.push("/Seller/AddProduct")}
              >
                + {t("buttons.addProduct")}
              </Button>
            </div>
          )}
          {loading && (
            <div style={{ marginTop: "100px" }}>
              <CircularProgress color="primary" />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SellerProducts;
