import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { colors } from "../../styles/commonCss";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    margin: "50px auto",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  content: {
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  header: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
  selected: {
    backgroundColor: colors.selectedItem,
  },
  darkColor: {
    backgroundColor: "#FF470B",
    color: "white",
  },
}));
const PaymentCard = (props) => {
  const {
    status,
    title,
    content,
    handleSelectedPlanChange,
    selectedPlan,
    paymentPlanInputHandler,
    savePaymentPlanSelected,
    flatFeeValue,
    commissionValue,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const selectionInputHandler = (status) => {
    switch (status) {
      case "FlatFee":
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <FormControl
              className={classes.margin}
              variant="outlined"
              style={{ width: "50%" }}
            >
              <InputLabel htmlFor="outlined-adornment-amount">
                {t("createBazar.paymentPlan.flatFee")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                name="flatFee"
                value={flatFeeValue}
                onChange={paymentPlanInputHandler}
                startAdornment={
                  <InputAdornment position="start">JD</InputAdornment>
                }
                labelWidth={60}
              />
            </FormControl>
            <Box mt={2}>
              <Button
                variant="contained"
                id={status}
                onClick={() => savePaymentPlanSelected(status)}
                style={{ backgroundColor: "#FF470B", color: "white" }}
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </Box>
        );
      case "Commission":
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <FormControl
              className={classes.margin}
              variant="outlined"
              style={{ width: "50%" }}
            >
              <InputLabel htmlFor="outlined-adornment-amount">
                {t("createBazar.paymentPlan.commission")}
              </InputLabel>
              <OutlinedInput
                name="commission"
                id="outlined-adornment-amount"
                value={commissionValue}
                onChange={paymentPlanInputHandler}
                startAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
                labelWidth={60}
              />
            </FormControl>
            <Box mt={2}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#FF470B", color: "white" }}
                onClick={() => savePaymentPlanSelected(status)}
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </Box>
        );
      case "FlatFeesWithCommission":
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
            >
              <FormControl
                className={classes.margin}
                variant="outlined"
                style={{ width: "40%" }}
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  {t("createBazar.paymentPlan.flatFee")}
                </InputLabel>
                <OutlinedInput
                  name="flatFee"
                  id="flatFee"
                  value={flatFeeValue}
                  onChange={paymentPlanInputHandler}
                  startAdornment={
                    <InputAdornment position="start">JD</InputAdornment>
                  }
                  labelWidth={60}
                />
              </FormControl>
              <FormControl
                className={classes.margin}
                variant="outlined"
                style={{ width: "40%" }}
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  {t("createBazar.paymentPlan.commission")}
                </InputLabel>
                <OutlinedInput
                  name="commission"
                  id="commission"
                  value={commissionValue}
                  onChange={paymentPlanInputHandler}
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  labelWidth={60}
                />
              </FormControl>
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={() => savePaymentPlanSelected(status)}
                style={{ backgroundColor: "#FF470B", color: "white" }}
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </Box>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      <Card className={classes.root}>
        <CardHeader title={title} className={classes.header} />

        <CardContent
          className={
            selectedPlan === status
              ? classes.selected + " " + classes.content
              : classes.content
          }
        >
          <Box paddingY={3} paddingX={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align={"center"}
            >
              {content}
            </Typography>
          </Box>
          {selectedPlan === status ? (
            selectionInputHandler(status)
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSelectedPlanChange(status)}
              >
                {t("buttons.select")}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PaymentCard;
