import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

// import AttachFileIcon from '@material-ui/icons/AttachFile'
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { Field, FieldArray, Form, Formik } from "formik";
import FormikField from "../../FormikField";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

import { CheckboxWithLabel, Select } from "formik-material-ui";
import { socialMediaArray } from "./constants";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAllCountries } from "../../../redux/address/actions";
import FormikSelect from "../../FormikSelect";
import { useHistory } from "react-router";
import { useStyles } from "./style";
import Message from "../../Message";
import { Link } from "react-router-dom";
import { uploadImageAsync } from "./../../../service/image";
import UploadButtons from "../../UploadButtons";
import ScrollToTopComponent from "../../ScrollToTopComponent";
import "./inputphone.css";
import LoginWithGoogle from "../../LoginWithGoogle";

const OrganizerSignUpForm = (props) => {
  const [phone, setPhone] = useState("");
  const [phoneWhats, setPhoneWhats] = useState("");
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");

  const responseFacebook = (response) => {
    console.log(response, "responseFacebook");
    // setData(response);
    // setPicture(response.picture.data.url);
    // if (response.accessToken) {
    //   setLogin(true);
    // } else {
    //   setLogin(false);
    // }
  };
  const {
    countries,
    serverErrors,
    handleSubmit,
    initialValues,
    validationSchema,
    welcomeMsg,
    registerImg,
    role,
    title,
    registeredSuccessfuly,
    img,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const formFieldNames = Object.keys(initialValues);
  const [certificateName, setCertificateName] = useState(" ");
  const currentLanguageCode = localStorage.i18nextLng;

  useEffect(() => {
    props.getCountries();
  }, []);
  const handleCertificateUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const name = file.name;
    console.log(file);
    setCertificateName("uploading...");

    try {
      const formDate = new FormData();
      formDate.set(name, file);
      const imageNewName = await uploadImageAsync(formDate);

      setFieldValue("companyCertificate", imageNewName);
      setFieldValue("certificateName", name);
      setCertificateName(name);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };

  const getCountryCities = (countryId) => {
    const country = countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };

  // const handleUploadCertificate = (e) => {
  //   console.log(e.target.files[0])
  //   setCertificateValue(e.target.files[0].name)
  // }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [props.serverErrors]);

  return (
    <Box mt={5} mb={5}>
      <Grid container spacing={4} direction="row" wrap="nowrap">
        <Grid item xs={1} md={1}></Grid>
        <Grid item md={props.modal ? 12 : 6} xs={12}>
          {/* {role === "buyer" && (
            <Box width={0.5} mt={3}>
              <LoginWithGoogle />
            </Box>
          )} */}
          {console.log(props.modal, "ghgh")}
          <Box>
            <Box mb={5}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={
                  props.modal
                    ? { fontWeight: "600", textAlign: "right" }
                    : { fontWeight: "600" }
                }
              >
                {t("signUp." + title)}
              </Typography>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                validateForm,
                isSubmitting,
                handleReset,
                setFieldValue,
              }) => {
                console.log(values);
                // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

                return (
                  <Form autoComplete="false">
                    {console.log(errors)}
                    {/* {serverErrors.length > 0 && <ScrollToTopComponent />} */}
                    {serverErrors?.length > 0 ? (
                      <Box mb={2}>
                        {serverErrors?.map((error, i) => (
                          <Typography color="error" key={i}>
                            {error}
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      ""
                    )}
                    {registeredSuccessfuly ? (
                      <Message
                        status="success"
                        msg="account created Successfuly"
                      />
                    ) : (
                      ""
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          name={formFieldNames[0]}
                          label={t("signUp." + formFieldNames[0])}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          name={formFieldNames[1]}
                          label={t("signUp." + formFieldNames[1])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikField
                          required
                          name={formFieldNames[2]}
                          label={t("signUp." + formFieldNames[2])}
                          type="email"
                        />
                      </Grid>
                      {currentLanguageCode === "ar" && (
                        <Grid item xs={12}>
                          <Typography
                            style={props.modal ? { textAlign: "right" } : {}}
                          >
                            ادخل كلمة المرور باللغة الانجليزية
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          name={formFieldNames[3]}
                          label={t("signUp." + formFieldNames[3])}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormikField
                          required
                          name={formFieldNames[4]}
                          label={t("signUp." + formFieldNames[4])}
                          type="password"
                        />
                      </Grid>
                      {console.log(
                        "history",
                        history.location.pathname,
                        history
                      )}
                      {props.modal ||
                      history.location.pathname === "/register/buyer" ? (
                        ""
                      ) : (
                        <>
                          <Grid item xs={12} md={6}>
                            <FormikSelect
                              required
                              name="countryId"
                              label={t("signUp.country")}
                              items={countries}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikSelect
                              required
                              name="cityID"
                              label={t("signUp.city")}
                              items={getCountryCities(values.countryId)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikField
                              required
                              name="street"
                              label={t("signUp.street")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikField
                              name="postalCode"
                              label={t("signUp.postalCode")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikField
                              required
                              name="phoneNumber"
                              label={t("signUp.phoneNumber")}
                            />
                            {/* <PhoneInput
                          placeholder={t("signUp.phoneNumber")}
                          value={values.phoneNumber}
                          // required
                          onChange={(value) => {
                            let x = formatPhoneNumberIntl(value);
                            // setPhone(x)
                            // values.phoneNumber = x
                            setFieldValue("phoneNumber", x || value);
                            console.log("values.phoneNumber", x || value);
                          }}
                          rules={
                            {
                              required: true,
                              validate: isValidPhoneNumber,
                            }
                          }
                          style={{ direction: "ltr" }}
                        /> */}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormikField
                              name="phoneWhats"
                              label={t("signUp.phoneWhats")}
                            />
                            {/* <PhoneInput
                          placeholder={t("signUp.phoneWhats")}
                          value={values.phoneWhats}
                          onChange={(value) => {
                            let x = formatPhoneNumberIntl(value);
                            // setPhoneWhats(x)
                            setFieldValue("phoneWhats", x || value);
                            console.log(x);
                            // values.phoneWhats = x
                          }}
                          rules={{
                            validate: isValidPhoneNumber,
                          }}
                          style={{ direction: "ltr" }}
                        /> */}
                          </Grid>
                        </>
                      )}
                      {role !== "buyer" && (
                        <Grid item xs={12}>
                          <Typography>
                            {t("signUp.companyCertificate")}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              alignItems: "center",
                            }}
                          >
                            <UploadButtons
                              handleChange={(e) =>
                                handleCertificateUpload(e, setFieldValue)
                              }
                              type="Button"
                            />
                            <div>{values.certificateName}</div>
                          </div>
                        </Grid>
                      )}
                      {/* <TextField
                        color="primary"
                        variant="outlined"
                        fullWidth
                        value={certificateValue}
                      
                      >
                        <Button variant="contained" component="label">
                          Upload File
                        </Button>
                        <input
                          type="file"
                          onChange={handleUploadCertificate}
                          hidden
                        />
                      </TextField> */}
                      {/* <Box className={classes.uploadField}>lll</Box>
                    // </Grid> */}
                      {role !== "buyer" && (
                        <>
                          <Grid item xs={12}>
                            <Typography>
                              {t("signUp.socialMediaTitle")}
                            </Typography>
                          </Grid>
                          <Grid item container>
                            <FieldArray name="socialMediaLinks">
                              {({ push }) => (
                                <>
                                  {values.socialMediaLinks.map((_, indx) => (
                                    <Grid
                                      item
                                      container
                                      spacing={2}
                                      justify="space-between"
                                      alignItems="center"
                                      xs={12}
                                      md={10}
                                      key={indx}
                                    >
                                      <Grid item xs={5} sm={3}>
                                        <Field
                                          component={Select}
                                          variant="outlined"
                                          name={`socialMediaLinks[${indx}].socialMediaType`}
                                          fullWidth
                                          size="small"
                                          type="number"
                                          style={{ height: "3.5rem" }}
                                        >
                                          {socialMediaArray.map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item.value}
                                                key={index}
                                              >
                                                {item.icon}
                                              </MenuItem>
                                            )
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={7} sm={9}>
                                        <FormikField
                                          variant="outlined"
                                          fullWidth
                                          label={t("signUp.socialMediaLink")}
                                          name={`socialMediaLinks[${indx}].url`}
                                          type="text"
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                  <Grid item xs={2}>
                                    <IconButton
                                      onClick={() =>
                                        push({ url: "", socialMediaType: "" })
                                      }
                                    >
                                      <AddCircleOutlineRoundedIcon
                                        color="secondary"
                                        style={{
                                          fontSize: "50PX",
                                        }}
                                      />
                                    </IconButton>
                                  </Grid>
                                  {role === "seller" ? (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {t("Zoommeet")}:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} lg={11}>
                                        <FormikField
                                          name={formFieldNames[11]}
                                          label={t(
                                            "signUp." + formFieldNames[11]
                                          )}
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </FieldArray>
                          </Grid>
                        </>
                      )}
                      {role === "serviceProvider" ? (
                        <Grid item>
                          <Typography>{t("signUp.cashTitle")}</Typography>
                          <Field
                            name="acceptCashDelivery"
                            type="checkbox"
                            component={CheckboxWithLabel}
                            color="primary"
                            Label={{
                              label: t("signUp.acceptCashDelivery"),

                              className: errors.acceptCashDelivery
                                ? classes.errorColor
                                : undefined,
                            }}
                            // FormHelperText={
                            //   errors.acceptedTerms
                            //     ? 'you should accept terms'
                            //     : ''
                            // }
                          />
                        </Grid>
                      ) : (
                        ""
                      )}

                      <Grid item>
                        <Field
                          required
                          name="acceptedTerms"
                          type="checkbox"
                          component={CheckboxWithLabel}
                          color="primary"
                          Label={{
                            label: t("signUp.termsAndConditions"),

                            className: errors.acceptedTerms
                              ? classes.errorColor
                              : undefined,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          {t("signUp.submit")}
                        </Button>
                        {!props.modal && (
                          <Box mt={3} justifyContent="center" display="flex">
                            Already a memeber ?
                            <Link
                              onClick={() => {
                                history.push("/login");
                                window.location.reload();
                              }}
                              className={classes.signInLink}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography color="primary"> Sign in</Typography>
                            </Link>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          {role === "buyer" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <Box width={0.5} mt={3}>
                <FacebookLogin
                  appId="3097431507239166"
                  autoLoad={true}
                  fields="name,email"
                  scope="public_profile,user_friends"
                  callback={responseFacebook}
                  icon="fa-facebook-f"
                  size="small"
                />
              </Box> */}

              {/* <Box width={0.5} mt={3}>
                <LoginWithGoogle />
              </Box> */}
            </div>
          )}
        </Grid>
        {role !== "buyer" ? (
          <>
            <Hidden only={["xs", "sm"]}>
              <Grid item md={6} xs={false} className={classes.flex}>
                <Box>
                  <img
                    src={`/assets/images/Registration/${img}`}
                    width="100%"
                  />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={1} md={1}></Grid>
          </>
        ) : (
          !props.modal && (
            <>
              <Hidden only={["xs", "sm"]}>
                <Grid
                  item
                  container
                  className={classes.buyerImg}
                  // justify="flex-end"
                  // alignItems="flex-end"
                  // style={{ paddingBottom: '0px', marginRight: '-440px' }}
                >
                  <Box>
                    <img
                      src={`/assets/images/Registration/${img}`}
                      width="100%"
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item xs={1} md={1}></Grid>
            </>
          )
        )}
      </Grid>
    </Box>
  );
};
const mapStateToProps = (state) => {
  console.log(state);
  return {
    countries: state.addressReducer.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getAllCountries()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizerSignUpForm);
