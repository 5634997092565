import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrganizerSignUpForm from "../../components/Registeration/OrganizerSignUpForm";
import {
  buyerRegister,
  organizerRegister,
  sellerRegister,
  serviceProviderRegister,
} from "../../redux/registeration/actions";
import * as Yup from "yup";
import { phoneRegExp } from "./constants";
import { Box, Grid, Link, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    ["@media (max-width:900px)"]: {
      width: "25%",
    },
    ["@media (max-width: 600px)"]: {
      width: "35%",
    },
    ["@media (max-width: 425px)"]: {
      width: "55%",
    },
  },
  wid: {
    width: "25%",
  },
}));

const Registeration = (props) => {
  const [registeredSuccessfuly, setRegisteredSuccessfuly] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const role = props?.match?.params?.role || props.role;
  console.log(role);
  const organizerInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryId: "",
    cityID: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    phoneWhats: "",
    zoomLink: "",
    companyCertificate: "",
    certificateName: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
    acceptedTerms: false,
  };

  const providerInitialvalues = {
    companyName: "",
    representativeName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryId: "",
    cityID: "",
    street: "",
    companyCertificate: "",
    certificateName: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
    acceptedTerms: false,
    acceptCashDelivery: false,
  };
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("validation.nameLength"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    phoneNumber: Yup.string().required(t("validation.phoneRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityID: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
    password: Yup.string().required(t("validation.passwordReqiured")),
    // .min(6, t('validation.minCharacters'))
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    //   `${t('validation.lowerCase')} , ${t('validation.upperCase')} , ${t(
    //     'validation.specialChar',
    //   )}`,
    // ),
    // .matches(/[a-z]/, t("validation.lowerCase"))
    // .matches(/[A-Z]/, t("validation.upperCase"))
    // .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, t("validation.specialChar")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("validation.confirmPassword")
    ),
    acceptedTerms: Yup.boolean().required().isTrue(),
  });
  const BuyerValidationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("validation.nameLength"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    password: Yup.string().required(t("validation.passwordReqiured")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("validation.confirmPassword")
    ),
    acceptedTerms: Yup.boolean().required().isTrue(),
  });
  const providerValidationSchema = Yup.object({
    companyName: Yup.string()
      .max(25, t("validation.nameLength25"))
      .required(t("validation.companyNameRequired")),
    representativeName: Yup.string().required(
      t("validation.representativeNameRequired")
    ),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    phoneNumber: Yup.string().required(t("validation.phoneRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityID: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
    password: Yup.string().required(t("validation.passwordReqiured")),
    // .min(6, t('validation.minCharacters'))
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    //   `${t('validation.lowerCase')} , ${t('validation.upperCase')} , ${t(
    //     'validation.specialChar',
    //   )}`,
    // ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("validation.confirmPassword")
    ),
    acceptedTerms: Yup.boolean().required().isTrue(),
  });

  useEffect(() => {
    if (props.accountCreated) {
      const { roles } = props.logedInUser;
      const userRole = roles[0];
      if (!props?.modal) {
        if (userRole === "Organizer") props.history.push("/createBazar");
        else props.history.push("/dashBoard/" + userRole);
      }
      console.log(props.logedInUser);
      return () => {};
    }
  }, [props.logedInUser]);
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values, "vvvvvvvv");
    // if (isValidPhoneNumber(values.phoneNumber) === false) {
    //   dispatch(setSnackbar(true, 'error', 'please enter valid number'))
    //   setSubmitting(false)
    // } else {
    switch (role) {
      case "organizer":
        props.submitOrganizerSignUp(values);
        break;
      case "seller":
        props.submitSellerSignUp(values);
        break;
      case "buyer":
        props.submitBuyerSignUp(values);
        break;
      case "serviceProvider":
        props.submitServiceProvider(values);
        break;
      default:
        break;
      // }
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
  };
  if (role === "buyer") {
    delete organizerInitialValues.companyCertificate;
    delete organizerInitialValues.certificateName;
  }
  return (
    <Grid container spacing={2} direction="row" wrap="nowrap">
      {/* <Grid item xs={1} md={2}></Grid> */}
      <Box margin="20px">
        <Box className={!props.modal ? classes.root : classes.wid}>
          <RouterLink to="/">
            <img src="/assets/images/logo/logo.png" width="100%" />
          </RouterLink>
        </Box>
        {
          {
            organizer: (
              <OrganizerSignUpForm
                handleSubmit={handleSubmit}
                initialValues={organizerInitialValues}
                validationSchema={validationSchema}
                role="organizer"
                registeredSuccessfuly={registeredSuccessfuly}
                serverErrors={props.serverErrors}
                title={t("organizerWelcome")}
                img={"organizer.svg"}
              />
            ),
            seller: (
              <OrganizerSignUpForm
                handleSubmit={handleSubmit}
                initialValues={organizerInitialValues}
                role="seller"
                registeredSuccessfuly={registeredSuccessfuly}
                validationSchema={validationSchema}
                serverErrors={props.serverErrors}
                title={t("sellerWelcome")}
                img={"seller.svg"}
              />
            ),
            buyer: (
              <OrganizerSignUpForm
                handleSubmit={handleSubmit}
                initialValues={organizerInitialValues}
                validationSchema={BuyerValidationSchema}
                role="buyer"
                registeredSuccessfuly={registeredSuccessfuly}
                serverErrors={props.serverErrors}
                title={t("buyerWelcome")}
                img={"buyer.svg"}
                modal={props?.modal || false}
              />
            ),
            serviceProvider: (
              <OrganizerSignUpForm
                handleSubmit={handleSubmit}
                initialValues={providerInitialvalues}
                validationSchema={providerValidationSchema}
                role="serviceProvider"
                registeredSuccessfuly={registeredSuccessfuly}
                serverErrors={props.serverErrors}
                title={t("serviceProvider")}
                img={"serviceProvider.svg"}
              />
            ),
          }[role]
        }
      </Box>
      <Grid item sm={2} xs={1}></Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  console.log(state);
  return {
    countries: state.addressReducer.countries,
    serverErrors: state.registerReducer.errors,
    message: state.registerReducer.message,
    accountCreated: state.registerReducer.accountCreated,
    logedInUser: state.registerReducer.logedInUser,
    registerReducer: state.registerReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submitOrganizerSignUp: (organizerInfo) =>
      dispatch(organizerRegister(organizerInfo)),
    submitSellerSignUp: (sellerInfo) => dispatch(sellerRegister(sellerInfo)),
    submitBuyerSignUp: (buyerInfo) => dispatch(buyerRegister(buyerInfo)),
    submitServiceProvider: (serviceProvderInfo) =>
      dispatch(serviceProviderRegister(serviceProvderInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registeration);
