import React from "react";
import "./cards.css";
import Card from "./../BoothCard/boothcard";
import { Container, Avatar, IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ZoomTable from "./../../components/ZoomTable";
import Product from "../BoothCard/CardBooth";
const useStyles = makeStyles((theme) => ({
  zoomcont: {
    display: "flex",
    ["@media (max-width: 1024px)"]: {
      flexDirection: "column-reverse",
    },
  },
}));
const Cards = ({
  zoomLink,
  products,
  name,
  notBuyer,
  flag,
  productCategoryRef,
  sellerAvailableTimes,
  zoomUrl,
  zoomRef,
  handleZoomScroll,
  currentPage,
}) => {
  const classes = useStyles();
  const isLogin = localStorage.token;
  const { t } = useTranslation();
  return (
    <Container className={classes.zoomcont}>
      {zoomLink ? (
        <div
          className="zoomT"
          ref={zoomRef}
          style={sellerAvailableTimes.length ? {} : { display: "none" }}
        >
          <h3>
            {t("boothPage.zoomAvailableTime")}
            <IconButton
              style={{ marginLeft: "10px" }}
              onClick={() =>
                flag === "Active" && zoomUrl ? window.open(zoomUrl) : ""
              }
            >
              <Avatar src="/assets/images/SVG/Zoom.svg" />
            </IconButton>
          </h3>
          <div
            style={{
              minWidth: "18rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ZoomTable sellerAvailableTimes={sellerAvailableTimes} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="cardsContainer" ref={productCategoryRef}>
        {products &&
          products
            .filter((itm) => itm.boothName === name)
            .map((item) => {
              let x =
                item?.productImage?.length > 0
                  ? `${localStorage.imagePath}${item.productImage[0].url}`
                  : "/assets/images/product.png";
              return (
                // <Card
                //   key={item.id}
                //   price={item.price}
                //   nam={item.name}
                //   rate={item.averageRate}
                //   productId={item.id}
                //   srcProductImg={x}
                //   notBuyer={notBuyer}
                //   flag={flag}
                //   isLoggedIn={isLogin}
                //   currentPage={currentPage}
                // />
                <Product
                  key={item.id}
                  offerPrice={item.offerPrice}
                  startOffer={item.startOffer}
                  endOffer={item.endOffer}
                  price={item.price}
                  currency={item.currency}
                  nam={item.name}
                  rate={item.averageRate}
                  productId={item.id}
                  srcProductImg={x}
                  notBuyer={notBuyer}
                  flag={flag}
                  isLoggedIn={isLogin}
                  currentPage={currentPage}
                  boothName={item.boothName}
                  boothLogo={item.boothLogo}
                  boothId={item.boothId}
                />
              );
            })}
      </div>
    </Container>
  );
};

export default Cards;
