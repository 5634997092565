import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  dis: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0",
    width: "20rem",
    ["@media (max-width:780px)"]: {
      margin: "10% 0",
      width: "470px",
    },
    ["@media (max-width:422px)"]: {
      margin: "10% 0",
      width: "260px",
    },
  },
});
const EmptyBazar = ({ handleAddButtonClicked }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.dis}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img src="/assets/images/empty/shop.svg" alt="emptyBazar" />
        </Grid>
        <Grid
          container
          item
          justify="center"
          style={{ margin: "1px 0", fontSize: ".8rem" }}
        >
          <span style={{ textAlign: "center" }}>{t("AddfirstBazar")}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyBazar;
