import React from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import "./style.css";
import { RegisterWithGoogle } from "../../redux/registeration/api";
import { useDispatch } from "react-redux";
import { USER_LOGIN } from "../../redux/actionTypes";
import { useHistory } from "react-router";

const LoginWithGoogle = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responseGoogle = async (response) => {
    console.log(response, "goog");
    let obj = {
      email: response.profileObj.email,
      provider: response.tokenObj.idpId,
      idToken: response.tokenObj.id_token,
    };
    let res = await RegisterWithGoogle(obj);
    debugger;
    dispatch({ type: USER_LOGIN, payload: res });
    history.push("/dashBoard/Buyer");
  };

  return (
    <>
      <GoogleLogin
        clientId="735453657047-6nnl5bmqbnkm365u24baa56s4hkr0lvb.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
        className="google-login"
      />
    </>
  );
};

export default LoginWithGoogle;
