import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import { useTranslation } from "react-i18next";

const PayPalButton = window.paypal?.Buttons.driver("react", {
  React,
  ReactDOM,
});

const PaypalButtons = ({ total, onSuccess, onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  debugger;
  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total.toString(),
          },
        },
      ],
    });
  };

  const onApproveOrder = async (data, actions) => {
    await actions.order.capture();
    onSuccess(data);
  };

  const onUserCancel = (data) => {
    onCancel();
    dispatch(setSnackbar(true, "error", t("messages.paymentCancelled")));
  };

  return (
    <PayPalButton
      createOrder={onCreateOrder}
      onApprove={onApproveOrder}
      onCancel={onUserCancel}
    />
  );
};

PaypalButtons.propTypes = {
  total: PropTypes.string.isRequired,
};

export default PaypalButtons;
