import "bootstrap/dist/css/bootstrap.min.css";
import RelatedCard from "../RelatedCard";
import Product from "./../BoothCard/CardBooth";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const RelatedProductSec = ({ products, currentPage }) => {
  const { t } = useTranslation();
  return (
    <section className="product-details-page">
      <section className="bazars-cards">
        {/* <div className="container"> */}
        <Container>
          <header
            style={
              localStorage.i18nextLng === "en" ? {} : { textAlign: "right" }
            }
          >
            <h3>{t("relatedProducts")}</h3>
          </header>
          <article>
            <div className="row">
              {products.slice(0, 4).map((item, i) => {
                let x =
                  item?.productImage?.length > 0
                    ? `${localStorage.imagePath}${item?.productImage[0]?.url}`
                    : "/assets/images/product.png";
                return (
                  <RelatedCard
                    currentPage={currentPage}
                    key={item.id}
                    price={item.price}
                    currency={item.currency}
                    nam={item.name}
                    rate={item.averageRate}
                    productId={item.id}
                    srcProductImg={x}
                    isLoggedIn={localStorage.token ? true : false}
                    boothName={item.boothName}
                    boothLogo={item.boothLogo}
                    boothId={item.boothId}
                  />
                );
              })}
            </div>
          </article>
          {/* </div> */}
        </Container>
      </section>
    </section>
  );
};

export default RelatedProductSec;
