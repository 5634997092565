export const OrganizerDrawerItems = [
  {
    path: '/dashBoard/Organizer',
    itemName: 'mybazars',
  },
  {
    path: '/organizer/boothRequests',
    itemName: 'boothRequests',
  },
  {
    path: '/organizer/manageOrders',
    itemName: 'manageOrders',
  },
  {
    path: '/Organizer/organizerProductsRequests',
    itemName: 'products',
  },
  // {
  //   path: '/organizer/payments',
  //   itemName: 'Payments',
  // },

  {
    path: '/Organizer/Profile',
    itemName: 'profile',
  },
  // {
  //   path: '/organizer/messages',
  //   itemName: 'Messages',
  // },
  // {
  //   path: '/help',
  //   itemName: 'help',
  // },
]
