import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import * as Yup from "yup";

import {
  Box,
  Avatar,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import ProfileForm from "../../components/ProfileForm/index";
import { phoneRegExp } from "../App/constants";
import UserProfileSection from "../../components/UserProfileSection";
import axios from "axios";
import { logger } from "./../../redux/middlewares/logger";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../utils/setLocalStorage";

const useStyles = makeStyles((theme) => ({
  marginX: { margin: "30px 0" },
}));
const OrganizerProfile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    cityId: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
    companyCertificate: "",
    preCode: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
  });
  const organizerId = localStorage.userId;
  const [countryName, setCountryName] = useState("");
  const [image, setImage] = useState(initialValues.profileImage);
  const token = localStorage.token;
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get("Organizer/GetOrganizerProfile?organizerId=" + organizerId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);

        const { data } = response;

        const profileValues = {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          profileImage: data.profileImage,
          email: data.email,
          countryId: data.country.id,
          countryName:
            localStorage.i18nextLng === "en"
              ? data.country.name
              : data.country.arabicName,
          cityId: data.cityId,
          street: data.street,
          postalCode: data.postalCode,
          phoneNumber: data.phoneNumber,
          whatsNumber: data.whatsNumber,
          companyCertificate: data.companyCertificate,
          certificateName: data.certificateName,
          preCode: data.preCode,
          socialMediaLinks:
            data.socialMediaLinks.length > 0
              ? //   ? data.socialMediaLinks
                //   : [{ url: '', type: 0 }],
                data.socialMediaLinks.map((socialLink) => ({
                  url: socialLink.url,
                  socialMediaType: socialLink.type,
                }))
              : [{ url: "", socialMediaType: 0 }],
        };
        debugger;
        setInitialValues(profileValues);
        setCountryName(
          localStorage.i18nextLng === "en"
            ? data.country.name
            : data.country.arabicName
        );
        setImage(data.profileImage);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          handleLogout();
          window.location.href = "/login";
        }
      });
  }, []);

  const handleProfileImgChange = (event) => {};

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("validation.nameLength"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    // phoneNumber: Yup.string()
    //   .matches(phoneRegExp, t('validation.invalidPhone'))
    //   .required(t('validation.phoneRequired')),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    values.profileImage = image;
    debugger;
    // if (isValidPhoneNumber(values.phoneNumber) === false) {
    //   dispatch(setSnackbar(true, "error", "please enter valid number"));
    // } else {
    axios
      .put(`Organizer/EditOrganizerProfile`, values, {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((response) => {
        console.log(response);
        setSubmitting(false);

        props.history.push("/dashboard/Organizer");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          handleLogout();
          window.location.href = "/login";
        }
      });
    // }
  };
  console.log(localStorage.userRole);
  return (
    <UserProfileSection
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      countryName={countryName}
      handleProfileImgChange={handleProfileImgChange}
      image={image}
      setImage={setImage}
    />
  );
};

export default OrganizerProfile;
