import React, { useState, useEffect } from "react";
import axios from "axios";
import MultiSelect from "../../components/MultiSelect";
import ChipInputt from "../../components/ChipInput";
import { makeStyles, Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ClipboardCopy from "./../CopyTo/index";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: "4%",
    marginLeft: "52%",
    width: "fitcontent",
    ["@media (max-width:780px)"]: {
      marginLeft: "2%",
    },
  },
  dd: {
    minWidth: "30rem",
    maxWidth: "30.2rem",
    ["@media (max-width:320px)"]: {
      minWidth: "205px",
    },
  },
}));
const InviteSeller = ({ inviteID, handleClose }) => {
  console.log(inviteID);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [multiSelect, setMultiSelect] = useState([]);
  const [chipInputt, setChipInputt] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [text, settext] = useState("");
  const classes = useStyles();
  const handleMultiSelect = (e) => {
    // debugger
    console.log(e);
    setMultiSelect(e);
  };
  const handleChipInputt = (e, blur) => {
    // debugger
    // if (blur) {
    //   const newChipInput = [...chipInputt, e]
    //   setChipInputt(newChipInput)
    // } else {
    // }
    setChipInputt(e);
  };
  const handltext = (e) => {
    debugger;
    settext(e.target.value);
  };
  const handleDeleteChip = (chip, i) => {
    console.log(chip, i, "delet");
  };

  const handleSend = () => {
    debugger;
    let multiSelectEmails = multiSelect.map((item) => item.email);
    axios
      .post(`Organizer/InviteSeller`, {
        bazarID: inviteID,
        emails: [...multiSelectEmails, ...chipInputt],
        message: text,
      })
      .then((res) => {
        debugger;
        handleClose();
        console.log(res.response, "invitation");
        dispatch(setSnackbar(true, "success", "your invitation is Done"));
      })
      .catch((err) => {
        debugger;
        console.log(err);
        dispatch(setSnackbar(true, "error", "Fail ! please try again"));
      });
  };
  useEffect(() => {
    axios
      .get(`Organizer/GetAllSeller`)
      .then((response) => {
        setSellers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className={classes.dd}>
      {/* <MultiSelect sellers={sellers} handleChangee={handleMultiSelect} /> */}
      <h3> {t("inviteSeller.Invite")} </h3>
      <p style={{ margin: 0 }}>{t("inviteSeller.inviteCopy")}</p>
      <ClipboardCopy
        copyText={"https://www..ebazarak.com" + "/register/seller"}
      />

      <p>
        {t("or")} {t("inviteSeller.inviteMore")}
      </p>
      <Autocomplete
        // style={{ marginBottom: '0' }}
        multiple
        id="sellersEmails"
        options={sellers}
        getOptionLabel={(option) =>
          option.firstName + " " + option.lastName + " / " + option.cityName
        }
        // defaultValue={[
        //   { id: 1, type: 'shirt' },
        //   { id: 2, type: 'btngan' },
        // ]}
        filterSelectedOptions
        onChange={(e, value) => handleMultiSelect(value)}
        // value={props.value}
        // defaultValue={props.value}
        // onBlur={props.blur}
        getOptionSelected={(option, value) => {
          // console.log(option, value, 'option and value')
          // console.log(JSON.stringify(option) === JSON.stringify(value))
          // return option.id === value.id
          return JSON.stringify(option) === JSON.stringify(value);
        }}
        renderInput={(params) => (
          <TextField
            // onBlur={props.blur}
            // name={props.name}
            {...params}
            variant="outlined"
            label={"Sellers to invite"}
            placeholder={t("inviteSeller.selectToinvite")}
            // error={props.error ? true : false}
            // helperText={props.error}
          />
        )}
        fullWidth
      />
      <ChipInputt
        handleChangee={handleChipInputt}
        handltext={handltext}
        handleDeleteChip={handleDeleteChip}
        settext={settext}
      />
      <Grid item xs={12} md={8} container justify="center">
        <Button
          className={classes.btn}
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSend}
        >
          Send
        </Button>
      </Grid>
    </div>
  );
};
export default InviteSeller;
