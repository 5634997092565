import React from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Box } from "@material-ui/core";

const BazarStatusIndicator = ({ bazarStatus }) => {
  const statusTitle = {
    CommingSoon: { title: "Coming Soon", color: "orange" },
    Finished: { title: "Finshed", color: "gray" },
    Active: { title: "Active", color: "green" },
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={localStorage.i18nextLng === "en" ? {} : { direction: "ltr" }}
    >
      <FiberManualRecordIcon
        // fontSize="small"
        style={{
          color: statusTitle[bazarStatus]?.color,
          marginRight: "5px",
          fontSize: "14px",
        }}
      />
      <span style={{ fontSize: "14px" }}>
        {statusTitle[bazarStatus]?.title}
      </span>
    </Box>
  );
};

export default BazarStatusIndicator;
