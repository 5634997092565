import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { blue, green } from "@material-ui/core/colors";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import { allBazarStatus } from "./../../containers/App/constants";
import {
  differenceInDays,
  format,
  differenceInHours,
  isFuture,
  intervalToDuration,
} from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
// import { Avatar } from '@material-ui/core/Avatar'
import { Avatar } from "@material-ui/core";
import { colors } from "../../styles/commonCss";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withRouter } from "react-router-dom";
import MouseOverPopover from "../PopoverOnHover";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { statusArr } from "./../../containers/App/constants";

import { getTimeBasedOnStatus } from "./../../utils/getTimeBasedOnStatus";

const useStyles = makeStyles((theme) => ({
  numberBadge: {
    width: "22px",
    height: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: "14px",
    color: "white",
    // backgroundColor: (props) => props.color === '',
    // textAlign: 'center',
  },
  iconSpacing: { marginRight: "10px" },
  finished: {
    position: "relative",
    // backgroundColor: 'black',
    // opcaity: 0.5,
    // zIndex: 10000,
  },
  finishedOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    // backgroundColor: '#4d4b4b30',

    // opacity: 0.5,
    color: "white",
    width: "100%",
    height: "100%",
  },
  soonOverlayColor: { backgroundColor: "#00000047" },
  finishedOverlayColor: { backgroundColor: "#4d4b4b30" },
}));
// const statusArr = {
//   Pending: { title: "Pending", color: "orange" },
//   Accepted: { title: "Accepted", color: "#57D953" },
//   Rejected: { title: "rejected", color: "red" },
//   CommingSoon: { title: "comingSoon", color: "red" },
//   Finished: { title: "finished", color: "gray" },
//   Active: { title: "active", color: "#57D953" },
// };
// export const getTimeBasedOnStatus = (status, startTime, endTime) => {
//   const remaining = (date) => {
//     const endDate = endTime
//     const now = new Date()
//     const end = new Date(date)
//     console.log(end)
//     return intervalToDuration({
//       start: now,
//       end: end,
//     })
//   }
//   const EndAfter = () => {
//     return intervalToDuration({
//       start: new Date(startTime),
//       end: new Date(endTime),
//     })
//   }
//   let x = EndAfter()
//   let dur = ''
//   switch (status) {
//     case 'Active':
//       dur = remaining(endTime)
//       console.log(endTime, dur, 'durr')
//       return `End After ${x.days - dur.days} days ${x.hours - dur.hours} h ${
//         x.minutes - dur.minutes
//       } m`
//     case 'CommingSoon':
//       dur = remaining(startTime)
//       // return format(new Date(startTime), 'dd-MM-yyyy')
//       return `${dur.days} days ${dur.hours} h ${dur.minutes} m for start`

//     default:
//       return
//   }
// }
// const statusBackgroundColor = (pendingBoothStatus) => {
//   switch (pendingBoothStatus) {
//     case 'Pending':
//       return colors.mediumOrange
//     case 'Accepted':
//       return '#57D953'
//       case 'Rejected'
//     default:
//       return 'black'
//   }
// }
const SellerBoothCard = (props) => {
  const {
    bazarStatus = "Finished",
    booth,
    generalBoothStatus,
    openShowReasonsModal,
    handleopenConfirmDelete,
  } = props;
  console.log(booth, "status, startTime, endTime, ");
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="outlined"
        style={{
          width: "260px",
          marginLeft: "20px",
          position: "relative",
          borderRadius: "12px",
        }}
      >
        <CardContent className={classes.finished}>
          <div
            style={{
              //   width: '200px',
              height: "160px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
              position: "relative",
            }}
          >
            <img
              src={
                booth.logo
                  ? `${localStorage.imagePath}${booth.logo}`
                  : "/assets/images/Booths/boothLogo.jpg"
              }
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />

            {generalBoothStatus === "Pending" && (
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  // left: '25%',
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: statusArr[booth.status].color,
                  padding: "10px 20px",
                  zIndex: 10,
                }}
              >
                {t("bazarStatus." + statusArr[booth.status].title)}
                {booth.status === "Pending" && (
                  // <div style={{ marginTop: '5px', width: '100px' }}>
                  <MouseOverPopover
                    text={
                      // <div style={{ marginTop: '5px', width: '200px' }}>
                      `${t("Waitingapproval")}`
                      // </div>
                    }
                  >
                    <InfoOutlinedIcon />
                  </MouseOverPopover>
                  // </div>
                )}
              </div>
            )}
            {generalBoothStatus !== "Pending" ? (
              bazarStatus === "Active" || bazarStatus === "Finished" ? (
                <div
                  style={{
                    position: "absolute",
                    top: "42.5%",
                    // left: '25%',
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: statusArr[bazarStatus].color,
                    padding: "10px 40px",
                    zIndex: 10,
                  }}
                >
                  {t("bazarStatus." + statusArr[bazarStatus].title)}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {bazarStatus === "Finished" && generalBoothStatus !== "Pending" && (
              <div
                status={bazarStatus}
                className={`${classes.finishedOverlay} ${classes.finishedOverlayColor}`}
              >
                {/* <Box
                  mt="37%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  bgcolor="secondary"
                  style={{
                    fontSize: '20px',
                    backgroundColor: 'gray',
                    padding: '10px 30px',
                  }}
                >
                  <Typography>{t('bazarStatus.finished')}</Typography>
                </Box> */}
              </div>
            )}
            {bazarStatus === "CommingSoon" && generalBoothStatus !== "Pending" && (
              <div
                className={`${classes.finishedOverlay} ${classes.soonOverlayColor}`}
              >
                <Box
                  mt="30%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <div
                    style={{
                      backgroundColor: "red",
                      padding: "1px 5px",
                      fontSize: "14px",
                    }}
                  >
                    {t("bazarStatus.coming")}
                  </div>
                  <Typography style={{ fontSize: "20px" }}>
                    {t("bazarStatus.soon")}
                  </Typography>
                </Box>
              </div>
            )}
          </div>
          {console.log(bazarStatus, "&&", generalBoothStatus)}
          {/* {bazarStatus === "Active" && generalBoothStatus !== "Pending" && (
            <Box display="flex">
              <Box mr={2} display="flex" alignItems="center">
                <Box
                  //   color={green[500]}
                  bgcolor={green[500]}
                  className={classes.numberBadge}
                  mr={1}
                >
                  {booth?.numOfOrders}
                </Box>
                <div>{t("order.orders")}</div>
              </Box>
              <Box mr={2} display="flex" alignItems="center">
                <Box
                  //   color={green[500]}
                  bgcolor={blue[500]}
                  className={classes.numberBadge}
                  mr={1}
                >
                  {booth.earning}
                </Box>
                <div>{t("earning")}</div>
              </Box>
            </Box>
          )} */}
        </CardContent>
        <Divider light />
        <div style={{ padding: " 10px" }}>
          <Box display={"flex"} justifyContent="center" alignItems="center">
            <Typography
              style={{
                fontWeight: "550",
                marginBottom: "10px",
                // fontSize: '1.2rem',
                textAlign: "center",
              }}
            >
              {booth.name}
              {console.log(booth, "boothh")}
            </Typography>
            {
              // generalBoothStatus !== "Pending" &&
              bazarStatus === "CommingSoon" &&
                (booth.bazarPaymentPlan === "Commission" ||
                  booth.bazarFlatFeePlan === 0) &&
                booth.status !== "Rejected" && (
                  <IconButton
                    style={{
                      display: "flex",
                      justifySelf: "flex-end",
                      marginTop: "-8px",
                      marginLeft: "10px",
                      // alignSelf: 'flex-end',
                      textAlign: "end",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleopenConfirmDelete(booth.id);
                    }}
                  >
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                )
            }
            {booth.status === "Rejected" && (
              <IconButton
                style={{
                  display: "flex",
                  justifySelf: "flex-end",
                  marginTop: "-8px",
                  marginLeft: "10px",
                  // alignSelf: 'flex-end',
                  textAlign: "end",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleopenConfirmDelete(booth.id);
                }}
              >
                <DeleteOutlineIcon color="error" />
              </IconButton>
            )}
          </Box>
          <Box display="flex" alignItems="center" mb="10px">
            <Avatar
              sizes="lg"
              src={
                booth.bazarLogo
                  ? localStorage.imagePath + booth.bazarLogo
                  : "/assets/images/Logo/bazar_logo.png"
              }
            />
            <Typography style={{ margin: "0 10px" }}>
              {booth.bazarName}
            </Typography>
          </Box>
          {generalBoothStatus !== "Pending" && (
            <>
              <Box display={"flex"}>
                <UpdateOutlinedIcon className={classes.iconSpacing} />

                {bazarStatus === "Finished" ? (
                  <div>{t("bazarStatus.finished")}</div>
                ) : booth?.startOfBazar ? (
                  <div>
                    {bazarStatus === "Active" && (
                      <>
                        {t("endAt") + ":  "}
                        {format(new Date(booth?.endOfBazar), "dd-MM-yyyy")}
                        {/* <span style={{ margin: '0 8px' }}> */}
                        {/* {getTimeBasedOnStatus(
                          bazarStatus,
                          booth?.startOfBazar,
                          booth?.endOfBazar
                        )} */}
                        {/* </span> {t("days")} */}
                      </>
                    )}
                    {bazarStatus === "CommingSoon" && (
                      <>
                        {/* <span style={{ margin: "0 8px" }}>
                          {getTimeBasedOnStatus(
                            bazarStatus,
                            booth?.startOfBazar,
                            booth?.endOfBazar
                          )}
                        </span>
                        {t("daysForStart")} */}
                        {t("startAt") + ":  "}
                        {format(new Date(booth?.startOfBazar), "dd-MM-yyyy")}
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
          {generalBoothStatus !== "Pending" && (
            <Box display={"flex"}>
              <LocationOnOutlinedIcon className={classes.iconSpacing} />
              <div>{booth?.boothCity}</div>
            </Box>
          )}
          {booth.status === "Rejected" && (
            <Button
              variant="outlined"
              color="primary"
              style={{ borderRadius: "20px" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (booth.declinedescription)
                  booth.declinedReason =
                    booth.declinedReason + " : " + booth.declineDescription;
                openShowReasonsModal(booth.declinedReason.split(","));
              }}
            >
              {console.log(booth, "rr")}
              Show Reasons
            </Button>
          )}
          {booth.status === "Accepted" && generalBoothStatus === "Pending" && (
            <Button
              variant="outlined"
              color="primary"
              style={{ borderRadius: "20px" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.history.push("/Seller/payments");
              }}
            >
              Payment Step
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default withRouter(SellerBoothCard);
