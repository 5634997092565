import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  dis: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "5% 0",
    width: "45rem",
    ["@media (max-width:780px)"]: {
      margin: "10% 0",
      width: "470px",
    },
    ["@media (max-width:422px)"]: {
      margin: "10% 0",
      width: "260px",
    },
  },
});
const EmptyDriver = ({ handleAddButtonClicked }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.dis}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img src="/assets/images/empty/5227.svg" alt="emptyDriver" />
        </Grid>
        <Grid
          container
          item
          justify="center"
          style={{ margin: "1% 0", fontSize: ".8rem" }}
        >
          <span style={{ textAlign: "center" }}>{t("AddfirstDriver")}</span>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          style={{
            margin: "1% 0%",
            cursor: "pointer",
            color: "orange",
            fontSize: "1rem",
          }}
          onClick={handleAddButtonClicked}
        >
          <Button color="primary" variant="contained">
            {t("buttons.addNewDriver")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyDriver;
