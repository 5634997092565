///////////////////// REGISTERATION ////////////////
export const Organizer_REGISTER = "Organizer_REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const SELLER_REGISTER = "SELLER_REGISTER";
export const BUYER_REGISTER = "BUYER_REGISTER";
export const PROVIDER_REGISTER = "PROVIDER_REGISTER";

export const USER_LOGIN = "USER_LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GET_COUNTRIES = "GET_COUNTRIES";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";

//////////////////////// CREATE BAZAR ///////////////////
export const CREATE_BAZAR = "CREATE_BAZAR";
export const CREATE_BAZAR_ERROR = "CREATE_BAZAR_ERROR";
export const ADD_PAYMENT_PLAN_TO_BAZAR = "ADD_PAYMENT_PLAN_TO_BAZAR";
export const ADD_PROVIDER_TO_BAZAR = "ADD_PROVIDER_TO_BAZAR";
export const ADD_PACKAGE_TO_BAZAR = "ADD_PACKAGE_TO_BAZAR";

///////////////////////// BAZAR ///////////////////////
export const GET_BAZAR_BY_ID = "GET_BAZAR_BY_ID";
export const GET_ALL_ORGANIZER_BAZARS = "GET_ALL_ORGANIZER_BAZARS";
export const DELETE_BAZAR = "DELETE_BAZAR";
export const EDIT_BAZAR_SETTING = "EDIT_BAZAR_SETTING";
export const FILTER_BAZAR_ON_STATUS = "FILTER_BAZAR_ON_STATUS";
export const GET_BAZAR_HOME = "GET_BAZAR_HOME";

export const GET_BAZAR_BOOTHS = "GET_BAZAR_BOOTHS";
export const GET_All_APP_BAZARS = "GET_All_APP_BAZARS";
export const GET_All_COMING_SOON_BAZARS = "GET_All_COMING_SOON_BAZARS";
export const GET_All_BUYER_BAZARS = "GET_All_BUYER_BAZARS";

export const RESET_BAZAR = "RESET_BAZAR";

export const GET_BAZAR_CATEGORIES = "GET_BAZAR_CATEGORIES";

export const UPLOAD_BAZAR_AVATAR_IMG = "UPLOAD_BAZAR_AVATAR_IMG";
export const UPLOAD_BAZAR_COVER_IMG = "UPLOAD_BAZAR_COVER_IMG";

export const LOADING_HOME_PAGE_BAZARS = "LOADING_HOME_PAGE_BAZARS";

////////////////////// SERVICE PROVIDER ///////////////////
export const GET_ALL_SERVICE_PROVIDERS = "GET_ALL_SERVICE_PROVIDERS";
export const GET_ALL_SERVICE_PROVIDERS_BY_BAZARID =
  "GET_ALL_SERVICE_PROVIDERS_BY_BAZARID";
export const GET_PROVIDER_BY_ID = "GET_PROVIDER_BY_ID";
export const GET_PROVIDER_BAZARS = "GET_PROVIDER_BAZARS";
export const GET_PROVIDER_BAZARS_REQUEST = "GET_PROVIDER_BAZARS_REQUEST";
export const ACCEPT_PROVIDER_BAZAR_REQUEST = "ACCEPT_PROVIDER_BAZAR_REQUEST";
export const DECLINE_PROVIDER_BAZAR_REQUEST = "DECLINE_PROVIDER_BAZAR_REQUEST";

////////////////////// SELLER ///////////////////////////
export const GET_SELLER_BY_ID = "GET_SELLER_BY_ID";
export const EDIT_PROVIDER_PROFILE = "EDIT_PROVIDER_PROFILE";

export const GET_SELLER_BOOTHS = "GET_SELLER_BOOTHS";
export const LOADING_SELLER_BOOTHS = "LOADING_SELLER_BOOTHS";
export const GET_SELLER_PENDING_BOOTHS = "GET_SELLER_PENDING_BOOTHS";
export const EDIT_SELLER_PROFILE = "EDIT_SELLER_PROFILE";
export const DELETE_SELLER_PENDING_BOOTH = "DELETE_SELLER_PENDING_BOOTH";
export const LOADING_SELLER_PENDING_BOOTH = "LOADING_SELLER_PENDING_BOOTH";

/////////////////////// USER /////////////////////////
export const GET_ORGANIZER_PROFILE = "GET_ORHANIZER_PROFILE";

/////////////////////////// ORGANIZER //////////////////

export const GET_ORGANIZER_BOOTH_REQUESTS = "GET_ORGANIZER_BOOTH_REQUESTS";
export const ACCEPT_BOOTH_REQUEST = "ACCEPT_BOOTH_REQUEST";
export const DECLINE_BOOTH_REQUEST = "DECLINE_BOOTH_REQUEST";
export const LOADING_ORGANIZER_BAZARS = "LOADING_ORGANIZER_BAZARS";

/////////////////////////////////////////////////////////////////
export const SET_SNACKBAR = "SET_SNACKBAR";
////////////////////////////////////BOOTH ////////////////////////////

export const GET_BOOTH_HOME = "GET_BOOTH_HOME";
export const GET_BOOTH_PRODUCTS = "GET_BOOTH_PRODUCTS";
export const UPLOAD_AVATAR_IMG = "UPLOAD_AVATAR_IMG";
export const UPLOAD_COVER_IMG = "UPLOAD_COVER_IMG";
export const FILTER_BOOTHS_ON_STATUS = "FILTER_BOOTHS_ON_STATUS";

export const RESET_BOOTH_HOME = "RESET_BOOTH_HOME";
///////////////////////////////////cart//////////////////////
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CHANGE_CART_PRODUCT_QUANTITY = "CHANGE_CART_PRODUCT_QUANTITY";
