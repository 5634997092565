import React from "react";
import BazarCardBuyer from "./../BazarCardBuyer";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const BazarCardsBuyer = ({
  boothes,
  CategoryRef,
  description,
  modalView,
  currentPage,
}) => {
  return (
    // <Container>
    <Grid
      item
      container
      spacing={3}
      // spacing={{ sm: 2, md: 4 }}
      // justify={['center', 'flex-end']}
      alignItems="center"
      align="center"
      justify="flex-start"
      wrap="wrap"
      // justifyContent="center"
      // justify={{ xs: 'center', md: 'flex-start' }}
      // justify="flex-start"
      direction="row"
      style={{
        // margin: '0 auto',
        marginBottom: "80px",
        marginTop: "20px",
        justifyContent: "center",
      }}
      ref={CategoryRef}
    >
      {boothes &&
        boothes.map((item, i) => {
          // debugger
          console.log(boothes, "boothes");
          return (
            <Grid
              key={i}
              item
              // wrap={'wrap'}
              // style={{ margin: '3%' }}
              // xs={12}
              // md={6}
              // lg={4}
              xs={12}
              sm={5}
              md={4}
              lg={3}
              // spacing={3}
              container
              justify="center"
              style={modalView ? { margin: "0 1rem" } : {}}
              // justify="flex-start"
              // direction="row"
            >
              {modalView ? (
                <BazarCardBuyer
                  name={item.name}
                  // country={item.boothCountry}
                  city={item.boothCity}
                  coverImage={item.coverImage}
                  boothLogo={item.logo}
                  modalView={modalView}
                />
              ) : (
                // <Link to={`/buyer/boothPage/${item.id}/` + currentPage}>
                <Link
                  to={
                    `/buyer/boothPage/${item.id}/` +
                    localStorage.getItem("boothPage")
                  }
                >
                  <BazarCardBuyer
                    name={item.name}
                    // country={item.boothCountry}
                    city={item.boothCity}
                    coverImage={item.coverImage}
                    boothLogo={item.logo}
                  />
                  {console.log(item, "iittmm")}
                </Link>
              )}
            </Grid>
          );
        })}
    </Grid>
    // </Container>
  );
};

export default BazarCardsBuyer;
