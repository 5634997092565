import axios from 'axios'

export const uploadImageAsync = async (image) => {
  const response = await axios.post('File/Upload/Seller', image, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  debugger
  // let path = response.data[0].path + 'Seller/' + response.data[0].newFileName
  // path = path.split('\\').join('/')
  // console.log(response.data[0].path + response.data[0].newFileName)
  console.log(response.data[0].newFileName)
  return response.data[0].newFileName
  // response.data[0].path + response.data[0].newFileName
}
