import React from "react";
import MaterialTable from "material-table";
import tableIcons from "../../styles/materialTableIcons";

export default function ControlledAppTable({
  data,
  columns,
  handlePageChange,
  handleSearchChange,
  title,
  handlePageSizeChange,
  pageSize,
  count,
  page,
  localization,
  search = true,
  sorting = false,
  ...rest
}) {
  return (
    <MaterialTable
      style={{ padding: "20px 0", overflowX: "scroll" }}
      {...rest}
      title={`${title} (${count})`}
      icons={tableIcons}
      columns={columns}
      data={data}
      onChangePage={handlePageChange}
      onSearchChange={handleSearchChange}
      onChangeRowsPerPage={handlePageSizeChange}
      onChangePage={handlePageChange}
      page={page}
      totalCount={count}
      localization={localization}
      options={{
        search: search,
        paginationType: "stepped",
        pageSize: pageSize,
        headerStyle: { backgroundColor: "#f1f1f1", textAlign: "center" },
        rowStyle: { textAlign: "center" },
        pageSizeOptions: [10],
        // showTitle: false,
        sorting: sorting,
      }}
    />
  );
}
