import { Organizer_REGISTER, REGISTER_ERROR } from '../actionTypes'
const initialState = {
  accountCreated: false,
  message: '',
  errors: [],
  logedInUser: {},
}
const registerReducer = (state = initialState, action) => {
  let logedInUser = { ...state.logedInUser }
  logedInUser = action.logedInUser
  switch (action.type) {
    case Organizer_REGISTER:
      localStorage.setItem('step', 0)
      return {
        ...state,
        message: action.message,
        accountCreated: true,
        logedInUser,
        errors: [],
      }
    case REGISTER_ERROR:
      debugger
      return {
        ...state,
        errors: action.payload || ['Sorry , Server Error'],
        message: '',
        accountCreated: false,
      }
    default:
      return state
  }
}
export default registerReducer
