import socialMedia from '../../enums/socialMedia'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { YouTube } from '@material-ui/icons'

export const socialMediaArray = [
  {
    value: socialMedia.facebook,
    icon: <FacebookIcon style={{ color: 'blue' }} />,
    type: 'Facebook',
  },
  {
    value: socialMedia.twitter,
    icon: <TwitterIcon style={{ color: '#00acee	' }} />,
  },
  {
    value: socialMedia.instagram,
    icon: <InstagramIcon style={{ color: '#fb3958	' }} />,
  },
  {
    value: socialMedia.linkedIn,
    icon: <LinkedInIcon style={{ color: '#0e76a8	' }} />,
  },
  {
    value: socialMedia.youtube,
    icon: <YouTube style={{ color: 'red	' }} />,
  },
]
export const statusArr = {
  Pending: { title: 'pending', color: 'orange' },
  Accepted: { title: 'accepted', color: '#57D953' },
  Rejected: { title: 'rejected', color: 'red' },
  CommingSoon: { title: 'comingSoon', color: 'red' },
  Finished: { title: 'finished', color: 'gray' },
  Active: { title: 'active', color: '#57D953' },
  Hybrid: { title: 'Hybrid', color: 'yellow' },
}

export const orderStatusIndicator = {
  Accepted: { title: 'accepted', color: 'green' },
  Rejected: { title: 'rejected', color: 'red' },
  Draft: { title: 'draft', color: 'gray' },
  Pending: { title: 'pending', color: '#18b8b8fa' },
  Complete: { title: 'complete', color: 'green' },
  Processing: { title: 'processing', color: '#ecec3b' },
  Canceled: { title: 'cancelled', color: 'red' },
  Shipped: { title: 'shipped', color: '' },
  ReadyToShip: { title: 'readyToShip', color: '' },
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const allBazarStatus = {
  CommingSoon: { title: 'bazarStatus.comingSoon', color: 'green' },
  Finished: { title: 'bazarStatus.finished', color: 'gray' },
  Active: { title: 'bazarStatus.active', color: 'orange' },
}
