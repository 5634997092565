import "bootstrap/dist/css/bootstrap.min.css";
import "./newSec.css";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import BazarSocailMediaIcons from "../BazarSocialMediaIcons";
import FBShareButton from "../FBShareButton";
import { withRouter } from "react-router-dom";
import ClipboardCopy from "../CopyTo";
import { Typography } from "@material-ui/core";
// import {
//   Card,
//   CardContent,
//   CardActions,
//   Typography,
//   IconButton,
//   Divider,
// } from "@material-ui/core";
// import useStyles from "./../BoothCard/styles";
import BazarStatusIndicator from "./../BazarStatusIndicator/index";

// const NewHeaderSes = () => {
//   const classes = useStyles();
//   return (
//     <section className={classes.newSec}>
//       <Card style={{ maxWidth: "85%" }}>
//         <div className={classes.newcard}>
//           <CardContent>
//             <img
//               className="cardd-img"
//               src="/assets/images/bazar/bazar-img.png"
//               alt="cardd-img"
//             />
//           </CardContent>
//           <CardContent>
//             <div className="cardd-body">
//               <h5 className="cardd-title">بازار فنان حيصير مشهور</h5>
//               <p>
//                 <img
//                   src="/assets/images/icons/calendar.png"
//                   width="18px"
//                   alt=""
//
//                   className={classes.marg}
//                 />
//                 <span>20 Nov 2021 </span>
//                 <strong>To </strong>
//                 <span>20 Dec 2021</span>
//               </p>
//               <Typography style={{ maxWidth: "80%" }}>
//                 مجموعة فريدة من الفنون والأعمال اليدوية والهوايات والتصاميم
//                 المبتكرة وإبداعات مميزة...وأكثر ستجد حتماً هدية مميزة لمن تحب
//                 ولنفسك أو شيئا مميز"ا يناسب مناسبتك القادمة
//               </Typography>
//             </div>
//           </CardContent>
//           <Divider
//             orientation="vertical"
//             variant="middle"
//             flexItem
//             style={{ margin: "3rem 0" }}
//           />
//           <CardContent className={classes.tablecard}>
//             <div className="more-details">
//               <table>
//                 <tbody>
//                   <tr style={{ display: "flex", justifyContent: "flex-start" }}>
//                     <th scope="row">Location:</th>
//                     <td>Jordan</td>
//                     <th scope="row" className={classes.margF}>
//                       Organizer:
//                     </th>
//                     <td>مي فريح</td>
//                   </tr>
//                   <tr
//                     style={{
//                       display: "flex",
//                       justifyContent: "flex-start",
//                       margin: "1rem 0",
//                     }}
//                   >
//                     <th scope="row">Type:</th>
//                     <td>Hybrid</td>
//                     <th scope="row" className={classes.marg2F}>
//                       Email:
//                     </th>
//                     <td>Sa_sam8@yahoo.com</td>
//                   </tr>
//                   <tr
//                     style={{
//                       display: "flex",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <th scope="row">Shops:</th>
//                     <td>115</td>
//                     <th scope="row" className={classes.marg2F}>
//                       Phone No:
//                     </th>
//                     <td>+962 7 7212 8150</td>
//                   </tr>
//                 </tbody>
//               </table>
//               <p>
//                 <a href="">
//                   <img
//                     src="/assets/images/bazar/fb.png"
//                     width="25px"
//                     alt=""
//
//                     className={classes.marg}
//                   />
//                 </a>
//                 <a className="mx-2" href="">
//                   <img
//                     src="/assets/images/bazar/fb-share.png"
//                     width="90px"
//                     alt=""
//
//                   />
//                 </a>
//               </p>
//             </div>
//           </CardContent>
//         </div>
//       </Card>
//     </section>
//   );
// };

// export default NewHeaderSes;

{
  /* <BazarSec
bazarNam={bazarHome?.name}
// OrganizerNam={
//   bazarHome.organizer.firstName + ' ' + bazarHome.organizer.lastName
// }
organizer={bazarHome?.organizer}
country={
  localStorage.i18nextLng === "en"
    ? bazarHome?.county?.name
    : bazarHome?.county?.arabicName
}
numofshops={bazarHome?.shopNo}
// description={bazarHome.description}
srcLogo={
  bazarHome?.imageProfile
    ? `${localStorage.imagePath}${bazarHome.imageProfile}`
    : logoimg
}
imglogo={bazarHome?.imageProfile ? true : false}
socialMediaLinks={bazarHome?.socialMediaLinks}
status={bazarHome?.status}
bazarStatus={bazarHome?.bazarStatus}
/>  
<Container>
   <div style={{ marginBottom: "50px" }}>{bazarHome?.description}</div>
</Container>*/
}

const HeaderAli = ({
  bazarNam,
  organizer,
  country,
  numofshops,
  description,
  srcLogo,
  socialMediaLinks,
  status,
  location,
  imglogo,
  bazarStatus,
  srcCover,
  start,
  end,
  viewsN,
  disp,
  dispNone,
  open,
}) => {
  const { t } = useTranslation();
  console.log(location, "loc", viewsN);
  return (
    <section
      className={open ? `bazar-page ${disp}` : `bazar-page ${disp} ${dispNone}`}
    >
      <section className="bazar-info">
        <div className="container">
          <div className="bazar-card">
            <div className="row">
              <div
                className="col-lg-6 my-3"
                // style={{ display: "flex", alignItems: "center" }}
              >
                <div className="card">
                  <div className="row no-gutters align-items-center">
                    <div className="col-md-4">
                      <img
                        className="card-img"
                        src={srcCover}
                        style={{ height: "200px" }}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{bazarNam}</h5>
                        <BazarStatusIndicator bazarStatus={status} />
                        <p>
                          <img
                            src="/assets/images/icons/calendar.png"
                            width="18px"
                            alt=""
                          />
                          {start ? (
                            <span
                              className={
                                localStorage.i18nextLng === "en"
                                  ? "ml-2"
                                  : "mr-2"
                              }
                            >
                              {format(new Date(start + "Z"), "dd-MM-yyyy")}
                            </span>
                          ) : (
                            ""
                          )}
                          <strong> {t("to")} </strong>
                          {end ? (
                            <span>
                              {format(new Date(end + "Z"), "dd-MM-yyyy")}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  localStorage.i18nextLng === "en"
                    ? "col-lg-6 my-3"
                    : `bazarAR col-lg-6 my-3`
                }
              >
                <div className="more-details">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tbody>
                        <tr style={{ textAlign: "start" }}>
                          <th scope="row">{t("location")}:</th>
                          <td>{country}</td>
                          <th scope="row">{t("roles.Organizer")}:</th>
                          <td>
                            {organizer.firstName + " " + organizer.lastName}
                          </td>
                        </tr>
                        <tr style={{ textAlign: "start" }}>
                          <th scope="row">{t("type")}:</th>
                          <td>{bazarStatus}</td>
                          <th scope="row">{t("signUp.emaill")}:</th>
                          <td> {organizer.email}</td>
                        </tr>
                        <tr style={{ textAlign: "start" }}>
                          <th scope="row">{t("shops")}:</th>
                          <td> {numofshops} </td>
                          <th scope="row" style={{ color: "#F8B334" }}>
                            {t("viewsN")}:
                          </th>
                          <td style={{ color: "#F8B334" }}> {viewsN} </td>
                          {/* <th scope="row">{t("phone")}:</th>
                          <td style={{ direction: "ltr" }}>
                            {organizer.phoneNumber}
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    style={
                      localStorage.i18nextLng === "en"
                        ? {}
                        : { textAlign: "right" }
                    }
                  >
                    {t("orderViaWats")}
                    <span style={{ color: "orange", direction: "ltr" }}>
                      {t("+966 56 121 1068")}
                    </span>
                  </Typography>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {socialMediaLinks.length ? (
                      <>
                        <BazarSocailMediaIcons
                          socialMediaLinks={socialMediaLinks}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <FBShareButton
                      url={
                        "https://www..ebazarak.com" +
                        location?.pathname
                      }
                    />
                    <ClipboardCopy
                      copyText={
                        "https://www..ebazarak.com" +
                        location?.pathname
                      }
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default withRouter(HeaderAli);
