import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { getOrganizerProfile } from "./../../redux/organizer/actions";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";

import { addDays, convertDateToIsoFormat } from "../../utils/dateUtils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import StepOneBasicInfo from "./../../components/CreateBazar/StepOneBasicInfo/index";
import StepTwoPaymentPlan from "./../../components/CreateBazar/StepTwoPaymentPlane/index";
import StepThreeDeliveryAndShipment from "./../../components/CreateBazar/StepThreeDeliveryAndShipment/index";
import StepFourChoosePackage from "../../components/CreateBazar/StepFourChoosePackage";
import paymentEnum from "../../enums/payment";
import packagesEnum from "../../enums/Package";
import * as actions from "../../redux/bazar/actions";
import StepFivePayment from "./../../components/CreateBazar/StepFivePayment/index";
import { RESET_BAZAR } from "../../redux/actionTypes";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { useParams } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    flexGrow: 1,
    backgroundColor: "#fff",
    height: "20px",
    paddingLeft: "0px",
    "& div": {
      height: "12px",
      borderRadius: "20px",
      width: "100%",
      paddingLeft: 0,
      // backgroundColor: theme.palette.primary.dark,
    },
  },
  progress: {
    width: "100%",
    height: "12px",
    borderRadius: "10px",
  },
  progress: {
    width: "100%",
    height: "12px",
    borderRadius: "10px",
  },
  arrowDirection: {
    transform: (props) => props.currentLang === "ar" && "rotate(180deg)",
  },
  nextArrow: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    // fontSize: '30px',
  },
}));

const CreateBazar = (props) => {
  const currentLang = localStorage.i18nextLng;
  const classes = useStyles({ currentLang });
  const { t } = useTranslation();
  const userId = localStorage.userId;

  const stepsText = {
    0: t("createBazar.Step1BasicInfo"),
    1: t("createBazar.Step2"),
    2: t("createBazar.Step3"),
    3: t("createBazar.Step4"),
    4: t("createBazar.Step5"),
  };
  // const theme = useTheme()
  // const [bazarCreated, setBazarCreated] = useState(false)
  // const [bazarId, setBazarId] = useState(localStorage.getItem('bazarId') || '')
  let [bazarCreatedFlag, setbazarCreatedFlag] = useState(false);

  const [paymentError, setPaymentError] = useState({
    errorExist: false,
    msg: "",
  });
  const [activeStep, setActiveStep] = useState(+localStorage.step);
  const editedBazarId = props.match.params.id;
  console.log(editedBazarId);
  ////////////////////////////// STEP 2 ///////////////////////////
  const [paymentValue, setpaymentValue] = React.useState({
    flatFee: "",
    commission: "",
  });
  console.log(props.bazarInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizerProfile(userId));
  }, []);
  useEffect(() => {
    if (editedBazarId) {
      // GET BAZAR BY ID
      localStorage.setItem("bazarId", editedBazarId);
      props.getBazarById(editedBazarId);
    }
  }, []);
  useEffect(() => {
    console.log(props.bazarInfo, props.bazarInfo?.currancy, "bazarInfo");
    setpaymentValue({
      flatFee: props.bazarInfo.flatFeePlan,
      commission: props.bazarInfo.commission,
    });
  }, [props.bazarInfo]);
  let idd = useParams().id;
  console.log(idd, "idd");
  useEffect(() => {
    if (props.bazarCreated) {
      console.log("ya mosheel");
      // setBazarCreated(true)
      // setBazarId(props.bazarId)
      setbazarCreatedFlag(true);
      // bazarCreatedFlag = true
      localStorage.setItem("bazarId", props.bazarId || idd);
      handleNext();
      // setTimeout(() => {
      //   // setBazarCreated(false)
      // }, 3000)
    }
  }, [props.bazarId || idd]);
  useEffect(() => {
    return () => {
      console.log("component unMounted");
      localStorage.step = 0;
      props.resetBazar();
    };
  }, []);
  useEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    // console.log(pathName)
  }, [activeStep]);

  const handleNext = () => {
    console.log(activeStep);

    console.log(props.packageId);
    // console.log(props.packageId)
    debugger;
    if (activeStep === 4) {
      props.resetBazar();
      props.history.push("/dashBoard/Organizer");
    } else if (
      (activeStep === 3 && props.bazarInfo.packagePlan === "FreePlan") ||
      (activeStep === 3 && props.bazarInfo.isPaid === true)
    ) {
      debugger;
      console.log("to dashBorad");
      props.resetBazar();

      props.history.push("/dashBoard/Organizer");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // debugger
    props.getBazarById(localStorage.bazarId || idd);
  };
  //////////////////////////////////////// STEP1 CREATE BAZAR SUBMIT ///////////////////
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    debugger;
    console.log(values, "values");
    // const bazarDuration = +values.bazarDuration;
    // const endDate = addDays(values.startDate, bazarDuration);

    values.startDate = convertDateToIsoFormat(values.startDate);
    values.endDate = convertDateToIsoFormat(values.endDate);

    const categoriesIds = values.categories.map((cat) => cat.id);
    values.categories = categoriesIds;
    console.log("finalValues", values);
    // debugger
    if (editedBazarId || bazarCreatedFlag) {
      // debugger
      const editBazarInfo = { ...values };
      editBazarInfo.bazarId = editedBazarId || localStorage.bazarId || idd;
      console.log(editBazarInfo);

      props.editBazar(editBazarInfo);
      setTimeout(() => {
        handleNext();
        // setBazarCreated(false)
      }, 3000);
    } else {
      props.createBazar(values);
    }

    setTimeout(() => {
      setSubmitting(false);
      // handleNext()
    }, 3000);
  };
  /////////////////////////////////////// STEP 2 SAVE SELECTED PAYMENT PLAN ////////////
  const PaymentErrorhandler = (paymentType) => {
    const x = { ...paymentError };
    x.errorExist = true;
    x.msg = `you should Enter ${paymentType} Value`;
    setPaymentError(x);
    setTimeout(() => {
      const x = { ...paymentError };
      x.errorExist = false;
      x.msg = "";
      setPaymentError(x);
    }, 4000);
  };
  const paymentPlanInputHandler = (e) => {
    const newPaymentValue = { ...paymentValue };
    newPaymentValue[e.target.name] = e.target.value;
    console.log(newPaymentValue);
    setpaymentValue(newPaymentValue);
  };

  const savePaymentPlanSelected = (paymentType) => {
    console.log("saved");
    let paymentPlanSubmited = {
      paymentPlan: 0,
      flatFeePlan: 0,
      commission: 0,
    };
    // debugger
    let error = false;
    switch (paymentType) {
      case "FlatFee":
        if (paymentValue.flatFee === "") {
          error = true;
          PaymentErrorhandler("Flat Fee");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.flatFee,
          flatFeePlan: +paymentValue.flatFee,
          commission: 0,
        };
        debugger;
        break;
      case "Commission":
        if (paymentValue.commission === "") {
          error = true;
          PaymentErrorhandler("Commision");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.commission,
          commission: +paymentValue.commission,
          flatFeePlan: 0,
        };
        break;
      case "FlatFeesWithCommission":
        if (paymentValue.commission === "" || paymentValue.flatFee === "") {
          error = true;
          PaymentErrorhandler("flatFee And Commission");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.flatFeeAndCommission,
          commission: +paymentValue.commission,
          flatFeePlan: +paymentValue.flatFee,
        };
        break;

      default:
        break;
    }
    paymentPlanSubmited.bazarId = +localStorage.bazarId;
    console.log(paymentPlanSubmited);

    error
      ? console.log("errorrrrrrrr")
      : props.addPaymentPlan(paymentPlanSubmited, paymentType);
  };
  console.log(bazarCreatedFlag);
  console.log(activeStep);
  localStorage.setItem("step", +activeStep);
  return (
    <>
      {console.log("props.organizerProfile", props.organizerProfile)}
      <Header currentUser={props.organizerProfile} />
      <Container
        fixed
        style={{
          marginTop: "120px",
          // marginBottom: '100px',
          justifyContent: "center",
          height: activeStep !== 4 && "100vh",
        }}
      >
        <Grid container vvvvvvvvv>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  {t("createBazar.CreateYourBazarIn5Steps")}
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={false} md={6}></Grid>
              </Hidden>
              <Grid item xs={12} lg={6}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {stepsText[activeStep]}
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={false} md={6}></Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <MobileStepper
                  variant="progress"
                  steps={5}
                  position="static"
                  activeStep={activeStep}
                  className={classes.root}
                  width={100}
                />
              </Grid>

              {
                {
                  0: (
                    <>
                      <StepOneBasicInfo
                        handleSubmit={handleSubmit}
                        bazarInfo={props.bazarInfo}
                      />
                      {/* {bazarCreated && (
                      <Message
                        msg={'Bazar Created Successfuly'}
                        status="success"
                        openMsg={bazarCreated}
                      />
                    )} */}
                    </>
                  ),
                  1: (
                    <StepTwoPaymentPlan
                      paymentPlanInputHandler={paymentPlanInputHandler}
                      savePaymentPlanSelected={savePaymentPlanSelected}
                      paymentError={paymentError}
                      bazarInfo={props.bazarInfo}
                      flatFeeValue={paymentValue.flatFee}
                      commissionValue={paymentValue.commission}
                    />
                  ),
                  2: (
                    <StepThreeDeliveryAndShipment
                      handleNext={handleNext}
                      bazarId={props.bazarId}
                    />
                  ),
                  3: <StepFourChoosePackage bazarId={props.bazarId} />,
                  4: <StepFivePayment />,
                }[activeStep]
              }
              {activeStep === 1 ||
              activeStep === 2 ||
              activeStep === 3 ||
              activeStep === 4 ? (
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ marginBottom: "40px" }}
                >
                  <Grid item>
                    <IconButton onClick={handleBack}>
                      <Box display="flex" color="primary">
                        {localStorage.i18nextLng === "en" ? (
                          <ArrowBackIcon
                            color="secondary"
                            className={classes.arrowDirection}
                          />
                        ) : (
                          ""
                        )}
                        <Typography color="textSecondary">
                          {t("buttons.back")}
                        </Typography>
                      </Box>
                    </IconButton>
                  </Grid>
                  {/* {activeStep !== 4 && ( */}
                  <Grid item>
                    <IconButton onClick={handleNext}>
                      <Box display="flex" color="primary">
                        <Typography className={classes.nextArrow}>
                          {t("buttons.next")}
                        </Typography>
                        {localStorage.i18nextLng === "en" ? (
                          <ArrowForwardIcon
                            className={
                              classes.nextArrow + classes.arrowDirection
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </IconButton>
                  </Grid>
                  {/* )} */}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>

        {/* <button onClick={handleNext}>NEXT </button> */}
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    bazarCreated: state.bazarReducer.bazarCreated,
    bazarId: state.bazarReducer.bazarId,
    packageId: state.bazarReducer.packageId,
    bazarInfo: state.bazarReducer.bazarInfo,
    organizerProfile: state.organizerReducer.organizerProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createBazar: (newBazar) => dispatch(actions.createBazar(newBazar)),
    addPaymentPlan: (paymentPlanInfo, paymentType) =>
      dispatch(actions.addPaymentPlan(paymentPlanInfo, paymentType)),
    getBazarById: (bazarId) => dispatch(actions.getBazarById(bazarId)),
    editBazar: (editedBazar) => dispatch(actions.editBazar(editedBazar)),
    resetBazar: () => dispatch({ type: RESET_BAZAR }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateBazar);
// dispatch(getOrganizerProfile(userId));
