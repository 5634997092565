import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  CircularProgress,
  Container,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import BazarCard from '../../components/BazarCard'
import SideBarDrawer from "./../../components/sideBarDrawer/index";
import { Divider } from "@material-ui/core/index";
import { SellerDrawerItems } from "./constants";
import { connect } from "react-redux";
// import { deleteBazar, getOrganizerBazars } from '../../redux/organizer/actions'
// import InputLabel from '@material-ui/core/InputLabel'
// import { FILTER_BAZAR_ON_STATUS } from '../../redux/actionTypes'
// import SellerBoothCard from '../../components/SellerBoothCard'
import SellerDashboardBoothsSection from "./../../components/SellerDashoardBoothsSection/index";
import {
  deletePendingBooth,
  getSellerBooths,
} from "../../redux/seller/actions";
import { useTranslation } from "react-i18next";
import SellerBoothCard from "../../components/SellerBoothCard";
import { FILTER_BOOTHS_ON_STATUS } from "../../redux/actionTypes";
import { getSellerPendingBooths } from "./../../redux/seller/actions";
import ControlledAppModal from "../../components/ControlledAppModal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ConfirmDeleteModalContent from "../../components/ConfirmDeleteModalContent";
import PaypalButtons from "./../../components/PaypalButtons/index";
import { confirmBoothPaymentAsync } from "../../redux/seller/api";
import { setSnackbar } from "../../redux/Message/actions";
import NoData from "./../../components/NoData/index";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  incompleteAlert: {
    backgroundColor: "#00000014",
    borderLeft: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
  incompleteAlertRight: {
    backgroundColor: "#00000014",
    borderRight: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
  divider: { margin: "60px 0" },
  statusDropDown: { margin: "60px 0" },
}));
const bazarAllStatus = ["Active", "CommingSoon", "Finished"];

const SellerDashboard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { booths } = props;
  const ar = localStorage.i18nextLng === "ar" ? true : false;
  const [status, setStatus] = useState("All");
  const [openDeclineReasons, setOpenDeclineReasons] = useState("");
  const [declineReasonsArr, setDeclineReasonsArr] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteBoothId, setDeleteBoothId] = useState("");
  const history = useHistory();

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [payBoothId, setPayBoothId] = useState("");

  const sellerId = localStorage.userId;

  const [statusArrs, setStatusArrs] = useState({
    Active: [],
    CommingSoon: [],
    Finished: [],
  });

  useEffect(() => {
    props.getSellerBooths(sellerId);
    props.getPendingBooths(sellerId);
  }, []);

  useEffect(() => {
    // let newStatusArrs = {
    //   Active: [],
    //   CommingSoon: [],
    //   Finished: [],
    // }
    // booths.forEach((booth) => {
    //   switch (booth.bazarStatus) {
    //     case 'Active':
    //       newStatusArrs.Active.push(booth)
    //       break
    //     case 'Finished':
    //       newStatusArrs.Finished.push(booth)
    //       break
    //     case 'CommingSoon':
    //       newStatusArrs.CommingSoon.push(booth)
    //       break
    //     default:
    //       newStatusArrs = { ...statusArrs }
    //       break
    //   }
    // })
    // console.log(newStatusArrs)
    // setStatusArrs(newStatusArrs)
  }, [booths]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    props.filterBooths(e.target.value);
  };

  const deleteBooth = (boothId) => {
    // alert('deleting booth Id ' + deleteBoothId)
    console.log(booths.find((itm) => itm.id === boothId));
    props.deleteSellerPendingBooth(boothId);
    handleCloseConfirmDelete();
  };
  ///////////////////// SHOW DECLINE REASONS MODAL ///////////////////
  const openShowReasonsModal = (declineReasons) => {
    setDeclineReasonsArr(declineReasons);
    setOpenDeclineReasons(true);
  };
  const closeShowReasonsModal = () => {
    setOpenDeclineReasons(false);
  };
  ////////////////////////DELETE MODAL ////////////////////////
  const handleopenConfirmDelete = (boothId) => {
    setDeleteBoothId(boothId);
    setOpenConfirmDelete(true);
  };
  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  ////////////////////// Payment Modal /////////////////////////////////
  // const handleOpenPaymentModal = (boothId) => {
  //   setPayBoothId(boothId)
  //   setOpenPaymentModal(true)
  // }
  // const handleClosePaymentModal = () => {
  //   setOpenPaymentModal(false)
  // }

  // const handlePaymentSuccess = async (data) => {
  //   try {
  //     console.log('Payment Done Succesfuly')

  //     const paymentObj = {
  //       orderId: data.orderID,
  //       payerId: data.payerID,
  //       id: payBoothId,
  //     }

  //     console.log(paymentObj)
  //     handleClosePaymentModal()
  //     await confirmBoothPaymentAsync(paymentObj)
  //     props.message('Payment Done Successfuly!')
  //     // tableRef.current.onQueryChange()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }
  return (
    <>
      {/* <SideBarDrawer DrawerItems={SellerDrawerItems} role="Seller"> */}
      <>
        {/* <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t('bazarHome.booths')}
            </Typography>
            <Button variant="contained" color="primary">
              <Link
                to="/Seller/CommingSoon"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                {t('buttons.joinBazar')}
              </Link>
            </Button>
          </Box> */}
        {/*   <Box align="end">
            <Link to="/Organizer/Bazars">See All</Link>
          </Box>
          <Box className={classes.incompleteAlert}>
            <Box display="flex" alignItems="center">
              <Avatar src="/assets/images/Logo/bazar_logo.png" />
              <Typography style={{ marginLeft: '10px' }}>
                Bazar Name - Active
              </Typography>
            </Box>
          </Box>
          <Box className={classes.incompleteBazars}>
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="center"
              align="center"
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{ marginBottom: '70px' }}
                // key={bazar.id}
              >
                <SellerBoothCard status="Active" />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.incompleteAlert}>
            <Box display="flex" alignItems="center">
              <Avatar src="/assets/images/Logo/bazar_logo.png" />
              <Typography style={{ marginLeft: '10px' }}>
                Bazar Name - Coming Soon
              </Typography>
            </Box>
          </Box>
          <Box className={classes.incompleteBazars}>
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="center"
              align="center"
            >
              <Grid
                item
                xs={12}
                md={4}
                style={{ marginBottom: '70px' }}
                // key={bazar.id}
              >
                <SellerBoothCard status="CommingSoon" />
              </Grid>
            </Grid>
          </Box> */}
        {/* <SellerDashboardBoothsSection
            bazarStatus="Active"
            boothsArr={statusArrs.Active}
          />
          <SellerDashboardBoothsSection
            bazarStatus="CommingSoon"
            boothsArr={statusArrs.CommingSoon}
          />
          <SellerDashboardBoothsSection
            bazarStatus="Finished"
            boothsArr={statusArrs.Finished}
          />
          <Divider className={classes.divider} /> */}
      </>
      {/* </SideBarDrawer> */}
      {/* ////////////////////////////// NEW DESIGN -_- /////////////////////////// */}
      <>
        <SideBarDrawer
          DrawerItems={SellerDrawerItems}
          role="Seller"
          handleDrawerToggle={props.handleDrawerToggle}
          mobileOpen={props.mobileOpen}
        >
          <ControlledAppModal
            handleClickOpen={openShowReasonsModal}
            open={openDeclineReasons}
            handleClose={closeShowReasonsModal}
          >
            {/* <InviteSeller
            handleClose={handleCloseInvitation}
            inviteID={bazarInvitationId}
          /> */}
            {declineReasonsArr
              .filter((item) => item !== "")
              .map((reason) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <HighlightOffIcon
                    color="error"
                    style={{ marginRight: "8px" }}
                  />
                  <span> {reason}</span>
                </div>
              ))}
          </ControlledAppModal>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {t("bazarHome.booths")}
          </Typography>
          {!props.loadingBooths &&
          !props.loadingPendingBooths &&
          props.pendingBooths.length === 0 &&
          props.booths.length === 0 ? (
            <Container>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "50px",
                  // marginTop: '-20px',
                  backgroundColor: "#fcfcfc",
                  paddingBottom: "50px",
                }}
              >
                {/* <NoData
                  src="/assets/images/SVG/shop.svg"
                  msg={t('JoinNow')}
                  btnText={t('buttons.joinBazar')}
                  onClickFn={() => history.push('/Seller/CommingSoon')}
                  imgWidth="180px"
                /> */}
                <Box width="30%">
                  <img
                    src="/assets/images/SVG/shop.svg"
                    width="100%"
                    height="100%"
                  />
                </Box>
                <Typography style={{ margin: "10px 0" }} variant="h5">
                  {t("notJoinedBazarYet")}
                </Typography>
                <Button variant="contained" color="primary">
                  <Link
                    to="/Seller/CommingSoon"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {t("buttons.joinBazar")}
                  </Link>
                </Button>
              </div>
            </Container>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="flex-end"
                mb={2}
                position="fixed"
                right="0px"
                style={{
                  backgroundColor: "#ffa50012",
                  padding: "10px 20px",
                  zIndex: "100",
                  top: "82px",

                  // width: '300px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    // position: 'fixed',
                    // justifySelf: 'flex-end',
                    // top: '100px',
                    // zIndex: 100,
                    marginRight: "20px",
                  }}
                  //   onScroll={()=>   style={{
                  //     position: 'fixed',
                  //     justifySelf: 'flex-end',
                  //     top: '100px',
                  //     zIndex: 100,
                  //   }}
                  // }
                >
                  <Link
                    to="/Seller/CommingSoon"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {t("buttons.joinBazar")}
                  </Link>
                </Button>
              </Box>
              <Box
                className={
                  ar ? classes.incompleteAlertRight : classes.incompleteAlert
                }
              >
                {t("pendingBooths")}
              </Box>
              <Box className={classes.incompleteBazars}>
                {/* //////////////////// Pending booths cards ///////////////////// */}
                <Grid
                  container
                  spacing={2}
                  justify={
                    props.pendingBooths.length > 0 ? "flex-start" : "center"
                  }
                  alignItems="baseline"
                  align="center"
                >
                  {props.loadingPendingBooths ? (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      <CircularProgress color="primary" />
                    </div>
                  ) : props.pendingBooths.length > 0 ? (
                    props.pendingBooths.map((booth) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ marginBottom: "70px" }}
                        key={booth.id}
                      >
                        {/* <Link
                      to={'/Seller/boothHome/' + booth.id}
                      style={{ textDecoration: 'none' }}
                    > */}
                        <SellerBoothCard
                          // bazarStatus={booth.bazarStatus}
                          booth={booth}
                          generalBoothStatus="Pending"
                          openShowReasonsModal={openShowReasonsModal}
                          handleopenConfirmDelete={handleopenConfirmDelete}
                          // handleOpenPaymentModal={handleOpenPaymentModal}
                        />
                        {/* </Link> */}
                      </Grid>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      {t("noPendingBooths")}
                    </div>
                  )}
                </Grid>
              </Box>
              <Divider className={classes.divider} />
              <Box
                align="end"
                className={classes.statusDropDown}
                alignItems="center"
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    {t("organizerDashboard.selectBazars")}
                  </InputLabel>
                  <Select
                    native
                    value={status}
                    onChange={handleStatusChange}
                    label={t("selectBooths")}
                    inputProps={{
                      name: "Select Booths",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value="All">{t("all")}</option>
                    {bazarAllStatus.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={2}
                  justify="flex-start"
                  alignItems="baseline"
                  align="center"
                >
                  {props.loadingBooths ? (
                    <Grid
                      container
                      justify="center"
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </Grid>
                  ) : booths.length > 0 ? (
                    booths.map((booth) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ marginBottom: "70px" }}
                        key={booth.id}
                      >
                        <Link
                          to={"/Seller/boothHome/" + booth.id}
                          style={{ textDecoration: "none" }}
                        >
                          <SellerBoothCard
                            bazarStatus={booth.bazarStatus}
                            booth={booth}
                            handleopenConfirmDelete={handleopenConfirmDelete}
                          />
                        </Link>
                      </Grid>
                    ))
                  ) : (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      {t("noData")}
                    </div>
                  )}
                </Grid>
              </Box>
            </>
          )}

          <ControlledAppModal
            handleClickOpen={handleopenConfirmDelete}
            open={openConfirmDelete}
            handleClose={handleCloseConfirmDelete}
          >
            <ConfirmDeleteModalContent
              handleDeleteConfirmed={deleteBooth}
              handleClose={handleCloseConfirmDelete}
              productId={deleteBoothId}
            />
          </ControlledAppModal>
          {/* //////////////////////////// Pay Button /////////////////////////// */}
          {/* <ControlledAppModal
            open={openPaymentModal}
            handleClickOpen={handleOpenPaymentModal}
            handleClose={handleClosePaymentModal}
          >
            <PaypalButtons
              // total={amount}
              onSuccess={handlePaymentSuccess}
              onCancel={handleClosePaymentModal}
            />
          </ControlledAppModal> */}
        </SideBarDrawer>
      </>
    </>
  );
};
const mapStateToProps = (state) => {
  // console.log(state.sellerReducer.sellerBooths)
  console.log(state.sellerReducer.pendingBooths, "pending Booths");
  return {
    loadingBooths: state.sellerReducer.loadingBooths,
    booths: state.sellerReducer.filteredBooths,
    loadingPendingBooths: state.sellerReducer.loadingPendingBooths,
    pendingBooths: state.sellerReducer.pendingBooths,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSellerBooths: (sellerId) => dispatch(getSellerBooths(sellerId)),
    getPendingBooths: (sellerId) => dispatch(getSellerPendingBooths(sellerId)),
    filterBooths: (status) =>
      dispatch({ type: FILTER_BOOTHS_ON_STATUS, payload: status }),
    deleteSellerPendingBooth: (boothId) =>
      dispatch(deletePendingBooth(boothId)),
    // message: () => console.log(''),
    message: (msg) => dispatch(setSnackbar(true, "success", msg)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SellerDashboard);
