import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import { colors } from '../../styles/commonCss'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: `9%`,
    left: `10%`,
    width: '80%',
    overflowX: 'auto',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    maxHeight: 320,
    overflowY: 'auto',
    // [theme.breakpoints.down('sm')]: {

    // },
    [theme.breakpoints.down('md')]: {
      width: '80%',
      top: `9%`,
      left: `10%`,
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: '5px',
  },
  avatarSection: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: '#f1f1f1',
    padding: '5px 20px ',
  },
  marginRight: {
    marginRight: '12px',
  },
  marginLeft: {
    marginLeft: '12px',
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  linkColor: { color: colors.link },
}))

const styles = (theme) => ({
  root: {
    margin: ' 0 auto',
    padding: theme.spacing(2),
    // borderRadius: '20px',
    // padding: '0 60px',
    // position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: { width: '800px', backgroundColor: 'red' },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null} */}
    </MuiDialogTitle>
  )
})

export default function OrderModalBody(props) {
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const TAX_RATE = 0.07

  function ccyFormat(num) {
    return `${num.toFixed(2)}`
  }

  function priceRow(qty, unit) {
    return qty * unit
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit)
    return { desc, qty, unit, price }
  }

  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
  }

  const rows = [
    createRow('Paperclips (Box)', 100, 1.15),
    createRow('Paper (Case)', 10, 45.99),
    createRow('Waste Basket', 2, 17.99),
  ]
  const invoiceSubtotal = subtotal(rows)
  const invoiceTaxes = TAX_RATE * invoiceSubtotal
  const invoiceTotal = invoiceTaxes + invoiceSubtotal
  const body = (
    <>
      <div className={classes.paper}>
        <DialogTitle>ya rab</DialogTitle>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="spanning table">
            <TableHead>
              {/* <TableRow>
            </TableRow> */}
              <TableRow>
                <TableCell align="left">Products</TableCell>
                {/* <TableCell>Desc</TableCell> */}
                <TableCell align="right">Cost </TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.desc}>
                  <TableCell>{row.desc}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">{row.unit}</TableCell>
                  <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={2}>Subtotal</TableCell>
                <TableCell align="right">
                  {ccyFormat(invoiceSubtotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                  0,
                )} %`}</TableCell>
                <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Grid container direction="column">
        <Grid item container wrap="nowrap">
          <Grid item container xs={6} justify="flex-start" wrap="nowrap">
            <Box width="800px">products</Box>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="flex-end"
            spacing={2}
            wrap="nowrap"
          >
            <Grid item>Cost </Grid>
            <Grid item>Qty</Grid>
            <Grid item>Total</Grid>
          </Grid>
        </Grid>
      </Grid> */}
      </div>
    </>
  )

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={handleOpen}
        style={{ color: colors.link, fontSize: '15px' }}
      >
        open
      </Link>
      {/* <Button type="button" variant="link" onClick={handleOpen}>
        View
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}
