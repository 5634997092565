import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Box, makeStyles, MenuItem, MenuList } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  selected: {
    borderLeft: "4px solid orange",
    backgroundColor: "orange",
  },
  selectedRight: {
    borderRight: "4px solid orange",
    backgroundColor: "orange",
  },
  menuItem: {
    color: "black",
    // fontSize: '5px',
  },

  drawerActive: {
    backgroundColor: "red",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  itemFont: {
    fontSize: "8px",
  },
}));
const DrawerNavigation = (props) => {
  const ar = localStorage.i18nextLng === "ar" ? true : false;
  const { DrawerItems } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? true : false;
  };
  return (
    <MenuList>
      {DrawerItems.map((prop, key) => {
        return (
          <NavLink to={prop.path} style={{ textDecoration: "none" }} key={key}>
            <MenuItem
              selected={activeRoute(prop.path)}
              //   className={activeRoute(prop.path) ? classes.selected : ''}
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
                // selected: ar ? classes.selectedRight : classes.selected,
              }}
              //   style={{ backgroundColor: 'transparent' }}
            >
              <ListItemText
                primary={t("sideMenu." + prop.itemName)}
                className={classes.itemFont}
                style={{ fontSize: "8px" }}
              />
              {/* {t('sideMenu.' + prop.itemName)} */}
              {console.log(prop.itemName, "prop.itemName")}
            </MenuItem>
          </NavLink>
        );
      })}
    </MenuList>
  );
};

export default withRouter(DrawerNavigation);
