import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import IconButton from "@material-ui/core/IconButton";

import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { useParams } from "react-router";
import {
  Edit,
  Store,
  LocationOn,
  AccessTime,
  LocalMall,
  Share,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { textalign } from "../../styles/commonCss";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "./../../components/lib/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import MyDate from "./../Date";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import VideocamIcon from "@material-ui/icons/Videocam";
import { getTimeBasedOnStatus } from "./../SellerBoothCard/index";
import { format } from "date-fns";
import UploadButtons from "../UploadButtons";
import {
  getBoothHome,
  uploadBoothAvatarImg,
  uploadBoothCoverImg,
} from "./../../redux/booth/actions";
import Alert from "@material-ui/lab/Alert";
import ControlledAppModal from "./../ControlledAppModal/index";
import axios from "axios";
import MouseOverPopover from "../PopoverOnHover";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BoothPage from "./../../containers/BoothPage/index";
import fileChangedHandler from "./../../containers/ImgUploader/index";
import { handleLogout } from "../../utils/setLocalStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: "0 auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bazarBackgroundCover: {
    // background: 'url(/assets/images/bazars/active_bazar.jpg)',
    width: "100%",
    height: "400px",
    // position: 'relative',
    // backgroundSize: 'cover',
    // borderRadius: '10px',
  },
  coverCameraContainer: {
    width: "230px",
    height: "50px",
    position: "absolute",
    right: "0px",
    backgroundColor: " #c5bebea3",
    bottom: "70px",
    display: "flex",
    alignItems: "center",
  },

  textAlignRight: {
    textAlign: textalign.right,
  },
  textAlignCenter: {
    textAlign: textalign.center,
  },
  button: {
    margin: theme.spacing(1),
  },
  InWrap: {
    padding: theme.spacing(1),
  },
  dashboardlogo: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "2rem",
    left: "calc(50 % - 56px)",
    position: "relative",
    //border: '2px solid',
    //borderColor: '#F8B334',
  },
  dvDashboardlogo: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "-55px",
    width: "100%",
  },
  settingIcon: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  gridDashboardlogo: {
    position: "relative",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridShortInfo: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
  },

  gridShortInfoItem: {
    display: "flex",
    padding: "10px",
  },
  statsIcons: {
    width: "100%",
    minHeight: "50px",
  },
  salesIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  RequestsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  EarningsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },

  gridroot: {
    flexGrow: 1,
  },

  GridItemList: {
    marginBottom: "50px",
  },
  dis: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
  mar: {
    marginLeft: "60px",
  },
}));
const BoothHomeBasicInfo = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openView, setOpenView] = useState(false);
  const [open, setOpen] = useState(false);
  const [imageType, setImageType] = useState("Logo");
  const {
    id,
    name,
    logo,
    coverImage,
    selles,
    status,
    bazarName,
    bazarLogo,
    startOfBazar,
    endOfBazar,
    bazarStatus,
    numOfOrders,
    numOfProducts,
    numOfProductRequests,
    boothCity,
    boothCountry,
    // boothCategories,
    zoomUrl,
  } = props.booth;
  const { boothCategories } = props;
  console.log(boothCategories, "red");
  const handleUploadLogoImg = (imag, type) => {
    // e.stopPropagation();
    // e.preventDefault();
    console.log(type);
    const image = imag;
    console.log(image, "wh", type);

    const formDate = new FormData();
    formDate.set("image", image);
    debugger;
    if (type === "Logo")
      dispatch(uploadBoothAvatarImg(formDate)).then(() => {
        setTimeout(() => handleOpen(), 2000);
      });
    else
      dispatch(uploadBoothCoverImg(formDate)).then(() => {
        setTimeout(() => handleOpen(), 2000);
      });
    setImageType(type);
  };

  // useEffect(() => {
  //   if (imageUpload !== null) {
  //     console.log('opeeeeeeeeeen')
  //     setTimeout(() => handleOpen(), 1600)
  //   }
  // }, [imageUpload])

  const submitImage = async () => {
    const obj = { boothId: id };
    imageType === "Logo" ? (obj.logo = logo) : (obj.coverPhoto = coverImage);
    try {
      debugger;
      const res = await axios.put("Booth/EditBoothInformation", obj);
      console.log(res);
    } catch (error) {
      console.log(error);
      dispatch(getBoothHome(id));
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
    handleClose();
  };

  const handleCancelImageUpload = () => {
    dispatch(getBoothHome(id));
    handleClose();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const role = localStorage.userRole;
  const handleViewPage = () => {
    handleopenView();
  };
  const handleopenView = () => {
    debugger;
    console.log(id, "andleopenView");
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };
  // const boothid = useParams().id;
  return (
    <>
      <Grid item xs={8} className={classes.textAlignRight}>
        <Typography component="div">
          <Box fontSize="h6.fontSize" m={1}>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleopenView}
              >
                <VisibilityIcon />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() =>
                  props.history.push(`/Seller/booth/${props.boothId}/products`)
                }
              >
                {t("manageproducts")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() =>
                  props.history.push(`/Seller/manageOrders/${props.boothId}`)
                }
              >
                {t("buttons.manageOrders")}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Edit>Edit</Edit>}
              >
                Edit
              </Button> */}
            </div>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className="" container justify="center">
        <div style={{ position: "relative", width: "98%" }}>
          {/* {coverImage} */}
          <img
            // src="/assets/images/shops/boothHome.jpg"
            alt=""
            src={
              coverImage
                ? `${localStorage.imagePath}${coverImage}`
                : "/assets/images/Logo/default-cover.png"
            }
            width="100%"
            height="400px"
            className={`img ${classes.bazarBackgroundCover}`}
          />
          <div className={classes.coverCameraContainer}>
            <Box position="absolute" zIndex="1000">
              <UploadButtons
                type="Camera"
                handleChange={(e) => {
                  fileChangedHandler(e, "Cover", null, handleUploadLogoImg);
                }}
                name="boothCover"
              />
            </Box>
            <div className={classes.mar}>{t("uploaYourCover")}</div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.gridDashboardlogo}>
        <div className={classes.dvDashboardlogo}>
          <Avatar
            alt={name?.toUpperCase()}
            src={logo ? `${localStorage.imagePath}${logo}` : ""}
            className={classes.dashboardlogo}
          />
          <Box
            width="50px"
            // height="50px"
            // bgcolor="primary"
            // border="2px solid black"
            bgcolor="#00000ed"
            position="absolute"
            bottom="-2px"
            marginRight="76px"
            // left="2px"
          >
            <div style={{ display: "flex", background: "#00000ed" }}>
              <UploadButtons
                type="Camera"
                handleChange={(e) =>
                  fileChangedHandler(e, "Logo", null, handleUploadLogoImg)
                }
                name="boothLogo"
              />
              {/* <Button variant="outlined" size="small">
                Save
              </Button> */}
            </div>
          </Box>
        </div>
        <ControlledAppModal
          open={open}
          handleClickOpen={handleOpen}
          handleClose={handleClose}
        >
          <>
            {imageType === "Logo" && <h3>Save Uploaded logo image </h3>}
            {imageType === "Cover" && <h3>Save Uploaded cover image </h3>}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button variant="contained" color="primary" onClick={submitImage}>
                {t("buttons.save")}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelImageUpload}
              >
                Cancel
              </Button>
            </div>
          </>
        </ControlledAppModal>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.settingIcon}>
          {bazarStatus === "Active" && zoomUrl !== null ? (
            <IconButton onClick={() => window.open(zoomUrl)}>
              <VideocamIcon color="primary" style={{ textAlign: "right" }} />
            </IconButton>
          ) : (
            <MouseOverPopover text={`${t("canteditbooth")}` + bazarStatus}>
              <IconButton disabled={true}>
                <VideocamIcon color="primary" style={{ textAlign: "right" }} />
              </IconButton>
            </MouseOverPopover>
          )}
          {/* {role === 'Seller' && bazarStatus === 'CommingSoon' && ( */}
          {role === "Seller" ? (
            bazarStatus !== "Finished" ? (
              <>
                <IconButton
                  onClick={() => props.openEditModal()}
                  // disabled={bazarStatus !== 'CommingSoon' ? true : false}
                >
                  <SettingsIcon
                    color="primary"
                    style={{ textAlign: "right" }}
                  />
                </IconButton>
              </>
            ) : (
              <IconButton
              // onClick={() => props.openEditModal()}
              // disabled={bazarStatus !== 'CommingSoon' ? true : false}
              >
                <MouseOverPopover text={`${t("canteditbooth")}` + bazarStatus}>
                  <SettingsIcon
                    color="primary"
                    style={{ textAlign: "right" }}
                  />
                </MouseOverPopover>
              </IconButton>
            )
          ) : (
            <div style={{ height: "30px" }}></div>
          )}
        </div>
      </Grid>
      <Grid item xs={10} container justify="flex-end">
        {/* <BazarStatusIndicator
          bazarStatus={status}
          // style={{ position: 'absolute', left: '50px' }}
        /> */}
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Typography component="div">
          <Box fontSize="h6.fontSize" m={1} paddingTop="5px">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: '105px',
                position: "relative",
              }}
            >
              <div style={{ textAlign: "center" }}>{name}</div>
            </div>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Typography component="span">
          <Box className={classes.centerFlex} my={1}>
            <span style={{ marginRight: "3px" }}>
              {bazarName} {t("ProviderManage.bazar")}
            </span>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Typography component="span">
          <Box className={classes.centerFlex}>
            <LocationOn fontSize="small" />
            <span style={{ marginRight: "3px" }}>{boothCity}</span>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Box paddingTop="10px">
          {boothCategories.length > 0
            ? boothCategories.map((cat) => (
                <Chip
                  key={cat.id}
                  label={
                    localStorage.i18nextLng === "en"
                      ? cat?.name
                      : cat?.arabicName
                  }
                  variant="outlined"
                  color="primary"
                  className={boothCategories.length > 1 ? "margin-right-s" : ""}
                />
              ))
            : ""}
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.gridShortInfo}>
        {/* <Box
          component="span"
          fontSize="h15.fontSize"
          className={classes.gridShortInfoItem}
        >
          <AccessTime fontSize="small" />
          //<span className="font-size-md"> 3 days - 12 hours</span>
          {bazarStatus === "Finished" ? (
            <span className="font-size-md">
              {endOfBazar && format(new Date(endOfBazar), "dd-MM-yyyy")}
            </span>
          ) : (
            <span className="font-size-md">
              {getTimeBasedOnStatus(bazarStatus, startOfBazar, endOfBazar)}
            </span>
          )}
        </Box> */}
        {/* <Box
          component="span"
          fontSize="h15.fontSize"
          className={classes.gridShortInfoItem}
        >
          <Store fontSize="small" />
          <span className="font-size-md"></span>
        </Box> */}
        {/* <Box
          component="span"
          fontSize="h15.fontSize"
          className={classes.gridShortInfoItem}
        >
          <LocalMall fontSize="small" />
          <span className="font-size-md">{selles}</span>
        </Box> */}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.textAlignCenter}
        justify="center"
      >
        <Box component="span" fontSize="h15.fontSize" className={classes.dis}>
          {console.log(startOfBazar, endOfBazar, "endOfBazar")}
          {startOfBazar ? (
            <>
              <MyDate
                ndate={format(new Date(startOfBazar + "Z"), "dd-MM-yyyy")}
              />
              <div style={{ margin: "1rem" }}>{t("to")} </div>
              <MyDate
                ndate={format(new Date(endOfBazar + "Z"), "dd-MM-yyyy")}
              />
            </>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.textAlignRight}>
        {/* <Button
          variant="outlined"
          className={classes.button}
          endIcon={<Share>Share</Share>}
        >
          Share
        </Button> */}
        {/* <div
          className="fb-share-button"
          data-href={`https://www..ebazarak.com${location.pathname}`}
          data-layout="button"
          data-size="small"
        >
          <a
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
            className="fb-xfbml-parse-ignore"
          >
            Share
          </a>
        </div> */}
      </Grid>
      {/* <ControlledAppModal
        open={openView}
        handleClickOpen={handleViewPage}
        handleClose={handleCloseView}
      >
        <div style={{ maxWidth: "800px" }}>
          <BoothPage boothId={id} />
        </div>
      </ControlledAppModal> */}
      <ControlledAppModal
        open={openView}
        handleClickOpen={handleViewPage}
        handleClose={handleCloseView}
      >
        <div style={{ maxWidth: "1100px" }}>
          <BoothPage boothId={id} modal={true} />
          {console.log("booid", id)}
        </div>
      </ControlledAppModal>
    </>
  );
};

export default withRouter(BoothHomeBasicInfo);
