import React, { useState } from 'react'

import { Box, Grid, Button } from '@material-ui/core'

import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input'
import { Form, Formik } from 'formik'
import FormikField from './../../components/FormikField/index'
import FormikMultiLineField from './../../components/FormikMultiLineField/index'
import { useTranslation } from 'react-i18next'

const AddEditDriverModal = ({
  initialValues,
  mode,
  onSubmit,
  validationSchema,
}) => {
  const [phone, setPhone] = useState('')
  const { t } = useTranslation()
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>
        {mode === 'Add' ? 'Add New Driver' : 'Edit Driver'}
      </h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isSubmitting,
          handleReset,
          setFieldValue,
        }) => {
          console.log(values)
          setPhone(values.phone)
          // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

          return (
            <Form autoComplete="false">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormikField name={'name'} label={t('driverName')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <FormikField name={"phone"} label={"Phone Number"} /> */}
                  <PhoneInput
                    style={{ paddingTop: '.5rem' }}
                    placeholder={t('signUp.phoneNumber')}
                    value={phone}
                    onChange={(value) => {
                      let x = formatPhoneNumberIntl(value)
                      setPhone(x)
                      values.phone = x
                      console.log('values.phoneNumber', values.phone)
                    }}
                    rules={{
                      required: true,
                      validate: isValidPhoneNumber,
                    }}
                    style={{ direction: 'ltr' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikMultiLineField name={'comment'} label={t('comment')} />
                </Grid>
                <Grid xs={12} item container justify="center">
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      // style={{ padding: '10 40px' }}
                      type="submit"
                      fullWidth
                    >
                      {mode}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddEditDriverModal
