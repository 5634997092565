import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import BasicTable from "../../components/ProviderOrderItemsSettings";

import {
  editOrderSettingsAsync,
  getAllDriversAsync,
  getAllOrderStatusAsync,
} from "../../redux/serviceProvider/api";
import { getOrderDetailsAsync } from "./../../redux/serviceProvider/api";
import { makeStyles } from "@material-ui/core/styles";
import ProviderOrderSettings from "./../../components/ProviderOrderSettings/index";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ControlledAppModal from "./../../components/ControlledAppModal";
import CompleteModalCotent from "./../../components/CompleteModalCotent";
const useStyles = makeStyles((theme) => ({
  basicInfoTitle: {
    // color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "15px 20px ",
    width: "100%",
    margin: "30px 0",
  },
}));
const cellStyle = { width: "fit-content", textAlign: "center" };
const ProviderOrderDetails = () => {
  const orderId = useParams().orderId;
  const providerId = localStorage.userId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [res, setRes] = useState({});
  const [order, setOrder] = useState({
    orderItems: [],
    buyer: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      whatsNumber: "",
      phoneNumber: "",

      street: "string",
      city: {
        id: 1,
        name: "",
      },
      country: {
        id: 1,
        name: "",
      },
    },
    total: 0,
    shippingPrice: 0,
  });
  const [orderItemStatus, setOrderItemStatus] = useState([]);
  const [orderStatusArr, setOrderStatusArr] = useState([]);
  const [orderInitialValues, setOrderInitialValues] = useState({
    orderId: "",
    driverId: "",
    orderItemStatus: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [val, setVal] = useState({});

  const handleOpenModal = () => {
    setOpenConfirmModal(true);
  };
  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };
  const handleConfirmed = async () => {
    handleCloseModal();
    try {
      const response = await editOrderSettingsAsync(val);
      setRes(response);
      dispatch(setSnackbar(true, "success", t("messages.orderProductEdited")));
      setOrderInitialValues(val);
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
  useEffect(() => {
    ////////////////// Asynchronus Call /////////////
    async function fetchOrder() {
      try {
        const orderDetails = await getOrderDetailsAsync(orderId);
        console.log(orderDetails);
        setOrder(orderDetails);
        setOrderInitialValues({
          orderId: orderDetails.orderNumber,
          driverId: orderDetails.driverId || "",
          orderItemStatus: orderDetails.orderStatus,
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchOrder();
    ////////////////// DRIVERS /////////////////////
    async function fetchDriversAndStatus() {
      try {
        let response = await getAllDriversAsync(providerId);
        console.log(response.data);
        setDrivers(response.data);
        response = await getAllOrderStatusAsync(providerId);

        setOrderItemStatus(response);
        response = await axios.get("Provider/TotalOrderStatusTypes");

        setOrderStatusArr(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchDriversAndStatus();
  }, [res]);
  console.log(order.orderItems);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values, "comcom", "orderItemStatus");
    debugger;
    setVal(values);
    // setSubmitting(true)
    if (values.orderItemStatus !== 2) {
      try {
        const response = await editOrderSettingsAsync(values);
        // resetForm({})
        setRes(response);
        dispatch(
          setSnackbar(true, "success", t("messages.orderProductEdited"))
        );
        setOrderInitialValues(values);
      } catch (error) {
        console.log(error);
        console.log(error?.response);
      }
    } else {
      handleOpenModal();
    }
  };

  return (
    <>
      <h1>{t("cartPage.orderDetails")}</h1>
      <Typography variant="h6">
        {t("order.orderNo")} {orderId}
      </Typography>

      {/* {order?.orderItems.lenght > 0 && ( */}
      <h3 className={classes.basicInfoTitle}>
        {t("ProviderOrder.orderSettings")}
      </h3>
      {console.log(order, "orderr")}
      <ProviderOrderSettings
        drivers={drivers}
        statusArr={orderStatusArr}
        initialValues={orderInitialValues}
        buyer={order.buyer}
        handleSubmit={handleSubmit}
        subTotal={order.subTotal}
        shipping={order.shippingPrice}
        {...order}
      />

      <h3 className={classes.basicInfoTitle}>
        {t("ProviderOrder.itemsSettings")}
      </h3>
      <BasicTable
        orderItems={order.orderItems || []}
        drivers={drivers}
        statusArr={orderItemStatus}
        orderStatus={orderInitialValues.orderItemStatus}
      />
      <div style={{ height: "5rem" }}></div>
      {/* )} */}
      {/* <MaterialTable
        icons={tableIcons}
        columns={[
          {
            title: 'Product ID',
            field: 'productId',
            cellStyle,
          },

          {
            title: 'Name',
            field: 'name',
            cellStyle,
          },
          {
            title: 'Price',
            field: 'price',
            cellStyle,
            render: (row) => <div>JD {row.price}</div>,
          },
          {
            title: 'Offer',
            field: 'offerPrice',
            cellStyle,
            render: (row) => (
              <div>{row.offerPrice ? 'JD' + row.offerPrice : '__'}</div>
            ),
          },
          {
            title: 'Category',
            field: 'categoryName',
            cellStyle,
          },
          {
            title: 'Quantity',
            field: 'quantity',
            cellStyle,
          },

          {
            title: 'Actions',
            field: 'actions',
            render: (row) => <></>,
          },
          {
            title: 'Status',
            field: 'status',
            cellStyle,
            render: (row, index) => {
              //   console.log(indexOf(row))
              return <div></div>
            },
          },
        ]}
        options={{
          search: true,
          paginationType: 'stepped',
          pageSize: 10,
          headerStyle: {
            backgroundColor: '#f1f1f1',
            textAlign: 'center',
            // borderBottom: '1px solid gray',
          },
          //   rowStyle: { borderBottom: '1px solid lightgray' },
          doubleHorizontalScroll: true,
          sorting: false,
          showTitle: false,
        }}
        data={orderItems}
      /> */}
      <ControlledAppModal
        handleClickOpen={handleOpenModal}
        open={openConfirmModal}
        handleClose={handleCloseModal}
      >
        <CompleteModalCotent
          handleConfirmed={handleConfirmed}
          handleClose={handleCloseModal}
        />
      </ControlledAppModal>
    </>
  );
};

export default ProviderOrderDetails;
