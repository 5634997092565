// import { GET_SELLER_BOOTHS, GET_SELLER_BY_ID } from '../actionTypes'
// import { getSellerBoothsAsync, getSellerByIdAsync } from './api'
import { handleLogout } from "../../utils/setLocalStorage";
import {
  GET_BOOTH_HOME,
  GET_BOOTH_PRODUCTS,
  UPLOAD_AVATAR_IMG,
  UPLOAD_COVER_IMG,
} from "../actionTypes";
import { uploadImageAsync } from "./../../service/image";
import {
  getBoothHomeAsync,
  getBoothProductsAsync,
  uploadBoothImageAsync,
} from "./api";

export const getBoothHome = (boothId) => {
  return async (dispatch) => {
    try {
      const result = await getBoothHomeAsync(boothId);
      console.log(result);

      dispatch(getBoothHomeSuccess(result));
    } catch (error) {
      console.log(error?.response?.data.message, error?.response?.status);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const getBoothHomeSuccess = (booth) => {
  return {
    type: GET_BOOTH_HOME,
    payload: booth,
  };
};
export const getBoothProducts = (boothId, pageIndex, pageSize) => {
  return async (dispatch) => {
    try {
      const result = await getBoothProductsAsync(boothId, pageIndex, pageSize);
      console.log(result);
      debugger;
      dispatch(getBoothProductsSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getBoothProductsSuccess = (boothData) => {
  return {
    type: GET_BOOTH_PRODUCTS,
    payload: boothData,
  };
};
export const uploadBoothAvatarImg = (image) => {
  return async (dispatch) => {
    try {
      const result = await uploadImageAsync(image);
      console.log(result);
      // debugger
      dispatch(uploadBoothAvatarImgSuccess(result));
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const uploadBoothAvatarImgSuccess = (imgPath) => {
  return { type: UPLOAD_AVATAR_IMG, payload: imgPath };
};
export const uploadBoothCoverImg = (image) => {
  return async (dispatch) => {
    try {
      const result = await uploadImageAsync(image);
      console.log(result);
      // debugger
      dispatch(uploadBoothCoverImgSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const uploadBoothCoverImgSuccess = (imgPath) => {
  return { type: UPLOAD_COVER_IMG, payload: imgPath };
};
