import axios from "axios";

export const getBoothHomeAsync = async (boothId) => {
  const token = localStorage.token;

  const response = await axios.get(`Booth/GetBoothHome?BoothId=${boothId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  debugger;
  return response.data;
};
export const getBoothCategoryByProductAsync = async (boothId) => {
  const token = localStorage.token;

  const response = await axios.get(
    `GetBoothCategoryByProduct?BoothId=${boothId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  debugger;
  return response.data;
};
export const getBoothProductsAsync = async (boothId, pageIndex, pageSize) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Organizer/GetAllBoothProducts?BoothId=${boothId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const editBoothSettingsAsync = async (boothInfo) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.put(`Booth/EditBoothSetting`, boothInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const uploadBoothImageAsync = async (image) => {
  const response = await axios.post("File/Upload/Seller", image, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  debugger;
  const path = await axios.get("File/Upload/Seller");
  // let path = response.data[0].path + 'Seller/' + response.data[0].newFileName
  // path = path.split('\\').join('/')
  // console.log(path)
  // return path
};
export const getImageUrlAsync = async (image) => {
  // const response = await axios.post('File/Upload/Seller', image, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   },
  // })
  // debugger
  const path = await axios.get(
    `File/GetImageUrl?ImageName=fdec688e-e567-40c3-9b07-ecd6c69a53e7.jpg`
  );
  debugger;
  // let path = response.data[0].path + 'Seller/' + response.data[0].newFileName
  // path = path.split('\\').join('/')
  // console.log(path)
  return path.data;
};
// export const uploadImageAsync = async (image) => {
//   debugger
//   const response = await axios.post('File/Upload/Seller', image, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
//   let path = response.data.path + response.data.originalFileName
//   // path = path.split('\\').join('/')
//   return path
// }

export const getSellerBoothProductsAsync = async (
  boothId,
  pageIndex,
  pageSize
) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerBoothProducts?BoothId=${boothId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
