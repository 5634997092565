import { Box, Button, Grid, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import FormikField from "../../FormikField";
import FormikSelect from "./../../FormikSelect/index";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { connect, useDispatch } from "react-redux";
import {
  getAllServiceProviders,
  getProviderByBazarCountry,
} from "../../../redux/serviceProvider/actions";
import { addServiceProviderToBazar } from "../../../redux/bazar/actions";
import ShowMessage from "./../../Message/index";
import { setSnackbar } from "../../../redux/Message/actions";
import ControlledAppModal from "./../../ControlledAppModal/index";
import MouseOverPopover from "../../PopoverOnHover";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { uploadImageAsync } from "./../../../service/image";
import Typography from "@material-ui/core/Typography";
import UploadButtons from "./../../UploadButtons/index";

const StepThreeDeliveryAndShipment = (props) => {
  const { t } = useTranslation();

  const { bazarInfo, setting } = props;
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [submitValues, setSubmitValues] = useState("");
  const [lastProviderName, setLastProviderName] = useState("");
  const [cancelInvitaionMsg, setCancelInvitatiomMsg] = useState("");
  const [shipmentFileUploadLoading, setShipmentFileUploadLoading] =
    useState(false);

  const initialValues = {
    providerStandardPrice: bazarInfo.provider
      ? bazarInfo.providerStandardPrice
      : "",
    providerLargePrice: bazarInfo.provider ? bazarInfo.providerLargePrice : "",
    providerId: bazarInfo.provider ? bazarInfo.provider.id : "",
    providerMessage: bazarInfo.providerMessage || "",
    shipmentDetails: bazarInfo.shipmentDetails || "",
    shipmentDetailsFile: bazarInfo.shipmentDetailsFile || "",
    shipmentDetailsFileName: bazarInfo.shipmentDetailsFileName || "",
  };
  console.log(bazarInfo, "bazarInfo");
  const validationSchema = Yup.object({
    providerStandardPrice: Yup.number()
      .min(0, "Min number 0")
      .required(t("EnterStandardPackage")),
    providerLargePrice: Yup.number()
      .min(0, "Min number 0")
      .required(t("EnterStandardPackage")),
    providerId: Yup.string().required(t("SelectServiceProvider")),
  });

  useEffect(() => {
    props.getProviders();
    props.getProvidersByBazarId(props.bazarId);
    console.log(props.bazarId);
  }, []);
  const handleOpen = (id) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // tableRef.current.onQueryChange()
  };
  const sendInvitationOnConfirm = () => {
    debugger;

    props.addServiceProvider(submitValues);
    handleClose();
  };
  useEffect(() => {
    if (props.providerAdded) {
      // setShowMessage(true)
      // setTimeout(() => {
      //   setShowMessage(false)
      // }, 3000)
      !setting && props.handleNext();
    }
  }, [props.providerAdded]);

  const handleProductImageUpload = async (e, setFieldValue) => {
    const img = e.target.files[0];
    const name = img.name;
    setFieldValue(`shipmentDetailsFileName`, name);
    try {
      const formDate = new FormData();
      formDate.set(name, img);
      setShipmentFileUploadLoading(true);
      const imageName = await uploadImageAsync(formDate);
      console.log("Donee");
      setFieldValue(`shipmentDetailsFile`, imageName);
      setShipmentFileUploadLoading(false);

      console.log(imageName);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // if (bazarInfo.id)
    debugger;
    values.bazarId = props.bazarId;

    const serviceProviderId = bazarInfo.provider?.id;
    const providerName = props.serviceProvidersById?.find(
      (provider) => provider.id === serviceProviderId
    )?.companyName;
    let cancelInvitaion = "";
    // if (
    //   values.providerId === serviceProviderId &&
    //   values.providerLargePrice === bazarInfo.providerLargePrice &&
    //   values.providerStandardPrice === bazarInfo.providerStandardPrice &&
    //   values.shipmentDetails === bazarInfo.shipmentDetails &&
    //   values.shipmentDetailsFile === bazarInfo.shipmentDetails &&
    //   values.providerMessage === bazarInfo.providerMessage
    // ) {
    //   dispatch(setSnackbar(true, 'error', t('messages.Provideralreadyinvited')))
    // }
    if (values.providerId === serviceProviderId) {
      cancelInvitaion = `${t(
        "messages.yourlastInvitaionto"
      )} ${providerName} ${t("messages.cancelInvition")}`;
      setCancelInvitatiomMsg(cancelInvitaion);
      handleOpen();
    } else if (serviceProviderId) {
      cancelInvitaion = `${t(
        "messages.yourlastInvitaionto"
      )} (${providerName}) ${t("messages.cancelInvitaionAndSendToOther")} ${
        props.serviceProvidersById.find(
          (provider) => provider.id === values.providerId
        )?.companyName
      }`;
      setLastProviderName(providerName);
      setCancelInvitatiomMsg(cancelInvitaion);
      handleOpen();
    } else {
      props.addServiceProvider(values);
    }

    console.log(values);
    setSubmitValues(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 4000);
  };

  return (
    <>
      <ControlledAppModal
        handleClickOpen={handleOpen}
        open={open}
        handleClose={handleClose}
      >
        <div style={{ maxWidth: "380px" }}>
          <Typography>{cancelInvitaionMsg}</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "30px",
            }}
          >
            <Button
              onClick={() => {
                console.log("editConfirmed");
                sendInvitationOnConfirm();
              }}
              color="primary"
              variant="contained"
            >
              {t("buttons.ok")}
            </Button>
            <Button
              onClick={() => {
                // setConfirmEdit(false)
                // cancelEditProduct()
                handleClose();
              }}
              color="error"
              variant="contained"
            >
              {t("buttons.cancel")}
            </Button>
          </div>
        </div>
      </ControlledAppModal>
      <Grid container item xs={12}>
        <Box
          width={1}
          // mt={'50px'}
          display="flex"
          justifyContent="space-between"
          mb={"30px"}
        >
          <Grid item xs={12} lg={!setting ? 6 : 12}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                validateForm,
                isSubmitting,
                setFieldValue,
                touched,
                handleBlur,
              }) => {
                // console.log(touched)
                console.log(values, "providerMessage");
                return (
                  <Form action="false">
                    {props.providerAdded && (
                      <ShowMessage
                        msg={"Service provider added Successfuly"}
                        status="success"
                      />
                    )}

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <div style={{ marginTop: "5px", width: "30px" }}>
                          <MouseOverPopover
                            text={
                              <div
                                style={{
                                  marginTop: "5px",
                                  width: "300px",
                                }}
                              >
                                {t("lessthan5kilos")}
                              </div>
                            }
                          >
                            <InfoOutlinedIcon />
                          </MouseOverPopover>
                        </div>
                        <FormikField
                          name="providerStandardPrice"
                          label={<div>{t("createBazar.standardPackage")}</div>}
                          type="number"
                          disabled={
                            bazarInfo.status === "Active" ? true : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} container>
                        <div
                          style={{
                            // marginLeft: "auto",
                            width: "30px",
                            textAlign: "left",
                          }}
                        >
                          <MouseOverPopover
                            text={
                              <div
                                style={{
                                  marginTop: "5px",
                                  width: "300px",
                                }}
                              >
                                {t("morethan5kilos")}
                              </div>
                            }
                          >
                            <InfoOutlinedIcon />
                          </MouseOverPopover>
                        </div>
                        <FormikField
                          name="providerLargePrice"
                          label={t("createBazar.largePackage")}
                          type="number"
                          disabled={
                            bazarInfo.status === "Active" ? true : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikSelect
                          name="providerId"
                          label={t("createBazar.serviceProvider")}
                          items={props.serviceProvidersById}
                          disabled={
                            bazarInfo.status === "Active" ? true : false
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={6}
                          type="text"
                          name="shipmentDetails"
                          label={t("createBazar.shipmentDetails")}
                          disabled={
                            bazarInfo.status === "Active" ? true : false
                          }
                        />
                      </Grid>
                      {
                        bazarInfo.status !== "Active" && (
                          // values.shipmentDetailsFile && (
                          <Grid item xs={12} container>
                            <Typography variant="h6">
                              {t("uploadShipmentDetails")}
                            </Typography>
                          </Grid>
                        )
                        // )
                      }

                      <Grid item xs={12} container>
                        {/* <Grid
                          item
                          container
                          xs={12}
                          md={8}
                          lg={4}
                          style={{ margin: '20px 0px' }}
                        > */}

                        <div
                          style={{
                            display: "flex",
                            // marginTop: '20px',
                            alignItems: "center",
                          }}
                        >
                          {bazarInfo.status !== "Active" && (
                            <UploadButtons
                              handleChange={(e) =>
                                handleProductImageUpload(e, setFieldValue)
                              }
                              type="Link"
                              disabled={
                                bazarInfo.status === "Active" ? true : false
                              }
                            />
                          )}

                          {shipmentFileUploadLoading ? (
                            <div>Loading...</div>
                          ) : (
                            <>
                              {values.shipmentDetailsFile && (
                                <a
                                  href={`${localStorage.imagePath}${values.shipmentDetailsFile}`}
                                  download
                                  target="_blank"
                                >
                                  {values.shipmentDetailsFileName}
                                </a>
                              )}
                            </>
                          )}
                        </div>
                        {/* <div>{values.certificateName}</div> */}
                        {/* <DownloadLink
                        label="Download"
                        filename={values.certificateName}
                        exportFile={() =>
                          Promise.resolve(`${values.companyCertificate}`)
                        }
                      /> */}
                        {/* </Grid> */}
                      </Grid>
                      {!setting && (
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            type="text"
                            name="providerMessage"
                            label={t("createBazar.messageToProvider")}
                          />
                        </Grid>
                      )}
                      {bazarInfo.status !== "Active" && (
                        <Grid item xs={12} align="end">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth={setting ? false : true}
                            size="large"
                          >
                            {t("buttons.send")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Hidden mdDown>
            {!setting && (
              <Grid item lg={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img
                    src="/assets/images/createBazarSteps/step.svg"
                    alt=""
                    width="100%"
                  />
                </Box>
              </Grid>
            )}
          </Hidden>
        </Box>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(
    "useeffect",
    state.serviceProviderReducer.allServiceProvidersById
  );
  return {
    serviceProviders: state.serviceProviderReducer.allServiceProviders,
    serviceProvidersById: state.serviceProviderReducer.allServiceProvidersById,
    providerAdded: state.serviceProviderReducer.providerAdded,
    bazarInfo: state.bazarReducer.bazarInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProviders: () => dispatch(getAllServiceProviders()),
    getProvidersByBazarId: (bazarId) =>
      dispatch(getProviderByBazarCountry(bazarId)),
    addServiceProvider: (deliveryInfo) =>
      dispatch(addServiceProviderToBazar(deliveryInfo)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepThreeDeliveryAndShipment);
