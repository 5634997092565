export const TestimonialsItems = [
  {
    id: 1,
    nam: "غادة الطواشي",
    imgsrc: "/assets/images/Testimonials/غادة.jpeg",
    job: "Seller",
    text: `   ساعدنا نقطة نقطة من خلال لقاءات على eBazarak فريق عمل 
    الزوم في انشاء كشك لكل مشروع ورفع البضاعة فيه والانضمام
    ونحن جاهزين لاستقبال الزبائن في البازار من 9 سبتمبر الى 11 سبتمبر Mompreneur للبازار الخاص ب
    `,
    textEn: `Point by point, through meetings on eBazarak, the Zoom team helped us to set up a booth for each project, raise the goods in it, and join us. We are ready to receive customers 
    in the bazaar from September 9 to September 11  for the bazaar of Mompreneur`,
    margin: "-90px auto 0",
  },

  {
    id: 3,
    nam: "داليا شراب ",
    imgsrc: "/assets/images/Testimonials/دليا.jpeg",
    job: "Organizer",
    text: `e-Bazarak فخورة جداً بالتعامل مع الفتيات والسيدات القائمات على
    بالمهنية لديهم كتير عالية وسرعة الاستجابة والدعم الفني متواصل
     24 ساعة في اليوم. وكتير بفتخر بالشراكة معهم وعندهم كتير أفكار
     حلوة للترويج وقدرة على التواصل مع كل السيدات و تلبية طلباتهم
     واحتياجاتهم على المنصة
     `,
    textEn: `Very proud to be associated with the girls and women of e-Bazarak
     In their professionalism, they have a great deal of high response speed, and technical support is continuous 24 hours a day. I am very proud of partnering with them, and they have many good ideas for promotion and the ability to communicate with all women and meet their requests and needs on the platform.`,
    margin: "-60px auto 0",
  },
  {
    id: 2,
    nam: "ابتسام أبو عاصي",
    imgsrc: "/assets/images/Testimonials/ابتسام.jpeg",
    job: "Seller",
    text: `أكيد فرصة حلوة كتير لتسويق وعرض منتجاتنا لزبائن جدد كنوع
    من التسويق الالكتروني لكن بطريقة سهلة وأنيقة و ممتعة 
    `,
    textEn: `Certainly a very nice opportunity to market and present the products of new customers as a kind of e-marketing, but in an easy, elegant and fun way`,
    margin: "-140px auto 0",
  },
  {
    id: 4,
    nam: "سمر مدوخ",
    imgsrc: "/assets/images/Testimonials/سمر.jpg",
    job: "Seller",
    text: `كتير حلو وأول مره تكون بغزة ان شاء الله تكون ناجحة والتعامل مع 
    فريق العمل الخاص بالبازار كتير حلو وبيساعدو بكل استفسار 
    وما بيبخلو بأي معلومة
    `,
    textEn: `Very nice, and the first time it is in Gaza, God willing, it will be successful. Dealing with the staff of the bazaar is very nice and they help with every inquiry and they are not stingy with any information`,
    margin: "-140px auto 0",
  },
];
