import React, { useEffect, useState } from "react";
import tableIcons from "./../../styles/materialTableIcons";
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  Link,
  Breadcrumbs,
} from "@material-ui/core";
import { getBazarHome } from "../../redux/bazar/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import UserInfoModal from "./../../components/UserInfoModal/index";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { colors } from "../../styles/commonCss";
import "./style.css";
import { format } from "date-fns";
import ControlledAppModal from "../../components/ControlledAppModal";
import ConfirmDeleteModalContent from "../../components/ConfirmDeleteModalContent";
import ControlledAppTable from "../../components/ControlledAppTable";
import { useTranslation } from "react-i18next";
import { deletePendingBooth } from "../../redux/seller/actions";
import Dropdown from "../../components/Dropdown";
import { getAllCountries } from "../../redux/address/actions";
import { getBoothInBazar } from "../../redux/organizer/api";
const BoothList = (props) => {
  const bazarId = props.match.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [cat, setCat] = useState("");
  const [city, setCity] = useState("");

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteBoothId, setDeleteBoothId] = useState("");
  const bazar = useSelector((state) => state.bazarReducer.bazarHome);
  console.log(bazar, "bbb");
  const handlePageChange = (page) => {
    setPage(page);
    debugger;
    fetchData(bazarId, pageSize, page, 0, 0, city, cat, search);
    console.log(page);
  };
  const handleopenConfirmDelete = (boothId) => {
    setDeleteBoothId(boothId);
    setOpenConfirmDelete(true);
  };
  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };
  const deleteBooth = async (boothId) => {
    debugger;
    await dispatch(deletePendingBooth(boothId));
    handleCloseConfirmDelete();
    window.location.reload();
  };
  const handleSearchChange = (search) => {
    setSearch(search);
    fetchData(bazarId, pageSize, 0, 0, 0, city, cat, search);
    console.log(search);
  };
  const handleCatChange = (e) => {
    setCat(e.target.value);
    fetchData(bazarId, pageSize, page, 0, 0, city, e.target.value, search);
    console.log(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
    fetchData(bazarId, pageSize, page, 0, 0, e.target.value, cat, search);
    console.log(e.target.value);
  };
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    fetchData(bazarId, pageSize, 0, 0, 0, city, cat, search);
    console.log(pageSize);
  };
  const getBazarById = (bazarId) => {
    dispatch(getBazarHome(bazarId));
  };
  useEffect(() => {
    fetchData(bazarId, pageSize, page);
  }, []);
  useEffect(() => {
    getBazarById(bazarId);
    dispatch(getAllCountries());
  }, []);
  const toggleHide = (booth) => {
    let data = [...tableData];

    if (booth.isHidden) {
      console.log("unhide", booth.id);
      axios.put("Organizer/UnHideBooth?BoothId=" + booth.id, {});
    } else {
      console.log("hide", booth.id);
      axios.put("Organizer/HideBooth?BoothId=" + booth.id, {});
    }
    data = data.map((item) => {
      if (item.id === booth.id) {
        item.isHidden = !booth.isHidden;
      }
      return item;
    });
    setTableData(data);
  };

  const columns = [
    {
      title: t("logo"),
      field: "image",
      sorting: false,
      render: (row) => {
        return (
          <Avatar
            alt={row?.name ? row.name[0].toUpperCase() : ""}
            src={
              row.logo
                ? localStorage.imagePath + row.logo
                : "/static/images/avatar/1.jpg"
            }
          />
        );
      },
    },
    {
      title: t("name"),
      sorting: false,
      field: "name",
      render: (row) => (
        <Link
          component="button"
          variant="body2"
          onClick={() =>
            props.history.push(
              "/buyer/boothPage/" + row.id + "?notBuyer=" + true
            )
          }
          style={{ color: colors.link, fontSize: "15px" }}
        >
          {row.name}
        </Link>
      ),
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("signUp.city"),
      sorting: false,
      field: "boothCity",
      render: (row) => <div>{row.boothCity}</div>,
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("products"),
      sorting: false,
      field: "numOfProducts",
      render: (row) => (
        <Link
          component="button"
          variant="body2"
          onClick={() =>
            props.history.push(
              "/buyer/boothPage/" + row.id + "?notBuyer=" + true
            )
          }
          style={{ color: colors.link, fontSize: "15px" }}
        >
          {row.numOfProducts}
        </Link>
      ),
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("sales"),
      field: "selles",
      customSort: (a, b) => a.selles - b.selles,
      render: (row) => (
        <div>
          {row.selles} {bazar?.currency}
        </div>
      ),
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("sellerName"),
      sorting: false,
      field: "seller.name",
      cellStyle: { textAlign: "center" },
      render: (row) =>
        row.seller ? (
          <div>
            <UserInfoModal
              user={row.seller}
              role={"Seller"}
              label={row.seller.firstName + " " + row.seller.lastName}
            />
          </div>
        ) : (
          ""
        ),
    },
    {
      title: t("productRequest"),
      field: "numOfProductRequests",
      customSort: (a, b) => a.numOfProductRequests - b.numOfProductRequests,
      render: (row) => (
        <Link
          component="button"
          variant="body2"
          onClick={() =>
            props.history.push("/Organizer/organizerProductsRequests/" + row.id)
          }
          // to={'/buyer/boothPage/' + row.id}
          style={{ color: colors.link, fontSize: "15px" }}
        >
          {row.numOfProductRequests}
        </Link>
      ),
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("DateJoin"),
      sorting: false,
      field: "acceptedBoothTime",
      render: (row) => (
        <div>{format(new Date(row.acceptedBoothTime + "Z"), "dd-MM-yyyy")}</div>
      ),
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("actions"),
      sorting: false,
      field: "actions",

      cellStyle: { textAlign: "center" },
      render: (row) => (
        <Box display="flex" alignItems="center">
          {console.log(row)}
          <>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                props.history.push(
                  "/buyer/boothPage/" + row.id + "?notBuyer=" + true
                )
              }
              // to={'/buyer/boothPage/' + row.id}
              style={{ color: colors.link, fontSize: "15px" }}
            >
              {t("links.view")}
            </Link>
            <div
              className="link-seperator"
              style={{
                width: "1px",
                backgroundColor: "lightgray",
                height: "20px",
                margin: "0 4px",
              }}
            ></div>
          </>
          <Link
            component="button"
            variant="body2"
            onClick={() => toggleHide(row)}
            style={{ color: colors.link, fontSize: "15px" }}
          >
            {row.isHidden ? t("links.unhide") : t("links.hide")}
          </Link>
          <div
            className="link-seperator"
            style={{
              width: "1px",
              backgroundColor: "lightgray",
              height: "20px",
              margin: "0 4px",
            }}
          ></div>
          <IconButton
            style={{
              display: "flex",
              justifySelf: "flex-end",
              padding: "1px",
              textAlign: "end",
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleopenConfirmDelete(row?.id);
            }}
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
        </Box>
      ),
    },
  ];
  const tableDiv = {
    marginTop: "30px",
  };

  const fetchData = async (
    bazarId = bazarId,
    pageSize = pageSize,
    currentPage = page,
    Selles = 0,
    Requests = 0,
    CityId,
    CategoryId,
    search
  ) => {
    setIsLoading(true);
    let url = await getBoothInBazar(
      bazarId,
      pageSize,
      currentPage,
      Selles,
      Requests,
      CityId,
      CategoryId,
      search
    );
    try {
      const { data, count } = url;
      console.log(data, "opoo", "boothCity");
      setTableData(data);
      setCount(count);
    } catch (err) {
      console.trace(err);
      console.log(err.response?.data);
      debugger;
      alert(err.message + "\r\n\r\nSee console for more info.");
    }
    setIsLoading(false);
  };
  const countries = useSelector((state) => state.addressReducer.countries);
  const getCountryCities = (countryId) => {
    const country = countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };

  return (
    <div style={tableDiv}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          style={{ color: "gray", cursor: "pointer" }}
          onClick={() => props.history.push(`/Organizer/bazarHome/${bazarId}`)}
        >
          {bazar.name}
        </Link>
        <Typography className="special">{t("bazarHome.booths")}</Typography>
      </Breadcrumbs>
      <Box display="flex" justifyContent="flex-end">
        <Dropdown
          items={getCountryCities(bazar?.county?.id)}
          label={t("signUp.city")}
          flag={true}
          handleChange={handleCityChange}
          value={city}
        />
        <Dropdown
          items={bazar.categories}
          label={t("category")}
          flag={true}
          handleChange={handleCatChange}
          value={cat}
        />
        {/* <FormControl variant="outlined" style={{ minWidth: "120px" }}>
          <InputLabel id="demo-simple-select-label">{t("category")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="Category"
            // onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}
      </Box>
      <ControlledAppTable
        data={tableData}
        columns={columns}
        isLoading={isLoading}
        handlePageChange={handlePageChange}
        handleSearchChange={handleSearchChange}
        handlePageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        title={t("bazarHome.booths")}
        count={count}
        page={page}
        sorting={true}
        localization={{
          toolbar: {
            searchPlaceholder: t("searchForBooth"),
          },
        }}
      />
      <ControlledAppModal
        handleClickOpen={handleopenConfirmDelete}
        open={openConfirmDelete}
        handleClose={handleCloseConfirmDelete}
      >
        <ConfirmDeleteModalContent
          handleDeleteConfirmed={deleteBooth}
          handleClose={handleCloseConfirmDelete}
          productId={deleteBoothId}
        />
      </ControlledAppModal>
    </div>
  );
};

export default BoothList;
