import React from 'react'
import Pagination from '@material-ui/lab/Pagination'

const AppPagination = ({
  count,
  page,
  handlePageChange,
  color = 'primary',
  size = 'small',
}) => {
  return (
    <div
      className={'margin-top-30'}
      style={{ marginTop: '30px', marginBottom: '30px' }}
    >
      <Pagination
        // count={Math.ceil(boothsCount / pageSize)}
        count={count}
        color={'primary'}
        size={size}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  )
}

export default AppPagination
