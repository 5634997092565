import React, { useEffect, useState } from "react";
import { createRef } from "react";
import UnControlledTable from "../../components/UncontrolledTable";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Box, Container, Link } from "@material-ui/core/index";
import Dropdown from "../../components/Dropdown";
import { format } from "date-fns";
import { colors } from "../../styles/commonCss";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Header from "../../components/Header";
import OrderItemsModal from "./../../components/ManageOrdersSection/partials/OrderItemsModal/index";
import { CardContent, Typography, Card } from "@material-ui/core";
import ShippmentAdressModal from "../../components/ShippmentAddress";
import NoData from "../../components/NoData";
import PrimarySearchAppBar from "./../../components/lib/AppBarPublic";
import MenuAppBar from "./../../components/testHeader/index";
import * as Yup from "yup";
import ControlledAppModal from "../../components/ControlledAppModal";
import CheckForm from "../../components/CheckFormik";
const statusIndicator = {
  Complete: { title: "Complete", color: "green" },
  Processing: { title: "Processing", color: "blue" },

  Pending: { title: "Pending", color: "orange" },
};
const BuyerOrders = (props) => {
  const tableRef = createRef();
  const buyerId = localStorage.userId;
  const { t } = useTranslation();
  const [providerBazars, setProviderBazars] = useState([]);
  const bazarId = useParams().bazarId || "";
  const [selectedBazar, setSelectedBazar] = useState(bazarId || "");
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [orderDetails, setOrderDetails] = useState("");
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    cityId: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
  });
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("mustBe15"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
    phoneNumber: Yup.string()
      .nullable()
      .required(t("validation.phoneRequired")),
    // .matches(phoneRegExp, t("validation.invalidPhone")),
    whatsNumber: Yup.string()
      .nullable()
      .required(t("validation.phoneRequired")),
    // .matches(phoneRegExp, t("validation.invalidPhone")),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values, "handleSubmit");

    axios
      .put(
        `Buyer/EditBuyerAddress?BuyerId=${localStorage.getItem(
          "userId"
        )}&OrderId=${orderId}`,
        values,
        {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        }
      )
      .then((response) => {
        handleClose();
        window.location.reload();
        history.replace(`/Buyer/orders`);
      });
    setTimeout(() => {
      setSubmitting(false);
    }, 200);
  };
  const noResultsMessage = (
    <NoData
      src="/assets/images/SVG/shipping.svg"
      onClickFn={() => props.history.push("/")}
      msg="infoMessages.noOrdersCreateYourFirstOne"
      btnText="buttons.goShopping"
    />
  );

  const handleOpenOrderModal = (e, rowData) => {
    setOrderDetails(rowData);
    setOpenOrderModal(true);
  };

  const handleCloseOrderModal = () => {
    setOpenOrderModal(false);
  };
  // const handleBazarFilterChange = (e) => {
  //   setSelectedBazar(+e.target.value)
  //   tableRef.current.onQueryChange()
  // }
  return (
    <>
      <Header />

      <Container style={{ marginTop: "150px ", marginBottom: "150px" }}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              style={{ textAlign: "center" }}
            >
              {t("anyQuestions")}
              <span style={{ color: "orange", direction: "ltr" }}>
                {t("+966 56 121 1068")}
              </span>
            </Typography>
          </CardContent>
        </Card>
        <UnControlledTable
          tableRef={tableRef}
          title={t("ProviderManage.manageOrders")}
          search={false}
          columns={[
            {
              title: t("ProviderManage.order"),
              field: "orderNumber",

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => {
                return (
                  <Link
                    component="button"
                    style={{
                      color: colors.link,
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                    variant="body2"
                    onClick={(e) => handleOpenOrderModal(e, row)}
                  >
                    {row.orderNumber}
                  </Link>
                );
              },
            },
            {
              title: t("status"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FiberManualRecordIcon
                      // fontSize="small"
                      style={{
                        color: statusIndicator[row.status]?.color,
                        marginRight: "10px",
                        fontSize: "12px",
                      }}
                    />
                    {row.status}
                  </Box>
                </div>
              ),
            },
            {
              title: t("totalPrice"),
              // field: 'organizer',

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row.currency} {row.total}
                </div>
              ),
            },
            // {
            //   title: t('ProviderManage.earning'),

            //   cellStyle: { width: 'fit-content', textAlign: 'center' },
            //   render: (row) => <div>JD {row?.earning}</div>,
            // },
            {
              title: t("ProviderManage.paymentMethod"),

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => <div>{row.paymentMethod}</div>,
            },
            {
              title: t("date"),
              field: "date",

              render: (row) => (
                <div>
                  {format(new Date(row?.orderDate || ""), "MMMM dd, yyyy ")}
                </div>
              ),
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("shippingAddress"),

              render: (row) => (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ShippmentAdressModal
                      label={t("links.view")}
                      user={row}
                      email={row.buyer.email}
                    />
                    <span
                      style={{
                        margin: "0 .5rem",
                      }}
                    >
                      |
                    </span>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setInitialValues({
                          id: row.buyer.id,
                          firstName: row.firstName,
                          lastName: row.lastName,
                          countryId: row.country.id,
                          cityId: row.city.id,
                          street: row.street,
                          postalCode: row.postalCode,
                          phoneNumber: row.phoneNumber,
                          whatsNumber: row.whatsNumber,
                        });
                        setOrderId(row.orderNumber);
                        handleClickOpen();
                      }}
                      style={{
                        color: colors.link,
                        fontSize: "15px",
                        textDecoration: "underline",
                      }}
                    >
                      {t("links.edit")}
                    </Link>
                  </Box>
                </>
              ),
              cellStyle: { textAlign: "center" },
            },
          ]}
          data={(query) => {
            return new Promise(async (resolve, reject) => {
              const { page, pageSize, search } = query;
              try {
                debugger;
                const result = await axios.get(
                  "Buyer/GetBuyerOrders?BuyerId=" + localStorage.userId
                );
                //   axios.get(
                //     `Buyer/GetBuyerOrders?BuyerId=${buyerId}&PageSize=${pageSize}&PageIndex=${page}`,
                //   )
                //    await GetProviderOrdersFilterByBazarAsync(
                //       buyerId,
                //       selectedBazar,
                //       page + 1,
                //       pageSize,
                //     )
                debugger;
                console.log(result, "ress");
                // setData(result.data)
                resolve({
                  data: result.data.data,
                  page: query.page,
                  totalCount: result.data.count || 1,
                });
              } catch (error) {
                console.log(error);
                console.log(error?.response);
                resolve({
                  data: [],
                  page: query.page,
                  totalCount: 1,
                });
              }
            });
          }}
          localization={{
            body: {
              emptyDataSourceMessage: noResultsMessage,
            },
          }}
        />

        <OrderItemsModal
          handleOpen={handleOpenOrderModal}
          handleClose={handleCloseOrderModal}
          open={openOrderModal}
          orderNo={orderDetails.orderNumber}
          orderItems={orderDetails.orderItems}
          shippingPrice={orderDetails.shippingPrice}
          total={orderDetails.total}
          subTotal={orderDetails.subTotal}
          role="Seller"
        />
        <ControlledAppModal open={open} handleClose={handleClose}>
          <div
            style={{
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CheckForm
              buyer={true}
              flag={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              handleSubmit={handleSubmit}
            />
          </div>
        </ControlledAppModal>
      </Container>
    </>
  );
};

export default BuyerOrders;
