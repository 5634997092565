import { combineReducers } from 'redux'
import registerReducer from './registeration/reducer'
import addressReducer from './address/reducer'
import loginReducer from './auth/reducer'
import bazarReducer from './bazar/reducer'
import catalogReducer from './catalog/reducer'
import serviceProviderReducer from './serviceProvider/reducer'
import organizerReducer from './organizer/reducer'
import sellerReducer from './seller/reducer'
import cartReducer from './cart/reducer'
import msgReducer from './Message/reducer'
import boothReducer from './booth/reducer'

export default combineReducers({
  cartReducer,
  registerReducer,
  addressReducer,
  loginReducer,
  bazarReducer,
  catalogReducer,
  serviceProviderReducer,
  organizerReducer,
  sellerReducer,
  msgReducer,
  boothReducer,
})
