import {
  GET_ALL_SERVICE_PROVIDERS,
  GET_SELLER_BOOTHS,
  GET_SELLER_BY_ID,
  EDIT_SELLER_PROFILE,
  FILTER_BOOTHS_ON_STATUS,
  GET_SELLER_PENDING_BOOTHS,
  DELETE_SELLER_PENDING_BOOTH,
  LOADING_SELLER_PENDING_BOOTH,
  LOADING_SELLER_BOOTHS,
} from "../actionTypes";
const initialState = {
  sellers: [],
  seller: {
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    countryName: "",
    cityId: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
    zoomLink: "",
    companyCertificate: "",
    certificateName: "",
    preCode: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
  },
  sellerBooths: [],
  filteredBooths: [],
  pendingBooths: [],
  loadingPendingBooths: false,
  loadingBooths: false,
};
const sellerReducer = (state = initialState, action) => {
  let sellers = [...state.sellers];
  let seller = { ...state.seller };
  let sellerBooths = [...state.sellerBooths];
  let filteredBooths = [...state.filteredBooths];
  let pendingBooths = [...state.pendingBooths];
  switch (action.type) {
    case GET_SELLER_BY_ID:
      const newSeller = action.payload;
      seller = {
        ...newSeller,
        countryId: newSeller?.country?.id || newSeller.countryId,
        countryName:
          localStorage.i18nextLng === "en"
            ? newSeller?.country?.name
            : newSeller?.country?.arabicName || newSeller.countryName,
        socialMediaLinks: newSeller.socialMediaLinks.map((socialLink) => ({
          url: socialLink.url,
          socialMediaType: socialLink.type,
        })),
      };

      return {
        ...state,
        seller,
      };
    case GET_SELLER_BOOTHS:
      sellerBooths = action.payload.data;
      console.log(sellerBooths);
      return {
        ...state,
        filteredBooths: sellerBooths,
        sellerBooths,
      };
    case GET_SELLER_PENDING_BOOTHS:
      pendingBooths = action.payload.data;
      console.log(sellerBooths);
      return {
        ...state,
        pendingBooths,
      };
    case FILTER_BOOTHS_ON_STATUS:
      const status = action.payload;
      debugger;

      filteredBooths =
        status === "All"
          ? sellerBooths
          : sellerBooths.filter((booth) => booth.bazarStatus === status);
      return {
        ...state,
        sellerBooths,
        filteredBooths,
      };

    case EDIT_SELLER_PROFILE:
      seller = action.payload;
      debugger;
      return {
        ...state,
        seller,
      };
    case DELETE_SELLER_PENDING_BOOTH:
      debugger;
      const deleteBoothId = action.payload;
      pendingBooths = pendingBooths.filter(
        (booth) => booth.id !== deleteBoothId
      );
      filteredBooths = filteredBooths.filter(
        (booth) => booth.id !== deleteBoothId
      );
      debugger;
      return {
        ...state,
        pendingBooths,
        filteredBooths,
      };
    case LOADING_SELLER_PENDING_BOOTH:
      return {
        ...state,
        loadingPendingBooths: action.payload,
      };
    case LOADING_SELLER_BOOTHS:
      return {
        ...state,
        loadingBooths: action.payload,
      };
    default:
      return state;
  }
};
export default sellerReducer;
