import React, { useEffect, useState } from "react";
import ControlledAppTable from "./../../components/ControlledAppTable/index";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import BoothRequetActionsDropdown from "./../../components/BoothRequetActionsDropdown/index";
import RequestActionsDropDown from "./../../components/RequestActionsDropdown/index";
import RequestInfoModal from "./../../components/RequestInfoModal/index";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptBazarRequest,
  declineBazarRequest,
  getProviderBazarsRequests,
} from "../../redux/serviceProvider/actions";
import { colors } from "../../styles/commonCss";

import BazarRequestInfoModal from "./../../components/BazarRequestInfoModal/index";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import { Link } from "@material-ui/core/index";
import { useTranslation } from "react-i18next";
import { setSnackbar } from "../../redux/Message/actions";

const headerStyle = {
  textAlign: "center",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};
const useStyles = makeStyles((theme) => ({
  dropDown: { width: "150px" },
}));

const ProviderBazarsRequests = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const providerId = localStorage.userId;
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(5);
  const [currentRow, setCurrentRow] = useState("");

  // const [tableData, setTableData] = useState([])

  const [openBazarInfoModal, setOpenBazarInfoModal] = useState(false);
  const [declineBazarId, setDeclineBazarId] = useState(false);
  const bazarRequests = useSelector(
    (state) => state.serviceProviderReducer.bazarRequests
  );
  const bazarRequestsCount = useSelector(
    (state) => state.serviceProviderReducer.bazarRequestsCount
  );
  const loadingRequests = useSelector(
    (state) => state.serviceProviderReducer.loadingBazarRequests
  );
  useEffect(() => {
    // fetchData()
    // props.getBoothRequests(page, pageSize, filteredBazarName)
    // props.getOrganizerBazars()
    dispatch(getProviderBazarsRequests(providerId, page + 1, pageSize));
    setTimeout(() => {
      console.log(bazarRequestsCount);
    }, 2000);
  }, []);
  const handleAcceptBazarRequest = (id) => {
    debugger;
    dispatch(acceptBazarRequest(id)).then(() =>
      dispatch(setSnackbar(true, "success", "Bazar request Accepted"))
    );
    // dispatch(getProviderBazarsRequests(providerId, page + 1, pageSize))
    setOpenBazarInfoModal(false);
  };
  const handleDeclineBazarRequest = (id) => {
    setOpenBazarInfoModal(false);
    dispatch(declineBazarRequest(id)).then(() =>
      dispatch(setSnackbar(true, "success", "Bazar request rejected "))
    );
    setDeclineBazarId(id);
    console.log(id);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    debugger;
    dispatch(getProviderBazarsRequests(providerId, newPage + 1, pageSize));

    console.log(newPage);
  };
  const handlePageSizeChange = (newSize) => {
    dispatch(getProviderBazarsRequests(providerId, page + 1, newSize));

    setPageSize(newSize);
    console.log(newSize);
  };
  const handleOpenBazarInfoModal = (e, row) => {
    setCurrentRow(row);
    setOpenBazarInfoModal(true);
  };

  const handleCloseBazarInfoModal = () => {
    setOpenBazarInfoModal(false);
  };
  const columns = [
    {
      title: t("bazarName"),
      field: "name",
      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => (
        <Link
          component="button"
          style={{
            color: colors.link,
            fontSize: "15px",
            textDecoration: "underline",
          }}
          variant="body2"
          onClick={(e) => handleOpenBazarInfoModal(e, row)}
        >
          {row.name}
        </Link>
      ),
    },
    {
      title: t("organizerName"),
      field: "organizer.firstName  organizer.lastName",

      cellStyle: { width: "fit-content", textAlign: "center" },
      // headerStyle: { textAlign: 'left' },
      render: ({ organizer: { firstName, lastName } }) => {
        return <div>{firstName + " " + lastName}</div>;
      },
    },
    {
      title: t("requestDate"),

      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => {
        return (
          <div style={{ fontWeight: 400, fontSize: "14px" }}>
            <div>
              {format(new Date(row.providerDateRequest), "MMMM dd, yyyy ")}
            </div>
          </div>
        );
      },
    },
    {
      title: t("location"),
      field:
        localStorage.i18nextLng === "en" ? "county.name" : "county.arabicName",
      cellStyle: { width: "fit-content", textAlign: "center" },
    },
    {
      title: t("ProviderBazar.standardPrice"),
      field: "providerStandardPrice",
      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => <div>JD {row.providerStandardPrice}</div>,
    },
    {
      title: t("ProviderBazar.largePrice"),
      field: "providerLargePrice",
      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => <div>JD {row.providerLargePrice}</div>,
    },
    {
      title: t("status"),
      cellStyle: { width: "fit-content", textAlign: "center" },
      field: "status",
    },
    {
      title: t("actions"),
      // field: 'county[name]',
      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => {
        console.log(row);
        return (
          <>
            {row.providerResponse === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <RequestActionsDropDown
                  handleAcceptRequest={handleAcceptBazarRequest}
                  handleDeclineRequest={handleDeclineBazarRequest}
                  id={row.id}
                  message={true}
                  providerResponse={row.providerResponse}
                />
                <div className="link-seperator"></div>
              </div>
            ) : (
              <div
                style={{
                  padding: "10px",
                  color: "white",
                  backgroundColor:
                    row.providerResponse === 1 ? "#57D953" : "red",
                }}
              >
                {row.providerResponse === 1
                  ? t("productStatus.accepted")
                  : t("productStatus.declined")}
                {/* {t('productStatus.' + row.providerResponse)} */}
              </div>
            )}
          </>
        );
      },
    },
  ];
  console.log(bazarRequests);
  return (
    <div>
      <ControlledAppModal
        handleOpen={handleOpenBazarInfoModal}
        handleClose={handleCloseBazarInfoModal}
        open={openBazarInfoModal}
        style={{ maxWidth: "1100px" }}
      >
        {console.log(currentRow, "currentRow")}
        <BazarRequestInfoModal
          organizer={currentRow.organizer}
          handleAcceptRequest={handleAcceptBazarRequest}
          handleDeclineRequest={handleDeclineBazarRequest}
          {...currentRow}
        />
      </ControlledAppModal>
      <ControlledAppTable
        data={bazarRequests}
        columns={columns}
        isLoading={loadingRequests ? true : false}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        count={+bazarRequestsCount}
        page={page}
        title={t("sideMenu.bazarRequests")}
        search={false}
      />
    </div>
  );
};

export default ProviderBazarsRequests;
