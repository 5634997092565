import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";

import { Box, Button, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";

import Link from "@material-ui/core/Link";
import { colors } from "../../styles/commonCss";
import PersonIcon from "@material-ui/icons/Person";

import { connect, useDispatch, useSelector } from "react-redux";

import { Field, Form, Formik } from "formik";
import FormikField from "../FormikField";
import CategoriesDropDown from "./../CategoriesDropDown/index";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import FormikSelect from "../FormikSelect";
import * as Yup from "yup";
import { handleLogout } from "../../utils/setLocalStorage";
import { getBazarById } from "../../redux/bazar/actions";

import { formatCurrency } from "./../../utils/formatCurrency";
import { uploadImageAsync } from "./../../service/image";

import UploadButtons from "./../UploadButtons/index";
import { useTranslation } from "react-i18next";
import { setSnackbar } from "../../redux/Message/actions";
import axios from "axios";
import { getSellerById } from "../../redux/seller/actions";
import { useHistory } from "react-router";
import fileChangedHandler from "../../containers/ImgUploader";
const useStyles = makeStyles((theme) => ({
  //   paper: {
  //     position: 'absolute',
  //     top: `9%`,
  //     left: `32%`,
  //     width: '40%',

  //     backgroundColor: theme.palette.background.paper,
  //     // border: '2px solid #000',
  //     boxShadow: theme.shadows[5],
  //     padding: theme.spacing(2, 4, 4),
  //     maxHeight: 520,
  //     overflowY: 'auto',
  //     // [theme.breakpoints.down('sm')]: {

  //     // },
  //     [theme.breakpoints.down('md')]: {
  //       width: '80%',
  //       top: `9%`,
  //       left: `10%`,
  //     },
  //     [theme.breakpoints.up('lg')]: {
  //       width: '40%',
  //     },
  //   },
  textCenter: {
    textAlign: "center",
  },
  large: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    marginBottom: "5px",
    background: "gray",
  },
  avatarSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "5px 20px ",
  },
  marginRight: {
    marginRight: "12px",
  },
  marginLeft: {
    marginLeft: "12px",
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  linkColor: { color: colors.link },
  uploadBtn: {
    color: colors.link,
    fontSize: "15px",
    textDecoration: "underline",
    cursor: "pointer",
    margin: "20px 0",
  },
  errorColor: { color: "red" },
  dir: { direction: "rtl" },
}));

const BookBooth = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  console.log(props, "opooo");
  const currency = props?.boothBazar?.currency;
  const {
    bazarId,
    countries,
    boothBazar: { id, paymentPlan, flatFeePlan, commission },
    // handleSubmit,
    handleCloseBookBoothModal,
  } = props;

  const seller = useSelector((state) => state.sellerReducer.seller);
  console.log(seller);
  useEffect(() => {
    props.getBazar(bazarId);
    dispatch(getSellerById(localStorage.userId));
  }, []);

  const getCountryCities = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };

  console.log(props.bazarId);

  const getPaymentValue = () => {
    switch (paymentPlan) {
      case "FlatFee":
        return `${formatCurrency(flatFeePlan)} ${currency}`;
      case "Commission":
        return commission + "%";
      case "FlatFeesWithCommission":
        return `${formatCurrency(flatFeePlan)} ${currency} + 
         ${t("createBazar.paymentPlan.commission")} ${commission}%`;
      default:
        break;
    }
  };
  const paymentPlantranslated = () => {
    switch (paymentPlan) {
      case "FlatFee":
        return t("createBazar.paymentPlan.flatFee");
      case "Commission":
        return t("createBazar.paymentPlan.commission");
      case "FlatFeesWithCommission":
        return t("createBazar.paymentPlan.FlatFeesWithCommission");
      default:
        break;
    }
  };

  const initialValues = {
    name: "",
    description: "",
    logo: "",
    sellerId: localStorage.userId,
    cityId: "",
    street: "",
    zipCode: "",
    bazarId: +props.bazarId,
    countryId: "",
    categories: [],
    acceptPlanAndPayment: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(20, t("mustBe")).required(t("boothRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    categories: Yup.array().min(1).required(),
    acceptPlanAndPayment:
      paymentPlan === "FlatFee" && flatFeePlan != 0
        ? Yup.boolean().required().isTrue()
        : "",
    // : "",
  });
  // if (paymentPlan === "FlatFee" && flatFeePlan === 0) {
  //   delete initialValues.acceptPlanAndPayment;
  //   delete validationSchema.acceptPlanAndPayment;
  // }
  const handleBoothLogoUpload = async (imag, setFieldValue) => {
    // e.preventDefault();
    // e.stopPropagation();
    const img = imag;
    const name = img.name;

    try {
      const formDate = new FormData();
      formDate.set(name, img);
      const imageName = await uploadImageAsync(formDate);

      setFieldValue("logo", imageName);

      console.log(imageName);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let newValues = { ...values };
    const categoriesIds = newValues.categories.map((cat) => cat.id);
    newValues.categories = categoriesIds;
    console.log(newValues);

    axios
      .post("Booth/CreateBooth", newValues)
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);

        dispatch(
          setSnackbar(true, "success", t("messages.Requestbookboothsent"))
        );

        handleCloseBookBoothModal();
        history.push("/dashboard/Seller");
      })
      .catch((error) => {
        console.log(error);
        console.log(error?.response);
        handleCloseBookBoothModal();
        if (error?.response?.data.message)
          dispatch(setSnackbar(true, "error", error?.response?.data.message));
        setSubmitting(false);
        if (error?.response?.status === 401) {
          handleLogout();
          window.location.href = "/login";
        }
      });
  };
  // console.log('selectedCountry222', selectedCountry)

  return (
    // <ControlledAppModal label="book a booth">
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isSubmitting,
          handleReset,
          setFieldValue,
          touched,
          handleBlur,
        }) => {
          console.log(values);
          // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

          return (
            <Form autoComplete="false" style={{ width: "100%" }}>
              <div className={classes.avatarSection}>
                <h3 className={classes.textCenter} id="simple-modal-title">
                  {t("boothHome.boothInfo")}
                </h3>
                <Avatar
                  alt={"logoImg"}
                  src={`${localStorage.imagePath}${values.logo}`}
                  className={classes.large}
                />
                {/* <input
                  accept="image/*"
                  className={classes.input}
                  id="bookBooth"
                  type="file"
                  name="logo"
                  style={{ display: 'none' }}
                  onChange={(e) => handleBoothLogoUpload(e, setFieldValue)}
                />
                <Link
                  //   variant="standard"
                  className={classes.uploadBtn}
                  // color="info"
                  component="button"
                  variant="body2"
                >
                  <label htmlFor="bookBooth" className={classes.uploadBtn}>
                    Upload Logo
                  </label>
                  
                </Link> */}
                <UploadButtons
                  type="Link"
                  name="logo"
                  handleChange={(e) =>
                    fileChangedHandler(
                      e,
                      setFieldValue,
                      null,
                      handleBoothLogoUpload
                    )
                  }
                  text="upload Image"
                />
              </div>
              <div>
                <h3 className={classes.basicInfoTitle}>
                  {t("SellerBasicInfo")}
                </h3>
                <div style={{ width: "100%" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <PersonIcon
                          className={classes.marginRight}
                          fontSize={"small"}
                          color="secondary"
                        />
                        <Typography>
                          {seller.firstName + " " + seller.lastName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <LocationOnOutlinedIcon
                          color="secondary"
                          className={classes.marginRight}
                          fontSize={"small"}
                          style={{ marginTop: "2px" }}
                        />
                        {console.log(seller, "opp")}
                        <Typography>
                          {localStorage.i18nextLng === "en"
                            ? seller?.country?.name
                            : seller?.country?.arabicName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <MailIcon
                          className={classes.marginRight}
                          fontSize={"small"}
                          color="secondary"
                        />
                        <Typography>{seller?.email}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <div> */}
                      <Box display="flex" alignItems="center" width="100%">
                        <PhoneIcon
                          className={classes.marginRight}
                          fontSize={"small"}
                          color="secondary"
                        />
                        <Typography
                          className={
                            localStorage.i18nextLng === "en" ? "" : classes.dir
                          }
                        >
                          {seller?.phoneNumber}
                        </Typography>
                      </Box>
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={12}>
                      {seller?.whatsNumber && (
                        <div>
                          <Box display="flex" alignItems="center" mt={1}>
                            <WhatsAppIcon
                              className={classes.marginRight}
                              fontSize={"small"}
                              color="secondary"
                            />
                            <Typography>{seller.whatsNumber}</Typography>
                          </Box>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <h3 className={classes.basicInfoTitle}>
                    {t("BoothBasicInfo")}
                  </h3>
                </Grid>
                <Grid
                  container
                  item
                  spacing={3}
                  lg={12}
                  xs={12}
                  justify="center"
                >
                  <Grid item xs={12}>
                    <FormikField name={"name"} label={t("name")} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CategoriesDropDown
                      categoryType="Bazar"
                      bazarId={bazarId}
                      setFieldValue={setFieldValue}
                      error={
                        touched.categories && errors.categories
                          ? errors.categories
                          : null
                      }
                      value={values.categories}
                      blur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={3}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        type="text"
                        name="description"
                        label={t("description")}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h3 className={classes.basicInfoTitle}>
                    {t("BoothAddress")}
                  </h3>
                </Grid>
                <Grid
                  container
                  item
                  spacing={3}
                  lg={12}
                  xs={12}
                  justify="center"
                >
                  <Grid item xs={12}>
                    <FormikSelect
                      name="countryId"
                      label={t("signUp.country")}
                      items={props.countries}
                      value={values.countryId}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("countryId", e.target.value);
                        setFieldValue("cityId", "");
                        // selectFirstCity(values)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikSelect
                      name="cityId"
                      label={t("signUp.city")}
                      items={getCountryCities(values.countryId)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikField name="street" label={t("signUp.street")} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h3 className={classes.basicInfoTitle}>
                    {t("PlaneDetails")}
                  </h3>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div>
                    <Typography>
                      {paymentPlantranslated()} : {getPaymentValue()}
                    </Typography>
                  </div>
                </Grid>
                {paymentPlan === "FlatFee" && flatFeePlan === 0 ? (
                  <div style={{ marginBottom: "10px" }}>{t("free")}</div>
                ) : (
                  //  ||(paymentPlan !== 'Commission'
                  <Grid item xs={12}>
                    <Field
                      required
                      name="acceptPlanAndPayment"
                      type="checkbox"
                      component={CheckboxWithLabel}
                      color="primary"
                      Label={{
                        label: `${t(
                          "agreeAndConfirm"
                        )} ${paymentPlantranslated()}`,
                        className: errors.acceptPlanAndPayment
                          ? classes.errorColor
                          : undefined,
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} style={{ margin: "20px 0" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting ? true : false}
                  >
                    {/* {t('signUp.save')} */}
                    {t("buttons.save")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log(state.addressReducer.countries);
  return {
    // countries: state.addressReducer.countries,
    boothBazar: state.bazarReducer.bazarInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getCountries: () => dispatch(getAllCountries()),
    getBazar: (bazarId) => dispatch(getBazarById(bazarId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookBooth);
