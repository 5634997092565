import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formikField: {
    margin: ' 10px 0',
  },
  uploadField: {
    border: '1px ',
    borderColor: theme.palette.secondary,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  buyerImg: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: '0px',
    marginRight: '-440px',
    // display: 'none',
    // '[dir="rtl"]': {
    //   justifyContent: 'flex-start',
    // },
  },
  signInLink: {
    color: theme.palette.primary,
  },
}))
