import React from "react";
import { makeStyles, Card } from "@material-ui/core";
import { textalign } from "./../../styles/commonCss";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  root: {
    maxWidth: "81%",
    margin: "1.5% 10% 0 10%",
    padding: "2%",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
const Message = () => {
  const classes = useStyles();
  const {t}=useTranslation();
  return (
    <Card className={classes.root}>
      <img
        src="/assets/images/summary/mark.svg"
        alt="mark"
        style={{ width: "2rem", height: "2rem" }}
      />
      <p style={{ textAlign: "center" }}>
        {t("summaryPage.thankYou!")}
      </p>
      <hr style={{ width: "70%", height: "1%" }} />
    </Card>
  );
};

export default Message;
