import { makeStyles, Card, CardContent, Divider } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Avatar } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    margin: "1% 0",
  },
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // backgroundColor: "#EDECEC",
    // padding: "0% 1%",
  },
});
const ReviewCard = ({ name, date, content, rate, profileImage }) => {
  const classes = useStyles();
  return (
    // <Card className={classes.root}>
    //   <div className={classes.dis}>
    //     <p>{name || "userName"}</p>
    //     <p>{new Date(date).toDateString()}</p>
    //   </div>
    //   <CardContent>{content}</CardContent>
    //   <Rating
    //     name="read-only"
    //     value={rate}
    //     readOnly
    //     style={{ padding: "1%" }}
    //   />
    // </Card>
    <>
      {/* <div className={classes.dis}>
        <p>{name || "userName"}</p>
        <p>{new Date(date + "Z").toDateString()}</p>
      </div>
      <Rating
        name="read-only"
        value={rate}
        readOnly
        // style={{ padding: "1%" }}
      />
      <p style={{ margin: "0" }}>{content}</p>
      <Divider
        style={{ background: "#a9a9a9", margin: "1rem 0" }}
        variant="middle"
      /> */}
      <div className="review-card">
        <div className="media">
          {/* <img
            className="mr-3"
            src={
              profileImage
                ? localStorage.imagePath + profileImage
                : ""
            }
            width="90px"
            alt="profileImage"
          /> */}
          <Avatar
            src={profileImage}
            alt={name}
            style={{ margin: "0 1rem", width: "80px", height: "80px" }}
          />
          <div className="media-body">
            <h5 className="mt-0">{name || "userName"}</h5>
            <div className="rating-time d-flex">
              <div className="rating">
                {Array.from({ length: rate }, (v, i) => (
                  <i key={i} className="fas fa-star"></i>
                ))}
              </div>
              <div className="time mx-2">
                <span>{new Date(date + "Z").toDateString()}</span>
              </div>
            </div>
            <p>{content}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
