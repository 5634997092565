import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Link,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ccyFormat from "../../utils/ccyFormatFun";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    margin: "1% 5%",
    boxShadow: "none",
    backgroundColor: "#F7F7F7",
  },
  disp: {
    display: "flex",
  },
  p: {
    padding: "0.1% 1%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  shipmentDetails: { margin: "10px 0" },
});
const SummaryCard = ({
  handleDeleteOrder,
  orderNumber,
  orderDate,
  address,
  shippingPrice,
  shipmentDetails,
  shipmentDetailsFile,
  subTotal,
  total,
  currency,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {orderDate && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 style={{ margin: "2% 5%" }}>
              {t("summaryPage.orderDetails")} :
            </h5>
            {/* <Link
              component="button"
              style={{ margin: "2% 5%" }}
              variant="body2"
              onClick={handleDeleteOrder}
            >
              Delete Order
            </Link> */}
            <Link
              component="button"
              style={{ margin: "2% 5%" }}
              variant="body2"
              onClick={() => history.push("/Buyer/orders")}
            >
              <b> {t("trackYourOrder")}</b>
            </Link>
          </div>
          <Card className={classes.root}>
            <CardContent className={classes.disp}>
              <Typography variant="p">
                {t("summaryPage.orderNumber")}:
              </Typography>
              <span className={classes.p}>
                <Link
                  component="button"
                  style={{ margin: "2% 5%" }}
                  variant="body2"
                  onClick={() => history.push("/Buyer/orders")}
                >
                  {orderNumber}
                </Link>
              </span>
            </CardContent>
            <CardContent className={classes.disp}>
              <Typography variant="p">{t("summaryPage.orderDate")}:</Typography>
              <span className={classes.p}>
                {new Date(orderDate).toDateString()}
              </span>
            </CardContent>
          </Card>
        </div>
      )}
      <div>
        <h5
          style={
            localStorage.i18nextLng === "en"
              ? { margin: "2% 5%" }
              : { textAlign: "right", margin: "2% 5%" }
          }
        >
          {t("summaryPage.shipmentDetails")}:
        </h5>
        <Card className={classes.root}>
          <CardContent className={classes.disp}>
            <Typography variant="p">{t("summaryPage.address")}:</Typography>
            <span className={classes.p}>{address}</span>
          </CardContent>
          {/* <CardContent className={classes.disp}>
            <Typography variant="p">
              {t("summaryPage.shipmentPrice")}:
            </Typography>
            <span className={classes.p}>
              {currency} {ccyFormat(shippingPrice)}
            </span>
          </CardContent> */}

          {/* <CardContent>
            <Typography variant="p" style={{ fontWeight: "600" }}>
              {t("shipmentPriceDetails")}:
            </Typography>
            <span className={classes.shipmentDetails} style={{ width: "80%" }}>
              <span
                className={classes.p}
                style={{ margin: "10px", display: "block", padding: 0 }}
              >
                {shipmentDetails &&
                  shipmentDetails
                    .split(/\r\n|\r|\n/g)
                    .map((line) => <div>{line}</div>)}
              </span>
            </span>
          </CardContent>
          <CardContent>
            <Typography
              variant="p"
              style={{ fontWeight: "600", marginBottom: "20px" }}
            >
              {t("shipmentPriceFileDetails")}:
            </Typography>
            {shipmentDetailsFile && (
              <div
                style={{ margin: "20px 10px", padding: 0 }}
                className={classes.p}
              >
                <span> {shipmentDetailsFile}</span>
                <a
                  href={`${localStorage.imagePath}${shipmentDetailsFile}`}
                  download
                  target="_blank"
                  style={{ margin: "0 10px" }}
                >
                  download
                </a>
              </div>
            )}
          </CardContent> */}

          {/* <CardContent className={classes.disp}>
            <Typography variant="p">Received Date:</Typography>
            <span className={classes.p}>7/4/2020</span>
          </CardContent> */}
        </Card>
      </div>
      <div>
        <h5
          style={
            localStorage.i18nextLng === "en"
              ? { margin: "2% 5%" }
              : { textAlign: "right", margin: "2% 5%" }
          }
        >
          {t("summaryPage.orderSummary")} :{" "}
        </h5>
        <Card className={classes.root}>
          <CardContent className={classes.disp}>
            <Typography variant="p">{t("subtotal")}:</Typography>
            <span className={classes.p}>
              {currency} {subTotal}
            </span>
          </CardContent>
          <CardContent className={classes.disp}>
            <Typography variant="p">{t("shippingInsideCountry")}:</Typography>
            <span className={classes.p}>{t("InDelivery")}</span>
          </CardContent>
          <CardContent className={classes.disp}>
            <Typography variant="p">{t("shippingOutsideCountry")}:</Typography>
            <span className={classes.p}>{t("OutDelivery")}</span>
          </CardContent>
          {/* <CardContent className={classes.disp}>
            <Typography variant="p">{t("summaryPage.shipment")} :</Typography>
            <span className={classes.p}>
              {currency} {ccyFormat(shippingPrice)}
            </span>
          </CardContent>
          <CardContent className={classes.disp}>
            <Typography variant="p">{t("total")}:</Typography>
            <span className={classes.p}>
              {currency} {total}
            </span>
          </CardContent> */}
        </Card>
      </div>
    </>
  );
};

export default SummaryCard;
