import React from "react";
import Rating from "@material-ui/lab/Rating";
import { makeStyles, Modal, Button, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "700",
    flexGrow: "1",
    minWidth: "700",
    transform: "translateZ(0)",
    ["@media (max-width: 600px)"]: {
      minWidth: "300",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid gray",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dis: { display: "none" },
}));

export default function ReviewModal({
  rating,
  setRating,
  field,
  setField,
  handleClose,
  open,
  handleAddReview,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={open ? classes.root : `${classes.root} ${classes.dis}`}>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <TextField
            id="outlined-full-width"
            label={t("cartPage.reviewContent")}
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            onChange={(e, val) => setField(e.target.value)}
            value={field}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <Rating
            value={rating}
            onChange={(e, value) => setRating(value)}
            style={{ margin: "4% 2%" }}
          />
          <div>
            <Button
              variant="contained"
              style={{
                color: "orange",
                border: "1px solid orange",
                backgroundColor: "white",
                marginLeft: "30%",
              }}
              onClick={handleAddReview}
            >
              {t("productDetails.addREVIEW")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
