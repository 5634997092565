import React, { useEffect, useState } from "react";
import paymentEnum from "../../enums/payment";
import StepTwoPaymentPlan from "./../../components/CreateBazar/StepTwoPaymentPlane/index";
import FormikSelect from "../../components/FormikSelect";
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
  Paper,
  Divider,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Select, TextField } from "formik-material-ui";
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../redux/bazar/actions";
import { RESET_BAZAR } from "../../redux/actionTypes";

import { useTranslation } from "react-i18next";
import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import FormikField from "../../components/FormikField/index";
import * as Yup from "yup";
import { socialMediaArray } from "../App/constants";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CountriesDropDown from "../../components/CountriesDropDown";
import CategoriesDropDown from "../../components/CategoriesDropDown";
import { differenceInDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../styles/commonCss";
import axios from "axios";
import ServiceProvidersDropDown from "./../../components/ServiceProviderDropDown/index";
import { getBazarById } from "../../redux/bazar/actions";
import { addDays, convertDateToIsoFormat } from "../../utils/dateUtils";
import UpgradeModal from "../../components/UpgradeModal";
import { editBazarAsync } from "../../redux/bazar/api";
import { setSnackbar } from "../../redux/Message/actions";
import StepThreeDeliveryAndShipment from "../../components/CreateBazar/StepThreeDeliveryAndShipment";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import { addServiceProviderToBazar } from "./../../redux/bazar/actions";
import { uploadImageAsync } from "./../../service/image";
import UploadButtons from "./../../components/UploadButtons/index";

const useStyles = makeStyles((theme) => ({
  nextArrow: {
    color: theme.palette.primary.dark,
    // fontSize: '30px',
  },
  back: {
    backgroundColor: "white",
    padding: "50px",
  },
  marginx: { margin: "10px 0" },
  marginX: { margin: "30px 0" },
  upgrade: {
    marginLeft: "10px",
    color: colors.link,
  },
}));
const BazarSettings = (props) => {
  const { t } = useTranslation();
  const bazarId = props.match.params.id;
  console.log(bazarId, "bazarInfo.id");
  const dispatch = useDispatch();
  const currencyTypes = [
    {
      id: 0,
      name: "USD",
    },
    {
      id: 1,
      name: "AED",
    },
    {
      id: 2,
      name: "JOD",
    },
  ];
  const bazarInfo = useSelector((state) => state.bazarReducer.bazarInfo);
  const [bazarStatusTypes, setBazarStatusTypes] = useState([]);
  console.log("bazarInfo.bazarStatus", bazarInfo);
  const [open, setOpen] = useState(false);
  const [PackageId, setPackageId] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState(bazarInfo.packagePlan);
  const [paid, setPaid] = useState(false);
  const [shipmentFileUploadLoading, setShipmentFileUploadLoading] =
    useState(false);
  const [openProviderConfirmModal, setOpenProviderConfirmModal] =
    useState(false);
  const [submitValues, setSubmitValues] = useState("");
  const [lastProviderName, setLastProviderName] = useState("");

  let bazarEdited = useSelector((state) => state.bazarReducer.bazarEdited);
  let serviceProviders = useSelector(
    (state) => state.serviceProviderReducer.allServiceProviders
  );
  console.log(bazarEdited);

  useEffect(() => {
    dispatch(getBazarById(bazarId));
  }, []);

  useEffect(() => {
    setSelectedPackage(bazarInfo.packagePlan);
  }, [bazarInfo.packagePlan]);
  useEffect(() => {
    axios
      .get(`Bazar/BazarStatusTypes`)
      .then((response) => {
        setBazarStatusTypes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // useEffect(() => {
  //   if (bazarEdited) {
  //     bazarEdited = false
  //     // props.history.goBack()
  //   }
  // }, [bazarEdited])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getSocialMedia = () =>
    bazarInfo.socialMediaLinks.map((socialMedia) => {
      return { url: socialMedia.url, socialMediaType: socialMedia.type };
    });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // const finalValues = { ...values }
    console.log(values, "fromsubuit");
    debugger;
    // const bazarDuration = +values.bazarDuration;
    // const endDate = addDays(values.startDate, bazarDuration);
    // debugger
    // finalValues.startDate = convertDateToIsoFormat(finalValues.startDate)
    // finalValues.endDate = convertDateToIsoFormat(endDate)

    const categoriesIds = values.categories.map((cat) => cat.id);
    // finalValues.categories = categoriesIds
    // finalValues.bazarId = bazarId
    // finalValues.packagePlan = PackageId
    const {
      bazarStatus,
      name,
      description,
      flatFeePlan,
      commission,
      countryId,
      socialMediaLinks,
      currancy,
      rulesAndConditions,
    } = values;
    const finalValues = {
      bazarStatus,
      rulesAndConditions,
      bazarId: +bazarId,
      name,
      description,
      countryId,
      startDate: convertDateToIsoFormat(values.startDate),
      endDate: convertDateToIsoFormat(values.endDate),
      flatFeePlan,
      commission,
      packagePlan: PackageId,
      categories: categoriesIds,
      socialMediaLinks,
      currancy,
    };
    console.log("finalValues", finalValues);

    try {
      const response = await editBazarAsync(finalValues);
      setSubmitting(false);
      props.history.goBack();
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    // setTimeout(() => {
    // }, 3000)

    // dispatch(editBazar(finalValues))
  };

  const handleSelectedPackageChange = (id, type) => {
    setSelectedPackage(type);
    setPackageId(id);
    // const packageInfo = { bazarId: +bazarId, packagePlan: +id }
    // console.log(packageInfo)
  };
  const handleOpenProviderModal = () => {
    setOpenProviderConfirmModal(true);
  };
  const handleCloseProviderModal = () => {
    setOpenProviderConfirmModal(false);
    // tableRef.current.onQueryChange()
  };
  const sendInvitationOnConfirm = () => {
    // handleCloseProviderModal()
    setOpenProviderConfirmModal(false);
    dispatch(addServiceProviderToBazar(submitValues));
  };
  const sendProviderInvitationHandler = (values) => {
    debugger;
    const serviceProviderId = bazarInfo.provider?.id;
    if (serviceProviderId) {
      const providerName = serviceProviders.find(
        (provider) => provider.id === serviceProviderId
      ).companyName;
      setLastProviderName(providerName);
      handleOpenProviderModal();
      setSubmitValues({
        providerLargePrice: values.providerLargePrice,
        providerStandardPrice: values.providerStandardPrice,
        providerId: values.providerId,
        bazarId: +bazarId,
        shipmentDetails: values.shipmentDetails,
        shipmentDetailsFile: values.shipmentDetailsFile,
      });
    }
  };
  const initialValues = {
    bazarStatus: bazarInfo.bazarStatus || "",
    name: bazarInfo.name || "",
    description: bazarInfo.description || "",
    countryId: bazarInfo.county.id || "",
    currancy: bazarInfo?.currancy || 0,
    startDate: bazarInfo.startDate
      ? new Date(bazarInfo.startDate + "Z")
      : new Date(),
    rulesAndConditions: bazarInfo.rulesAndConditions || "",
    endDate: bazarInfo.endDate ? new Date(bazarInfo.endDate + "Z") : new Date(),
    organizerId: localStorage.userId,
    // bazarDuration: bazarInfo.startDate
    //   ? differenceInDays(
    //       new Date(bazarInfo.endDate),
    //       new Date(bazarInfo.startDate)
    //     )
    //   : "",
    categories: bazarInfo.categories ? bazarInfo.categories : [],
    socialMediaLinks: getSocialMedia(),
    // owner: [{ type: 'shirt' }, { type: 'mug' }],    providerStandardPrice: bazarInfo.providerStandardPrice || '',
    providerStandardPrice: bazarInfo.provider
      ? bazarInfo.providerStandardPrice
      : "",
    providerLargePrice: bazarInfo.provider ? bazarInfo.providerLargePrice : "",
    providerId: bazarInfo.provider ? bazarInfo.provider.id : "",
    packagePlan: bazarInfo.packagePlan,
    shipmentDetails: bazarInfo.shipmentDetails || "",
    shipmentDetailsFile: bazarInfo.shipmentDetailsFile || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.bazarNameRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    // bazarDuration: Yup.number(t("validation.bazarDuration"))
    //   .min(1, t("validation.minDuration"))
    //   .max(60, t("validation.maxDuration"))
    //   .required(t("validation.durationRequired")),

    startDate: Yup.date().required(t("validation.startDateAndTime")),
    endDate: Yup.date().required(t("validation.endDateAndTime")),

    categories: Yup.array().min(1).required(),
  });
  const [openView, setOpenView] = useState(false);
  const classes = useStyles();
  console.log(bazarInfo, "setting");

  // bazarInfo.noOfShops = 1
  debugger;
  const checkFrist = () => {
    if (bazarInfo.status === "CommingSoon" && bazarInfo.noOfShops > 0) {
      dispatch(
        setSnackbar(
          true,
          "error",
          t("messages.YouCannotChangePlanCommingSoonBazarWithShops")
        )
      );
    } else if (bazarInfo.status === "CommingSoon") {
      handleopenView();
    } else if (bazarInfo.status === "Active") {
      dispatch(
        setSnackbar(true, "error", t("messages.YouCannotChangePlanActiveBazar"))
      );
    }
    // bazarInfo.status === 'CommingSoon'
    //   ? handleopenView()
    //   : dispatch(setSnackbar(true, 'error', t('YouCanotChange')))
  };
  const handleopenView = () => {
    // console.log(bazarInfo.id);
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };
  const [paymentError, setPaymentError] = useState({
    errorExist: false,
    msg: "",
  });
  const [paymentValue, setpaymentValue] = React.useState({
    flatFee: "",
    commission: "",
  });
  useEffect(() => {
    setpaymentValue({
      flatFee: bazarInfo.flatFeePlan,
      commission: bazarInfo.commission,
    });
  }, [bazarInfo]);
  const paymentPlanInputHandler = (e) => {
    const newPaymentValue = { ...paymentValue };
    newPaymentValue[e.target.name] = e.target.value;
    console.log(newPaymentValue);
    setpaymentValue(newPaymentValue);
  };
  const PaymentErrorhandler = (paymentType) => {
    const x = { ...paymentError };
    x.errorExist = true;
    x.msg = `you should Enter ${paymentType} Value`;
    setPaymentError(x);
    setTimeout(() => {
      const x = { ...paymentError };
      x.errorExist = false;
      x.msg = "";
      setPaymentError(x);
    }, 4000);
  };
  // const savePaymentPlanSelected = (paymentType) => {
  //   let paymentPlanSubmited = {
  //     paymentPlan: 0,
  //     flatFeePlan: 0,
  //     commission: 0,
  //   }
  //   // debugger
  //   let error = false
  //   console.log(paymentValue)
  //   switch (paymentType) {
  //     case 'FlatFee':
  //       if (!paymentValue.flatFee) {
  //         error = true
  //         PaymentErrorhandler('Flat Fee')
  //       }
  //       paymentPlanSubmited = {
  //         paymentPlan: paymentEnum.flatFee,
  //         flatFeePlan: +paymentValue.flatFee,
  //         commission: 0,
  //       }
  //       break
  //     case 'Commission':
  //       if (!paymentValue.commission) {
  //         error = true
  //         PaymentErrorhandler('Commision')
  //       }
  //       paymentPlanSubmited = {
  //         paymentPlan: paymentEnum.commission,
  //         commission: +paymentValue.commission,
  //         flatFeePlan: 0,
  //       }
  //       break
  //     case 'FlatFeesWithCommission':
  //       if (!paymentValue.commission || !paymentValue.flatFee) {
  //         error = true
  //         PaymentErrorhandler('flatFee And Commission')
  //       }
  //       paymentPlanSubmited = {
  //         paymentPlan: paymentEnum.flatFeeAndCommission,
  //         commission: +paymentValue.commission,
  //         flatFeePlan: +paymentValue.flatFee,
  //       }
  //       break

  //     default:
  //       break
  //   }
  //   paymentPlanSubmited.bazarId = +bazarId
  //   console.log(paymentPlanSubmited.bazarId, 'paymentPlanSubmited.bazarId')

  //   error
  //     ? console.log('errorrrrrrrr')
  //     : props.addPaymentPlan(paymentPlanSubmited, paymentType)
  //   console.log('saved')
  //   handleCloseView()
  // }
  const savePaymentPlanSelected = (paymentType) => {
    console.log("saved");
    let paymentPlanSubmited = {
      paymentPlan: 0,
      flatFeePlan: 0,
      commission: 0,
    };
    // debugger
    let error = false;
    switch (paymentType) {
      case "FlatFee":
        if (paymentValue.flatFee === "") {
          error = true;
          PaymentErrorhandler("Flat Fee");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.flatFee,
          flatFeePlan: +paymentValue.flatFee,
          commission: 0,
        };
        debugger;
        break;
      case "Commission":
        if (paymentValue.commission === "") {
          error = true;
          PaymentErrorhandler("Commision");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.commission,
          commission: +paymentValue.commission,
          flatFeePlan: 0,
        };
        break;
      case "FlatFeesWithCommission":
        if (paymentValue.commission === "" || paymentValue.flatFee === "") {
          error = true;
          PaymentErrorhandler("flatFee And Commission");
        }
        paymentPlanSubmited = {
          paymentPlan: paymentEnum.flatFeeAndCommission,
          commission: +paymentValue.commission,
          flatFeePlan: +paymentValue.flatFee,
        };
        break;

      default:
        break;
    }
    paymentPlanSubmited.bazarId = +bazarId;
    console.log(paymentPlanSubmited);

    error
      ? console.log("errorrrrrrrr")
      : props.addPaymentPlan(paymentPlanSubmited, paymentType);
    handleCloseView();
  };

  // const handleProductImageUpload = async (e, setFieldValue) => {
  //   const img = e.target.files[0]
  //   const name = img.name

  //   try {
  //     const formDate = new FormData()
  //     formDate.set(name, img)
  //     setShipmentFileUploadLoading(true)
  //     const imageName = await uploadImageAsync(formDate)
  //     console.log('Donee')
  //     setFieldValue(`shipmentDetailsFile`, imageName)
  //     setShipmentFileUploadLoading(false)

  //     console.log(imageName)
  //   } catch (error) {
  //     console.log(error)
  //     console.log(error?.response?.data)
  //   }
  // }
  const paramsBazarId = useParams().id;
  const [rulesUpload, setRulesUpload] = useState(false);
  const handleRulesUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const name = file.name;
    console.log(file);
    setRulesUpload(true);

    try {
      const formDate = new FormData();
      formDate.set(name, file);
      const imageNewName = await uploadImageAsync(formDate);
      setFieldValue("rulesAndConditions", name);
      setRulesUpload(false);
    } catch (error) {
      setRulesUpload(false);
      console.log(error);
      console.log(error?.response?.data);
    }
  };
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
        <Link
          style={{ color: "gray", cursor: "pointer" }}
          onClick={() =>
            props.history.push(`/Organizer/bazarHome/${paramsBazarId}`)
          }
        >
          {bazarInfo.name}
        </Link>
        <Typography className="special">{t("bazarSettings")}</Typography>
      </Breadcrumbs>
      <Paper className={classes.back}>
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          {t("bazarSettings")}
        </Typography>
        <Box my={5}>
          <Typography variant="h6">{t("basicInfo")}</Typography>
        </Box>
        <Grid container>
          {/* <Grid container item xs={12}> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              validateForm,
              isSubmitting,
              setFieldValue,
              touched,
              handleBlur,
            }) => {
              // console.log(touched)
              console.log(values, initialValues, "sett");

              return (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Form style={{ width: "100%" }}>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        item
                        xs={12}
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <FormikField
                            name={"name"}
                            label={t("createBazar.step1Form.bazarName")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CategoriesDropDown
                            setFieldValue={setFieldValue}
                            error={
                              touched.categories && errors.categories
                                ? errors.categories
                                : null
                            }
                            value={values.categories}
                            blur={handleBlur}
                            IdCatBazar={paramsBazarId}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CountriesDropDown
                            name="countryId"
                            label={t("createBazar.step1Form.country")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormikSelect
                            name="currancy"
                            label={t("signUp.currency")}
                            items={currencyTypes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={DatePicker}
                            name="startDate"
                            label={t("createBazar.step1Form.startDate")}
                            inputVariant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon />
                                </InputAdornment>
                              ),
                            }}
                            minDate={new Date()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TimePicker}
                            name="startDate"
                            label={t("createBazar.step1Form.at")}
                            inputVariant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={DatePicker}
                            name="endDate"
                            label={t("createBazar.step1Form.endDate")}
                            inputVariant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarTodayIcon />
                                </InputAdornment>
                              ),
                            }}
                            minDate={new Date()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TimePicker}
                            name="endDate"
                            label={t("createBazar.step1Form.at")}
                            inputVariant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ margin: ".5rem 0" }}>
                            {t("bazarrStatus")}:
                          </Typography>

                          <div role="group" aria-labelledby="my-radio-group">
                            {bazarStatusTypes.length > 0 ? (
                              <>
                                {bazarStatusTypes
                                  .filter((item) => item.name !== "NotSelected")
                                  .map((itm) => (
                                    <label
                                      className={
                                        localStorage.i18nextLng === "en"
                                          ? classes.mr
                                          : ""
                                      }
                                      key={itm.id}
                                    >
                                      <Field
                                        error={errors.bazarStatus}
                                        type="radio"
                                        name="bazarStatus"
                                        value={"" + itm.name}
                                      />
                                      {itm.name}
                                    </label>
                                  ))}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <ErrorMessage
                            name="bazarStatus"
                            render={(msg) => (
                              <div
                                style={{
                                  color: "#f44336",
                                  fontSize: "0.75rem",
                                  margin: "1px 14px",
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          />
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          style={{ margin: "0", padding: "12px 12px 0" }}
                        >
                          <Typography color="secondary">
                            {t("createBazar.step1Form.Note")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormikField
                            name="bazarDuration"
                            label={t("createBazar.step1Form.BazarDuration")}
                            type="number"
                          />
                        </Grid> */}

                        <Grid item xs={12}>
                          <Box mt={2}>
                            <Field
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              type="text"
                              name="description"
                              label={t("createBazar.step1Form.description")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {" "}
                            {t("signUp.rulesAndConditions")}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              alignItems: "center",
                            }}
                          >
                            <UploadButtons
                              handleChange={(e) =>
                                handleRulesUpload(e, setFieldValue)
                              }
                              type="Button"
                            />
                            {rulesUpload ? (
                              <div>Loading...</div>
                            ) : (
                              <>
                                <a
                                  href={`https://api.ebazarak.com/api/File/GetCertificate?CertificateName=${values.rulesAndConditions}`}
                                  download
                                  target="_blank"
                                >
                                  {values.rulesAndConditions}
                                </a>
                              </>
                            )}
                          </div>
                        </Grid>
                        <Grid item container>
                          <FieldArray name="socialMediaLinks">
                            {({ push }) => (
                              <>
                                {values.socialMediaLinks.map((_, indx) => (
                                  <Grid
                                    item
                                    container
                                    spacing={2}
                                    justify="space-between"
                                    alignItems="center"
                                    xs={12}
                                    md={10}
                                    key={indx}
                                  >
                                    <Grid item xs={5} sm={3}>
                                      <Field
                                        component={Select}
                                        variant="outlined"
                                        name={`socialMediaLinks[${indx}].socialMediaType`}
                                        fullWidth
                                        size="small"
                                        type="number"
                                      >
                                        {socialMediaArray.map((item, index) => (
                                          <MenuItem
                                            value={item.value}
                                            key={index}
                                          >
                                            {item.icon}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={7} sm={9}>
                                      <FormikField
                                        variant="outlined"
                                        fullWidth
                                        label={t("signUp.socialMediaLink")}
                                        name={`socialMediaLinks[${indx}].url`}
                                        type="text"
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                                <Grid item xs={2}>
                                  <IconButton
                                    onClick={() =>
                                      push({ url: "", socialMediaType: "" })
                                    }
                                  >
                                    {/* <Button
                                onClick={() =>
                                  push({ url: '', socialMediaType: '' })
                                }
                              >
                                ooo
                              </Button> */}
                                    <AddCircleOutlineRoundedIcon
                                      color="secondary"
                                      style={{
                                        fontSize: "50PX",
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              </>
                            )}
                          </FieldArray>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="end"
                        style={{ margin: "10px 28px" }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          // fullWidth
                        >
                          {t("buttons.save")}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.marginX} />
                    </Grid>
                  </Form>
                  {/* <Grid container item xs={12} lg={7} spacing={2}>
                      <StepThreeDeliveryAndShipment
                        setting={true}
                        bazarId={bazarId}
                      />
                    </Grid> */}
                </MuiPickersUtilsProvider>
              );
            }}
          </Formik>
          {/* </Grid> */}
          <Grid container item xs={12} lg={7}>
            <Typography variant="h6" className={classes.marginX}>
              {t("createBazar.changeServiceProvider")}
            </Typography>
            <StepThreeDeliveryAndShipment setting={true} bazarId={bazarId} />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.marginX} />
          </Grid>
          {bazarInfo.packagePlan === "FreePlan" && (
            <>
              <Grid item xs={12} lg={7}>
                <Typography variant="h6" className={classes.marginX}>
                  {t("links.upgradePackage")}
                </Typography>

                <Box display="flex" alignItems="center">
                  <Typography>
                    {selectedPackage} {t("links.package")}
                  </Typography>
                  <Button className={classes.upgrade} onClick={handleOpen}>
                    {t("links.upgrade")}
                  </Button>
                  {bazarInfo.packagePlan ? (
                    <UpgradeModal
                      open={open}
                      handleClose={handleClose}
                      selected={selectedPackage}
                      bazarId={bazarId}
                      handleSelectedPackageChange={handleSelectedPackageChange}
                      // handlePaymentSuccess={handlePaymentSuccess}
                      save={false}
                    />
                  ) : (
                    ""
                  )}
                  {/* <Link to="" >
                          Upgrade
                        </Link> */}
                </Box>
              </Grid>
            </>
          )}
          <>
            <Grid item xs={12}>
              <Divider className={classes.marginX} />
            </Grid>
            {bazarInfo.status !== "Finished" ? (
              <>
                <Grid item xs={12} lg={7}>
                  <Typography variant="h6" className={classes.marginX}>
                    {t("paymentPlan")}
                  </Typography>

                  <Box display="flex" alignItems="center">
                    <Typography>
                      {bazarInfo.commission ? (
                        <>
                          {bazarInfo.paymentPlan}&nbsp;&nbsp;
                          {bazarInfo.commission}%
                          <br />
                        </>
                      ) : (
                        <>
                          {bazarInfo.packagePlan}&nbsp;&nbsp;
                          {bazarInfo.flatFeePlan}JD
                        </>
                      )}
                    </Typography>
                    <Button className={classes.upgrade} onClick={checkFrist}>
                      {t("links.edit")}
                    </Button>
                    <ControlledAppModal
                      open={openView}
                      handleClickOpen={handleopenView}
                      handleClose={handleCloseView}
                    >
                      <div style={{ maxWidth: "800px" }}>
                        <StepTwoPaymentPlan
                          paymentPlanInputHandler={paymentPlanInputHandler}
                          savePaymentPlanSelected={savePaymentPlanSelected}
                          paymentError={paymentError}
                          bazarInfo={bazarInfo}
                          flatFeeValue={paymentValue.flatFee}
                          commissionValue={paymentValue.commission}
                        />
                        {console.log(paymentValue, "paymentValue")}
                      </div>
                    </ControlledAppModal>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.marginX} />
                </Grid>
              </>
            ) : (
              ""
            )}
          </>
          {/* <Grid item xs={12} lg={8}></Grid> */}
          <ControlledAppModal
            handleClickOpen={handleOpenProviderModal}
            open={openProviderConfirmModal}
            handleClose={handleCloseProviderModal}
          >
            <div style={{ maxWidth: "260px" }}>
              <div>
                {/* {t('editProductIntruction')} */}
                {/* if you edit the product pictures ,Product will be hidden from booth
            until the organizer approve changes */}
                Your Last Inviation to
                {" " + lastProviderName + " "}
                will be cancelled
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "30px",
                }}
              >
                <Button
                  onClick={() => {
                    console.log("editConfirmed");
                    sendInvitationOnConfirm();
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t("buttons.ok")}
                </Button>
                <Button
                  onClick={() => {
                    // setConfirmEdit(false)
                    // cancelEditProduct()
                    handleCloseProviderModal();
                  }}
                  color="error"
                  variant="contained"
                >
                  {t("buttons.cancel")}
                </Button>
              </div>
            </div>
          </ControlledAppModal>
        </Grid>
      </Paper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBazar: (newBazar) => dispatch(actions.createBazar(newBazar)),
    addPaymentPlan: (paymentPlanInfo, paymentType) =>
      dispatch(actions.addPaymentPlan(paymentPlanInfo, paymentType)),
    getBazarById: (bazarId) => dispatch(actions.getBazarById(bazarId)),
    editBazar: (editedBazar) => dispatch(actions.editBazar(editedBazar)),
    resetBazar: () => dispatch({ type: RESET_BAZAR }),
  };
};

export default connect(null, mapDispatchToProps)(BazarSettings);
// bazarInfo.id
