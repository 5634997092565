import {
  GET_ORGANIZER_BOOTH_REQUESTS,
  DELETE_BAZAR,
  FILTER_BAZAR_ON_STATUS,
  GET_ALL_ORGANIZER_BAZARS,
  GET_BAZAR_BOOTHS,
  GET_ORGANIZER_PROFILE,
  ACCEPT_BOOTH_REQUEST,
  DECLINE_BOOTH_REQUEST,
  LOADING_ORGANIZER_BAZARS,
} from '../actionTypes'
const initialState = {
  organizerProfile: {
    id: '',
    firstName: '',
    lastName: '',
    cityName: '',
    companyCertificate: '',
    countryName: '',
    email: '',
    lastName: '',
    phoneNumber: '',
    preCode: '',
    profileImage: '',
    socialMediaLinks: [],
    whatsNumber: '',
  },
  bazars: [],
  filteredBazars: [],
  bazarBooths: [],
  boothRequests: [],
  boothRequestsCount: 0,
  loading: false,
  loadingOrganizerBazars: false,
}
const organizerReducer = (state = initialState, action) => {
  let organizerProfile = { ...state.organizerProfile }
  let bazars = [...state.bazars]
  let filteredBazars = [...state.bazars]
  let boothRequests = [...state.boothRequests]
  let boothId = ''

  switch (action.type) {
    case GET_ORGANIZER_PROFILE:
      organizerProfile = action.payload
      return {
        ...state,
        organizerProfile,
      }
    case GET_ALL_ORGANIZER_BAZARS:
      console.log(action.payload)
      bazars = action.payload
      filteredBazars = action.payload
      return {
        ...state,
        bazars,
        filteredBazars,
      }
    case FILTER_BAZAR_ON_STATUS:
      const selectedStatus = action.payload

      filteredBazars =
        selectedStatus === 'All'
          ? bazars
          : filteredBazars.filter((bazar) => bazar.status === selectedStatus)
      return {
        ...state,
        bazars,
        filteredBazars,
      }
    case DELETE_BAZAR:
      const deletedBazarId = action.payload
      debugger
      bazars = bazars.filter((bazar) => bazar.id !== deletedBazarId)

      return {
        ...state,
        filteredBazars: bazars,
        bazars,
      }
    case GET_BAZAR_BOOTHS:
      let bazarBooths = action.payload
      return {
        ...state,
        bazarBooths,
      }
    case GET_ORGANIZER_BOOTH_REQUESTS:
      boothRequests = action.data
      let boothRequestsCount = action.count
      return {
        ...state,
        boothRequests,
        boothRequestsCount,
      }
    case ACCEPT_BOOTH_REQUEST:
      boothId = action.payload
      console.log('Before filter', boothRequests)
      debugger
      boothRequests = boothRequests.map((request) => {
        return request.id === boothId
          ? { ...request, requestResponse: 'accepted' }
          : request
      })
      debugger
      console.log('after filter', boothRequests)
      return {
        ...state,
        boothRequests,
      }
    case DECLINE_BOOTH_REQUEST:
      boothId = action.payload
      console.log('Before filter', boothRequests)
      // boothRequests = boothRequests.filter((request) => request.id !== boothId)
      boothRequests = boothRequests.map((request) =>
        request.id === boothId
          ? { ...request, requestResponse: 'declined' }
          : request,
      )
      console.log('after filter', boothRequests)
      return {
        ...state,
        boothRequests,
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.value,
      }
    case LOADING_ORGANIZER_BAZARS:
      return {
        ...state,
        loadingOrganizerBazars: action.payload,
      }

    default:
      return state
  }
}
export default organizerReducer
