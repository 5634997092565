import {
  GET_BAZAR_BY_ID,
  CREATE_BAZAR_ERROR,
  CREATE_BAZAR,
  ADD_PAYMENT_PLAN_TO_BAZAR,
  ADD_PROVIDER_TO_BAZAR,
  ADD_PACKAGE_TO_BAZAR,
  GET_BAZAR_HOME,
  GET_BAZAR_BOOTHS,
  RESET_BAZAR,
  EDIT_BAZAR_SETTING,
  GET_All_APP_BAZARS,
  GET_All_COMING_SOON_BAZARS,
  GET_All_BUYER_BAZARS,
  GET_BAZAR_CATEGORIES,
  UPLOAD_BAZAR_AVATAR_IMG,
  UPLOAD_BAZAR_COVER_IMG,
  LOADING_HOME_PAGE_BAZARS,
} from "../actionTypes";
const bazarInformationObj = {
  id: "",
  name: "",
  county: {
    id: "",
    name: "",
    arabicName: "",
  },
  currency: "",
  description: "",
  status: "",
  socialMediaLinks: [],
  request: 0,
  startDate: "",
  endDate: "",
  providerLargePrice: "",
  providerStandardPrice: "",
  earning: 0,
  shopNo: 0,
  imageProfile: "",
  coverPhoto: "",
  provider: {
    id: "",
    companyName: "",
    representativeName: null,
    email: "",
    whatsNumber: null,
    password: "12eman@Ebazar",
    phoneNumber: "0123356455",
    cityName: "Cairo",
    countryName: "Egypt",
    postalCode: null,
    street: null,
  },
  packagePlan: "",
  paymentPlan: "",
  flatFeePlan: "",
  commission: "",
  isPaid: false,
  categories: [],
  socialMediaLinks: [{ url: "", type: 0 }],
};
const initialState = {
  bazarCreated: false,
  bazarId: localStorage.bazarId,
  errors: [],
  providerAdded: false,
  packageId: "",
  bazarInfo: JSON.parse(JSON.stringify(bazarInformationObj)),
  bazarHome: "",
  bazarBooths: [],
  bazarBoothsCount: "",
  allBazars: [],
  allAppBazars: [],
  allAppBazarsCount: 0,
  bazarCategories: [],
  homeLoading: "",
};
const bazarReducer = (state = initialState, action) => {
  let bazarInfo = { ...state.bazarInfo };
  let allAppBazars = [...state.allAppBazars];
  let allAppBazarsCount = "";
  let bazarHome = { ...state.bazarHome };
  switch (action.type) {
    case CREATE_BAZAR:
      localStorage.bazarId = action.payload;
      return {
        ...state,
        bazarId: action.payload,
        bazarCreated: true,
        errors: [],
      };
    case CREATE_BAZAR_ERROR:
      return {
        ...state,
        errors: action.payload,
        message: "",
        bazarCreated: false,
      };
    case ADD_PAYMENT_PLAN_TO_BAZAR:
      bazarInfo.paymentPlan = action.paymentType;
      bazarInfo = {
        ...bazarInfo,
        commission: action.paymentPlan.commission,
        flatFeePlan: action.paymentPlan.flatFeePlan,
      };
      return {
        ...state,
        bazarInfo,
      };
    case ADD_PROVIDER_TO_BAZAR:
      return {
        ...state,
        message: action.payload.message,
        providerAdded: action.payload.successed,
      };
    case ADD_PACKAGE_TO_BAZAR:
      bazarInfo.packagePlan = action.PackageType;
      console.log("action.PackageType,", action.PackageType);
      console.log("bazarInfo,", bazarInfo);

      console.log("reducer", action.packageId);
      return {
        ...state,
        packageId: action.packageId,
        bazarInfo,
      };
    case GET_BAZAR_BY_ID:
      bazarInfo = { ...action.payload };
      console.log(bazarInfo);
      return {
        ...state,
        bazarInfo,
        bazarId: bazarInfo.id,
      };
    case GET_BAZAR_HOME:
      bazarHome = action.payload;
      return {
        ...state,
        bazarHome,
      };
    case GET_BAZAR_BOOTHS:
      let bazarBooths = action.payload.data;
      let bazarBoothsCount = action.payload.count;
      return {
        ...state,
        bazarBooths,
        bazarBoothsCount,
      };
    case EDIT_BAZAR_SETTING:
      return { ...state, bazarEdited: action.succeeded };
    case RESET_BAZAR:
      bazarInfo = {
        id: "",
        name: "",
        county: {
          id: "",
          name: "",
          arabicName: "",
        },
        description: "",
        status: "",
        socialMediaLinks: [],
        request: 0,
        startDate: "",
        endDate: "",
        providerLargePrice: "",
        providerStandardPrice: "",
        earning: 0,
        shopNo: 0,
        imageProfile: "",
        coverPhoto: "",
        provider: {
          id: "",
          companyName: "",
          representativeName: null,
          email: "",
          whatsNumber: null,
          password: "12eman@Ebazar",
          phoneNumber: "0123356455",
          cityName: "Cairo",
          countryName: "Egypt",
          postalCode: null,
          street: null,
        },
        packagePlan: "",
        paymentPlan: "",
        flatFeePlan: "",
        commission: "",
        isPaid: false,
        categories: [],
        socialMediaLinks: [{ url: "", type: 0 }],
      };
      localStorage.bazarId = "";
      localStorage.step = 0;
      return {
        ...state,
        bazarInfo,
        bazarCreated: false,
        // bazarId: bazarInfo.bazarId,
      };
    case GET_All_APP_BAZARS:
      allAppBazars = [...action.payload.data];
      allAppBazarsCount = action.payload.count;
      return {
        ...state,
        allAppBazars,
        allAppBazarsCount,
      };
    case GET_All_COMING_SOON_BAZARS:
      allAppBazars = [...action.payload.data];
      allAppBazarsCount = action.payload.count;
      return {
        ...state,
        allAppBazars,
        allAppBazarsCount,
      };
    case GET_All_BUYER_BAZARS:
      allAppBazars = [...action.payload.data];
      allAppBazarsCount = action.payload.count;
      return {
        ...state,
        allAppBazars,
        allAppBazarsCount,
      };
    case GET_BAZAR_CATEGORIES:
      const bazarCategories = [...action.payload];

      return {
        ...state,
        bazarCategories,
      };
    case UPLOAD_BAZAR_AVATAR_IMG:
      bazarHome.imageProfile = action.payload;

      return {
        ...state,
        bazarHome,
      };
    case UPLOAD_BAZAR_COVER_IMG:
      bazarHome.coverPhoto = action.payload;

      return {
        ...state,
        bazarHome,
      };
    case LOADING_HOME_PAGE_BAZARS:
      return {
        ...state,
        homeLoading: action.loading,
      };
    default:
      return state;
  }
};
export default bazarReducer;
