import { createMuiTheme } from '@material-ui/core/styles'
// Create a theme instance.

export default createMuiTheme({
  palette: {
    primary: {
      main: '#F8B334',
      dark: '#FF470B',
      light: '#EB9413',
      contrastText: '#fff',
    },
    secondary: {
      main: '#707070',
      light: '#71D875',
    },
    success: {
      main: '#88C982',
    },
    initial: {
      main: '#000',
    },
  },
  typography: {
    fontFamily: 'verdana',
    body2: {
      fontFamily: ['verdana', 'Times New Roman', 'Arial', 'sans-serif'],
      fontSize: '0.8rem',
    },
    // body1: {
    //   fontSize: '0.9rem',
    // },
    // button: { fontSize: '0.rem' },
  },
  MuiButton: {
    root: {
      // padding: '20px',
      fontSize: '1rem',
      textTransform: 'capitalize',
    },
  },
  containedPrimary: {
    textTransform: 'capitalize',
  },
  direction: localStorage.i18nextLng === 'en' ? 'ltr' : 'rtl',
  // overrides: {
  //   MuiListItemRoot: {
  //     textAlign: "right",
  //   },
  // },
})

// {
//   palette: {
//     primary: {
//       main: '#f00',
//     },
//     secondary: {
//       main: '#0f0',
//     },
//   },
//   typography: {
//     fontFamily: 'Comic Sans MS',
//     body2: {
//       fontFamily: 'Times New Roman',
//       fontSize: '1.1rem',
//     },
//   },
//   shape: {
//     borderRadius: 30,
//   },
//   spacing: 8,
//   overrides: {
//     MuiFilledInput: {
//       root: {
//         backgroundColor: 'green',
//       },
//     },
//     MuiInputLabel: {
//       root: {
//         backgroundColor: 'yellow',
//       },
//     },
//     MuiTextField: {
//       root: {},
//     },
//     MuiButton: {
//       root: {
//         textTransform: 'none',
//         padding: '20px',
//       },
//       fullWidth: {
//         maxWidth: '300px',
//       },
//     },
//   },
//   props: {
//     MuiButton: {
//       disableRipple: true,
//       variant: 'contained',
//       color: 'primary',
//     },
//     MuiCheckbox: {
//       disableRipple: true,
//     },
//     MuiTextField: {
//       variant: 'filled',
//       InputLabelProps: {
//         shrink: true,
//       },
//     },
//     MuiPaper: {
//       elevation: 12,
//     },
//     MuiCard: {
//       elevation: 12,
//     },
//   },
// }
