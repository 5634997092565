import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import tableIcons from "../../styles/materialTableIcons";
import { Link } from "react-router-dom";
import RequestInfoModal from "./../../components/RequestInfoModal/index";
import axios from "axios";
import { format } from "date-fns";
import BoothRequetActionsDropdown from "./../../components/BoothRequetActionsDropdown/index";
import ControlledAppTable from "./../../components/ControlledAppTable/index";
import { connect } from "react-redux";
import {
  acceptBoothRequest,
  getOrganizerBazars,
  getOrganizerBoothRequests,
} from "../../redux/organizer/actions";
import DeclineBoothReasonsModal from "../../components/DeclineBoothReasonsModal";
import OrganizerBazarsDropdown from "./../../components/OrganizerBazarsDropdown/index";
import { useTranslation } from "react-i18next";

const headerStyle = {
  textAlign: "center",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};
const useStyles = makeStyles((theme) => ({
  dropDown: { width: "150px" },
  dis: {
    display: "flex",
    justifyContent: "center",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
}));
const OrganizerBoothRequests = (props) => {
  const token = localStorage.token;
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(5);
  const [filteredBazarName, setFilteredBazarName] = useState("");

  // const [tableData, setTableData] = useState([])

  const [search, setSearch] = useState("");
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [declineBoothId, setDeclineBoothId] = useState(false);

  useEffect(() => {
    // fetchData()
    props.getBoothRequests(page, pageSize, filteredBazarName);
    // props.getOrganizerBazars()
  }, []);

  const handleFilterChange = (e) => {
    console.log(e.target.value);
    debugger;
    setFilteredBazarName(e.target.value);
    setPage(0);
    props.getBoothRequests(0, pageSize, e.target.value);
  };
  const handleAcceptBoothRequest = (id) => {
    setOpenDeclineModal(false);
    props.acceptBoothRequest(id);
  };
  const handleDeclineBoothRequest = (id) => {
    setOpenDeclineModal(true);
    setDeclineBoothId(id);
    console.log(id, "idid");
  };

  const handlePageChange = (page) => {
    setPage(page);
    props.getBoothRequests(page, pageSize, filteredBazarName);
    console.log(page);
  };
  const handleSearchChange = (search) => {
    setSearch(search);
    // fetchData(search)
    console.log(search);
  };
  const handlePageSizeChange = (pageSize) => {
    props.getBoothRequests(page, pageSize, filteredBazarName);
    setPageSize(pageSize);

    console.log(pageSize);
  };
  const handleOpenDeclineModal = () => {
    setOpenDeclineModal(true);
  };

  const handleCloseDeclineModal = () => {
    // setDescription('')
    setOpenDeclineModal(false);
  };

  const columns = [
    {
      title: t("boothName"),
      field: "name",
      cellStyle: {
        width: "fit-content",
        textAlign: "center",
        justifyContent: "center",
      },

      render: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: 'space-between',
          }}
        >
          <Avatar
            alt={row.name[0].toUpperCase()}
            src={
              localStorage.imagePath + row.logo || "/static/images/avatar/1.jpg"
            }
            // className={classes.dashboardlogo}
            // className="margin-right-s"
          />
          <div style={{ margin: "0 12px" }}>{row.name}</div>
        </div>
      ),
    },
    {
      title: t("location"),
      field: "boothCountry",

      cellStyle: { width: "fit-content", textAlign: "center" },
      // headerStyle: { textAlign: 'left' },
    },
    {
      title: t("requestTime"),
      field: "requestTime",

      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => {
        return (
          <div style={{ fontWeight: 400, fontSize: "14px" }}>
            <div>
              {format(new Date(row.requestTime + "Z"), "MMMM dd, yyyy ")}
            </div>
            {/* {console.log(row.requestTime)} */}
            <div>{format(new Date(row.requestTime + "Z"), "HH:mm a")}</div>
          </div>
        );
      },
    },
    {
      title: t("bazarName"),
      field: "bazarName",
      cellStyle: { width: "fit-content", textAlign: "center" },
    },
    {
      cellStyle: { width: "fit-content", textAlign: "center" },
      title: t("viewInfo"),
      render: (row) => (
        <div style={{ marginBottom: "0px" }}>
          {console.log(row)}
          <RequestInfoModal
            {...row}
            user={row.seller}
            boothName={row.name}
            boothId={row.id}
            boothCity={row.boothCity}
            boothCountry={row.boothCountry}
            boothStreet={row.boothStreet}
            role="booth"
            type="Booth"
            label={t("links.view")}
            handleDeclineBoothRequest={handleDeclineBoothRequest}
            handleAcceptBoothRequest={handleAcceptBoothRequest}
          />
        </div>
      ),
    },
    {
      title: t("actions"),
      // field: 'county[name]',
      cellStyle: { width: "fit-content", textAlign: "center" },
      render: (row) => {
        console.log(row);
        return (
          <>
            {row.requestResponse ? (
              <>
                {/* {row.requestResponse ==='accepted'&&} */}
                <div
                  style={{
                    padding: "10px",
                    color: "white",
                    backgroundColor:
                      row.requestResponse === "accepted" ? "#57D953" : "red",
                  }}
                >
                  {t("productStatus." + row.requestResponse)}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  marginLeft: "25px",
                  marginBottom: "15px",
                }}
              >
                <BoothRequetActionsDropdown
                  handleAcceptBoothRequest={handleAcceptBoothRequest}
                  handleDeclineBoothRequest={handleDeclineBoothRequest}
                  boothId={row.id}
                />
              </div>
            )}
            {/* <div
              className="link-seperator"
              style={{ marginRight: '10px' }}
            ></div> */}
          </>
        );
      },
    },
  ];
  const body = (
    <Grid container className={classes.dis} xs={12}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img
            src="/assets/images/empty/shop.svg"
            alt="emptyBazar"
            style={{ width: "250px" }}
          />
        </Grid>
        <Grid
          container
          item
          justify="center"
          style={{ margin: "1px 0", fontSize: ".8rem" }}
        >
          <span style={{ textAlign: "center" }}>{t("donotboothRequest")}</span>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      {/* <Button onClick={handleRequestInfoModalOpen}>oopenn</Button> */}
      <div>
        <DeclineBoothReasonsModal
          open={openDeclineModal}
          declineBoothId={declineBoothId}
          handleOpen={handleOpenDeclineModal}
          handleClose={handleCloseDeclineModal}
        />

        <div>
          <Box display="flex" justifyContent="flex-end" mb="15px">
            <OrganizerBazarsDropdown
              // classes={classes}
              variant="outlined"
              handleChange={handleFilterChange}
              value={filteredBazarName}
            />
          </Box>
          {console.log(props.boothRequests, "props.boothRequests")}
          <ControlledAppTable
            data={props.boothRequests}
            columns={columns}
            isLoading={props.loading ? true : false}
            handlePageChange={handlePageChange}
            handleSearchChange={handleSearchChange}
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
            count={props.boothRequestsCount}
            page={page}
            title={t("sideMenu.boothRequests")}
            search={false}
            localization={{
              body: {
                emptyDataSourceMessage: body,
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(state.boothRequests, "boothRequests");
  return {
    boothRequests: state.organizerReducer.boothRequests,
    boothRequestsCount: state.organizerReducer.boothRequestsCount,
    loading: state.organizerReducer.loading,
    organizerBazars: state.organizerReducer.bazars,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBoothRequests: (page, pageSize, filter) =>
      dispatch(getOrganizerBoothRequests(page, pageSize, filter)),
    getOrganizerBazars: () => dispatch(getOrganizerBazars(localStorage.userId)),
    acceptBoothRequest: (boothId) => dispatch(acceptBoothRequest(boothId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizerBoothRequests);
