import { SET_SNACKBAR } from './../actionTypes'
export const setSnackbar = (
  snackbarOpen,
  snackbarType = 'success',
  snackbarMessage = '',
  duration,
) => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage,
  duration,
})
