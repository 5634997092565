import axios from "axios";
import store from "../store";

export const getAllServicePrvidersAsync = async () => {
  const token = localStorage.token;

  const response = await axios.get("Provider/GetAllProvider");
  return response.data;
};
export const getProviderByBazarCountryAsync = async (BazarId) => {
  const response = await axios.get(
    `Provider/GetProviderByBazarCountry?BazarId=${BazarId}`
  );
  return response.data;
};
export const getServiceProviderByIdAsync = async (providerId) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Provider/GetProviderProfile?ProviderId=${providerId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};
export const editProviderProfileAsync = async (providerInfo) => {
  debugger;
  const token = localStorage.token;

  const response = await axios.post(
    `Provider/EditProviderProfile`,
    providerInfo,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const getProviderBazarsAsync = async (providerId) => {
  const token = localStorage.token;
  const response = await axios.get(
    `Provider/GetProviderBazars?ProviderId=${providerId}&PageSize=1000&PageIndex=1`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};
export const getBazarOrderItemsAsync = async (
  BazarId,
  pageIndex,
  pageSize,
  CityId
) => {
  debugger;
  const token = localStorage.token;
  let url = `Provider/GetBazarOrderItems?BazarId=${BazarId}`;
  if (CityId) {
    url += `&CityId=${CityId}`;
  }
  url += `&PageSize=${pageSize}`;
  url += `&PageIndex=${pageIndex}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  console.log(response.data, "apiiiiiii");

  return response.data;
};
export const getProviderBazarsRequestsAsync = async (
  providerId,
  pageIndex,
  pageSize
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Provider/GetBazarRequests?ProviderId=${providerId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};
export const acceptBazarRequestAsync = async (bazarId) => {
  const token = localStorage.token;

  const response = await axios.put(
    `Provider/AcceptBazarRequest?BazarId=${bazarId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const declineBazarRequestAsync = async (bazarId) => {
  const token = localStorage.token;

  const response = await axios.put(
    `Provider/DeclineBazarRequest?BazarId=${bazarId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const getAllDriversAsync = async (providerId) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Provider/GetAllDrivers?ProviderId=${providerId}&PageSize=10000&PageIndex=1`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const getAllOrderStatusAsync = async () => {
  const token = localStorage.token;

  const response = await axios.get(`Provider/OrderStatusTypes`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};
export const editOrderItemSettingsAsync = async (editedObj) => {
  const token = localStorage.token;

  const response = await axios.put(
    `Provider/EditProviderOrderItemSetting`,
    editedObj,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const editOrderSettingsAsync = async (editedObj) => {
  const token = localStorage.token;

  const response = await axios.put(
    `Provider/EditProviderOrderSetting`,
    editedObj,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const addNewDriverAsync = async (newDriver) => {
  const token = localStorage.token;

  const response = await axios.post(`Provider/AddDriver`, newDriver, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};
export const editDriverAsync = async (driver) => {
  debugger;
  const token = localStorage.token;

  const response = await axios.post(
    `Provider/EditDriver?DriverId=${driver.id}`,
    driver,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const deleteDriverAsync = async (driverId) => {
  debugger;
  const token = localStorage.token;

  const response = await axios.post(
    `Provider/DeleteDriver?DriverId=${driverId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const getAllProviderOrdersAsync = async (
  providerId,
  pageIndex,
  pageSize,
  Status,
  PaymentMethod
) => {
  debugger;
  const token = localStorage.token;

  const response = await axios.get(
    `Provider/GetAllOrders?ProviderId=${providerId}&Status=${Status}&PaymentMethod=${PaymentMethod}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};

export const GetProviderOrdersFilterByBazarAsync = async (
  providerId,
  bazarId,
  pageIndex,
  pageSize,
  Status,
  PaymentMethod
) => {
  debugger;
  const token = localStorage.token;

  const response = await axios.get(
    `Provider/GetProviderOrdersFilterByBazar?ProviderId=${providerId}&Status=${Status}&PaymentMethod=${PaymentMethod}&BazarId=${bazarId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const getOrderDetailsAsync = async (orderId) => {
  const token = localStorage.token;

  const response = await axios.get(`Order/GetOrderInfo?OrderId=${orderId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};
