import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  ButtonGroup,
  TextField,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  root: {
    maxWidth: "80%",
    margin: "1% 10%",
  },
  media: {
    height: "130px",
    minWidth: " 130px",
    borderRadius: "2%",
  },
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#FCFCFC",
    ["@media (max-width:768px)"]: {
      flexDirection: "column",
    },
  },
  buttonCounter: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  productIdStyle: {
    alignSelf: "flex-start",

    padding: "16px",
    marginTop: "10px",
    ["@media (max-width:768px)"]: {
      alignSelf: "center",
      justifySelf: "center",
    },
  },
});

export default function UserShoppingCard({
  quantity,
  productName,
  productCatogory,
  productImage,
  unitPrice,
  color,
  size,
  handleChangeCounter,
  basketItemId,
  handleDeleteBasketItem,
  id,
  isEmpty,
  totalQuantity,
  description,
  bazarId,
  currency,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [counter, setCounter] = useState(quantity || 1);
  let subtotal = counter * unitPrice;
  let history = useHistory();
  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.dis}>
        <CardMedia
          className={classes.media}
          image={
            productImage[0].url
              ? `${localStorage.imagePath}${productImage[0].url}`
              : "/assets/images/product.png"
          }
          title={t("contemplativeReptile")}
          onClick={() => history.push(`/buyer/productDetailsPage/${id}`)}
        />
        <Typography
          // style={{
          //   alignSelf: 'flex-start',
          //   textAlign: 'center',

          //   padding: '16px',
          //   marginTop: '10px',
          // }}
          className={classes.productIdStyle}
        >
          {t("productId")} {id}
        </Typography>
        <CardContent>
          <Typography variant="h6">{productName}</Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("cartPage.catogory")}: {productCatogory}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("cartPage.color")}: {color}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("cartPage.size")} : {size}
          </Typography>
        </CardContent>
        {/* <CardContent>JD {unitPrice} </CardContent> */}
        <CardContent>
          {!isEmpty ? (
            <>
              <ButtonGroup
                size="small"
                variant="contained"
                disableElevation
                aria-label="small contained button group"
              >
                <Button
                  disabled={counter <= 1}
                  onClick={() => {
                    setCounter(counter - 1);
                    handleChangeCounter(basketItemId, counter - 1, id, bazarId);
                  }}
                  className={classes.buttonCounter}
                >
                  -
                </Button>
                <Button disabled>
                  {counter <= totalQuantity ? counter : totalQuantity}
                  {/* {counter} */}
                </Button>
                <Button
                  disabled={counter >= totalQuantity}
                  onClick={() => {
                    setCounter(counter + 1);
                    handleChangeCounter(basketItemId, counter + 1, id, bazarId);
                  }}
                  className={classes.buttonCounter}
                >
                  +
                </Button>
              </ButtonGroup>
              {counter >= totalQuantity ? (
                <div
                  style={{ margin: ".5rem", color: "red", textAlign: "center" }}
                >
                  {t("cartPage.maxmumQuantitiy")}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div>{t("cartPage.outOfStock")}</div>
          )}
        </CardContent>
        <CardContent>
          {currency} {subtotal}
        </CardContent>
        {/* {description ? <CardContent>{description}</CardContent> : ""} */}
        <CardContent>
          <DeleteForeverIcon
            onClick={() => handleDeleteBasketItem(basketItemId, id, bazarId)}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
