import * as authApi from "./api";
import * as actionTypes from "../actionTypes";

export const logIn = (userInfo) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await authApi.logInAsync(userInfo);
      console.log(result);

      dispatch(logedInSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      console.log(error?.response?.data.error);
      dispatch(logedInError(error?.response?.data.error));
    }
  };
};
const logedInSuccess = (userAuthenticationInfo) => {
  return {
    type: actionTypes.USER_LOGIN,
    payload: userAuthenticationInfo,
  };
};
const logedInError = (error) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: error,
  };
};
