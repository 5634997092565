import React from "react";
import ResetPassword1 from "../../components/ResetPassword1/index";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Container, IconButton, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Grid } from "@material-ui/core/index";
import ResetPassword2 from "../../components/ResetPassword2";
import { setSnackbar } from "../../redux/Message/actions";
import { Typography, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    ["@media (max-width:900px)"]: {
      width: "25%",
    },
    ["@media (max-width: 600px)"]: {
      width: "35%",
    },
    ["@media (max-width: 425px)"]: {
      width: "55%",
    },
  },
}));

const ForgetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [emailEntered, setEmailEntered] = useState(false);
  const [email, setEmail] = useState("");

  const handleForgetPassword = async (values, { setSubmitting }) => {
    setEmail(values.email);
    debugger;
    await handleForgetPasswordAsync(values.email);
    setSubmitting(false);
  };
  const handleForgetPasswordAsync = async (firstEmail) => {
    try {
      debugger;
      const response = await axios.post("/ForgetPassword", {
        email: firstEmail || email,
      });
      console.log(response);

      setEmailEntered(true);
      dispatch(setSnackbar(true, "success", t("messags.emailSent")));
    } catch (err) {
      console.log(err);
    }
  };
  const handleBack = () => {
    setEmailEntered(false);
    setEmail("");
  };

  return (
    <div>
      <Box margin="20px" className={classes.root}>
        <RouterLink to="/">
          <img src="/assets/images/logo/logo.png" width="100%" />
        </RouterLink>
      </Box>
      <Container style={{ marginTop: "65px" }}>
        <Grid item container wrap="nowrap" spacing={3}>
          <Grid item xs={12} md={6}>
            {
              {
                false: (
                  <ResetPassword1 handleForgetPassword={handleForgetPassword} />
                ),
                true: (
                  <ResetPassword2
                    handleForgetPasswordAsync={handleForgetPasswordAsync}
                  />
                ),
              }[emailEntered]
            }

            {email && (
              <IconButton onClick={handleBack} style={{ marginTop: "50px" }}>
                <Box display="flex" color="primary">
                  <ArrowBackIcon color="secondary" />
                  <Typography color="textSecondary">
                    {" "}
                    {t("buttons.back")}
                  </Typography>
                </Box>
              </IconButton>
            )}
          </Grid>

          <Hidden only={["xs", "sm"]}>
            <Grid item md={6} xs={false}>
              <img
                src={`/assets/images/ResetPassword/ForgetPassword.svg`}
                className="img"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgetPassword;
