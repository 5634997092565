import React from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ProfileForm from "../ProfileForm";
import { useSelector } from "react-redux";

// import ProfileForm from './../../components/ProfileForm/index'
import MailIcon from "@material-ui/icons/Mail";
import { uploadImageAsync } from "./../../service/image";
import fileChangedHandler from "./../../containers/ImgUploader/index";

const useStyles = makeStyles((theme) => ({
  marginX: { margin: "30px 0" },
  marginRight: { marginRight: " 5px", marginTop: "10px" },
  break: {
    width: "100%",
    ["@media (max-width:350px)"]: {
      width: "50%",
      wordWrap: "break-word",
    },
  },
}));
const UserProfileSection = ({
  initialValues,
  validationSchema,
  handleSubmit,
  countryName,
  handleProfileImgChange,
  image,
  role,
  fieldNames,
  setImage,
}) => {
  const classes = useStyles();
  const currentLang = localStorage.i18nextLng;

  const countries = useSelector((state) => state.addressReducer.countries);
  console.log(countries);
  const handleImageUpload = async (imag, setFieldValue) => {
    const file = imag;
    const name = file.name;
    console.log(file);

    try {
      const formDate = new FormData();
      formDate.set(name, file);
      const imageNewName = await uploadImageAsync(formDate);
      setImage(imageNewName);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };

  return (
    <div style={{ border: "1px solid #0000001f", padding: "20px" }}>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        paddingBottom="15px"
        position="relative"
      >
        <Avatar
          // src={
          //   image
          //     ? 'https://ebazaraknewapi2.azurewebsites.net/api/File/GetImageUrl?ImageName=' +
          //       image
          //     : '/static/images/avatar/1.jpg'
          // }
          src={`${localStorage.imagePath}` + image}
          sizes={"large"}
          // className={classes.large}
          // alt={organizerProfile.firstName}
          style={{ width: "70px", height: "70px", marginRight: "20px" }}
          onChange={(e) => console.log(e.target.value)}
        />
        <div>
          <input
            type="file"
            accept="image/*"
            // hidden
            id="profile-img-uploaddd"
            style={{ display: "none" }}
            onChange={(e) =>
              fileChangedHandler(e, null, null, handleImageUpload)
            }
          />
          <IconButton
            style={{
              position: "absolute",
              bottom: 0,
              left: currentLang === "en" ? -10 : "",
              right: currentLang === "ar" ? -8 : "",
              color: "black",
            }}
          >
            <label htmlFor="profile-img-uploaddd">
              <CameraAltIcon />
            </label>
          </IconButton>
        </div>
        <div>
          <Typography variant="h6">
            {role === "Provider"
              ? initialValues.companyName
              : initialValues.firstName + " " + initialValues.lastName}
          </Typography>
          <Box display="flex" mt={1}>
            <LocationOnOutlinedIcon />
            <Typography>
              {/* {role === 'organizer' && organizerProfile.countryName} */}
              {/* {countries.length > 0 &&
                countries.find(
                  (country) => country.id === +initialValues.countryId,
                ).name} */}
              {countryName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <MailIcon
              className={classes.marginRight}
              fontSize={"small"}
              // color="secondary"
            />
            <Typography className={classes.break}>
              {initialValues.email}
            </Typography>
          </Box>
        </div>
      </Box>
      <Divider className={classes.marginX} />
      {/* <LoginForm /> */}
      <ProfileForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        fieldNames={fieldNames}
        role={role}
      />
    </div>
  );
};

export default UserProfileSection;
