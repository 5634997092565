import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FEF1DA",
    color: "#707070",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(dateTime, startAvailable, endAvailable) {
  return { dateTime, startAvailable, endAvailable };
}

const useStyles = makeStyles({
  table: {
    Width: "100%",
  },
});

export default function ZoomTable({ sellerAvailableTimes }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const rows = sellerAvailableTimes.map((itm) => {
    return createData(
      format(new Date(itm.dateTime + "Z"), "dd/MM/yyyy"),
      format(new Date(itm.startAvailable + "Z"), "h:mm a"),
      format(new Date(itm.endAvailable + "Z"), "h:mm a")
    );
  });
  return sellerAvailableTimes.length ? (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{t("date")}</StyledTableCell>
            <StyledTableCell align="center">{t("startAt")}</StyledTableCell>
            <StyledTableCell align="center">{t("endAt")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center">{row.dateTime}</StyledTableCell>
              <StyledTableCell align="center">
                {row.startAvailable}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.endAvailable}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer component={Paper} style={{ display: "none" }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{t("noTime")}</StyledTableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
