import { Box, Grid, Hidden, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoginForm from "./../../components/LoginForm/index";
import * as Yup from "yup";
import * as loginActions from "../../redux/auth/actions";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginWithGoogle from "../../components/LoginWithGoogle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    ["@media (max-width:900px)"]: {
      width: "25%",
    },
    ["@media (max-width: 600px)"]: {
      width: "35%",
    },
    ["@media (max-width: 425px)"]: {
      width: "55%",
    },
  },
  wid: {
    width: "25%",
  },
}));

const isRememberMe = (values) => {
  if (values.rememberMe === true) {
    localStorage.setItem("remeberMe", true);

    localStorage.setItem(
      "remeberedUser",
      JSON.stringify({ username: values.username, password: values.password })
    );
  } else {
    localStorage.setItem("remeberMe", false);
    localStorage.setItem("remeberedUser", "");
  }
};

const LoginPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });

  useEffect(() => {
    const remeberMe = localStorage.remeberMe;
    console.log(remeberMe);
    if (remeberMe === "true") {
      const remeberedUser = JSON.parse(localStorage.getItem("remeberedUser"));
      console.log(remeberedUser);
      setInitialValues({
        username: remeberedUser.username,
        password: remeberedUser.password,
        rememberMe: true,
      });
    }
  }, []);

  useEffect(() => {
    if (props.logedIn) {
      console.log("logedin", props.logedIn);
      console.log("user", props.userInfo);
      !props.modal &&
        props.history.push("/dashBoard/" + props.userInfo.roles[0]);
    }
  }, [props.logedIn, props.userInfo]);

  const validationSchema = Yup.object({
    username: Yup.string()
      .email("validation.invalidEmail")
      .required(t("validation.emailRequired")),
    password: Yup.string().required(t("validation.passwordReqiured")),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    props.login(values);
    isRememberMe(values);
    setTimeout(() => {
      setSubmitting(false);
    }, 4000);
  };
  console.error(props.error);
  return (
    <>
      {/* <div>
        <div>Hello,</div>
        <div style={{ color: 'orange' }}>
          Looks Like you are a POPULAR SELLER !
        </div>
        <span style={{ textDecoration: 'underline' }}>Marwan</span> wants you to
        join his eBazark{' '}
        <span
          style={{ fontSize: '18px', color: 'darhgray', fontWeight: 'bold' }}
        >
          Woow Bazar
        </span>{' '}
        .
        <div>
          Connect with organizer to know more <a href="">Click here</a>
        </div>{' '}
        <div>Message from Marwan (inviter) :" "</div>
      </div> */}
      <Box
        margin={!props.modal && "20px"}
        className={!props.modal ? classes.root : classes.wid}
      >
        <RouterLink to="/" style={{ width: "100%" }}>
          <img src="/assets/images/logo/logo.png" width="100%" />
        </RouterLink>
      </Box>
      {/* <LoginWithGoogle /> */}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        style={{ height: !props.modal && "100vh" }}
      >
        <Grid item xs={1} md={2}></Grid>
        <Grid item container xs={12} md={8}>
          <Box mt={7}>
            <Grid item xs={12}>
              <Box mt={!props.modal && { xs: 3, lg: 8 }} ml={{ xs: 0, lg: 5 }}>
                {/* {initialValues && ( */}

                <LoginForm
                  handleSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  error={props.error}
                  modal={props.modal || false}
                />
                {/* )} */}
              </Box>
            </Grid>
          </Box>
        </Grid>
        {!props.modal && (
          <>
            <Grid item>
              <Hidden only={["xs", "sm"]}>
                <Box>
                  <img
                    src={`/assets/images/Registration/${"buyer.svg"}`}
                    width="100%"
                  />
                </Box>
              </Hidden>
            </Grid>
          </>
        )}
        <Hidden mdUp>
          <Grid item xs={1} md={2}></Grid>
        </Hidden>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(state.loginReducer.error);
  return {
    logedIn: state.loginReducer.logedIn,
    userInfo: state.loginReducer.userInfo,
    error: state.loginReducer.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (user) => dispatch(loginActions.logIn(user)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
