import { Box, Button, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import RoleCardsList from "./../../components/RoleCardsList/index";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import SwitchLanguage from "./../../components/LanguageDropdown/index";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    // height: '90vh',
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8vh",
    marginBottom: "20px",
  },
}));

const SelectRolePage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  // const
  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value);
  };
  const goToRegister = () => {
    if (value) props.history.push("/register/" + value);
    else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  };
  const roles = [
    { id: 2, name: "seller", label: t("roles.Seller"), img: "seller.svg" },
    { id: 3, name: "buyer", label: t("roles.Buyer"), img: "buyer.svg" },
    {
      id: 1,
      name: "organizer",
      label: t("roles.Organizer"),
      img: "organizer.svg",
    },

    {
      id: 4,
      name: "serviceProvider",
      label: t("roles.Provider"),
      img: "serviceProvider.svg",
    },
  ];
  console.log(showError);
  return (
    <>
      <Box m="10px 30px">
        <SwitchLanguage />
      </Box>
      <Box className={classes.pageContainer}>
        <Box
          width={{ xs: 1, sm: 0.37 }}
          style={{ textAlign: "center", marginBottom: "30px" }}
        >
          <Link to="/">
            <img src="/assets/images/logo/logo.png" width="75%" />
          </Link>
        </Box>
        <Box>
          <RoleCardsList
            roles={roles}
            roleValue={value}
            handleChange={handleChange}
          />
        </Box>
        <Box></Box>
        <Box
          mt={{ xs: "60px", md: "60px" }}
          mr={{ xs: "30px", md: "80px" }}
          alignSelf="flex-end"
        >
          <Button variant="contained" color="primary" onClick={goToRegister}>
            {t("buttons.next")}
          </Button>
        </Box>
        {showError && (
          <Message msg="You should Select Role" status="error" openMsg={true} />
        )}
      </Box>
    </>
  );
};

export default SelectRolePage;
