import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import UserProfileSection from "./../../components/UserProfileSection/index";
import { phoneRegExp } from "../App/constants";
import { editSellerProfile } from "./../../redux/seller/actions";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
const SellerProfile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [image, setImage] = useState("");
  //   const organizerId = localStorage.userId
  const [countryName, setCountryName] = useState("");
  const seller = useSelector((state) => state.sellerReducer.seller);
  const initialValues = seller;

  useEffect(() => {
    setImage(seller.profileImage || "");
  }, [seller]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("validation.nameLength"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    // phoneNumber: Yup.string()
    //   .matches(phoneRegExp, t("validation.invalidPhone"))
    //   .required(t("validation.phoneRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
  });
  const handleProfileImgChange = (event) => {
    const newImage = event.target?.files?.[0];
    console.log(newImage);
    const formData = new FormData();
    formData.set(newImage.name, newImage);
    ////////////////////////// Call UPLOAD IMG API //////////////
    console.log(newImage);
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    values.profileImage = image;
    debugger;
    // if (isValidPhoneNumber(values.phoneNumber) === false) {
    //   dispatch(setSnackbar(true, "error", "please enter valid number"));
    // } else {
    dispatch(editSellerProfile(values));
    setTimeout(() => {
      // resetForm({})
      props.history.push("/dashBoard/Seller");
    }, 4000);
    // }
  };
  console.log(initialValues, "initialValues", seller);
  return (
    <UserProfileSection
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      countryName={
        localStorage.i18nextLng === "en"
          ? seller?.country?.name
          : seller?.country?.arabicName
      }
      handleProfileImgChange={handleProfileImgChange}
      image={image}
      setImage={setImage}
    />
  );
};

export default SellerProfile;
