import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
const ConfirmDeleteModalContent = ({
  handleDeleteConfirmed,
  handleClose,
  productId,
}) => {
  const {t}=useTranslation()
  return (
    <>
      <div style={{ width: '250px' }}>
        <Typography
          variant="h6"
          style={{ marginBottom: '20px', textAlign: 'center' }}
        >
          {t("DriversPage.confirmDelete")}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textTransform: 'uppercase',
          }}
        >
          <Button
            variant="outlined"
            style={{ color: 'orange', border: '1px solid orange' }}
            onClick={() => handleDeleteConfirmed(productId)}
          >
            {t("buttons.yes")}
          </Button>
          <Button
            variant="outlined"
            style={{ color: 'red', border: '1px solid red' }}
            onClick={handleClose}
          >
            {t("buttons.No")}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ConfirmDeleteModalContent
