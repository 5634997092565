import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import { Container } from '@material-ui/core'
import { Card, CardContent } from '@material-ui/core'

import { useState } from 'react'
import Header from '../Header'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: { backgroundColor: '#8080800f', textAlign: 'left' },
  MuiExpanded: {
    // margin: 20,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  rolesContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',

    // marginTop: '-28px',
    flexWrap: 'wrap',
  },
  roleCard: {
    padding: '18px 24px',
    margin: '20px 20px 5px 5px ',
    cursor: 'pointer',
    '&:hover': {
      // cursor: 'poniter',
    },
  },
  activeRole: {
    backgroundColor: '#f8b334',
    color: 'white',
  },
  helpWelcome: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '200px',
    // backgroundColor: '#c9c3c345',
    backgroundColor: '#8080800f',
    padding: '10px',
  },
  roleDescription: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '200px',
  },
}))

const QuestionsAccordion = ({
  selectedRole,
  handleRoleChange,
  allRolesQuestions,
  allRolesDescription,
}) => {
  const classes = useStyles()
  // const questionsArr = [
  //   { id: 1, q: 'ssss ?', ans: 'jjjkkk' },
  //   { id: 2, q: 'qqqq2 ?', ans: 'ans 2' },
  // ]
  const { t } = useTranslation()
  const lang = localStorage.i18nextLng
  // const [selectedRole, setselectedRole] = useState('Organizer')
  // const rolesArr = ['Seller', 'Buyer', 'Organizer', 'Provider']
  // const {t}=useTranslation()
  debugger
  return (
    <>
      <div className={classes.root}>
        <div className={classes.roleDescription}>
          <h3>{t('roles.' + selectedRole)}</h3>
          <Typography>{allRolesDescription[selectedRole]}</Typography>
        </div>
        {allRolesQuestions[selectedRole].map((item, index) => (
          // <Container>
          <Accordion key={item.id} classes={{ root: classes.MuiExpanded }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {t('question')} {index + 1}: {item.q[lang]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography>{item.ans[lang]}</Typography>
            </AccordionDetails>
          </Accordion>
          // </Container>
        ))}
      </div>
      {/* </Container> */}
    </>
  )
}

export default QuestionsAccordion
