import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { Form, Formik } from "formik";
import FormikField from "./../FormikField/index";

import * as Yup from "yup";

const ResetPassword3 = ({ handleResetPassword }) => {
  const { t } = useTranslation();

  const initialValues = { password: "", confirmPassword: "" };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("validation.passwordReqiured"))
      .min(6, t("validation.minCharacters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        `${t("validation.lowerCase")} , ${t("validation.upperCase")} , ${t(
          "validation.specialChar"
        )}`
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("validation.confirmPassword")
    ),
  });
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <h2>{t("forgetPassword.resetYourPassword")}</h2>
      </Grid>
      <Formik
        initialValues={initialValues}
        //   enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleResetPassword}
      >
        {({ values, errors }) => {
          // console.log(values)
          // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

          return (
            <Form
              autoComplete="false"
              style={{
                width: "100%",
                // display: 'flex',
                // alignItems: 'center',
              }}
            >
              <Grid item xs={12} md={8} style={{ margin: "60px 0" }}>
                <FormikField
                  type="password"
                  name="password"
                  label={t("signUp.password")}
                />
              </Grid>
              <Grid item xs={12} md={8} style={{ margin: "60px 0" }}>
                <FormikField
                  type="password"
                  name="confirmPassword"
                  label={t("signUp.confirmPassword")}
                />
              </Grid>
              <Grid item xs={12} md={8} container justify="center">
                <Button color="primary" variant="contained" type="submit">
                  {t("forgetPassword.resetPassword")}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default ResetPassword3;
