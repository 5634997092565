import React, { useEffect, useState, useRef } from "react";
import { createRef } from "react";
import UnControlledTable from "../../components/UncontrolledTable";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Box, Link, Grid, Typography, makeStyles } from "@material-ui/core";
import { textalign } from "../../styles/commonCss";
import Dropdown from "../../components/Dropdown";
import { handleLogout } from "../../utils/setLocalStorage";
import {
  getProviderBazarsAsync,
  getBazarOrderItemsAsync,
  GetProviderOrdersFilterByBazarAsync,
  getAllProviderOrdersAsync,
} from "../../redux/serviceProvider/api";
import { format } from "date-fns";
import { colors } from "../../styles/commonCss";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import ShippmentAdressModal from "../../components/ShippmentAddress";
import ControlledAppModal from "../../components/ControlledAppModal";
import BuyerInfoModalContent from "../../components/ManageOrdersSection/partials/BuyerModalContent";
import ProductDetailsPage from "./../../containers/ProductDetailPage/index";
import axios from "axios";
import AppModal from "./../../components/AppModal/index";
import { getCountryCities } from "./../../utils/getCountryCities";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries } from "../../redux/address/actions";
import { getServiceProviderById } from "./../../redux/serviceProvider/actions";
import { setSnackbar } from "./../../redux/Message/actions";
const useStyles = makeStyles((theme) => ({
  chartSize: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    margin: "15px 40px 60px 40px",
  },
  // color: {
  //   width: "150px",
  //   height: "200px",
  //   backgroundColor: "whitesmoke",
  //   margin: "1rem",
  // },
  border: {
    border: "3px solid gray",
  },
}));

const statusIndicator = {
  Complete: { title: "Complete", color: "green" },
  Processing: { title: "Processing", color: "blue" },

  Pending: { title: "Pending", color: "orange" },
};
const ProviderManageOrders = (props) => {
  const tableRef = createRef();
  console.log(tableRef, "tableRef");
  const providerId = localStorage.userId;
  const { t } = useTranslation();
  const [providerBazars, setProviderBazars] = useState([]);
  const [items, setItems] = useState([]);
  const bazarId = useParams().bazarId || "";
  const [selectedBazar, setSelectedBazar] = useState(+bazarId || "");
  const [dataa, setData] = useState([]);
  const [count, setCount] = useState(0);
  let location = useLocation();
  const [currentDispaly, setCurrentDisplay] = useState(
    selectedBazar ? localStorage.getItem("radiobtn") || "orders" : "orders"
  );
  const classes = useStyles();
  const [openSellerModal, setOpenSellerModal] = useState(false);
  const [productId, setProductId] = useState();
  const [openViewProduct, setOpenViewProduct] = useState(false);
  const [seller, setSeller] = useState("");
  const [city, setCity] = useState("");
  const dispatch = useDispatch();
  // const { data, isFetching } = useQuery(["items", selectedBazar], () =>
  //   axios.get(
  //     `Provider/GetBazarOrderItems?BazarId=${selectedBazar}&PageSize=${1}&PageIndex=${10}`
  //   )
  // );

  const fetchData = (selectedDisplay = currentDispaly) => {
    debugger;
    async function getProviderBazars() {
      const response = await getProviderBazarsAsync(providerId);
      console.log(response.data, "providerBazars", selectedBazar);
      setProviderBazars(response.data);
    }
    // async function getBazarOrderItemsAsync() {
    //   const response = await getBazarOrderItemsAsync(selectedBazar, 1, 10);
    //   console.log(response, "setItems", selectedBazar);
    //   setItems(response.data);
    // }
    // selectedDisplay === "items" && selectedBazar
    //   ? getBazarOrderItemsAsync()
    //   :
    getProviderBazars();
  };
  let sum = 0;
  let x = dataa?.map((itm) => {
    sum = sum + itm?.earning;
    return sum;
  });
  const handleBazarFilterChange = (e) => {
    setSelectedBazar(+e.target.value);
    props.history.push(`/Provider/manageOrders/${e.target.value}`);
    // tableRef.current.onQueryChange();
  };

  useEffect(() => {
    debugger;
    fetchData(currentDispaly);
  }, []);
  const [earning_, setEarning_] = useState([]);
  useEffect(() => {
    axios
      .get(`Provider/GetEarningToProvider?BazarId=${selectedBazar}`)
      .then((response) => {
        setEarning_(response.data);
        console.log(response.data, ",,ll");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedBazar]);
  const changeCurrentDisplayHandler = (e) => {
    setCurrentDisplay(e.target.value);
    localStorage.setItem("radiobtn", e.target.value);
    fetchData(e.target.value);
    tableRef.current.onQueryChange({ page: 0 });
  };

  const handleOpenSellerModal = (seller) => {
    setSeller(seller);

    setOpenSellerModal(true);
  };

  const handleCloseSellerModal = () => {
    setOpenSellerModal(false);
  };
  const handleViewBoothProduct = (productId) => {
    setProductId(productId);
    handleopenViewProductModal();
  };
  const handleopenViewProductModal = () => {
    setOpenViewProduct(true);
  };
  const handleCloseViewProduct = () => {
    setOpenViewProduct(false);
  };

  const provider = useSelector(
    (state) => state.serviceProviderReducer.provider
  );
  const countries = useSelector((state) => state.addressReducer.countries);
  const handleCityChange = (e) => {
    setCity(e.target.value);
    tableRef.current.onQueryChange();
    console.log(e.target.value);
  };
  useEffect(() => {
    dispatch(getServiceProviderById(providerId));
    dispatch(getAllCountries());
  }, []);
  const pathname = location.pathname;
  const handleDownload = (e) => {
    try {
      const obj = {
        bazarId: bazarId,
        pageIndex: 0,
        pageSize: 0,
        language: localStorage.i18nextLng === "en" ? "en" : "ar",
        providerId: providerId,
        status: 0,
        paymentMethod: 0,
        reportType: 2,
        paramReport: [
          {
            name: "ReportTitle",
            value: "اداره طلبات",
          },
        ],
      };
      const objItems = {
        bazarId: bazarId,
        pageIndex: 0,
        pageSize: 0,
        language: localStorage.i18nextLng === "en" ? "en" : "ar",
        sellerId: null,
        cityId: city,
        reportType: 2,
        paramReport: [
          {
            name: "ReportTitle",
            value: "اداره طلبات",
          },
          {
            name: "CityName",
            value: getCountryCities(provider?.country?.id, countries).find(
              (itm) => itm.id === city
            )?.name,
          },
          {
            name: "BazarName",
            value: providerBazars.find((itm) => itm.id === selectedBazar)?.name,
          },
        ],
      };
      currentDispaly === "orders"
        ? axios
            .post(
              "https://api.ebazarak.com/Report/GetProviderOrdersReport",
              obj
            )
            .then((response) => {
              console.log(response.data);
              window.open(localStorage.imagePath + response.data, "_blank");
            })
        : axios
            .post(
              "https://api.ebazarak.com/Report/GetBazarOrderItems",
              objItems
            )
            .then((response) => {
              console.log(response.data);
              window.open(localStorage.imagePath + response.data, "_blank");
            });
    } catch (e) {
      console.log(e);
      dispatch(setSnackbar(true, "success", t("messages.somethingWentWrong")));
    }
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      {providerBazars.find((itm) => itm.id === selectedBazar) ? (
        <div>
          <Grid item xs={12}>
            <h3 style={{ margin: "0", fontSize: "1.2rem" }}>{t("stats")}</h3>
          </Grid>
          <Grid
            container
            xs={12}
            style={{ marginBottom: "10px" }}
            item
            justify="center"
          >
            <Grid item className={classes.color}>
              <div className={classes.chartSize}>
                <img src="/assets/images/charts/order.svg" width="100%" />
                <div style={{ fontSize: "16px", marginTop: "-10%" }}>
                  {t("order.orders")}
                </div>
                <div>
                  {/* {
                    providerBazars.find((itm) => itm.id === selectedBazar)
                      .numOfOrders
                  } */}
                  {count}
                </div>
              </div>
            </Grid>
            {/* <Grid item className={classes.color}>
          <div className={classes.chartSize}>
            <img src="/assets/images/charts/selles.svg" width="100%" />
            <div style={{ fontSize: "16px", marginTop: "-10%" }}>
              {t("sales")}
            </div>
            <div>{selles}</div>
          </div>
        </Grid> */}
            <Grid item className={classes.color}>
              <div className={classes.chartSize}>
                <img src="/assets/images/charts/earnings.svg" width="100%" />
                <div style={{ fontSize: "16px", marginTop: "-10%" }}>
                  {t("earning")}
                </div>
                {/* <div>{sum}</div> */}
                <div>{earning_?.earning}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}
      <Box display="flex" justifyContent="flex-end">
        {selectedBazar ? (
          <FormControl component="fieldset" style={{ marginTop: "8px" }}>
            <RadioGroup
              row
              aria-label="gender"
              name="row-radio-buttons-group"
              value={currentDispaly}
              onChange={changeCurrentDisplayHandler}
            >
              <FormControlLabel
                value="orders"
                control={<Radio color="primary" />}
                label={t("order.orders")}
              />
              <FormControlLabel
                value="items"
                control={<Radio color="primary" />}
                label={t("items")}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          ""
        )}
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ fontSize: "1.2rem", margin: "0" }}>
          {t("ProviderManage.manageOrders")}
          {pathname.includes("Provider/manageOrders/") ? (
            <i class="fas fa-file-excel icons" onClick={handleDownload}></i>
          ) : (
            ""
          )}
        </h3>
        <div>
          <Dropdown
            items={providerBazars}
            // style={{ marginBottom: "50px" }}
            className={classes.border}
            label={t("ProviderManage.bazar")}
            value={selectedBazar}
            handleChange={handleBazarFilterChange}
          />
          {currentDispaly === "items" ? (
            <Dropdown
              items={getCountryCities(provider?.country?.id, countries)}
              label={t("signUp.city")}
              flag={true}
              handleChange={handleCityChange}
              value={city}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {currentDispaly === "orders" ? (
        <UnControlledTable
          tableRef={tableRef}
          // title={t("ProviderManage.manageOrders")}
          sorting={true}
          search={false}
          toolbar={false}
          padding="0px"
          doubleHorizontalScroll={false}
          columns={[
            {
              title: t("ProviderManage.order"),
              sorting: false,
              field: "orderNumber",
              type: "text",
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => {
                return (
                  <Link
                    component="button"
                    style={{
                      color: colors.link,
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                    variant="body2"
                    onClick={(e) =>
                      props.history.push(
                        "/Provider/orderDetails/" + row.orderNumber
                      )
                    }
                  >
                    {row.orderNumber}
                  </Link>
                );
              },
            },
            {
              title: t("bazarName"),
              field: "bazarName",
              sorting: false,

              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("date"),
              field: "date",
              sorting: false,

              render: (row) => (
                <div>
                  {row.orderDate
                    ? format(new Date(row?.orderDate), "MMMM dd, yyyy ")
                    : ""}
                </div>
              ),
              cellStyle: { width: "fit-content", textAlign: "center" },
            },

            {
              title: t("totalPrice"),
              sorting: false,
              // field: 'organizer',

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row.currency} {row.total}
                </div>
              ),
            },

            {
              title: t("ProviderManage.earning"),
              sorting: false,

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row.currency} {row?.earning}
                </div>
              ),
            },
            {
              title: t("ProviderManage.paymentMethod"),

              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => <div>{row.paymentMethod}</div>,
            },
            {
              title: t("status"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FiberManualRecordIcon
                      // fontSize="small"
                      style={{
                        color: statusIndicator[row.status]?.color,
                        marginRight: "10px",
                        fontSize: "12px",
                      }}
                    />
                    {row.status}
                  </Box>
                </div>
              ),
            },
          ]}
          data={
            //   [
            //     {
            //       orderNumber: 11,
            //       date: '2/5/2021',
            //       price: 200,
            //       earning: 50,
            //       payment: 'Paypal',
            //       status: 'Pending',
            //     },
            //   ]
            (query) => {
              debugger;
              return new Promise(async (resolve, reject) => {
                const { page, pageSize, search, orderBy, orderDirection } =
                  query;
                let ascORdes = orderDirection === "desc" ? 1 : 2;
                try {
                  let result;
                  if (orderBy?.title === "Status" && selectedBazar) {
                    result = await GetProviderOrdersFilterByBazarAsync(
                      providerId,
                      selectedBazar,
                      page + 1,
                      pageSize,
                      ascORdes,
                      0
                    );
                  } else if (
                    selectedBazar &&
                    orderBy?.title === "Payment Method"
                  ) {
                    result = await GetProviderOrdersFilterByBazarAsync(
                      providerId,
                      selectedBazar,
                      page + 1,
                      pageSize,
                      0,
                      ascORdes
                    );
                  } else if (orderBy && !selectedBazar) {
                    result =
                      orderBy?.title === "Payment Method"
                        ? await getAllProviderOrdersAsync(
                            providerId,
                            page + 1,
                            pageSize,
                            0,
                            ascORdes
                          )
                        : await getAllProviderOrdersAsync(
                            providerId,
                            page + 1,
                            pageSize,
                            ascORdes,
                            0
                          );
                  } else {
                    result =
                      !selectedBazar && !orderBy
                        ? await getAllProviderOrdersAsync(
                            providerId,
                            page + 1,
                            pageSize,
                            0,
                            0
                          )
                        : await GetProviderOrdersFilterByBazarAsync(
                            providerId,
                            selectedBazar,
                            page + 1,
                            pageSize,
                            0,
                            0
                          );
                  }
                  debugger;
                  console.log(result, "ProviderOrdersFilter");
                  resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.count || 1,
                  });
                  setCount(result.count);
                } catch (error) {
                  console.log(error);
                  console.log(error?.response);
                  resolve({
                    data: [],
                    page: query.page,
                    totalCount: 1,
                  });
                  if (error?.response?.status === 401) {
                    handleLogout();
                    window.location.href = "/login";
                  }
                }
              });
            }
          }
        />
      ) : (
        <UnControlledTable
          tableRef={tableRef}
          search={false}
          toolbar={false}
          padding="0px"
          doubleHorizontalScroll={false}
          columns={[
            {
              title: t("item"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <Link
                  component="button"
                  style={{
                    color: colors.link,
                    fontSize: "15px",
                    textDecoration: "underline",
                    // textAlign: 'center',
                  }}
                  variant="body2"
                  onClick={() => handleViewBoothProduct(row?.product?.id)}
                >
                  {row?.product?.name}
                </Link>
              ),
            },
            {
              title: t("description"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  <AppModal
                    titleStyle={{ margin: "8px auto" }}
                    label={t("links.view")}
                  >
                    {row?.description}
                  </AppModal>
                </div>
              ),
            },
            {
              title: t("itemId"),
              render: (row) => <div>{row?.product?.id}</div>,
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("order.orderNo"),
              field: "orderNo",
              type: "text",
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => {
                return (
                  <Link
                    component="button"
                    style={{
                      color: colors.link,
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                    variant="body2"
                    onClick={(e) =>
                      props.history.push(
                        "/Provider/orderDetails/" + row.orderNo
                      )
                    }
                  >
                    {row.orderNo}
                  </Link>
                );
              },
            },
            {
              title: t("sellerName"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <Link
                  component="button"
                  style={{
                    color: colors.link,
                    fontSize: "15px",
                    textDecoration: "underline",
                  }}
                  variant="body2"
                  onClick={() => handleOpenSellerModal(row?.product?.seller)}
                >
                  {row?.product?.seller ? (
                    <>
                      {row?.product?.seller?.firstName +
                        " " +
                        row?.product?.seller?.lastName}
                    </>
                  ) : (
                    ""
                  )}
                </Link>
              ),
            },
            {
              title: t("BasicTable.Price"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row?.product?.currency} {row?.product?.price}
                </div>
              ),
            },
            {
              title: t("quantity"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => <div>{row?.units}</div>,
            },
            {
              title: t("totalItem"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row?.product?.currency} {+row?.unitPrice * +row?.units}
                </div>
              ),
            },

            {
              title: t("shippingAddress"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  <ShippmentAdressModal label={t("links.view")} user={row} />
                </div>
              ),
            },

            {
              title: t("status"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <FiberManualRecordIcon
                    // fontSize="small"
                    style={{
                      color: statusIndicator[row.orderItemStatusName]?.color,
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  />
                  {row.orderItemStatusName}
                </Box>
              ),
            },
          ]}
          data={(query) => {
            return new Promise(async (resolve, reject) => {
              const { page, pageSize } = query;
              try {
                const result = await getBazarOrderItemsAsync(
                  selectedBazar,
                  page + 1,
                  pageSize,
                  city
                );
                console.log(result, "ppppppppppppppppppppppppppppp");
                resolve({
                  data: result.data || items,
                  page: query.page,
                  totalCount: result.count || 1,
                });
                const x = await GetProviderOrdersFilterByBazarAsync(
                  providerId,
                  selectedBazar,
                  1,
                  1000
                );
                setData(x?.data);
                setCount(x?.count);
                console.log(x?.data, "x?.data");
              } catch (error) {
                console.log(error, "console.result");
                console.log(error?.response);
                resolve({
                  data: [],
                  page: query.page,
                  totalCount: 1,
                });
                if (error?.response?.status === 401) {
                  handleLogout();
                  window.location.href = "/login";
                }
              }
            });
          }}
        />
      )}
      <ControlledAppModal
        open={openViewProduct}
        handleClickOpen={handleViewBoothProduct}
        handleClose={handleCloseViewProduct}
      >
        <div style={{ maxWidth: "800px" }}>
          <ProductDetailsPage productId={productId} />
        </div>
      </ControlledAppModal>
      <ControlledAppModal
        handleClickOpen={handleOpenSellerModal}
        handleClose={handleCloseSellerModal}
        open={openSellerModal}
        // title=""
        // contentStyle={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   padding: '8px 80px',
        //   marginBottom: '50px',
        // }}
        titleStyle={{ margin: "8px auto" }}
      >
        <BuyerInfoModalContent {...seller} />
      </ControlledAppModal>
    </div>
  );
};

export default ProviderManageOrders;
