import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAllCountries } from './../../redux/address/actions'
import FormikSelect from './../FormikSelect/index'
const CountriesDropDown = (props) => {
  const { name, label } = props
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCountries())
  }, [])

  console.log(props.countries)
  return <FormikSelect name={name} label={label} items={props.countries} />
  //   return <></>
}
const mapStateToProps = (state) => {
  console.log(state)
  return {
    countries: state.addressReducer.countries,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getAllCountries()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountriesDropDown)
