import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import { Box, Button, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";

import { socialMediaArray } from "./../Registeration/OrganizerSignUpForm/constants";
import Link from "@material-ui/core/Link";
import { colors } from "../../styles/commonCss";
import Chip from "@material-ui/core/Chip";
import { blue, red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: `9%`,
    left: `32%`,
    width: "40%",

    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    maxHeight: 520,
    overflowY: "auto",
    // [theme.breakpoints.down('sm')]: {

    // },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      top: `9%`,
      left: `10%`,
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: "5px",
  },
  avatarSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "5px 20px ",
  },
  marginRight: {
    marginRight: "12px",
  },
  marginLeft: {
    marginLeft: "12px",
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  linkColor: { color: colors.link },
  acceptBtn: {
    fontSize: 14,
    border: "1px solid",
    borderColor: blue[800],
    color: blue[700],
    fontWeight: "bold",
    textTransform: "capitalize",
    // marginBottom: '0.875em',
  },
  declineBtn: {
    fontSize: 14,
    border: "1px solid",
    color: red[500],
    borderColor: red[500],
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  display: {
    display: "flex",
    alignItems: "center",
    ["@media (max-width:500px)"]: {
      flexDirection: "column",
    },
  },
}));
const RequestInfoModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState("");
  const {
    user: {
      id,
      name,
      profileImage,
      email,
      whatsNumber,
      phoneNumber,
      cityName,
      country,
      postalCode,
      street,
      companyCertificate,
      socialMediaLinks = [],
    },
    boothId,
    role,
    label,
    boothName,
    boothCity,
    boothStreet,
    boothCountry,
    categories = [],
    description,
    boothCategories = [],
    handleDeclineBoothRequest,
    handleAcceptBoothRequest,
    requestResponse,
  } = props;

  console.log(categories, "categories");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const body = (
    <div className={classes.paper}>
      <div className={classes.avatarSection}>
        <h3 className={classes.textCenter} id="simple-modal-title">
          {t("requestInfo")}
        </h3>
        <Avatar
          alt={name?.toUpperCase()}
          src={
            profileImage
              ? localStorage.imagePath + profileImage
              : "/static/images/avatar/1.jpg"
          }
          className={classes.large}
        />
        <Typography>{name}</Typography>
        <Box className={classes.display} style={{ direction: "ltr" }}>
          <MailIcon
            className={classes.marginRight}
            fontSize={"small"}
            color="secondary"
          />
          <Typography>{email}</Typography>
        </Box>
        <div>
          <Box
            display="flex"
            justifyContent="center"
            mt={1}
            alignItems="center"
            style={{ direction: "ltr" }}
          >
            <LocationOnOutlinedIcon
              //   className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>
              {localStorage.i18nextLng === "en"
                ? country?.name
                : country?.arabicName}
            </Typography>
          </Box>
        </div>
      </div>
      <div>
        <h3 className={classes.basicInfoTitle}>
          {t(role)} {t("info")}
        </h3>

        <Typography variant="h6" style={{ marginBottom: "2px" }}>
          {boothName}
        </Typography>
        <div>
          <Box display="flex" alignItems="stretch">
            <LocationOnOutlinedIcon
              color="secondary"
              className={classes.marginRight}
              fontSize={"small"}
              style={{ marginTop: "2px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{boothCountry}</Typography>

              {/* {!boothStreet && <Typography>, {boothCity} </Typography>} */}
              <>
                <div style={{ display: "flex" }}>
                  <Typography>{boothCity} </Typography>
                  {boothStreet && (
                    <>
                      <div style={{ margin: "0 5px" }}> , </div>
                      <Typography> {boothStreet} Street</Typography>
                    </>
                  )}
                </div>
                {/* <Typography>{postalCode}</Typography> */}
              </>
            </div>
          </Box>
          {categories.length > 0
            ? categories && (
                <Box paddingTop="10px" marginLeft="27px">
                  {categories.map((cat) => (
                    <Chip
                      key={cat.id}
                      label={
                        localStorage.i18nextLng === "en"
                          ? cat?.name
                          : cat?.arabicName
                      }
                      variant="outlined"
                      color="primary"
                      className={categories.length > 1 && "margin-right-s"}
                      style={{ margin: "10px 0" }}
                    />
                  ))}
                </Box>
              )
            : boothCategories && (
                <Box paddingTop="10px">
                  {boothCategories.map((cat) => (
                    <Chip
                      key={cat.id}
                      label={
                        localStorage.i18nextLng === "en"
                          ? cat?.name
                          : cat?.arabicName
                      }
                      variant="outlined"
                      color="primary"
                      className={boothCategories.length > 1 && "margin-right-s"}
                      style={{ margin: "5px " }}
                    />
                  ))}
                </Box>
              )}
          <Box
            // className={classes.display}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <h4>{t("description")} :</h4>
            <div>
              <Typography>{description}</Typography>
            </div>
          </Box>
        </div>
      </div>
      <div>
        <h3 className={classes.basicInfoTitle}>{t("contactInfo")}</h3>
      </div>

      <div>
        <Box display="flex" alignItems="center" dir="ltr">
          <PhoneIcon
            className={classes.marginRight}
            fontSize={"small"}
            color="secondary"
          />
          <Typography>{phoneNumber}</Typography>
        </Box>
      </div>

      <>
        {socialMediaLinks.length
          ? socialMediaLinks.map((socialLink, index1) => (
              <Box display="flex" alignItems="center" mt={1} key={index1}>
                {socialMediaArray.map((item) => {
                  if (item.type === socialLink.type) {
                    return (
                      <>
                        {socialLink.url ? item?.icon : ""}{" "}
                        <Typography
                          className={classes.marginLeft}
                          key={item.value}
                        >
                          {socialLink.url}
                        </Typography>
                      </>
                    );
                  } else return "";
                })}
              </Box>
            ))
          : ""}
      </>
      {!requestResponse && (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            // width: '70%',
          }}
        >
          <Box display="flex" my={2} justifyContent="space-around" width={0.75}>
            <Button
              variant="outlined"
              className={classes.acceptBtn}
              onClick={() => handleAcceptBoothRequest(boothId)}
              style={{ marginRight: "20px" }}
            >
              {t("buttons.accept")}
            </Button>
            <Button
              variant="outlined"
              className={classes.declineBtn}
              onClick={() => handleDeclineBoothRequest(boothId)}
            >
              {t("buttons.decline")}
            </Button>
          </Box>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={handleOpen}
        style={{ color: colors.link, fontSize: "15px" }}
      >
        {label}
      </Link>
      {/* <Button type="button" variant="link" onClick={handleOpen}>
            View
          </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default RequestInfoModal;
