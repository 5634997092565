import { GET_ALL_CATEGORIES } from "../actionTypes";
import { getAllCategoriesAsync } from "./api";

export const getCateories = () => {
  return async (dispatch) => {
    try {
      const result = await getAllCategoriesAsync();
      debugger;
      // dispatch(getCategoriesSuccess(result.catalogTypes));
      dispatch(getCategoriesSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getCategoriesSuccess = (categories) => {
  return {
    type: GET_ALL_CATEGORIES,
    payload: categories,
  };
};
