import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import tableIcons from '../../styles/materialTableIcons'
import { Link } from 'react-router-dom'
import RequestInfoModal from './../../components/RequestInfoModal/index'
import { useTranslation } from 'react-i18next'

const BoothRequetActionsDropdown = (props) => {
  const { t } = useTranslation()
  const { handleAcceptBoothRequest, handleDeclineBoothRequest, boothId } = props
  const [user, setUser] = useState('')

  const [action, setAction] = useState('')

  const handleActionChange = (e, user) => {
    console.log(e.target.value, 'uuyy')
    const selectedAction = e.target.value
    setAction(selectedAction)
    switch (selectedAction) {
      case 'Accept':
        /// call accept booth request api
        handleAcceptBoothRequest(boothId)

        break
      case 'Decline':
        //// call Declin booth reuest api
        handleDeclineBoothRequest(boothId)
        break

      default:
        break
    }
    setAction('')
  }
  return (
    <>
      <FormControl
        variant="standard"
        style={{ width: '80px', marginRight: '10px' }}
        // onSubmit={handleSubmit}
      >
        <InputLabel htmlFor="Language">{t('action')}</InputLabel>
        <Select
          labelId="Language"
          value={action}
          //   name={'actions' + row.id}
          onChange={(e) => handleActionChange(e, {})}
          inputProps={{
            id: 'open-select',
          }}
        >
          <MenuItem value="">
            <em>{t('homePage.none')}</em>
          </MenuItem>
          {/* {[t("buttons.accept"), t("buttons.decline")].map((option, key) => ( */}
          {[
            { label: t('buttons.accept'), value: 'Accept' },
            { label: t('buttons.decline'), value: 'Decline' },
          ].map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default BoothRequetActionsDropdown
