import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { Grid, Button, Link } from "@material-ui/core";
import RowForm from "./RowForm";
import { useEffect, useState } from "react";
import ControlledAppModal from "../ControlledAppModal/index";
import BuyerInfoModalContent from "../ManageOrdersSection/partials/BuyerModalContent";
import { colors } from "../../styles/commonCss";
import ProductDetailsPage from "./../../containers/ProductDetailPage/index";
import { useTranslation } from "react-i18next";
import { id } from "date-fns/locale";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    // margin: '50px 50px',
  },
  row: { padding: "0px 10px", marginBottom: "10px", fontSize: "14px" },
});

export default function BasicTable({
  orderItems,
  drivers,
  statusArr,
  orderStatus,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [seller, setSeller] = useState("");
  const [openSellerModal, setOpenSellerModal] = useState(false);
  const [productId, setProductId] = useState();
  const [openViewProduct, setOpenViewProduct] = useState(false);

  const handleOpenSellerModal = (seller) => {
    setSeller(seller);

    setOpenSellerModal(true);
  };

  const handleCloseSellerModal = () => {
    setOpenSellerModal(false);
  };
  const handleViewBoothProduct = (productId) => {
    setProductId(productId);
    handleopenViewProductModal();
  };
  const handleopenViewProductModal = () => {
    setOpenViewProduct(true);
  };
  const handleCloseViewProduct = () => {
    setOpenViewProduct(false);
  };

  useEffect(() => {}, []);
  console.log(orderItems, "orderItems");
  return (
    <>
      <ControlledAppModal
        handleClickOpen={handleOpenSellerModal}
        handleClose={handleCloseSellerModal}
        open={openSellerModal}
        // title=""
        // contentStyle={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   padding: '8px 80px',
        //   marginBottom: '50px',
        // }}
        titleStyle={{ margin: "8px auto" }}
      >
        <BuyerInfoModalContent {...seller} />
      </ControlledAppModal>
      <ControlledAppModal
        open={openViewProduct}
        handleClickOpen={handleViewBoothProduct}
        handleClose={handleCloseViewProduct}
        // title={' You can add new product Or exist product'}
        // titleStyle={{ textAlign: 'center' }}
      >
        <div style={{ maxWidth: "800px" }}>
          <ProductDetailsPage productId={productId} />
        </div>
      </ControlledAppModal>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <div className={classes.table}>
          <div style={{ dispaly: "inline-block" }}>
            <Grid container>
              <Grid
                container
                justify="space-around"
                // className={classes.row}
                style={{
                  backgroundColor: "#d3d3d357",
                  fontWeight: 600,
                  padding: "15px 10px",
                }}
                wrap="nowrap"
              >
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.Product")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.ProductId")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.Price")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.Quantity")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("totalItem")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.seller")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("BasicTable.driver")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("status")}
                </Grid>
                <Grid item xs={4} container justify="center">
                  {t("action")}
                </Grid>
              </Grid>
              {orderItems.map(
                ({
                  product: {
                    categoryName,
                    name,
                    price,
                    total,
                    seller,
                    driver,
                    status,
                    productId,
                    id: boothProductId,
                    quantity,
                  },
                  units,
                  driverId,
                  orderItemStatus,
                  unitPrice,
                  id,
                }) => (
                  <Grid
                    container
                    justify="space-around"
                    className={classes.row}
                    wrap="nowrap"
                    alignItems="center"
                    key={id}
                  >
                    <Grid
                      item
                      xs={4}
                      container
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      <Grid item xs={12}>
                        <Link
                          component="button"
                          style={{
                            color: colors.link,
                            fontSize: "15px",
                            textDecoration: "underline",
                            // textAlign: 'center',
                          }}
                          variant="body2"
                          onClick={() => handleViewBoothProduct(boothProductId)}
                        >
                          {name}
                        </Link>
                        {/* <div style={{ textAlign: 'center' }}> </div> */}
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center", marginTop: "7px" }}>
                          {categoryName}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      {boothProductId}
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      JD {unitPrice}
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      {units}
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      JD {total || unitPrice * units}
                    </Grid>
                    <Grid item xs={4} container justify="center">
                      <Link
                        component="button"
                        style={{
                          color: colors.link,
                          fontSize: "15px",
                          textDecoration: "underline",
                        }}
                        variant="body2"
                        onClick={() => handleOpenSellerModal(seller)}
                      >
                        {seller.firstName}
                      </Link>
                    </Grid>
                    <RowForm
                      drivers={drivers}
                      initialValues={{
                        orderItemId: id,
                        driverId: driverId || "",
                        orderItemStatus:
                          orderStatus === 3
                            ? // ? statusArr[statusArr.length - 1].id
                              4
                            : orderItemStatus,
                      }}
                      statusArr={statusArr}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </div>
        </div>
      </TableContainer>
    </>
  );
}
