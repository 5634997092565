import React from 'react'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { handleLogout } from '../../utils/setLocalStorage'
const Logout = ({ variant = 'outlined', color, history, ...rest }) => {
  // const handleLogout = () => {
  //   console.log('log outttttttttttttttttttttt')
  //   localStorage.userInfo = ''
  //   localStorage.userAllInfo = ''
  //   localStorage.userRole = ''
  //   localStorage.userId = ''
  //   localStorage.token = ''
  //   localStorage.bazarId = ''
  //   localStorage.step = 0

  //   // history.replace('/')
  //   window.location.href = '/'
  // }
  const { t } = useTranslation()
  return (
    <Button
      variant={variant}
      color={color || 'primary'}
      onClick={handleLogout}
      {...rest}
    >
      {t('buttons.logout')}
    </Button>
  )
}

export default withRouter(Logout)
