import * as registerApis from "./api";
import * as actionTypes from "./../actionTypes";
import { logIn } from "../auth/actions";
import { logInAsync } from "../auth/api";
import { setUserInfoTolocalStorage } from "./../../utils/setLocalStorage";

export const organizerRegister = (organizerInfo) => {
  return async (dispatch) => {
    try {
      const result = await registerApis.organizerRegisterAsync(organizerInfo);
      console.log(result);
      debugger;
      const logedInUser = await logInForRegisteredUser(organizerInfo);
      dispatch(registerSuccess(result.message, logedInUser));
    } catch (error) {
      console.log(error);
      debugger;
      console.log(error?.response?.data.error);
      dispatch(registerError(error?.response?.data.error));
    }
  };
};
export const sellerRegister = (seller) => {
  return async (dispatch) => {
    try {
      const result = await registerApis.sellerRegisterAsync(seller);
      console.log(result);
      const logedInUser = await logInForRegisteredUser(seller);
      debugger;
      dispatch(registerSuccess(result.message, logedInUser));
    } catch (error) {
      console.log(error);
      debugger;
      console.log(error?.response?.data?.error);
      dispatch(registerError(error?.response?.data?.error));
    }
  };
};
export const buyerRegister = (buyer) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await registerApis.buyerRegisterAsync(buyer);
      console.log(result);
      const logedInUser = await logInForRegisteredUser(buyer);
      dispatch(registerSuccess(result.message, logedInUser));
      // dispatch(registerSuccess(result.message))
    } catch (error) {
      console.log(error?.response);
      console.log(error?.response?.data?.error);
      dispatch(registerError(error?.response?.data?.error));
    }
  };
};
export const serviceProviderRegister = (serviceProvider) => {
  return async (dispatch) => {
    try {
      const result = await registerApis.serviceProviderRegisterAsync(
        serviceProvider
      );
      console.log(result);
      // logInForRegisteredUser(serviceProvider)
      debugger;
      const logedInUser = await logInForRegisteredUser(serviceProvider);
      dispatch(registerSuccess(result.message, logedInUser));
      dispatch(registerSuccess(result.message));
    } catch (error) {
      console.log(error);
      debugger;
      console.log(error?.response?.data?.error);
      dispatch(registerError(error?.response?.data?.error));
    }
  };
};

const registerSuccess = (message, logedInUser) => {
  return {
    type: actionTypes.Organizer_REGISTER,
    message,
    logedInUser,
  };
};
const registerError = (error) => {
  return {
    type: actionTypes.REGISTER_ERROR,
    payload: error,
  };
};
const logInForRegisteredUser = async (user) => {
  const logedInUser = await logInAsync({
    username: user.email,
    password: user.password,
  });
  setUserInfoTolocalStorage(logedInUser);
  return logedInUser;
};
