import React from "react";
import { makeStyles, Card, Avatar, Box } from "@material-ui/core";
import { Typography } from "@material-ui/core/index";

const useStyles = makeStyles({
  root: {
    maxWidth: "81%",
    margin: "1% 10%",
    padding: "1%",
    ["@media (max-width:650px)"]: {
      margin: "3% 10%",
    },
  },
  tr: {
    marginLeft: "10px",
    cursor: "pointer",
  },
});
const CartTitle = ({ bazarName, bazarLogo, handleBack, bazarId }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display="flex" alignItems="center" mb="10px">
        <Avatar
          onClick={() => handleBack(bazarId)}
          style={{ cursor: "pointer" }}
          sizes="lg"
          src={
            bazarLogo
              ? localStorage.imagePath + bazarLogo
              : "/assets/images/Logo/bazar_logo.png"
          }
        />
        <Typography className={classes.tr} onClick={() => handleBack(bazarId)}>
          {/* {booth.bazarName} */}
          {bazarName}
        </Typography>
      </Box>
    </Card>
  );
};

export default CartTitle;
