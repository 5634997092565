import React, { useState } from "react";
import QuestionsAccordion from "../../components/QuestionsAccordion";
import Header from "../../components/Header";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Card, CardContent } from "@material-ui/core";
import ContactUs from "../../components/ContactUs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: { backgroundColor: "#F9F9F9" },
  MuiExpanded: {
    // margin: 20,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  rolesContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",

    // marginTop: '-28px',
    flexWrap: "wrap",
  },
  roleCard: {
    padding: "18px 24px",
    margin: "20px 20px 5px 5px ",
    cursor: "pointer",
    "&:hover": {
      // cursor: 'poniter',
    },
  },
  activeRole: {
    backgroundColor: "#f8b334",
    color: "white",
  },
  helpWelcome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "200px",
    // backgroundColor: '#c9c3c345',
    backgroundColor: "#F9F9F9",
    padding: "10px",
    marginTop: "50px",
  },
  roleDescription: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "200px",
  },
}));
const Help = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState("Seller");
  const rolesArr = ["Seller", "Buyer", "Organizer", "Provider"];
  const { t } = useTranslation();
  const allRolesDescription = {
    Seller: t("rolesDescription.Seller"),
    Buyer: t("rolesDescription.Buyer"),
    Provider: t("rolesDescription.Provider"),
    Organizer: t("rolesDescription.Organizer"),
  };
  const allRolesQuestions = {
    Seller: [
      {
        id: 1,
        q: {
          en: "How to join eBazar ?",
          ar: "؟ eBazar  كيف تستطيع الانضمام ل  ",
        },
        ans: {
          en: 'You can press the "Join Bazaar" button from your dashboard and then you can see all the upcoming bazars , then you can choose the bazaar you want to join to press "Book a Booth" and then you can fill out the form to apply ',
          ar: 'You can press the "Join Bazaar" button from your dashboard and then you can see all the upcoming bazars , then you can choose the bazaar you want to join to press "Book a Booth" and then you can fill out the form to apply ',
        },
      },
    ],
    Buyer: [
      {
        id: 1,
        q: {
          en: "How to call a seller?",
          ar: "كيف تستطيع الاتصال ببائع ؟",
        },
        ans: {
          en: "You can enter the active booth and press the zoom button, to speak with the seller who owns the booth within his available times",
          ar: "You can enter the active booth and press the zoom button, to speak with the seller who owns the booth within his available times",
        },
      },
      {
        id: 1,
        q: {
          en: "How to buy a product?",
          ar: "كيف تستطيع شراء منتج ؟",
        },
        ans: {
          en: 'You can enter any Active Bazaar and see the available stores and products and click on "add the product" , then choose the quantity and details, then you can buy and pay via PayPal or cash',
          ar: 'You can enter any Active Bazaar and see the available stores and products and click on "add the product" , then choose the quantity and details, then you can buy and pay via PayPal or cash',
        },
      },
    ],
    Organizer: [
      {
        id: 1,
        q: {
          en: "How do you invite sellers ?",
          ar: "كيف ترسل دعوة الي البائعين ؟",
        },
        ans: {
          en: 'You can enter your bazaar and press the "Invite sellers" button on the sellers page Or from your dashboard you can press the "Invite Sellers" button from your bazaar card ,Then you can write one or more emails to send the invitation, or you can choose from existing sellers',
          ar: 'You can enter your bazaar and press the "Invite sellers" button on the sellers page Or from your dashboard you can press the "Invite Sellers" button from your bazaar card ,Then you can write one or more emails to send the invitation, or you can choose from existing sellers',
        },
      },
      {
        id: 2,
        q: {
          en: "How to create eBazar?",
          ar: "كيف تستطيع انشاء بازار ؟",
        },
        ans: {
          en: "You can click on the Create Bazaar button from the main page or from your Dashboard to create a bazaar in five easy and quick steps",
          ar: "You can click on the Create Bazaar button from the main page or from your Dashboard to create a bazaar in five easy and quick steps",
        },
      },
      {
        id: 3,
        q: {
          en: "How to create free eBazar?",
          ar: "كيف تستطيع انشاءبازار مجاني ؟",
        },
        ans: {
          en: "You can create a complete free bazaar by choosing the free plan with sellers in the second step, or setting a zero for the commission plan, and then choosing the free package in the fourth step for an additional ten sellers only for free",
          ar: "You can create a complete free bazaar by choosing the free plan with sellers in the second step, or setting a zero for the commission plan, and then choosing the free package in the fourth step for an additional ten sellers only for free",
        },
      },
    ],
    Provider: [
      {
        id: 1,
        q: {
          en: "what is the service Provider role ?",
          ar: " ما هو دور مزود الخدمة ؟",
        },
        ans: {
          en: "Provider role is about deliver products in different bazars ",
          ar: "  الاشتراك في البازار وتوصيل الطلبا الخاصة بالبازار الي المشتريين   ",
        },
      },
    ],
  };

  const handleRoleChange = (selectedRole) => {
    setActiveTab(selectedRole);
  };
  return (
    <>
      <Header />
      {/* <Container style={{ marginTop: '50px', marginBottom: '50px' }}> */}
      <h3>Help</h3>
      <div className={classes.helpWelcome}>
        <h3>{t("howCanWeHelpYou")}</h3>
        <Typography style={{ textAlign: "center" }}>
          {t("chooseRole")}
        </Typography>
      </div>
      <Container>
        <div className={classes.rolesContainer}>
          {rolesArr.map((role) => (
            <Card
              key={role}
              className={
                activeTab === role
                  ? classes.roleCard + " " + classes.activeRole
                  : classes.roleCard
              }
              onClick={() => handleRoleChange(role)}
            >
              {t("roles." + role)}
            </Card>
          ))}
        </div>
        <QuestionsAccordion
          handleRoleChange={handleRoleChange}
          selectedRole={activeTab}
          allRolesQuestions={allRolesQuestions}
          allRolesDescription={allRolesDescription}
        />
      </Container>
      <ContactUs />
      {/* </Container> */}
    </>
  );
};

export default Help;
