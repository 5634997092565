import React from "react";
import { makeStyles, Card, CardContent, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ccyFormat from "../../utils/ccyFormatFun";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    padding: "3%",
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "#FCFCFC",
  },
  back: {
    backgroundColor: "#FCFCFC",
    margin: "2%",
    width: "50%",
    ["@media (max-width:780px)"]: {
      width: "100%",
    },
    ["@media (max-width:600px)"]: {
      width: "fit-content",
    },
  },
  cart: {
    maxWidth: "100%",
    padding: "1.5%",
    boxShadow: "none",
    paddingLeft: "2.5%",
  },
  item: {
    maxWidth: "100%",
    backgroundColor: "#FCFCFC",
    padding: "2%",
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dis2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  shipmentDetails: {
    // color: '#FCFCFC',
  },
});
const OrderDetails = ({
  orderItems,
  Shipping,
  total,
  subTotal,
  shipmentDetailsFile,
  shipmentDetails,
  handleChangeShippingArea,
  shippingArea,
  loadingShipping,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const shippingAreasArr = [
    { area: t("shippingInsideCountry"), value: 1 },
    { area: t("shippingOutsideCountry"), value: 2 },
    // { area: t("shippingOutsideCountry"), value: 3 },
  ];

  return (
    <div className={classes.back}>
      <Card className={classes.root}>{t("cartPage.orderDetails")}</Card>
      <Card className={classes.cart}>{orderItems.boothName}</Card>
      {orderItems.length > 0
        ? orderItems.map((itm, i) => {
            return (
              <>
                <Card className={classes.item} key={i}>
                  <CardContent>
                    <Typography variant="h6">{itm.product.name}</Typography>
                    <Typography variant="body1" component="p">
                      {t("price")} : {itm.currency} {itm.units * itm.unitPrice}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontFamily: "verdana",
                        lineHeight: "1.6",
                        margin: "10px 0",
                      }}
                    >
                      {t("productId")}
                      {": "}
                      {itm.product.id}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="p"
                    >
                      {t("cartPage.catogory")}:{itm.product.categoryName}
                    </Typography>

                    <Typography
                      variant="body1"
                      color="textSecondary"
                      component="p"
                    >
                      {t("quantity")}:{itm.units}
                    </Typography>
                    {itm.color ? (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {t("cartPage.color")}:{itm.color}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {itm.size ? (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {t("cartPage.size")}:{itm.size}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {itm.description ? (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {t("itemDetails")}:{itm.description}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </CardContent>
                  {/* <CardContent>{itm.currency} {itm.units * itm.unitPrice}</CardContent> */}
                </Card>
                <hr style={{ width: "85%" }} />
              </>
            );
          })
        : ""}
      {/* <div style={{ padding: "2%" }}>
        <CardContent>
          <Typography variant="h6" style={{ marginBottom: "20px" }}>
            {t("summaryPage.shipment")}
          </Typography>
          <Typography variant="p" style={{ fontWeight: "600" }}>
            {t("shipmentPriceDetails")}:
          </Typography>
          <span className={classes.shipmentDetails} style={{ width: "80%" }}>
            <span
              className={classes.p}
              style={{
                fontSize: "1rem",
                margin: "10px",
                display: "block",
                padding: 0,
                color: "rgba(0, 0, 0, 0.54)",
              }}
            >
              {shipmentDetails &&
                shipmentDetails
                  .split(/\r\n|\r|\n/g)
                  .map((line) => <div>{line}</div>)}
            </span>
          </span>
        </CardContent>
        <CardContent>
          <Typography
            variant="p"
            style={{ fontWeight: "600", marginBottom: "20px" }}
          >
            {t("shipmentPriceFileDetails")}:
          </Typography>
          {shipmentDetailsFile && (
            <div
              style={{ margin: "20px 10px", padding: 0 }}
              className={classes.p}
            >
              <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {shipmentDetailsFile}
              </span>
              <a
                href={`${localStorage.imagePath}${shipmentDetailsFile}`}
                download
                target="_blank"
                style={{ margin: "0 10px" }}
              >
                {t("download")}
              </a>
            </div>
          )}
        </CardContent>
      </div>
      <hr style={{ width: "85%" }} /> */}
      <div style={{ padding: "2%", paddingBottom: 0, display: "flex" }}>
        <CardContent>
          <FormControl component="fieldset" style={{ marginTop: "20px 0" }}>
            <FormLabel component="legend">
              <Typography
                variant="h6"
                style={{ color: "black", display: "flex" }}
              >
                {t("shippingArea")}:
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              style={{ display: "flex", flexDirection: "row" }}
              value={shippingArea}
              onChange={handleChangeShippingArea}
            >
              {shippingAreasArr.map((item) => (
                <FormControlLabel
                  value={String(item.value)}
                  control={<Radio color="primary" />}
                  label={item.area}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CardContent>
      </div>

      <CardContent className={classes.dis2}>
        <div style={{ width: "92%" }}>
          {shippingArea !== 0 && (
            <>
              {!loadingShipping ? (
                // <>
                //   <p className={classes.dis}>
                //     {t("subtotal")}
                //     <span>
                //       {orderItems[0].currency} {subTotal}
                //     </span>
                //   </p>
                //   <p className={classes.dis}>
                //     {t("shipping")}
                //     <span>
                //       {orderItems[0].currency} {ccyFormat(Shipping)}
                //     </span>
                //   </p>
                //   <p className={classes.dis}>
                //     {t("total")}
                //     <span>
                //       {orderItems[0].currency} {total}=USD
                //       {ccyFormat(total * 1.4088)}
                //     </span>
                //   </p>
                //   <p
                //     style={{
                //       margin: 0,
                //       display: "flex",
                //     }}
                //   >
                //     <span style={{ color: "red" }}>
                //       {t("Paypal")} =&gt; USD {ccyFormat(total * 1.4088)}
                //     </span>
                //   </p>
                //   {shippingArea === "1" ? (
                //     <p
                //       style={{
                //         margin: 0,
                //         display: "flex",
                //       }}
                //     >
                //       <span style={{ color: "red" }}>{t("Inship")}</span>
                //     </p>
                //   ) : (
                //     ""
                //   )}
                // </>
                <>
                  <p className={classes.dis}>
                    {t("subtotal")}
                    <span>
                      {orderItems[0].currency} {subTotal}
                    </span>
                  </p>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                    }}
                  >
                    {shippingArea === "1" ? (
                      <span style={{ color: "red" }}>{t("InDelivery")}</span>
                    ) : (
                      <span style={{ color: "red" }}>{t("OutDelivery")}</span>
                    )}
                  </p>
                </>
              ) : (
                "loading..."
              )}
            </>
          )}
        </div>
      </CardContent>
    </div>
  );
};

export default OrderDetails;
