import React from 'react'
import { format } from 'date-fns'
const MyDate = ({ ndate }) => {
  return (
    <div
      style={{
        backgroundColor: '#F9F9F9',
        borderRadius: '0.3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow:
          ' rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
        padding: '8px 12px',
        direction: 'ltr',
      }}
    >
      <img
        src="/assets/images/icons/date.svg"
        alt="date"
        style={{
          width: '1.5rem',
          height: '1.5rem',
          // margin: '0.7rem ',
        }}
      />
      <div
        style={{
          color: 'black',
          margin: '0.7rem',
          // transform: localStorage.i18nextLng === 'ar' && 'rotate(-360deg)',
        }}
      >
        {ndate}
      </div>
    </div>
  )
}

export default MyDate
