import { Divider, Grid } from '@material-ui/core'
import React from 'react'

const DividerWithCenteredText = ({ text }) => {
  return (
    <Grid item xs={12} container alignItems="center" justifyContent="center">
      <Grid item xs={5}>
        <Divider style={{ margin: '20px 0' }} />
      </Grid>
      <Grid item xs={2} container justify="center">
        <div style={{ textAlign: 'center' }}>{text}</div>
      </Grid>
      <Grid item xs={5}>
        <Divider style={{ margin: '20px 0' }} />
      </Grid>
    </Grid>
  )
}

export default DividerWithCenteredText
