import React from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const EmptyCard = () => {
  const role = localStorage.userRole;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20% 0",
      }}
    >
      <Grid container item justify="center">
        <img src="/assets/images/cart/emptycard.svg" alt="emptyCart" />
      </Grid>
      <Grid container item justify="center" style={{ margin: "1% 0" }}>
        <span>{t("cartPage.emptyCard")} </span>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="center"
        style={{ margin: "2% 0%", cursor: "pointer", color: "orange" }}
        onClick={() => history.push(`/dashboard/${role ? role : "Buyer"}`)}
      >
        <ArrowBackIcon />
        <span>{t("cartPage.backToShop")} </span>
      </Grid>
    </div>
  );
};

export default EmptyCard;
