import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoothProducts } from "../../redux/booth/actions";
import { Box, Grid, Typography } from "@material-ui/core";
import ProductRequestCard from "./../ProductRequestCard/index";
import Pagination from "@material-ui/lab/Pagination";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const BoothProductsSection = ({ boothId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.boothReducer.boothProducts);
  const productsCount = useSelector(
    (state) => state.boothReducer.boothProductsCount
  );
  console.log(products);
  const [page, setPage] = useState(1);
  const pageSize = 3;
  const PageRef = useRef();
  useEffect(() => {
    dispatch(getBoothProducts(boothId, 1, pageSize));
  }, []);
  const scrollPage = () => PageRef?.current?.scrollIntoView();
  const handlePageChange = (event, newPage) => {
    // props.getBazarBooths(bazarId, newPage, pageSize)
    dispatch(getBoothProducts(boothId, newPage, pageSize));
    setPage(newPage);
    setTimeout(() => scrollPage(), 1000);
  };
  const productExists = products.length > 0 ? true : false;
  return (
    <>
      <Grid
        item
        xs={12}
        container
        justify="space-between"
        alignItems="baseline"
        className={"margin-top-50"}
        ref={PageRef}
      >
        <Grid>
          {productExists && (
            <Typography variant="h6">
              <Box marginBottom="15px">{t("products")}</Box>
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* <div className={'margin-top-50'}> */}
      <Grid
        container
        item
        spacing={2}
        // justify="flex-start"
        direction="row"
        xs={12}
        alignItems="center"
        align="center"
      >
        <Grid container spacing={3}>
          {productExists ? (
            products.map((product) => (
              <Grid
                container
                xs={12}
                // sm={6}
                lg={4}
                key={product.id}
                justify="center"
              >
                <ProductRequestCard {...product} status="active" />
              </Grid>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                height: "100px",
                marginTop: "100px",
              }}
            >
              {t("noProducts")}
            </div>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} container justify="center">
        {productExists && (
          <div className={"margin-top-30"}>
            <Pagination
              count={Math.ceil(productsCount / pageSize)}
              color="primary"
              size="small"
              page={page}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Grid>
    </>
  );
};

export default BoothProductsSection;
