import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrambs from "./../../components/Breadcrumbs";
import Cards from "./../../components/BoothCards";
import BasicPagination from "./../../components/Pagination/page";
import Header from "./../../components/Header";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core/index";
import SearchIcon from "@material-ui/icons/Search";
import {
  Typography,
  Container,
  CircularProgress,
  Divider,
  NativeSelect,
  TextField,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import NewBoothSec from "../../components/NewBoothSec";
import { getBoothCategoryByProductAsync } from "../../redux/booth/api";
import MobileBooth from "./../../components/MobileBooth/index";
const useStyles = makeStyles((theme) => ({
  width230: {
    ["@media (max-width:700px)"]: {
      minWidth: "248px",
      padding: "1px 6px",
    },
    ["@media (max-width:320px)"]: {
      minWidth: "230px",
      padding: "1px 6px",
    },
  },
  dis: {
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
}));
const BoothPage = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState(300);
  const productCategoryRef = useRef();
  const zoomRef = useRef();
  const PageRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  const { t } = useTranslation();
  const [products, setProducts] = useState([{ productImage: { url: "" } }]);
  let [currency, setCurrency] = useState("");
  const [boothCat, setboothCat] = useState([]);
  const [booth, setBooth] = useState({
    name: "",
    boothCategories: [],
    seller: {
      name: "",
      socialMediaLinks: [],
    },
    sellerAvailableTimes: [],
  });
  let cat = [
    { id: 0, name: "All Categories", arabicName: "كل الفئات" },
    ...boothCat,
  ];
  const [filteredproducts, setFilteredProducts] = useState([]);
  const id = useParams().id;
  const boothid = useParams().boothId || id;
  const pp = useParams().pag;
  console.log("props.boothId", id);
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("page") ? localStorage.getItem("page") : 1
  );
  const pagesize = 20;
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState(
    localStorage.getItem("filter") ? localStorage.getItem("filter") : 0
  );
  const [open, setOpen] = useState("");
  const notBuyer = useParams().boothId
    ? new URLSearchParams(props.location.search).get("notBuyer")
    : "";
  const handleSearchChange = (event, value) => {
    fetchData(1, filter, event.target.value);
    setCurrentPage(1);
    setSearch(event.target.value);
  };
  const handlePriceFilter = (e) => {
    setMaxPrice(e.target.value);
    fetchData(1, filter, search, e.target.value);
  };
  const fetchData = (
    pageIndex = currentPage,
    categoryId = filter,
    searchVal = search,
    maxVal = maxPrice
  ) => {
    setIsLoading(true);
    axios
      .get(
        `Buyer/GetBoothProducts?BoothId=${boothid}&ProductName=${searchVal}&CategoryId=${categoryId}&Min=0&Max=${maxVal}&PageSize=${pagesize}&PageIndex=${pageIndex}`
      )
      .then((response) => {
        setIsLoading(false);
        setProducts(response.data.data);
        setCurrency(response.data.data[0].currency);
        setCount(response.data.count);
        console.log(response.data.data, "mmmm");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(async () => {
    let result = await getBoothCategoryByProductAsync(boothid);
    setboothCat(result);
    console.log(result, "why");
  }, []);
  useEffect(() => {
    axios
      .get(`Buyer/GetBoothHome?BoothId=${boothid}`)
      .then((response) => {
        debugger;
        console.log(response.data, "boo");
        setBooth(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (currentPage > Math.ceil(parseInt(count) / pagesize)) fetchData(1);
    fetchData(currentPage);
  }, []);
  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);
    fetchData(newPage);
    setTimeout(() => scrollPage(), 1000);
  };

  const handleBtnFilter = (id) => {
    setFilter(id.target.value);
    fetchData(1, id.target.value);
    setTimeout(() => scroll(), 1000);
  };
  let zoomUrl = booth.zoomUrl;
  // const handleOpenZoomTime = (e) => {
  //   setOpen(true);
  // };
  // const handleCloseZoomTime = () => {
  //   setOpen(false)
  // }

  const scroll = () => productCategoryRef?.current?.scrollIntoView();
  const scrollZoom = () => zoomRef?.current?.scrollIntoView();
  const handleZoomScroll = () => setTimeout(() => scrollZoom(), 1000);
  const scrollPage = () => PageRef?.current?.scrollIntoView();
  return (
    <div className="">
      {localStorage.setItem("filter", filter)}
      {/* {window.localStorage.removeItem("page")} */}
      {!props.modal ? (
        <Header
          user={user}
          home={true}
          // cond={true}
        />
      ) : (
        ""
      )}
      <Container
        style={
          props.modal
            ? { padding: "0" }
            : { margin: "0rem", marginTop: "4rem", padding: "0" }
        }
        ref={PageRef}
      >
        <MobileBooth
          boothNam={booth.name}
          viewsN={booth.viewsNum}
          srcCover={
            // booth.coverImage
            booth.logo
              ? `${localStorage.imagePath}${booth.logo}`
              : "/assets/images/buyer/shops.png"
          }
          seller={booth.seller}
          boothCategories={cat}
          filter={filter}
          srcLogo={
            booth.logo
              ? localStorage.imagePath + `${booth.logo}`
              : "/assets/images/booth/online.png"
          }
          description={booth.description}
          // handleZoom={handleOpenZoomTime}
          handleZoomScroll={handleZoomScroll}
          socialMediaLinks={booth.seller.socialMediaLinks}
          zoomUrl={zoomUrl}
          flag={booth.bazarStatus}
          country={
            localStorage.i18nextLng === "en"
              ? booth?.country?.name
              : booth?.country?.arabicName
          }
        />
      </Container>
      <Container className={classes.dis}>
        <Breadcrambs booth={booth} pag={pp} />
      </Container>

      {booth && (
        <NewBoothSec
          disp={classes.dis}
          boothNam={booth.name}
          viewsN={booth.viewsNum}
          srcCover={
            // booth.coverImage
            booth.logo
              ? `${localStorage.imagePath}${booth.logo}`
              : "/assets/images/buyer/shops.png"
          }
          seller={booth.seller}
          boothCategories={cat}
          filter={filter}
          srcLogo={
            booth.logo
              ? localStorage.imagePath + `${booth.logo}`
              : "/assets/images/booth/online.png"
          }
          description={booth.description}
          // handleZoom={handleOpenZoomTime}
          handleZoomScroll={handleZoomScroll}
          socialMediaLinks={booth.seller.socialMediaLinks}
          zoomUrl={zoomUrl}
          flag={booth.bazarStatus}
          country={
            localStorage.i18nextLng === "en"
              ? booth?.country?.name
              : booth?.country?.arabicName
          }
        />
      )}

      {/* <BuyerFilter
        boothCategories={cat}
        handleBtnFilter={handleBtnFilter}
        filter={filter}
      /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            border: "1px solid #F8B334",
            backgroundColor: "#fff6ea",
            width: "80%",
            minHeight: "4rem",
          }}
        >
          <Grid item>
            <TextField
              onChange={handleSearchChange}
              placeholder={t("lookingfor")}
              value={search}
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: " 0 5%", visibility: "hidden" }}
          />
          <Grid item>
            <FormControl>
              <NativeSelect
                className={classes.width230}
                defaultValue={filter}
                value={filter}
                onChange={handleBtnFilter}
              >
                {cat.map((itm, i) => (
                  <option key={i} value={itm.id}>
                    {localStorage.i18nextLng === "en"
                      ? itm.name
                      : itm.arabicName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: " 0 5%", visibility: "hidden" }}
          />
          <Grid item>
            <FormControl>
              <NativeSelect
                className={classes.width230}
                defaultValue={maxPrice}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
                value={maxPrice}
                onChange={handlePriceFilter}
              >
                <option value={20}>0-20 {currency}</option>
                <option value={50}>0-50 {currency}</option>
                <option value={100}>0-100 {currency}</option>
                <option value={150}>0-150 {currency}</option>
                <option value={200}>0-200 {currency}</option>
                <option value={250}>0-250 {currency}</option>
                <option value={300}>0-300 {currency}</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: " 0 5%", visibility: "hidden" }}
          />
        </Grid>
      </div>

      {isLoading ? (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            padding: "50px 0",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : products.length > 0 ? (
        <Cards
          zoomLink={booth.seller.zoomLink}
          sellerAvailableTimes={booth.sellerAvailableTimes}
          productCategoryRef={productCategoryRef}
          products={products}
          name={booth.name}
          notBuyer={notBuyer}
          flag={booth.bazarStatus}
          zoomUrl={zoomUrl}
          zoomRef={zoomRef}
          currentPage={currentPage}
        />
      ) : (
        <Typography
          style={{
            textAlign: "center",
            textColor: "black",
            padding: "50px 0",
          }}
        >
          {t("noProducts")}
        </Typography>
      )}
      {pagesize < count && (
        <BasicPagination
          pagesize={pagesize}
          count={count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
      {/* <Footer /> */}
      {!props.modal && (
        <Container>
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            style={{
              margin: "1% 0%",
              cursor: "pointer",
              color: "orange",
              marginBottom: "40px",
            }}
            onClick={() => {
              localStorage.removeItem("page");
              props.history.goBack();
            }}
          >
            <ArrowBackIcon />
            <span>{t("cartPage.backToShop")} </span>
          </Grid>
        </Container>
      )}
      {/* </Container> */}
      {/* <ControlledAppModal
        open={open}
        handleClickOpen={handleOpenZoomTime}
        handleClose={handleCloseZoomTime}
      >
        {booth.sellerAvailableTimes.length ? (
          <>
            <h3>{t("boothPage.zoomAvailableTime")}</h3>
            <div
              style={{
                minWidth: "20rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ZoomTable sellerAvailableTimes={booth.sellerAvailableTimes} />
            </div>
          </>
        ) : (
          t("zoomNotAvilable")
        )}
      </ControlledAppModal> */}
    </div>
  );
};

export default BoothPage;
//  productsPerPage={pagesize}  // countProducts={count}
