export const setUserInfoTolocalStorage = (userInfo) => {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
  localStorage.setItem("userAllInfo", JSON.stringify(userInfo));
  localStorage.setItem("token", userInfo.token);
  localStorage.setItem("userId", userInfo.id);
  debugger;
  localStorage.setItem("userRole", userInfo.roles[0]);
  localStorage.setItem("step", 0);
  if (localStorage.userRole === "Buyer") {
    localStorage.removeItem("cartItems");
  }
  // setTimeout(() => {
  //   handleLogout();
  //   window.location.href = "/Home";
  // }, 60 * 60 * 24 * 1000);
};

export const handleLogout = () => {
  console.log("log outttttttttttttttttttttt");
  localStorage.userInfo = "";
  localStorage.userAllInfo = "";
  localStorage.userRole = "";
  localStorage.userId = "";
  localStorage.token = "";
  localStorage.bazarId = "";
  localStorage.step = 0;
  window.location.href = "/";
};
