import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { setSnackbar } from '../../redux/Message/actions'
// import { setSnackbar } from './redux/Message/setSnackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const CustomizedSnackbars = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackbarOpen = useSelector((state) => state.msgReducer.snackbarOpen)
  const snackbarType = useSelector((state) => state.msgReducer.snackbarType)
  const duration = useSelector((state) => state.msgReducer.duration)
  const snackbarMessage = useSelector(
    (state) => state.msgReducer.snackbarMessage,
  )
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage))
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={duration || 3000}
        onClose={handleClose}
        key="top center"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          // elevation={6}
          variant="filled"
          onClose={handleClose}
          color={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomizedSnackbars
