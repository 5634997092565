import { Field, FieldArray, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Typography,
} from "@material-ui/core";

import CategoriesDropDown from "../CategoriesDropDown";
import FormikField from "../FormikField";

import { getAllCountries } from "./../../redux/address/actions";
import { Grid } from "@material-ui/core/index";
import FormikSelect from "../FormikSelect";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import IconButton from "@material-ui/core/IconButton";
import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import differenceInDays from "date-fns/differenceInDays";
import DividerWithCenteredText from "../DividerWithCenteredText";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
const EditBoothSettings = (props) => {
  const {
    minStartDate,
    maxEndDate,
    initialValues,
    handleSubmit,
    validationSchema,
  } = props;
  const { t } = useTranslation();
  console.log(initialValues, "initialValues");
  const bazarDays = differenceInDays(maxEndDate, minStartDate);
  console.log(bazarDays);
  const getCountryCities = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };

  return (
    <Grid container item xs={12}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isSubmitting,
          handleReset,
          setFieldValue,
          handleBlur,
          touched,
        }) => {
          // console.log(values);
          // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form autoComplete="false" style={{ maxWidth: "700px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormikField name="name" label={t("boothName")} />
                  </Grid>
                  <Grid item xs={12}>
                    <CategoriesDropDown
                      setFieldValue={setFieldValue}
                      error={
                        touched.categories && errors.categories
                          ? errors.categories
                          : null
                      }
                      value={values.categories}
                      blur={handleBlur}
                      categoryType="Bazar"
                      bazarId={props.bazarId}
                      IdCatBooth={initialValues.boothId}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="countryId"
                      label={t("signUp.country")}
                      items={props.countries}
                      value={values.countryId}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("countryId", e.target.value);
                        setFieldValue("cityId", "");
                        // selectFirstCity(values)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="cityId"
                      label={t("signUp.city")}
                      items={getCountryCities(values.countryId)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField name="street" label={t("signUp.street")} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        type="text"
                        name="description"
                        label={t("description")}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>{t("selectAvailableTime")}</Typography>
                  </Grid>
                  <Divider />

                  <Grid item container spacing={2}>
                    <Divider />
                    <FieldArray name="availableTime">
                      {({ push, remove }) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                            }}
                          >
                            {values.availableTime.map((_, indx) => (
                              <>
                                <DividerWithCenteredText
                                  text={`${t("day")} ${indx + 1}`}
                                />
                                <Grid
                                  item
                                  container
                                  spacing={2}
                                  justify="space-between"
                                  alignItems="center"
                                  xs={12}
                                  md={10}
                                  key={indx}
                                  style={{ margin: "10px 0" }}
                                >
                                  <Grid item xs={12} md={4}>
                                    <Field
                                      component={DatePicker}
                                      name={`availableTime[${indx}].dateTime`}
                                      label={t("date")}
                                      inputVariant="outlined"
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <CalendarTodayIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                      minDate={minStartDate}
                                      maxDate={maxEndDate}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Field
                                      ampm={false}
                                      component={TimePicker}
                                      name={`availableTime[${indx}].startTime`}
                                      label={t("startAt")}
                                      inputVariant="outlined"
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <Field
                                      ampm={false}
                                      component={TimePicker}
                                      name={`availableTime[${indx}].endTime`}
                                      label={t("endAt")}
                                      inputVariant="outlined"
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <AccessTimeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <IconButton onClick={() => remove(indx)}>
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                                {/* <Divider style={{ margin: '20px 0' }} /> */}
                              </>
                            ))}
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() =>
                                  push({
                                    dateTime: minStartDate,
                                    startTime: minStartDate,
                                    endTime: minStartDate,
                                  })
                                }
                              >
                                <AddCircleOutlineRoundedIcon
                                  color="secondary"
                                  style={{
                                    fontSize: "50PX",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {/* {t('signUp.save')} */}
                      {t("buttons.save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </MuiPickersUtilsProvider>
          );
        }}
      </Formik>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  console.log(state.addressReducer.countries);
  return {
    countries: state.addressReducer.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getAllCountries()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBoothSettings);
