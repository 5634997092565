import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import BazarCard from "../../components/BazarCard";
import SideBarDrawer from "./../../components/sideBarDrawer/index";
import { Divider } from "@material-ui/core/index";
import { OrganizerDrawerItems } from "./constants";
import { connect } from "react-redux";
import { deleteBazar, getOrganizerBazars } from "../../redux/organizer/actions";
import InputLabel from "@material-ui/core/InputLabel";
import { FILTER_BAZAR_ON_STATUS, RESET_BAZAR } from "../../redux/actionTypes";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import InviteSeller from "./../../components/InviteSellerModalContent/index";
import { CircularProgress } from "@material-ui/core";
import ConfirmDeleteModalContent from "./../../components/ConfirmDeleteModalContent/index";
import EmptyBazar from "./../../components/EmptyBazars";

const useStyles = makeStyles((theme) => ({
  incompleteAlert: {
    backgroundColor: "#00000014",
    borderLeft: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
  incompleteAlertRight: {
    backgroundColor: "#00000014",
    borderRight: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
  divider: { margin: "60px 0" },

  statusDropDown: {
    margin: "60px 0",
    display: "flex",
    justifyContent: "space-between",
  },
}));
const bazarAllStatus = [
  { title: "incomplete", name: "InComplete" },
  { title: "active", name: "Active" },
  { title: "comingSoon", name: "ComminSoon" },
  { title: "finished", name: "Finished" },
];
const OrganizerDashBoard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ar = localStorage.i18nextLng === "ar" ? true : false;

  const { allBazars } = props;
  console.log(allBazars, "lolo");
  const organizerId = localStorage.userId;
  const [status, setStatus] = useState("All");
  const [bazarInvitationId, setBazarInvitationId] = useState("");
  const [deleteBazarId, setdeleteBazarId] = useState("");
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    props.getOrganizerBazars(organizerId);
  }, []);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    props.filterBazars(e.target.value);
  };

  const deleteBazar = (bazarId) => {
    console.log(bazarId);
    props.deleteBazar(bazarId);
  };
  const inCompleteExist = allBazars.find(
    (bazar) => bazar.status === "InComplete"
  );
  console.log(inCompleteExist);
  const [openIN, setOpenIN] = useState(false);

  const handleOpenInvitation = (id) => {
    setBazarInvitationId(id);
    setOpenIN(true);
  };
  const handleCloseInvitation = () => {
    setOpenIN(false);
  };
  const handleOpen = (id) => {
    setdeleteBazarId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteConfirmed = () => {
    console.log("deleted");
    handleClose();
    // handleDeleteProduct(productId)
    deleteBazar(deleteBazarId);
  };
  return (
    <>
      <SideBarDrawer
        DrawerItems={OrganizerDrawerItems}
        role="Organizer"
        handleDrawerToggle={props.handleDrawerToggle}
        mobileOpen={props.mobileOpen}
      >
        <ControlledAppModal
          handleClickOpen={handleOpenInvitation}
          open={openIN}
          handleClose={handleCloseInvitation}
        >
          <InviteSeller
            handleClose={handleCloseInvitation}
            inviteID={bazarInvitationId}
          />
        </ControlledAppModal>
        <ControlledAppModal
          handleClickOpen={handleOpen}
          open={open}
          handleClose={handleClose}
        >
          <ConfirmDeleteModalContent
            handleDeleteConfirmed={handleDeleteConfirmed}
            handleClose={handleClose}
            productId={deleteBazarId}
          />
        </ControlledAppModal>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {t("organizerDashboard.bazars")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({ type: RESET_BAZAR });
              props.history.push("/createBazar");
            }}
          >
            {/* <Link
                to="/createBazar"
                style={{ textDecoration: 'none', color: 'white' }}
              > */}
            {t("buttons.createBazar")}
            {/* </Link> */}
          </Button>
        </Box>
        {/* <Box align="end">
          <Link to="/Organizer/Bazars">{t("seeAll")}</Link>
        </Box> */}
        {/* <Box
          className={
            ar ? classes.incompleteAlertRight : classes.incompleteAlert
          }
        >
          {t('organizerDashboard.incompleteBazars')}
        </Box>{' '} */}
        {/* <Box className={classes.incompleteBazars}> */}
        {/* </Box> */}
        {/* <Divider className={classes.divider} /> */}
        <Box align="end" className={classes.statusDropDown} alignItems="center">
          <Box align="start">
            <Link to="/Organizer/Bazars" style={{ fontSize: "1rem" }}>
              {t("seeAll")} {t("sideMenu.bazars")}
            </Link>
          </Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("organizerDashboard.selectBazars")}
            </InputLabel>
            <Select
              native
              value={status}
              onChange={handleStatusChange}
              label={t("organizerDashboard.selectBazars")}
              inputProps={{
                name: "Select Bazars",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="All">{t("all")}</option>
              {bazarAllStatus.map((status, i) => (
                <option
                  key={i}
                  value={status.name}
                  style={{ padding: "20px 0" }}
                >
                  {t("bazarStatus." + status.title)}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>

        {allBazars.length === 0 ? (
          <Grid container justify="center" item xs={12}>
            <EmptyBazar />
          </Grid>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="center"
              align="center"
            >
              {props.loadingOrganizerBazars ? (
                <Grid
                  container
                  justify="center"
                  // style={{
                  //   textAlign: 'center',
                  //   marginTop: '10px',
                  //   width: '100%',
                  // }}
                >
                  {/* <CircularProgress color="primary" /> */}
                </Grid>
              ) : (
                allBazars.map((bazar) =>
                  bazar.status === "InComplete" ? (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ marginBottom: "70px" }}
                      key={bazar.id}
                    >
                      <BazarCard
                        bazarStatus="InComplete"
                        // deleteBazar={deleteBazar}
                        handleOpenDeleteModal={handleOpen}
                        {...bazar}
                      />
                    </Grid>
                  ) : (
                    ""
                  )
                )
              )}
              {/* {!inCompleteExist && !props.loadingOrganizerBazars && (
            <Box display="flex" justifyContent="center" width="100%">
              <Typography>
                {t('organizerDashboard.noIncompleteBazars')}
              </Typography>
            </Box>
          )} */}
            </Grid>
            <Box>
              <Grid
                container
                spacing={6}
                justify="flex-start"
                alignItems="center"
                align="center"
              >
                {props.loadingOrganizerBazars ? (
                  <Grid
                    container
                    justify="center"
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Grid>
                ) : (
                  allBazars.map(
                    (bazar) =>
                      bazar.status !== "InComplete" ? (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ marginBottom: "70px" }}
                          key={bazar.id}
                        >
                          <Link to={"/Organizer/bazarHome/" + bazar.id}>
                            <BazarCard
                              bazarStatus={bazar.status}
                              {...bazar}
                              role="Organizer"
                              handleOpenInvitation={handleOpenInvitation}
                              handleOpenDeleteModal={handleOpen}
                            />
                          </Link>
                        </Grid>
                      ) : (
                        ""
                      ),
                    <></>
                  )
                )}
              </Grid>
            </Box>
          </>
        )}
        <Box width="100%" m="50px 0"></Box>
      </SideBarDrawer>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(state.organizerReducer.bazars);
  console.log(state.organizerReducer.filteredBazars);
  return {
    allBazars: state.organizerReducer.filteredBazars,
    loadingOrganizerBazars: state.organizerReducer.loadingOrganizerBazars,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteBazar: (bazarId) => dispatch(deleteBazar(bazarId)),
    getOrganizerBazars: (organizerId) =>
      dispatch(getOrganizerBazars(organizerId)),
    filterBazars: (status) =>
      dispatch({ type: FILTER_BAZAR_ON_STATUS, payload: status }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganizerDashBoard)
);
