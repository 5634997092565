import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
const NewFooter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-content">
          <div className="logo-sec">
            <img
              onClick={() => history.push(`/`)}
              src="/assets/images/logo/logo.png"
              width="150px"
              style={{ cursor: "pointer" }}
            />
            <p>All Rights © eBazarak 2021</p>
          </div>
          <div className="social-links">
            <h5>{t("follow")}:</h5>
            <ul className="m-0 p-0 list-unstyled">
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/eBazarakcom-100210322137343"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCrLtOsV32yxgEPxbc6ZbBtA/featured"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/e-bazark/?viewAsMember=true"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://instagram.com/ebazarak_website?utm_medium=copy_link"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
