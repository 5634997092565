import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import { AutoRotatingCarousel } from 'material-auto-rotating-carousel'

import "./header.css";
import Dropdown from "../Dropdown";
import { useDispatch } from "react-redux";
import { getAllCountries } from "./../../redux/address/actions";
import { Link, withRouter } from "react-router-dom";

import Header from "../Header";
import { useTranslation } from "react-i18next";
import {
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) => ({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  inputsHover: {
    "&:hover": {
      borderRadius: "15px",
    },
  },
});

function SellerHeader({
  handleCountryChange,
  selectedCountry,
  handleSearchChange,
  searchValue,
  countries,
  history,
  onSearchIconClick,
  role,
}) {
  console.log(selectedCountry, "selectedCountry");
  console.log(searchValue, "searchValue");
  const { t } = useTranslation();

  const classes = styles();

  const dispatch = useDispatch();
  useEffect(() => {
    // if (isLoggedIn) {
    //   const user = JSON.parse(localStorage.userInfo)
    //   setUser(user)
    // }
  }, []);

  return (
    <>
      {/* <div className="header-white"> */}
      {/* <p className="text-header">eBazarak.com</p> */}
      {/* <div className="p-head">
          {' '}
          <img
            className="flag"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_United_Kingdom.svg/320px-Flag_of_the_United_Kingdom.svg.png"
          />{' '}
          <p>eng </p> <ArrowDropDownIcon />
        </div> */}
      {/* </div> */}
      <Header />
      <div className="image-header-container">
        <div className="image-header-content">
          <p className="header-content-text">
            {t("homePage.findThePerfect")}{" "}
            <span className="orange-text">{t("homePage.bazaar")}</span>
          </p>
          <div className="country-header">
            {/* <div className="inp-cont">
              <span className="loc-logo">
                <i className="fas fa-search"></i>
              </span>
              <input
                className="text-search-header"
                type="text"
                placeholder={t("homePage.i'mLookingFor")}
                onChange={handleSearchChange}
                value={searchValue || ''}
              />
            </div> */}

            <TextField
              // label="With normal TextField"
              id="filled-start-adornment"
              placeholder={t("SearchforBazar")}
              // className={clsx(classes.margin, classes.textField)}
              onChange={handleSearchChange}
              value={searchValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              style={{
                width: "320px",
                backgroundColor: "white",
                // borderRadius: '15px',
              }}
              className={classes.inputsHover}
            />
            <div>
              {/* <div
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  border: 'none',
                  borderRadius: '3px',
                }}
              > */}
              {/* <span className="loc-logo">
                  <i className="fas fa-map-marker-alt"></i>
                </span> */}
              {/* <Dropdown
                items={countries}
                label={t('homePage.countries')}
                handleChange={handleCountryChange}
                // value={selectedCountry}
                value={selectedCountry}
                className={classes.select}
                home={true}
              /> */}
              <InputLabel></InputLabel>
              <TextField
                id="filled-select-currency"
                select
                label={selectedCountry ? "" : t("signUp.country")}
                InputLabelProps={{
                  shrink: false,
                }}
                // placeholder={t("signUp.country")}
                onChange={handleCountryChange}
                value={selectedCountry}
                // value={currency}
                // onChange={handleChange}
                // helperText="Please select your currency"
                className={styles.root}
                style={{
                  width: "250px",
                  backgroundColor: "white",
                  margin: "0.5rem",
                  borderRadius: "2px",
                  // '&:hover': {
                  //   borderRadius: '15px',
                  // },
                }}
                variant="outlined"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <LocationOnIcon color="primary" />
                //     </InputAdornment>
                //   ),
                // }}
              >
                <MenuItem value={""}>{t("all")}</MenuItem>
                {countries.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        // fontSize: '0.5rem',
                      }}
                    >
                      <LocationOnIcon
                        color="primary"
                        style={{ marginRight: "5px" }}
                        size="small"
                      />
                      <div>
                        {localStorage.i18nextLng === "en"
                          ? option.name
                          : option.arabicName}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </TextField>

              {/* <select
                style={{
                  padding: ' 5px 0 5px 10px',
                  width: '130px',
                  height: '45px',
                }}
              >
                <option
                  style={{ padding: '15px 20px', fontSize: '15px' }}
                ></option>
                <option style={{ padding: '15px 20px', fontSize: '15px' }}>
                  Egypt
                </option>
                <option style={{ padding: '15px 20px', fontSize: '15px' }}>
                  Jordan
                </option>
                <option style={{ padding: '15px 20px', fontSize: '15px' }}>
                  Usa
                </option>
              </select> */}
              {/* </div> */}
            </div>
            <div className="header-search-btn" onClick={onSearchIconClick}>
              <i className="fas fa-search"></i>
            </div>
          </div>
          {role !== "Buyer" && (
            <Link
              className="header-create-btn"
              to={role !== "Organizer" ? "/register/organizer" : "/createBazar"}
            >
              {t("buttons.createYourBazaar")}
            </Link>
          )}
        </div>
      </div>
      ‏ ‏
    </>
  );
}
export default withRouter(SellerHeader);
