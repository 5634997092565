import * as actionTypes from "../actionTypes";
import { handleLogout } from "../../utils/setLocalStorage";
import { setSnackbar } from "./../Message/actions";
import { uploadImageAsync } from "./../../service/image";
import {
  createBazarAsync,
  addPaymentPlanAsync,
  choosePackageAsync,
  addServiceProviderAsync,
  editBazarAsync,
  getBazarByIdAsync,
  getBazarHomeAsync,
  getBazarBoothsAsync,
  getAllAppBazarsAsync,
  getAllComingSoonBazarsAsync,
  getBazarCategoriessAsync,
  getAllBuyerBazarsAsync,
} from "./api";

////////////////////////////////////// STEP 1 CREATE BAZAR /////////////////////////////
export const createBazar = (bazarInfo) => {
  return async (dispatch) => {
    try {
      const bazarId = await createBazarAsync(bazarInfo);
      dispatch(createBazarSuccess(bazarId));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      console.log(error?.response?.data.error);
      dispatch(createBazarError(error?.response?.data.error));
    }
  };
};
const createBazarSuccess = (bazarId) => {
  return {
    type: actionTypes.CREATE_BAZAR,
    payload: bazarId,
  };
};
const createBazarError = (error) => {
  return {
    type: actionTypes.CREATE_BAZAR,
    payload: error,
  };
};
////////////////////////////////////// STEP 2 ADD PAYMENT PLAN /////////////////////////////

export const addPaymentPlan = (paymentPlan, paymentType) => {
  return async (dispatch) => {
    try {
      const result = await addPaymentPlanAsync(paymentPlan);
      console.log(result);
      dispatch(setSnackbar(true, "success", "Saved Successfuly"));
      dispatch(addPaymentPlanSuccess(paymentPlan, paymentType));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(addPaymentPlanError(error?.response?.data.error))
    }
  };
};
const addPaymentPlanSuccess = (paymentPlan, paymentType) => {
  return {
    type: actionTypes.ADD_PAYMENT_PLAN_TO_BAZAR,
    paymentType,
    paymentPlan,
  };
};
// const addPaymentPlanError = (error) => {
//   return {
//     type: actionTypes.CREATE_BAZAR,
//     payload: error,
//   }
// }
////////////////////////////////////// STEP 3 DELIVERY AND SHIPMENT  /////////////////////////////
export const addServiceProviderToBazar = (deliveryInfo) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await addServiceProviderAsync(deliveryInfo);
      console.log(result);
      dispatch(
        setSnackbar(true, "success", "Invitation Sent To provider Sucessfuly")
      );
      dispatch(addServiceProviderSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(addPaymentPlanError(error?.response?.data.error))
    }
  };
};
export const addServiceProviderSuccess = (result) => {
  return {
    type: actionTypes.ADD_PROVIDER_TO_BAZAR,
    payload: result,
  };
};
////////////////////////////////////// STEP 4 DELIVERY AND SHIPMENT  /////////////////////////////

export const choosePackage = (packageInfo, packageId, type) => {
  return async (dispatch) => {
    try {
      dispatch(choosePackageSuccess(packageId, type));
      const result = await choosePackageAsync(packageInfo);
      console.log(result);
    } catch (error) {
      dispatch(choosePackageSuccess("", ""));

      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(choosePackageError(error?.response?.data.error))
    }
  };
};
const choosePackageSuccess = (packageId, PackageType) => {
  return {
    type: actionTypes.ADD_PACKAGE_TO_BAZAR,
    packageId,
    PackageType,
  };
};
// const choosePackageError = (error) => {
//   return {
//     type: actionTypes.ADD_PACKAGE_TO_BAZAR,
//     payload: error,
//   }
// }
///////////////////////////// EDIT  BAZAR /////////////////////////////

export const getBazarById = (bazarId) => {
  return async (dispatch) => {
    try {
      const result = await getBazarByIdAsync(bazarId);
      console.log(result);
      dispatch(getBazarByIdSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getBazarByIdSuccess = (bazarInfo) => {
  return {
    type: actionTypes.GET_BAZAR_BY_ID,
    payload: bazarInfo,
  };
};
export const editBazar = (values) => {
  return async (dispatch) => {
    try {
      const result = await editBazarAsync(values);
      console.log(result);
      dispatch(editBazarSuccess(result));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      console.log(error?.response);

      // console.log(error?.response?.data.error)
      // dispatch(editBazarError(error?.response?.data.error))
    }
  };
};
const editBazarSuccess = (result) => {
  return {
    type: actionTypes.EDIT_BAZAR_SETTING,
    succeeded: result.succeeded,
  };
};
//////////////////////////////////////////////
export const getBazarHome = (bazarId) => {
  console.log(bazarId, "resulttttttttttttttttt");
  return async (dispatch) => {
    try {
      const result = await getBazarHomeAsync(bazarId);
      console.log(result, "resulttttttttttttttttt");
      dispatch(getBazarHomeSuccess(result));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const getBazarHomeSuccess = (bazarHomenfo) => {
  return {
    type: actionTypes.GET_BAZAR_HOME,
    payload: bazarHomenfo,
  };
};
export const getBazarBooths = (bazarId, currentPage, pageSize) => {
  return async (dispatch) => {
    try {
      const result = await getBazarBoothsAsync(bazarId, currentPage, pageSize);
      console.log("getBazarBooths", result);
      dispatch(getBazarBoothsSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(getBazarBoothsError(error?.response?.data.error))
    }
  };
};
const getBazarBoothsSuccess = (bazarId) => {
  return { type: actionTypes.GET_BAZAR_BOOTHS, payload: bazarId };
};
/////////////////////////////////// GET APPLICATION BAZARS //////////////

export const getAllAppBazars = (pageIndex, search, filter, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(startHomeBazarsLoadingSuccess());

      const result = await getAllAppBazarsAsync(
        pageIndex,
        search,
        filter,
        pageSize
      );
      console.log("getBazarBooths", result);
      dispatch(endHomeBazarsLoadingSuccess());

      dispatch(getAppBazarsSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(getBazarBoothsError(error?.response?.data.error))
    }
  };
};
const getAppBazarsSuccess = (allAppBazars) => {
  return { type: actionTypes.GET_All_APP_BAZARS, payload: allAppBazars };
};
export const getAllComingSoonBazars = (pageIndex, search, filter, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(startHomeBazarsLoadingSuccess());
      const result = await getAllComingSoonBazarsAsync(
        pageIndex,
        search,
        filter,
        pageSize
      );
      console.log("getBazarBooths", result);
      dispatch(endHomeBazarsLoadingSuccess());
      dispatch(getAllComingSoonBazarsSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(getBazarBoothsError(error?.response?.data.error))
    }
  };
};
const getAllComingSoonBazarsSuccess = (allAppBazars) => {
  return {
    type: actionTypes.GET_All_COMING_SOON_BAZARS,
    payload: allAppBazars,
  };
};
export const getAllBuyerBazars = (pageIndex, search, filter, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(startHomeBazarsLoadingSuccess());

      const result = await getAllBuyerBazarsAsync(
        pageIndex,
        search,
        filter,
        pageSize
      );
      console.log("getBazarBooths", result);
      dispatch(endHomeBazarsLoadingSuccess());

      dispatch(getAllBuyerBazarsSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(getBazarBoothsError(error?.response?.data.error))
    }
  };
};
const getAllBuyerBazarsSuccess = (allAppBazars) => {
  return { type: actionTypes.GET_All_BUYER_BAZARS, payload: allAppBazars };
};

export const getBazarCategories = (bazarId) => {
  return async (dispatch) => {
    try {
      const result = await getBazarCategoriessAsync(bazarId);
      // console.log('getBazarCategories', result)
      dispatch(getBazarCategoriesSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(getBazarBoothsError(error?.response?.data.error))
    }
  };
};
const getBazarCategoriesSuccess = (categories) => {
  return { type: actionTypes.GET_BAZAR_CATEGORIES, payload: categories };
};
export const uploadBazarAvatarImg = (image) => {
  return async (dispatch) => {
    try {
      const result = await uploadImageAsync(image);
      console.log(result);

      dispatch(uploadBazarAvatarImgSuccess(result));
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const uploadBazarAvatarImgSuccess = (imgPath) => {
  return { type: actionTypes.UPLOAD_BAZAR_AVATAR_IMG, payload: imgPath };
};
export const uploadBazarCoverImg = (image) => {
  return async (dispatch) => {
    try {
      const result = await uploadImageAsync(image);
      console.log(result);

      dispatch(uploadBazarCoverImgSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const uploadBazarCoverImgSuccess = (imgPath) => {
  return { type: actionTypes.UPLOAD_BAZAR_COVER_IMG, payload: imgPath };
};
const startHomeBazarsLoadingSuccess = (imgPath) => {
  return { type: actionTypes.LOADING_HOME_PAGE_BAZARS, loading: true };
};
const endHomeBazarsLoadingSuccess = (imgPath) => {
  return { type: actionTypes.LOADING_HOME_PAGE_BAZARS, loading: false };
};
