// import { makeStyles } from '@material-ui/core/styles'
// const useStyles = makeStyles((theme) => ({
//   mediumOrange: {
//     color: '#FF470B',
//   },
//   darkOrgange: {
//     color: '#FF470B',
//   },
//   selectedItem: {
//     color: '#FAD184',
//   },
// }))
// export default useStyles
export const colors = {
  mediumOrange: '#FF470B',
  darkOrgange: '#FF470B',
  selectedItem: '#fceccd',
  link: '#03a9f4',
}
export const textalign = {
  left: 'left',
  center: 'center',
  right: 'right',
}
