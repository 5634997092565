import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import React from 'react'
import RoleCard from '../RoleCard'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const RoleCardsList = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { roles, roleValue, handleChange } = props

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={roleValue}
          onChange={handleChange}
        >
          <Grid item></Grid>
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            direction="row"
          >
            {roles.map((role) => (
              <Grid item key={role.id}>
                <RoleCard {...role} />
                <FormControlLabel
                  className={classes.flex}
                  value={`${role.name}`}
                  control={<Radio color="primary" />}
                  label={t(role.label)}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default RoleCardsList
