import React, { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getOrganizerBazars } from '../../redux/organizer/actions'
import { useTranslation } from 'react-i18next'
const OrganizerBazarsDropdown = ({
  variant = 'standard',
  handleChange,
  value,
  styleObj,
}) => {
  const organizerId = localStorage.userId
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getOrganizerBazars(organizerId))
  }, [])
  const bazars = useSelector((state) => state.organizerReducer.bazars)
  return (
    <FormControl
      variant="outlined"
      style={styleObj || { width: '160px', margin: '4px' }}
    >
      <InputLabel>{t('dropdowns.selectBazar')}</InputLabel>
      <Select
        // labelId="demo-simple-select-label"
        // id="demo-simple-select"
        // fullWidth
        value={value}
        onChange={handleChange}
        label={t('dropdowns.selectBazar')}
      >
        <MenuItem value={''}>none</MenuItem>

        {bazars.map(
          (bazar) =>
            bazar.status !== 'InComplete' && (
              <MenuItem key={bazar.id} value={bazar.id}>
                {bazar.name}
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  )
}

export default OrganizerBazarsDropdown
