import React, { useEffect, useState } from "react";
import AddProductForm from "./../../components/AddProductForm/index";
import { useLocation, useParams } from "react-router";
import * as Yup from "yup";
import axios from "axios";
import { setSnackbar } from "../../redux/Message/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import { Button } from "@material-ui/core";
import { getBazarByBoothIdAsync } from "../../redux/bazar/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SellerAddProduct = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const boothId = +params.boothId;
  const productId = +params.productId;
  // debugger
  console.log("boothId", boothId);
  console.log("productId : ", productId);
  const existProduct = useQuery().get("existProduct");
  let initialProduct = {
    name: "",
    description: "",
    price: "",
    quantity: "",
    height: "",
    weight: "",
    lenght: "",
    widht: "",
    productImage: [
      {
        url: "",
      },
    ],
    categoryId: "",
    sellerId: "",
    productColor: [
      {
        color: "",
        url: "",
        imageName: "",
        quantity: "",
        productSize: [
          {
            size: "",
            quantity: "",
          },
        ],
      },
    ],
  };
  if (boothId) {
    initialProduct = {
      ...initialProduct,
      offerPrice: "",
      startOffer: "",
      endOffer: "",
      productStatus: 1,
    };
  }
  const [product, setProduct] = useState(initialProduct);
  const [openConfirmEdit, setOpenConfirmEdit] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [editingProduct, setEditingProduct] = useState(false);
  const [boothBazar, setBoothBazar] = useState("");

  const validationObj = {
    name: Yup.string().required(t("validation.productNameRequired")),
    quantity: Yup.number()
      .min(1, "minmun qantity 1")
      .required(t("validation.productQuantityRequired")),
    categoryId:
      props?.location?.pathname === "/Seller/AddProduct"
        ? ""
        : Yup.number().required(t("validation.productCategoryRequired")),
    weight: Yup.number()
      // .test('weight', 'invalid decimal', (value) =>
      //   (value + '').match(/^\d*\.{1}\d*$/),
      // )
      .positive(t("validation.weightPositive"))
      .required(t("validation.weightRequired")),

    height: Yup.number().positive(t("validation.heightPositive")),
    lenght: Yup.number().positive(t("validation.lengthPositive")),
    widht: Yup.number().positive(t("validation.widthPositive")),
    price: boothId ? Yup.number().required(t("validation.priceRequired")) : "",
    productStatus: boothId
      ? Yup.number().required(t("validation.statusRequired"))
      : "",
  };
  const validationSchema = Yup.object(
    // boothId
    //   ?
    validationObj
    // : { name: Yup.string().required(t('validation.productNameRequired')) },
  );
  const handleOpen = () => {
    setOpenConfirmEdit(true);
  };
  const handleClose = () => {
    setOpenConfirmEdit(false);
    cancelEditProduct();
  };
  useEffect(() => {
    async function getProductToEdit() {
      try {
        const response = await axios.get(
          "Seller/GetProductDetailsByProductId?ProductId=" + productId
        );

        let newProduct = response.data;
        console.log(newProduct, "newProduct");
        // debugger
        if (existProduct) newProduct.productStatus = 1;
        if (newProduct.productColor.length === 0) {
          newProduct.productColor.push({
            color: "",
            url: "",
            imageName: "",
            quantity: "",
            productSize: [
              {
                size: "",
                quantity: "",
              },
            ],
          });
        } else {
          newProduct.productColor.forEach((color) => {
            if (color.productSize.length === 0) {
              // debugger
              color.productSize.push({
                size: "",
                quantity: color.quantity,
              });
            }
          });
        }
        if (newProduct.productImage.lenght === 0) {
          newProduct.productImage.push({ url: "" });
        }
        for (const key in newProduct) {
          if (newProduct[key] === null || newProduct[key] === 0) {
            newProduct[key] = "";
          }
        }
        if (newProduct.status === "Draft") newProduct.productStatus = 0;
        else newProduct.productStatus = 1;

        // debugger
        if (boothId) {
          const bazar = await getBazarByBoothIdAsync(boothId);
          setBoothBazar(bazar);
          // debugger
          newProduct = {
            ...newProduct,
            startOffer: new Date(newProduct.startOffer + "Z")
              ? new Date(newProduct.startOffer + "Z")
              : new Date(bazar.startDate + "Z"),
            endOffer: new Date(newProduct.endOffer + "Z")
              ? new Date(newProduct.endOffer + "Z")
              : new Date(bazar.endDate + "Z"),

            productStatus: 1,
          };
        }
        // debugger
        setProduct(newProduct);
      } catch (error) {
        console.log(error, "err");
        console.log(error?.response, "error?.response");
      }
    }
    // debugger

    const getBoothBazar = async () => {
      const bazar = await getBazarByBoothIdAsync(boothId);
      setBoothBazar(bazar);
      debugger;
      const newProduct = {
        ...product,
        startOffer: bazar.startDate,
        endOffer: bazar.endDate,
      };
      setProduct(newProduct);

      debugger;
    };
    if (productId) {
      getProductToEdit();
    } else {
      if (boothId) {
        getBoothBazar();
      }
    }

    // if (boothId) {
    //   getBoothBazar()
    // }
  }, []);

  const addProductToBoothAndSendRequestToOrganizer = async (product) => {
    debugger;
    const response = await axios.post("Seller/AddExistProductToBooth", product);
    dispatch(setSnackbar(true, "success", t("productWillReviewed"), 10000));
    props.history.push(`/Seller/booth/${boothId}/products`);
    // debugger
    console.log(response, "res add to booth");
  };
  const editProductOnConfirm = async () => {
    const response = await axios.put("Seller/EditProduct", editingProduct);
    if (boothId)
      props.history.push(
        `/Seller/booth/${boothId}/products/${localStorage.getItem(
          "ProductsTablePage"
        )}`
      );
  };
  const cancelEditProduct = () => {
    debugger;
    if (boothId) props.history.push(`/Seller/booth/${boothId}/products`);
  };
  const submitProduct = async (product, setSubmitting) => {
    console.log(product, "submitProduct");
    if (!boothId) delete product.productStatus;
    try {
      if (!productId) {
        /////////////////// ADD NEW PRODUCT
        debugger;
        if (!boothId) {
          const response = await axios.post("Seller/AddProducts", product);
          console.log(response, "res AddProduct");
          props.history.push("/Seller/products");
        } else {
          // debugger
          const response = await axios.post(
            "Seller/AddProductsToBooth",
            product
          );
          console.log(response, "AddProductsToBooth");
          dispatch(
            setSnackbar(
              true,
              "success",
              t("addProduct.ProductAddedSentorganizer"),
              10000
            )
          );

          props.history.push(`/Seller/booth/${boothId}/products`);
        }
      } else {
        /////////////////// EDIT PRODUCT
        debugger;

        let existProductAddedToBoothId = "";
        product.productId = productId;
        setEditingProduct(product);
        let changed = false;
        if (!existProduct) {
          changed = checkChangesInProductImagesOrName(product);
        }
        if (existProduct) {
          await addProductToBoothAndSendRequestToOrganizer(product);
        }

        debugger;

        if (!changed) {
          debugger;
          if (!existProduct) {
            console.log(product);
            const response = await axios.put("Seller/EditProduct", product);
            if (boothId)
              props.history.push(
                `/Seller/booth/${boothId}/products/${localStorage.getItem(
                  "ProductsTablePage"
                )}`
              );
            // console.log(response, "EditProductt");
          }
        } else {
          console.log("exist changes, no edit");
        }
        // if (boothId) props.history.push(`/Seller/booth/${boothId}/products`);

        // if (boothId) props.history.push(`/Seller/booth/${boothId}/products`)
        // else props.history.push(`/Seller/products`)
        // setSnackbar(true, 'success', 'Product edited Successfuly')
      }
    } catch (error) {
      console.log(error, "edit err");
      if (boothId) props.history.push(`/Seller/booth/${boothId}/products`);
    }
    setSubmitting(false);
  };
  const checkChangesInProductImagesOrName = (editedProduct) => {
    let isChanged = false;
    let isExist = false;

    const initialImagesUrl = product.productImage.map((image) => image.url);
    const editedProductImagesUrl = editedProduct.productImage.map(
      (image) => image.url
    );
    if (
      JSON.stringify(initialImagesUrl) !==
      JSON.stringify(editedProductImagesUrl)
    )
      isChanged = true;

    debugger;
    if (isChanged) handleOpen();
    return isChanged;
  };

  return (
    <>
      {console.log(props?.location?.pathname, "props?.location?.pathname")}
      <ControlledAppModal
        handleClickOpen={handleOpen}
        open={openConfirmEdit}
        handleClose={handleClose}
      >
        <div style={{ maxWidth: "260px" }}>
          <div>
            {t("editProductIntruction")}
            {/* if you edit the product pictures ,Product will be hidden from booth
            until the organizer approve changes */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "30px",
            }}
          >
            <Button
              onClick={() => {
                console.log("editConfirmed");
                editProductOnConfirm();
                setConfirmEdit(true);
              }}
              color="primary"
              variant="contained"
            >
              {t("buttons.ok")}
            </Button>
            <Button
              onClick={() => {
                setConfirmEdit(false);
                cancelEditProduct();
              }}
              color="error"
              variant="contained"
            >
              {t("buttons.cancel")}
            </Button>
          </div>
        </div>
      </ControlledAppModal>
      <AddProductForm
        initialValues={product}
        boothId={boothId}
        location={props?.location?.pathname}
        validationSchema={validationSchema}
        submitProduct={submitProduct}
        existProduct={existProduct}
        boothBazar={boothBazar}
      />
    </>
  );
};

export default SellerAddProduct;
