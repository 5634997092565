import { Box, Grid, RadioGroup } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import PaymentCard from '../../PaymentCard'
import Message from '../../Message'
import { useTranslation } from 'react-i18next'

const StepTwoPaymentPlan = (props) => {
  const { t } = useTranslation()
  const {
    paymentPlanInputHandler,
    savePaymentPlanSelected,
    paymentError,
    bazarInfo,
    commissionValue,
    flatFeeValue,
  } = props
  console.log(bazarInfo.paymentPlan)
  const [selectedPlan, setSelectedPlan] = useState(bazarInfo.paymentPlan)
  const paymentPlans = {
    flatFee: {
      title: t('createBazar.paymentPlan.flatFee'),
      status: 'FlatFee',
      content: t('createBazar.paymentPlan.flatFeeContent'),
    },
    commission: {
      title: t('createBazar.paymentPlan.commission'),
      status: 'Commission',
      content: t('createBazar.paymentPlan.commissionContent'),
    },
    flatAndCommision: {
      title: t('createBazar.paymentPlan.FlatFeesWithCommission'),
      status: 'FlatFeesWithCommission',
      content: t('createBazar.paymentPlan.FlatFeesWithCommissionContent'),
    },
  }
  useEffect(() => {
    setSelectedPlan(bazarInfo.paymentPlan)
  }, [bazarInfo.paymentPlan])

  const handleSelectedPlanChange = (newSelected) => {
    console.log(newSelected)
    setSelectedPlan(newSelected)
  }

  console.log(props.bazarId, 'step 2')
  return (
    <Grid container spacing={2}>
      {paymentError.errorExist && (
        <Message status="error" msg={paymentError.msg} />
      )}

      <PaymentCard
        {...paymentPlans.flatFee}
        selectedPlan={selectedPlan}
        handleSelectedPlanChange={handleSelectedPlanChange}
        paymentPlanInputHandler={paymentPlanInputHandler}
        savePaymentPlanSelected={savePaymentPlanSelected}
        flatFeeValue={flatFeeValue}
        commissionValue={commissionValue}
      />
      <PaymentCard
        {...paymentPlans.commission}
        selectedPlan={selectedPlan}
        handleSelectedPlanChange={handleSelectedPlanChange}
        paymentPlanInputHandler={paymentPlanInputHandler}
        savePaymentPlanSelected={savePaymentPlanSelected}
        flatFeeValue={flatFeeValue}
        commissionValue={commissionValue}
      />
      <PaymentCard
        {...paymentPlans.flatAndCommision}
        selectedPlan={selectedPlan}
        handleSelectedPlanChange={handleSelectedPlanChange}
        paymentPlanInputHandler={paymentPlanInputHandler}
        savePaymentPlanSelected={savePaymentPlanSelected}
        flatFeeValue={flatFeeValue}
        commissionValue={commissionValue}
      />
    </Grid>
  )
}

export default StepTwoPaymentPlan
