import MaterialTable from "material-table";
import React, { createRef, useState } from "react";
import { format } from "date-fns";

import UnControlledTable from "../../components/UncontrolledTable";
import {
  confirmBoothPaymentAsync,
  getSellerBoothsToPayAsync,
} from "../../redux/seller/api";
import { Link, Grid, Button, makeStyles } from "@material-ui/core";
import { colors } from "../../styles/commonCss";
import PayButton from "../../components/PayButton";
import ControlledAppModal from "../../components/ControlledAppModal";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import PaypalButtons from "./../../components/PaypalButtons/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import EmptyPayment from "./../../components/EmptyPayment/index";
import { handleLogout } from "../../utils/setLocalStorage";

const useStyles = makeStyles((theme) => ({
  flex: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
  },
  dis: {
    display: "flex",
    justifyContent: "center",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
}));
const SellerPaymets = () => {
  const sellerId = localStorage.userId;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState("");
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [boothId, setBoothId] = useState("");
  const history = useHistory();
  const tableRef = createRef();

  const handlePayButtonClicked = (e, row) => {
    console.log("Row Data", row);
    if (row.currentSeller < row.maxSeller) {
      setAmount(row.amount);
      setBoothId(row.id);
      handleOpenPaymentModal();
    } else
      dispatch(
        setSnackbar(
          true,
          "error",
          t("messages.bazarFullPaymentCannotcompleted")
        )
      );
    debugger;
  };
  const handleOpenPaymentModal = (e, row) => {
    setOpen(true);
  };
  const handleClosePaymentModal = () => {
    setOpen(false);
  };

  // dispatch(setSnackbar(true, 'success', 'Payment Done Successfuly!'))

  const handlePaymentSuccess = async (data) => {
    try {
      console.log("Payment Done Succesfuly");

      const paymentObj = {
        orderId: data.orderID,
        payerId: data.payerID,
        id: boothId,
        userId: localStorage.userId,
      };

      console.log(paymentObj);
      handleClosePaymentModal();
      await confirmBoothPaymentAsync(paymentObj);
      dispatch(setSnackbar(true, "success", "Payment Done Successfuly!"));
      tableRef.current.onQueryChange();
    } catch (e) {
      console.log(e);
    }
  };
  const body = (
    <Grid container className={classes.dis} xs={12}>
      <EmptyPayment />
    </Grid>
  );
  return (
    <div>
      <ControlledAppModal
        open={open}
        handleClickOpen={handleOpenPaymentModal}
        handleClose={handleClosePaymentModal}
      >
        <PaypalButtons
          total={amount}
          onSuccess={handlePaymentSuccess}
          onCancel={handleClosePaymentModal}
        />
      </ControlledAppModal>
      <div className={classes.flex}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/Seller/paymentHistory")}
          style={{ margin: "1rem 0" }}
        >
          {t("paymentHistory")}
        </Button>
      </div>
      <UnControlledTable
        tableRef={tableRef}
        title={t("payment")}
        search={false}
        localization={{
          body: {
            emptyDataSourceMessage: body,
          },
        }}
        columns={[
          {
            title: t("boothName"),
            field: "name",
            type: "text",
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("bazarName"),
            field: "bazar.name",
            type: "text",
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("BazarDate"),
            field: "date",

            render: (row) => (
              <div>
                {format(
                  new Date(row?.bazar?.startDate || ""),
                  "MMMM dd, yyyy "
                )}
              </div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },

          {
            title: t("organizerName"),
            // field: 'organizer',Plane

            cellStyle: { width: "fit-content", textAlign: "center" },
            render: ({
              bazar: {
                organizer: { firstName, lastName },
              },
            }) => <div> {firstName + " " + lastName}</div>,
          },
          {
            title: t("amount"),

            type: "numeric",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => <div>JD {row?.amount}</div>,
          },
          {
            title: t("plan"),

            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => <div> {row?.plan}</div>,
          },
          {
            title: t("action"),

            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <div>
                {
                  <Link
                    component="button"
                    style={{
                      color: colors.link,
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                    variant="body2"
                    onClick={(e) => handlePayButtonClicked(e, row)}
                  >
                    Pay
                  </Link>
                }
              </div>
            ),
          },
        ]}
        data={(query) => {
          return new Promise(async (resolve, reject) => {
            const { page, pageSize, search } = query;
            try {
              const result = await getSellerBoothsToPayAsync(
                sellerId,
                page + 1,
                pageSize
              );

              console.log(result, "dada");
              setData(result.data);
              resolve({
                data: result.data,
                page: query.page,
                totalCount: result.count || 1,
              });
            } catch (error) {
              setData([]);
              console.log(error, "dad");
              console.log(error?.response);
              resolve({
                data: [],
                page: query.page,
                totalCount: 1,
              });
              if (error?.response?.status === 401) {
                handleLogout();
                window.location.href = "/login";
              }
            }
          });
        }}
      />
    </div>
  );
};

export default SellerPaymets;
