import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import { Provider, useSelector } from "react-redux";
import store from "./redux/store";

import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";

import "./i18n";

import "flag-icon-css/css/flag-icon.min.css";

import App from "./containers/App/App";

// import 'node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import "./../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "react-multi-carousel/lib/styles.css";
import appTheme from "./styles/appTheme";
import "./index.css";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
// const token = localStorage.token
// console.log(token)
// https://ebazarakapi.azurewebsites.net/
// axios.defaults.baseURL = 'https://localhost:44339/api/'
// axios.defaults.baseURL = 'https://ebazaraknewapi2.azurewebsites.net/api/'

localStorage.setItem(
  "imagePath",
  "https://api.ebazarak.com/public/images/"
);
axios.defaults.baseURL =
  "https://api.ebazarak.com/api/";

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.token;

    request.headers.common["Authorization"] = `Bearer ${token}`;
    console.log(request);
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

ReactDOM.render(
  <Suspense fallback={<div>loading....</div>}>
    <Provider store={store}>
      <BrowserRouter>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={appTheme}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </ThemeProvider>
        </StylesProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
