import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  CircularProgress,
  Container,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deletePendingBooth,
  getSellerBooths,
} from "../../redux/seller/actions";
import { useTranslation } from "react-i18next";
import AppPagination from "../../components/Pagination";
import BazarsListing from "../../components/BazarsListing/index";
import { getAllComingSoonBazars } from "./../../redux/bazar/actions";
import { getAllCountries } from "./../../redux/address/actions";
import ControlledAppModal from "../../components/ControlledAppModal";
import BookBooth from "./../../components/BookBooth/index";
import { getSellerById } from "./../../redux/seller/actions";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  incompleteAlert: {
    backgroundColor: "#00000014",
    borderLeft: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
  incompleteAlertRight: {
    backgroundColor: "#00000014",
    borderRight: "4px solid orange",
    padding: "20px 10px",
    fontSize: "20px",
    margin: "30px 0",
  },
}));

const SellerDashboard = (props) => {
  const isLoggedIn = localStorage.token;
  const [open, setOpen] = useState(false);
  const [selectedBazar, setSelectedBazar] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const ar = localStorage.i18nextLng === "ar" ? true : false;
  const [selectedCountry, setSelectedCountry] = useState("");
  const [search, setSearch] = useState("");
  const pageSize = 9;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const bazarListContainerRef = useRef();
  const role = localStorage.userRole;

  const allAppBazars = useSelector((state) => state.bazarReducer.allAppBazars);
  const allAppBazarsCount = useSelector(
    (state) => state.bazarReducer.allAppBazarsCount
  );

  const countries = useSelector((state) => state.addressReducer.countries);
  const loading = useSelector((state) => state.bazarReducer.homeLoading);

  useEffect(() => {
    dispatch(getAllCountries());
    fetchData(page, search, selectedCountry, pageSize);
  }, []);

  useEffect(() => {
    if (selectedBazar) {
      axios
        .get(
          `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${selectedBazar}`
        )
        .then((response) => {
          if (response.data.succeeded) {
            handleClickOpen();
          } else if (!response.data.succeeded) {
            console.log(response.data, "sellll");
            dispatch(setSnackbar(true, "error", response.data.message));
          }
        });
    }
  }, [selectedBazar]);
  const checkSellerAlreadyHasBooth = async (bazarID) => {
    setSelectedBazar(bazarID);
    try {
      const response = await axios.get(
        `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${bazarID}`
      );
      if (response.data.succeeded) {
        handleClickOpen();
      } else if (!response.data.succeeded) {
        console.log(response.data, "sellll");
        dispatch(setSnackbar(true, "error", response.data.message));
      }
    } catch (error) {
      console.log("err", error.message);
    }
  };
  const fetchData = (page, search, selectedCountry, pageSize, activeStatus) => {
    if (role === "Seller") {
      dispatch(getAllComingSoonBazars(page, search, selectedCountry, pageSize));
      dispatch(getSellerById(localStorage.userId));
    }
  };
  const executeScroll = () => bazarListContainerRef?.current?.scrollIntoView();
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    fetchData(newPage, search, selectedCountry, pageSize);
  };
  const handleClickOpen = (bazarID) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "5rem" }}>
        {t("chooseBazar")}
      </h2>
      <Box className={classes.incompleteBazars}>
        <Container>
          {loading ? (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                padding: "50px 0",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : allAppBazars.length > 0 ? (
            <>
              <BazarsListing
                bazarsArr={allAppBazars}
                role={isLoggedIn ? role : ""}
                handleOpenBookBoothModal={checkSellerAlreadyHasBooth}
                handleCloseBookBoothModal={handleClose}
                bazarListContainerRef={bazarListContainerRef}
              />
              <ControlledAppModal open={open} handleClose={handleClose}>
                <div style={{ maxWidth: "600px" }}>
                  <BookBooth
                    bazarId={selectedBazar}
                    countries={countries}
                    handleCloseBookBoothModal={handleClose}
                  />
                </div>
              </ControlledAppModal>
            </>
          ) : (
            <Typography
              style={{
                textAlign: "center",
                textColor: "black",
                padding: "50px 0",
              }}
            >
              {t("noBazars")}
            </Typography>
          )}
        </Container>
        <Grid item xs={12} container justify="center" position="relative">
          {allAppBazars.length > pageSize ? (
            <AppPagination
              count={Math.ceil(allAppBazarsCount / pageSize)}
              page={page}
              handlePageChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </Grid>
      </Box>
    </>
  );
};

export default SellerDashboard;
