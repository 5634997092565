import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Link } from "@material-ui/core";
import { colors } from "../../styles/commonCss";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  camera: { position: "absolute", left: "20px" },
  link: {
    textDecoration: "underline",
    color: colors.link,
    fontSize: "16px",
    margin: "10px 0",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function UploadButtons({
  type,
  handleChange,
  linkText = "",
  name,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {type === "Button" && (
        <>
          <input
            // accept="image/*"
            className={classes.input}
            id={"contained-button-file" + name}
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={"contained-button-file" + name}>
            <Button component="span" color="primary" variant="outlined">
              {t("upload")}
            </Button>
          </label>
        </>
      )}
      {type === "Camera" && (
        <>
          <input
            accept="image/*"
            className={classes.input}
            id={"icon-button-file" + name}
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={"icon-button-file" + name}>
            <IconButton
              // color="primary"

              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera syle={{ color: "#1b1818", zIndex: 10 }} />
            </IconButton>
          </label>
        </>
      )}

      {type === "Link" && (
        <>
          <input
            // accept="image/*"
            className={classes.input}
            id={"contained-button-file" + name}
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={"contained-button-file" + name}>
            <Link component="span" className={classes.link}>
              {t("upload")} {linkText}
            </Link>
          </label>
        </>
      )}
    </div>
  );
}
