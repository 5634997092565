import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import PackageCard from '../PackageCard'
import { packages } from './../CreateBazar/StepFourChoosePackage/constants'
import { Button, Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { choosePackage } from './../../redux/bazar/actions'
import PaypalButtons from '../PaypalButtons'
import { confirmBazarPaymentAsync } from '../../redux/bazar/api'
import { setSnackbar } from '../../redux/Message/actions'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    overflow: 'hidden',
    height: '100%',
    maxHeight: 500,
    overflowY: 'scroll',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '10%',
    left: '10%',
  },
}))

const UpgradeModal = (props) => {
  const classes = useStyles()

  const {
    handleClose,
    open,
    selected,
    bazarId,
    handleSelectedPackageChange,
    handlePackageSubmit,
    save,
    // handlePaymentSuccess,
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handlePaymentSuccess = async (data) => {
    try {
      console.log('Payment Done Succesfuly')

      const paymentObj = {
        orderId: data.orderID,
        payerId: data.payerID,
        id: bazarId,
        userId: localStorage.userId,
      }
      debugger
      console.log(paymentObj)
      await confirmBazarPaymentAsync(paymentObj)
      dispatch(setSnackbar(true, 'success', t('messages.successPayment')))
      // setRefresh(refresh + 1)
      // setPaid(true)
    } catch (e) {
      console.log(e)
    }
  }

  console.log('open ', open)
  const body = (
    <div className={classes.paper}>
      <Grid container spacing={2} display="flex">
        {packages.map(
          (pack) =>
            pack.type !== 'FreePlan' && (
              <Grid item lg={6} xs={12} key={pack.id}>
                <PackageCard
                  {...pack}
                  key={pack.id}
                  handleSelectedPackageChange={handleSelectedPackageChange}
                  selectedPackage={selected}
                />
              </Grid>
            ),
        )}
        {selected !== 'FreePlan' && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <PaypalButtons total={'1000'} onSuccess={handlePaymentSuccess} />
          </div>
        )}
        {save && (
          <Grid item container display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              onClick={handlePackageSubmit}
            >
              {t('buttons.save')}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )

  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        Open Modal
      </button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default UpgradeModal
