import React, { useEffect, useLayoutEffect, useState } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import tableIcons from "./../../styles/materialTableIcons";
import { colors } from "../../styles/commonCss";
import UpgradeModal from "../../components/UpgradeModal";
import { useDispatch, useSelector } from "react-redux";
import { getBazarById } from "../../redux/bazar/actions";
import { choosePackage } from "./../../redux/bazar/actions";
import axios from "axios";
import TableContainer from "@material-ui/core/TableContainer";
import "./table.css";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../utils/setLocalStorage";

const useStyles = makeStyles((theme) => ({
  disabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
  upgrade: {
    marginLeft: "10px",
    color: colors.link,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
const token = localStorage.token;
const headerStyle = {
  textAlign: "center",
  width: "fit-content",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};

const BazarsTable = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const statusTitle = {
    CommingSoon: { title: t("bazarStatus.soon"), color: "green" },
    Finished: { title: t("bazarStatus.finished"), color: "gray" },
    Active: { title: t("bazarStatus.active"), color: "orange" },
  };
  const [open, setOpen] = useState(false);
  const [bazarId, setBazarId] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packageId, setPackageId] = useState("");

  const dispatch = useDispatch();

  const bazarInfo = useSelector((state) => state.bazarReducer.bazarInfo);

  useLayoutEffect(() => {
    setSelectedPackage(bazarInfo.packagePlan);
  }, [bazarInfo.packagePlan]);

  const handleOpen = (bazarId) => {
    dispatch(getBazarById(bazarId));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectedPackageChange = (id, type) => {
    setSelectedPackage(type);
    setPackageId(id);
    // const packageInfo = { bazarId: +bazarId, packagePlan: +id }
    // dispatch(choosePackage(packageInfo, +id, type))
    // console.log(packageInfo)
  };
  const handlePackageSubmit = () => {
    const packageInfo = { bazarId: +bazarId, packagePlan: packageId };
    console.log(packageInfo);
    dispatch(choosePackage(packageInfo, +bazarId, selectedPackage));
    setOpen(false);
  };
  return (
    <>
      {/* {selectedPackage !== 'FreePlan' && ( */}
      <UpgradeModal
        open={open}
        handleClose={handleClose}
        selected={selectedPackage}
        bazarId={bazarId}
        handleSelectedPackageChange={handleSelectedPackageChange}
        handlePackageSubmit={handlePackageSubmit}
        save={true}
      />
      {/* )} */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography
          variant="h6"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          {t("organizerDashboard.bazars")}
        </Typography>
        <Button variant="contained" color="primary">
          <Link
            to="/createBazar"
            style={{ textDecoration: "none", color: "white" }}
          >
            {t("buttons.createBazar")}
          </Link>
        </Button>
      </Box>
      {/* <TableContainer
        style={{
          fontSize: '15px',
          // overflowX: 'auto',
          // minWidth: '650px',
          display: 'block',
          // borderCollapse: 'collapse',
          // width: '100%',
        }}
      > */}
      {/* <div style={{ overflowX: 'auto', display: 'block' }}> */}
      <Paper className="container" style={{ padding: 0 }}>
        <MaterialTable
          style={{
            fontSize: "15px",
            // overflowX: 'auto',
            minWidth: "650px",
            display: "block",
            width: "100%",
            overflowX: "scroll",
            // borderCollapse: 'collapse',
            // width: '100%',
          }}
          icons={tableIcons}
          title=""
          columns={[
            {
              title: t("name"),
              field: "name",
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("shops"),
              field: "shopNo",
              type: "numeric",
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("totalSales"),
              field: "selles",
              type: "numeric",
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row?.currency} {row.selles}
                </div>
              ),
            },
            {
              title: t("earning"),
              field: "earning",
              cellStyle: { width: "fit-content", textAlign: "center" },
              render: (row) => (
                <div>
                  {row?.currency} {row.earning}
                </div>
              ),
            },
            {
              title: t("signUp.country"),
              field:
                localStorage.i18nextLng === "en"
                  ? "county[name]"
                  : "county[arabicName]",
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("date"),
              field: "startDate",
              render: (row) => (
                <div>{format(new Date(row.startDate + "Z"), "MM/dd/yyyy")}</div>
              ),
              cellStyle: { width: "fit-content", textAlign: "center" },
            },
            {
              title: t("status"),
              cellStyle: { width: "fit-content", textAlign: "center" },
              field: "status",
              render: (row) => (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <FiberManualRecordIcon
                    // fontSize="small"
                    style={{
                      color: statusTitle[row.status].color,
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  />
                  {statusTitle[row.status].title}
                </Box>
              ),
            },
            {
              title: t("actions"),
              field: "actions",
              render: (row) => (
                <Box display="flex" justifyContent="space-between">
                  <>
                    <Link
                      style={{ color: "#03a9f4" }}
                      to={"/Organizer/Bazar/settings/" + row.id}
                      color="secondary"
                      className={
                        row.status !== "CommingSoon" ? classes.disabled : ""
                      }
                    >
                      {t("links.edit")}
                    </Link>
                    <div
                      style={{
                        width: "1px",
                        backgroundColor: "lightgray",
                        height: "30px",
                        margin: "0 5px",
                      }}
                    ></div>
                  </>
                  <div
                    onClick={() => {
                      handleOpen(row.id);
                      setBazarId(row.id);
                    }}
                    // style={{ color: '#03a9f4', textDecoration: 'underline' }}
                    className={
                      row.status === "Finished" ||
                      row.packagePlan !== "FreePlan"
                        ? classes.disabled + " " + classes.upgrade
                        : classes.upgrade
                    }
                  >
                    {t("links.upgrade")}
                  </div>
                  {/* <Link
                  style={{ color: '#03a9f4' }}
                  to={'/bazar/' + row.id}
                  className={row.status === 'Finished' && classes.disabled}
                >
                  Upgrade
                </Link> */}
                </Box>
              ),
            },
          ]}
          options={{
            search: true,
            paginationType: "stepped",
            pageSize: 10,
            headerStyle,
            doubleHorizontalScroll: true,
            tableLayout: "auto",
            rowStyle: { width: "fit-content", textAlign: "center" },
            sorting: false,
          }}
          data={(query) => {
            return new Promise((resolve, reject) => {
              const token = localStorage.token;

              const { page, pageSize, search } = query;
              let url = !search
                ? `Organizer/GetBazarsOfOrganizerComingSoon?OrganizerId=${
                    localStorage.userId
                  }&PageSize=${pageSize}&PageIndex=${page + 1}`
                : `Bazar/SearchByBazarName?OrganizerId=${
                    localStorage.userId
                  }&BazarName=${search}&PageSize=${pageSize}&PageIndex=${
                    page + 1
                  }`;
              debugger;
              axios
                .get(url, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(({ data }) => {
                  const response = data;
                  console.log(data);
                  resolve({
                    data: response.data || response,
                    page: query.page,
                    totalCount: response.count || 1,
                  });
                })
                .catch((error) => {
                  console.log(error);
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    handleLogout();
                    window.location.href = "/login";
                  }
                });
            });
          }}
        />
      </Paper>

      {/* </div> */}
      {/* </TableContainer> */}
    </>
  );
};

export default BazarsTable;
