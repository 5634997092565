import React from "react";
import "./breadcrambs.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Breadcrambs = ({ booth, pag }) => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <section className="secondSec">
      <div className="breadcrambs">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.localStorage.removeItem("boothPage");
            history.push("/");
          }}
        >
          {t("home")}
        </span>
        /
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push(`/buyer/bazarPage/${booth?.bazarId}/` + pag)
          }
        >
          {booth?.bazarName}
        </span>
        /<span className="special">{booth?.name}</span>
      </div>
      <div className="contact">
        {/* <div className="containIcon">
          <i className=" special far fa-envelope"></i>
        </div>
        <div className="containIcon">
          <i className=" special fas fa-phone-alt"></i>
        </div> */}
      </div>
    </section>
  );
};

export default Breadcrambs;
