import React from "react";
import { Field, Form, Formik } from "formik";

import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import fileChangedHandler from "./../../containers/ImgUploader/index";
import { useTranslation } from "react-i18next";
import FormikField from "./../FormikField/index";
import { TextField } from "@material-ui/core";
import { uploadImageAsync } from "./../../service/image";

import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";

import * as Yup from "yup";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";

const useStyles = makeStyles((theme) => ({
  cmm: {
    backgroundColor: "#F9F9F9",
    margin: "0px 5px",
    padding: "0",
    textAlign: "center",
  },
  em: {
    backgroundColor: "#F9F9F9",
    margin: "30px 0",
    padding: "30px",
    textAlign: "center",
  },
}));
const ContactUs = ({ cm }) => {
  const { t } = useTranslation();
  const initialValues = { email: "", message: "", file: "" };
  const dispatch = useDispatch();
  const classNamees = useStyles();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
  });

  // const handleProductImageUpload = async (imag, setFieldValue) => {
  //   const img = imag
  //   const name = img.name

  //   try {
  //     const formDate = new FormData()
  //     formDate.set(name, img)
  //     const imageName = await uploadImageAsync(formDate)
  //     console.log('Donee')
  //     setFieldValue(`file`, imageName)

  //     console.log(imageName)
  //   } catch (error) {
  //     console.log(error)
  //     console.log(error?.response?.data)
  //   }
  // }
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    console.log(values);
    axios.post("ContactUs/ContactUs", values).then((response) => {
      dispatch(setSnackbar(true, "success", "Sent Successfuly"));
      setSubmitting(false);
      resetForm({});
    });
    // setTimeout(() => {
    // }, 2000)
  };
  return (
    <>
      <section className="contact-us ">
        <div className="container ">
          <div className="row align-items-center d-flex">
            <div className="col-md-6 my-3 order-2 order-md-1">
              <div className="contact-form">
                <header>
                  <h3 className="d-flex">{t("contactUs")}</h3>
                </header>
                <article>
                  <p className="d-flex">{t("contactUsContent")}</p>

                  <Formik
                    initialValues={initialValues}
                    // enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      validateForm,
                      isSubmitting,
                      handleReset,
                      setFieldValue,
                    }) => {
                      return (
                        <Form
                          autoComplete="false"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: 'center',
                            // justifyContent: 'center',
                          }}
                        >
                          <Grid container item xs={12}>
                            <FormikField
                              name="email"
                              label={t("signUp.email")}
                            />
                          </Grid>
                          <Grid container item xs={12}>
                            <FormikField
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                              type="text"
                              name="message"
                              label={t("message")}
                              onChange={(e) =>
                                setFieldValue("message", e.target.value)
                              }
                            />
                          </Grid>
                          <div className="form-group">
                            <div className="send-btn">
                              <button
                                className="hvr-float-shadow"
                                type="submit"
                                disabled={isSubmitting ? true : false}
                              >
                                {t("buttons.submit")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </article>
              </div>
            </div>
            <div className="col-md-6 my-3 order-1 order-md-2">
              <div className="contact-img">
                <img
                  className="w-100"
                  src="/assets/images/home/contact.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Grid
        xs={12}
        container
        justify="center"
        alignItems="center"
        direction="column"
        spacing={3}
        classNameName={cm ? classNamees.cmm : classNamees.em}
      >
        <Container>
          <h2>{t('contactUs')}</h2>
          <Formik
            initialValues={initialValues}
            // enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              validateForm,
              isSubmitting,
              handleReset,
              setFieldValue,
            }) => {
              return (
                <Form
                  autoComplete="false"
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid container item xs={12} >
                    <FormikField name="email" label={t('signUp.email')} />
                  </Grid>
                  <Grid container item xs={12} md={8} lg={4}>
                    <FormikField
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      type="text"
                      name="message"
                      label={t('message')}
                      onChange={(e) => setFieldValue('message', e.target.value)}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    md={8}
                    lg={4}
                    justify="center"
                    style={{ margin: '2rem 0' }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      style={{ borderRadius: '15px', fontSize: '10px' }}
                      size="small"
                      disabled={isSubmitting ? true : false}
                    >
                      {t('buttons.submit')}
                    </Button>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </Grid> */}
    </>
  );
};

export default ContactUs;
