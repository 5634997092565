import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import { Link, withRouter } from "react-router-dom";
import { Call, Email } from "@material-ui/icons";
import BookBooth from "./../BookBooth/index";
import { getTimeBasedOnStatus } from "./../../utils/getTimeBasedOnStatus";
import ControlledAppModal from "./../ControlledAppModal/index";
import ConfirmDeleteModalContent from "./../ConfirmDeleteModalContent/index";
import { useTranslation } from "react-i18next";
import InviteSeller from "./../InviteSellerModalContent";
import MouseOverPopover from "./../PopoverOnHover/index";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { statusArr } from "../../containers/App/constants";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  imgCard: {
    backgroundColor: "black",
    borderRadius: "20px",
    width: "280px",
    height: "330px",

    position: "relative",
    // zIndex: 1,
  },
  infoCard: {
    // backgroundColor: 'blue',
    width: "230px",
    maxHeight: "225px",
    borderRadius: "15px",
    paddding: "10px 5px",
    // position: 'relative',
    // color: theme.palette.text.secondary,
    zIndex: 2,
    // left: '41.5%',
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  completeButton: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "20px",
    margin: "10px 0",
    textDecoration: "none",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&a": {
      color: "white",
    },
  },
  deleteBtn: {
    color: "red",
    textDecoration: "underline",
  },
  statusBadge: {
    position: "absolute",
    zIndex: 2,
    top: "25%",
    left: "18%",
    color: "white",
    padding: "10px 10px",
    width: "180px",
    fontWeight: "700",
  },
  stepsBadge: {
    position: "absolute",
    zIndex: 2,
    top: "25%",
    left: "35%",
    color: "black",
    padding: "10px 10px",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    // fontWeight: '700',
    backgroundColor: "#fffffff0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activeColor: {
    backgroundColor: "#57D953",
  },
  comingColor: {
    backgroundColor: "red",
  },
  finishedColor: {
    backgroundColor: "gray",
  },
  paddingBottom: {
    paddingBottom: "10px",
  },
  MuiButtonLabel: { fontSize: "10px" },
  manageOrders: { borderRadis: "15px" },
  boothCardContentRoot: {
    paddingBottom: 0,
  },
}));

// const statusArr = {
//   Pending: { title: 'pending', color: 'orange' },
//   Accepted: { title: 'accepted', color: '#57D953' },
//   Rejected: { title: 'rejected', color: 'red' },
//   CommingSoon: { title: 'comingSoon', color: 'red' },
//   Finished: { title: 'finished', color: 'gray' },
//   Active: { title: 'active', color: '#57D953' },
//   Hybrid: { title: 'Hybrid', color: 'yellow' },
// }
const BazarCard = (props) => {
  const classes = useStyles();
  console.log(props, "Bazaaar");
  let {
    status,
    booth,
    logo,
    deleteBazar,
    id,
    name,
    startDate,
    endDate,
    county,
    boothCountry,
    shopNo,
    request,
    imageProfile,
    role,
    handleOpenBookBoothModal,
    handleCloseBookBoothModal,
    remainingStepsNo,
    packagePlan,
    handleOpenInvitation,
    stepContent,
    location,
    handleOpenDeleteModal,
  } = props;
  if (booth) status = "";
  // console.log(boothCountry)
  // console.log(status)
  const [open, setOpen] = useState(false);
  const pathname = location.pathname;

  // const handleOpen = () => {
  //   setOpen(true)
  // }
  // const handleClose = () => {
  //   setOpen(false)
  // }

  // const handleDeleteConfirmed = () => {
  //   console.log('deleted')
  //   handleClose()
  //   // handleDeleteProduct(productId)
  //   deleteBazar(id)
  // }
  // src={

  //   productImage[0]?.url
  // }
  const getCardImage = () => {
    let img = "";
    switch (status) {
      case "booth":
        img = localStorage.imagePath + imageProfile;
        break;

      default:
        img = "/assets/images/bazars/big_bazar1.jpg";
        break;
    }
    return img;
  };
  const { t } = useTranslation();

  const remainigStepContent = stepContent?.split(",");
  console.log(remainigStepContent);

  let srcimg;
  if (status === "CommingSoon") {
    srcimg = "/assets/images/icons/ComingSoon.svg";
  } else if (status === "Active") srcimg = "/assets/images/icons/Active.svg";
  else if (status === "Finished") srcimg = "/assets/images/icons/Finished.svg";
  else srcimg = "/assets/images/bazars/big_bazar1.jpg";
  console.info(logo, "boothCard");
  return (
    <div
      style={{
        position: "relative",
        width: "max-content",
        border: "1px solid lightgray",
        borderRadius: "1.3rem",
      }}
    >
      {/* <ControlledAppModal
        handleClickOpen={handleOpen}
        open={open}
        handleClose={handleClose}
      >
        <ConfirmDeleteModalContent
          handleDeleteConfirmed={handleDeleteConfirmed}
          handleClose={handleClose}
          productId={id}
        />
      </ControlledAppModal> */}
      <div className={classes.imgCard}>
        {/* <div
          style={{
            background: `url(${
              getCardImage() || '/assets/images/bazars/big_bazar1.jpg'
            })`,
            width: '100%',
            minHeight: '330px',
            backgroundSize: 'cover',
            borderRadius: '10px',
          }}
        ></div> */}
        <img
          src={
            booth
              ? logo
                ? localStorage.imagePath + logo
                : srcimg
              : imageProfile
              ? localStorage.imagePath + imageProfile
              : srcimg
          }
          // src="https://ebazaraknewapi2.azurewebsites.net/api/File/GetImageUrl?ImageName=9516efc1-61d6-4494-8658-015d86a438d0.jpg"
          style={{
            borderRadius: "20px",
            objectFit: "cover",
            backgroundColor: "white",
          }}
          alt=""
          width="100%"
          height="100%"
          className="img"
        />
        {status === "Finished" && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "#ffffff30",
              width: "100%",
              height: "100%",
            }}
          ></div>
        )}
      </div>
      {status === "InComplete" || booth === true ? (
        ""
      ) : (
        <Box mx="auto">
          <Paper
            className={
              status === "Active"
                ? `${classes.statusBadge}  ${classes.activeColor}`
                : status === "CommingSoon"
                ? `${classes.statusBadge}  ${classes.comingColor}`
                : `${classes.statusBadge}  ${classes.finishedColor}`
            }
            // className={
            //   status === 'Finished' &&
            //   `${classes.statusBadge}  ${classes.finishedColor}`
            // }
          >
            <Typography align="center" style={{ fontWeight: "bold" }}>
              {t("bazarStatus." + statusArr[status]?.title)}
            </Typography>
          </Paper>
        </Box>
      )}
      {status === "InComplete" && (
        <Paper
          className={`${classes.stepsBadge}`}
          // style={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          // }}
        >
          <div>
            <Typography align="center" style={{ color: "#F8B334" }}>
              {packagePlan === "FreePlan"
                ? `${remainingStepsNo}/4`
                : `${remainingStepsNo}/5`}
            </Typography>
            {t("steps")}
            <div style={{ marginTop: "5px", width: "100px" }}>
              <MouseOverPopover
                text={
                  <div style={{ marginTop: "5px", width: "300px" }}>
                    {remainigStepContent.map((step, index) => (
                      <div key={index}>{step}.</div>
                    ))}
                  </div>
                }
              >
                <InfoOutlinedIcon />
              </MouseOverPopover>
            </div>
          </div>
        </Paper>
      )}

      <Box
        position="absolute"
        top="62%"
        left="9%"
        mb={role === "Organizer" && pathname !== "/" && "20px"}
      >
        <Card className={classes.infoCard}>
          <CardContent align="center" className={classes.CardContent}>
            {status === "CommingSoon" &&
              role === "Organizer" &&
              shopNo === 0 &&
              pathname !== "/" && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "end",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenDeleteModal(id);
                  }}
                >
                  {/* <IconButton
                  style={{
                    justifySelf: 'flex-end',
                    marginTop: '-8px',
                    marginLeft: '10px',
                    // alignSelf: 'flex-end',
                  }}
                > */}
                  <DeleteOutlineIcon color="error" />
                  {/* </IconButton> */}
                </div>
              )}
            {booth !== true && (
              <Typography
                // align="center"
                style={{
                  // marginBottom: '10px',
                  fontWeight: "bold",
                  fontSize: "17px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                {name}
              </Typography>
            )}

            {status === "InComplete" && (
              <>
                <Link
                  to={`/createBazar/${id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    className={classes.completeButton}
                    onClick={() => (localStorage.step = 0)}
                  >
                    {t("bazarCard.completeNow")}
                  </Button>
                </Link>
                <Button
                  className={classes.deleteBtn}
                  onClick={() => handleOpenDeleteModal(id)}
                >
                  {t("buttons.delete")}
                </Button>
              </>
            )}
            {status === "Active" && (
              <Box
                mt={"10px"}
                color={"text.secondary"}
                fontWeight="bold"
                dir="ltr"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  mb="10px"
                  justifyContent="center"
                >
                  <LocationOnOutlinedIcon color="primary" />
                  <Typography align="center">
                    {localStorage.i18nextLng === "en"
                      ? county?.name
                      : county?.arabicName}
                  </Typography>
                </Box>
                <Typography align="center">
                  {t("shops")}: {shopNo}
                </Typography>
                {/* {role === 'Organizer' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      borderRadius: '20px',
                      margin: '5% 0',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleOpenInvitation()
                    }}
                  >
                    Invite Sellers
                  </Button>
                )} */}
              </Box>
            )}
            {status === "Hybrid" && (
              <Box mt={"10px"} color={"text.secondary"} fontWeight="bold">
                <Box
                  display="flex"
                  alignItems="center"
                  mb="10px"
                  justifyContent="center"
                >
                  <LocationOnOutlinedIcon color="primary" />
                  <Typography align="center">
                    {localStorage.i18nextLng === "en"
                      ? county?.name
                      : county?.arabicName}
                  </Typography>
                </Box>
                <Typography align="center">
                  {t("shops")}: {shopNo}
                </Typography>
                {/* {role === 'Organizer' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{
                      borderRadius: '20px',
                      margin: '5% 0',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleOpenInvitation()
                    }}
                  >
                    Invite Sellers
                  </Button>
                )} */}
              </Box>
            )}
            {status === "CommingSoon" && (
              <Box
                color={"text.secondary"}
                fontWeight="bold"
                style={{ marginTop: "8px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  mr="15px"
                  justifyContent="center"
                >
                  <LocationOnOutlinedIcon color="primary" />
                  <Typography align="center">
                    {localStorage.i18nextLng === "en"
                      ? county?.name
                      : county?.arabicName}
                  </Typography>
                </Box>
                {role === "Organizer" && (
                  <Typography align="center">
                    {t("requests")}: {request}
                  </Typography>
                )}

                <Typography align="center">
                  {t("shops")}: {shopNo}
                </Typography>
                {role === "Seller" && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      margin: "10px 0",
                      borderRadius: "20px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleOpenBookBoothModal(id);
                    }}
                  >
                    <div
                      style={{ fontSize: "12px", textTransform: "capitalize" }}
                    >
                      {t("buttons.bookBooth")}
                      {/* Book a booth */}
                      {/* <BookBooth bazarId={14} /> */}
                    </div>
                  </Button>
                )}
                {role === "Organizer" && pathname !== "/" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{
                      borderRadius: "20px",
                      margin: "5% 0",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleOpenInvitation(id);
                    }}
                  >
                    {/* <div
                      style={{ fontSize: '12px', textTransform: 'capitalize' }}
                    > */}
                    {/* {t('buttons.bookBooth')} */}
                    {/* Book a booth */}
                    {/* <BookBooth bazarId={14} /> */}
                    {t("inviteSeller.Invite")}
                    {/* </div> */}
                  </Button>
                )}
              </Box>
            )}
            {status === "Finished" && (
              <Box color={"text.secondary"} fontWeight="bold">
                <Box display="flex" alignItems="center" my="5px">
                  <LocationOnOutlinedIcon color="primary" />
                  <Typography align="center">
                    {localStorage.i18nextLng === "en"
                      ? county?.name
                      : county?.arabicName}
                  </Typography>
                </Box>

                <Box my="5px">
                  <Typography align="center">
                    {t("shops")}: {shopNo}
                  </Typography>
                </Box>
                {/* <Box display="flex" mt={2} color="text.primary">
                  <UpdateOutlinedIcon />
                  <Typography align="center">Finished</Typography>
                </Box> */}
              </Box>
            )}
            {status !== "InComplete" && !booth && (
              <Box display={"flex"}>
                <UpdateOutlinedIcon className={classes.iconSpacing} />
                {console.log(startDate, endDate, "startDate, endDate")}
                {status === "Finished" ? (
                  <div>{t("bazarStatus.finished")}</div>
                ) : (
                  //  startDate ? (
                  //   <div>
                  //     {status === 'Active' && (
                  //       <>
                  //         {t('endAfter')}{' '}
                  //         {/* <span style={{ margin: '0 8px' }}> */}
                  //         {getTimeBasedOnStatus(status, startDate, endDate)}
                  //         {/* </span> */} {t('days')}
                  //       </>
                  //     )}
                  //     {status === 'CommingSoon' && (
                  //       <>
                  //         <span style={{ margin: '0 8px' }}>
                  //           {getTimeBasedOnStatus(status, startDate, endDate)}
                  //         </span>
                  //         {t('daysForStart')}
                  //       </>
                  //     )}
                  //   </div>
                  // ) : (
                  //   ''
                  // )
                  <>
                    {status === "Active" && (
                      <>
                        <div style={{ margin: "0 5px" }}> {t("endAt")}</div>{" "}
                        <div>
                          {format(new Date(endDate + "Z"), "dd-MM-yyyy")}
                        </div>{" "}
                      </>
                    )}
                    {status === "CommingSoon" && (
                      <>
                        <div style={{ margin: "0 5px" }}> {t("startAt")}</div>{" "}
                        {format(new Date(startDate + "Z"), "dd-MM-yyyy")}
                      </>
                    )}
                  </>
                )}
              </Box>
            )}
            {role === "Provider" && pathname !== "/" && (
              <Button
                variant="outlined"
                color="primary"
                // className={classes.manageOrders}
                onClick={() =>
                  props.history.push(`/Provider/manageOrders/${id}`)
                }
                style={{ borderRadius: "15px", margin: "10px 0" }}
              >
                {t("buttons.manageOrders")}
              </Button>
            )}
            {booth === true && (
              <CardContent
                className={classes.CardContent}
                style={{ padding: 0 }}
                classes={{ root: classes.boothCardContentRoot }}
              >
                <Typography
                  align="left"
                  style={{
                    marginBottom: "10px",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  {name}
                </Typography>
                {boothCountry && (
                  <Box>
                    <Box display="flex" mx="5px">
                      <LocationOnOutlinedIcon color="primary" />
                      <Typography>{boothCountry}</Typography>
                    </Box>
                  </Box>
                )}

                <Box align="left">
                  <IconButton color="primary" component="span">
                    <Call />
                  </IconButton>
                  <IconButton color="primary" component="span">
                    <Email />
                  </IconButton>
                </Box>
              </CardContent>
            )}
          </CardContent>
        </Card>
      </Box>
      {/* </Link> */}
    </div>
  );
};

export default withRouter(BazarCard);
// ؟
