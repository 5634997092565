import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

import CardContent from '@material-ui/core/CardContent'

import { Avatar, Radio } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    height: 230,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '18px',
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}))

const RoleCard = ({ id, img, name }) => {
  console.log(name)
  const classes = useStyles()

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Avatar
            alt="Cindy Baker"
            src={`/assets/images/rolesImages/${img}`}
            className={classes.large}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default RoleCard
