import React, { useState } from "react";
import cx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

import { Button, Grid, Typography } from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";

import { withRouter } from "react-router-dom";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { IconButton } from "@material-ui/core/index";
import ControlledAppModal from "./../ControlledAppModal/index";
import ConfirmDeleteModalContent from "./../ConfirmDeleteModalContent/index";
import ProductDetailsPage from "./../../containers/ProductDetailPage/index";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    width: 295,
    textAlign: "center",
    marginTop: "20px",
    padding: "20px",
    cursor: "pointer",
    textDecoration: "none",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  padY: { padding: "10px 0" },
  price: { color: green["A700"] },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
  },
  acceptBtn: {
    fontSize: 14,
    border: "1px solid",
    borderColor: blue[800],
    color: blue[700],
    fontWeight: "bold",
    textTransform: "capitalize",
    // marginBottom: '0.875em',
  },
  declineBtn: {
    fontSize: 14,
    border: "1px solid",
    color: red[500],
    borderColor: red[500],
    // fontWeight: 'bold',
    textTransform: "capitalize",
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    letterSpacing: "1px",
  },
  marginTop: {
    marginTop: "10px",
  },
  outlinedPrimary: {
    padding: "5px 15px",
    border: "1px solid",
    borderColor: palette.primary,
    textTransform: "upperCase",
  },
}));
const ProductRequestCard = ({
  id,
  name,
  description,
  price,
  productImage,
  categoryId,
  categoryName,
  productId,
  boothName,
  seller,
  status,
  handleAcceptRequest,
  handleDeclineRequest,
  handleDeleteProduct,
  boothId,
  history,
  role,
  currency,
}) => {
  // const { pending } = props
  // debugger

  // console.log(status)
  console.log(productImage);
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const [openViewProduct, setOpenViewProduct] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteConfirmed = (productId) => {
    console.log("deleted");
    handleClose();
    handleDeleteProduct(productId);
  };
  const handleViewBoothProduct = () => {
    handleopenViewProductModal();
  };
  const handleopenViewProductModal = () => {
    console.log(id);
    setOpenViewProduct(true);
  };
  const handleCloseViewProduct = () => {
    setOpenViewProduct(false);
  };
  const userRole = localStorage.userRole;
  return (
    <>
      <ControlledAppModal
        open={openViewProduct}
        handleClickOpen={handleViewBoothProduct}
        handleClose={handleCloseViewProduct}
        // title={' You can add new product Or exist product'}
        // titleStyle={{ textAlign: 'center' }}
      >
        <div style={{ maxWidth: "800px" }}>
          <ProductDetailsPage
            productId={userRole === "Organizer" ? id : productId || id}
            outBooth={userRole === "Organizer" ? false : true}
          />
        </div>
      </ControlledAppModal>
      <ControlledAppModal
        handleClickOpen={handleOpen}
        open={open}
        handleClose={handleClose}
      >
        <ConfirmDeleteModalContent
          handleDeleteConfirmed={handleDeleteConfirmed}
          handleClose={handleClose}
          productId={id}
        />
      </ControlledAppModal>
      <Card className={cx(styles.card)}>
        {role === "Seller" && (
          <div style={{ display: "flex" }}>
            <IconButton
              style={{ display: "flex", justifyContent: "flex-start" }}
              onClick={handleOpen}
            >
              <DeleteOutlineIcon color="error" />
            </IconButton>
            {/* <IconButton
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onClick={() => history.push('/Seller/EditProduct/' + id)}
            >
              <EditOutlinedIcon color="secondary" />
            </IconButton> */}
          </div>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          className={styles.price}
        >
          <div style={{ display: "flex" }} onClick={handleopenViewProductModal}>
            {status === "Pending" && role === "Organizer" && (
              <VisibilityIcon color="primary" />
            )}
          </div>
          <div style={{ alignSelf: "flex-end" }}>
            {price} {currency}
          </div>
        </Box>

        <div className={styles.cardContent}>
          <Box
            height="100px"
            width="100px"
            display="flex"
            justifyContent="center"
          >
            <img
              width="100%"
              height={"100%"}
              className={"img"}
              // src={'https://i.pravatar.cc/300'}
              src={
                productImage[0]?.url
                  ? localStorage.imagePath + productImage[0]?.url
                  : "/assets/images/buyer/Product.svg"
              }
            />
          </Box>
          <h3 className={styles.heading}>{name}</h3>
          {/* <Typography color="inherit" className={styles.marginTop}>
            {boothName}
          </Typography> */}
          {role !== "Seller" && (
            <div>
              <Typography color="inherit">
                {seller ? seller?.firstName + " " + seller?.lastName : seller}
              </Typography>
            </div>
          )}

          <Typography
            color="textSecondary"
            variant="body2"
            className={styles.padY}
            style={{ fontSize: "12px" }}
          >
            {description.length >= 100
              ? description.slice(0, 100).concat("...")
              : description}
          </Typography>
          {status === "Pending" ? (
            <Box
              display="flex"
              justifyContent="space-between"
              width="86%"
              my={2}
            >
              <Button
                variant="outlined"
                // className={styles.acceptBtn}
                color="primary"
                onClick={() => handleAcceptRequest(id)}
              >
                {t("buttons.accept")}
              </Button>
              <Button
                variant="outlined"
                className={styles.declineBtn}
                onClick={() => handleDeclineRequest(id)}
              >
                {t("buttons.decline")}
              </Button>
            </Box>
          ) : (
            <Grid
              container
              justify="center"
              style={{ marginTop: "10px" }}
              spacing={2}
            >
              <Grid item xs={6} container justify="center" alignItems="center">
                <Button
                  color="primary"
                  className={styles.outlinedPrimary}
                  onClick={handleopenViewProductModal}
                >
                  {/* {t("buttons.showMore")} */}
                  {t("buttons.viewAs")}
                </Button>
              </Grid>
              {boothId && role === "Seller" && (
                <Grid item xs={5}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      borderColor: "#008bda",
                      color: "#008bda",
                      marginTop: "4%",
                    }}
                    onClick={() =>
                      history.push(
                        `/Seller/booth/${boothId}/EditProduct/${id}?existProduct=true`
                      )
                    }
                  >
                    {t("buttons.add")}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Card>
    </>
  );
};

export default withRouter(ProductRequestCard);
