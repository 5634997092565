import React from "react";

import { Box, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useTranslation } from "react-i18next";
const BuyerInfoModalContent = ({
  id,
  firstName,
  lastName,
  email,
  whatsNumber,
  phoneNumber,
  postalCode,
  street,
  city,
  country,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 80px",
        marginBottom: "50px",
        backgroundColor: "white",
      }}
    >
      <h3>{firstName + " " + lastName}</h3>
      <div>
        <Box display="flex" alignItems="center">
          <MailIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          <Typography>{email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" dir="ltr">
          <PhoneIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          <Typography>{phoneNumber}</Typography>
        </Box>
        {whatsNumber && (
          <Box display="flex" alignItems="center">
            <WhatsAppIcon
              // className={classes.marginRight}
              className={"margin-right-s"}
              fontSize={"small"}
              color="primary"
            />
            {/* <Typography>{organizer.whatsNumber}</Typography> */}
            <Typography style={{ direction: "ltr" }}>{whatsNumber}</Typography>
          </Box>
        )}
      </div>

      <div>
        <Box display="flex" mt={1} mr={6}>
          <LocationOnOutlinedIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          {city?.name && <Typography>{city?.name + ", "}</Typography>}
          {country?.name && (
            <Typography>
              {localStorage.i18nextLng === "en"
                ? country?.name
                : country?.arabicName}
            </Typography>
          )}
        </Box>
        <div>
          {street} {t("signUp.street")}, {postalCode}
        </div>
      </div>
    </div>
  );
};

export default BuyerInfoModalContent;
