import axios from "axios";

export const logInAsync = async (userInfo) => {
  const response = await axios.post("authenticate", userInfo);
  // call api to save cart items

  const cartItems = localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [];
  const allCartProducts = cartItems.map((cartItem) =>
    cartItem.items.map((product) => ({
      buyerId: response?.data?.id,
      catalogItemId: product?.catalogItemId,
      description: product?.description || "",
      colorId: product?.color,
      sizeId: product?.size,
      quantity: product?.quantity || 0,
    }))
  );
  console.log(allCartProducts.flat(), "allCartProducts");
  debugger;
  const postCartItems = await axios.post(
    "Buyer/AddListToBasket",
    allCartProducts.flat()
  );
  if (localStorage.userRole === "Buyer") {
    localStorage.removeItem("cartItems");
    console.log("postCartItems", postCartItems);
  }

  return response.data;
};
