import React from 'react'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function ShowMessage({
  status = 'success',
  msg = '',
  vertical = 'top',
  horizantal = 'center',
  openMsg,
}) {
  const [open, setOpen] = React.useState(true)
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    // <div className={classes.root}>
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      key="top center"
      anchorOrigin={{ vertical: vertical, horizontal: horizantal }}
      // anchorOrigin="top center"
    >
      <Alert onClose={handleClose} severity={status}>
        {msg}
      </Alert>
    </Snackbar>
    // </div>
  )
}
