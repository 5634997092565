import React from "react";
import { Form, Formik } from "formik";
import Dropdown from "../Dropdown";
import { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import FormikSelect from "../FormikSelect";
import { editOrderItemSettingsAsync } from "../../redux/serviceProvider/api";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import MailIcon from "@material-ui/icons/Mail";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ccyFormat from "../../utils/ccyFormatFun";
const useStyles = makeStyles({
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dis2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // width: '28%',
  },
});
const ProviderOrderSettings = ({
  initialValues,
  drivers,
  statusArr,
  /////////////////// shipping address /////////////
  firstName,
  lastName,
  email,
  whatsNumber,
  phoneNumber,
  postalCode,
  street,
  country,
  city,
  buyer,
  // : {
  //   firstName,
  //   lastName,
  //   country,
  //   phoneNumber,
  //   city,
  //   email,
  //   street,
  //   postalCode,
  //   whatsNumber,
  // }
  handleSubmit,
  subTotal,
  shipping,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      //   validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        validateForm,
        isSubmitting,
        handleReset,
        setFieldValue,
      }) => {
        return (
          <Form
            autoComplete="false"
            style={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <Grid container wrap="wrap">
              <Grid
                item
                xs={6}
                md={6}
                lg={2}
                container
                // justify="center"
                // alignItems="center"
              >
                <Box width="140px" mb="15px">
                  <FormikSelect
                    name="driverId"
                    label={t("driver")}
                    variant="outlined"
                    items={drivers}
                    // disabled={initialValues.orderItemStatus === 2}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} lg={2} container>
                <Box width="140px" mb="15px">
                  <FormikSelect
                    name="orderItemStatus"
                    label={t("status")}
                    variant="outlined"
                    items={statusArr}
                    // disabled={initialValues.orderItemStatus === 2}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={2}
                container
                // justify="center"
                style={{ direction: "ltr", margin: " 10px" }}
              >
                <Box width="180px" mb="15px">
                  <Typography
                    variant="h6"
                    style={{ marginTop: "0px", marginBottom: "20px" }}
                  >
                    {t("buyerInfo")}
                  </Typography>
                  <div>{buyer?.firstName + " " + buyer?.lastName}</div>
                  <Box
                    display="flex"
                    // justifyContent="center"
                    // mt={1}
                    alignItems="center"
                    style={{ direction: "ltr" }}
                  >
                    <LocationOnOutlinedIcon
                      //   className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />
                    <div>
                      {localStorage.i18nextLng === "en"
                        ? buyer?.country?.name
                        : buyer?.country?.arabicName}
                      , {buyer?.city?.name}
                    </div>
                  </Box>
                  <div>
                    {buyer?.street} {t("signUp.street")}, {buyer?.postalCode}
                  </div>
                  <Box display="flex" alignItems="center" dir="ltr">
                    <PhoneIcon
                      className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />

                    <div style={{ direction: "ltr" }}>{buyer?.phoneNumber}</div>
                  </Box>
                  {whatsNumber && (
                    <Box display="flex" alignItems="center">
                      <WhatsAppIcon
                        className={classes.marginRight}
                        fontSize={"small"}
                        color="secondary"
                      />
                      {/* <Typography>{organizer.whatsNumber}</Typography> */}
                      <div style={{ direction: "ltr" }}>
                        {buyer?.whatsNumber}
                      </div>
                    </Box>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 3px",
                    }}
                  >
                    <MailIcon
                      className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />
                    {buyer?.email}
                  </div>
                  {/* <Box className={classes.display} style={{ direction: 'ltr' }}>
                    <MailIcon
                      className={classes.marginRight}
                      fontSize={'small'}
                      color="secondary"
                    />
                    <Typography>{email}</Typography>
                  </Box> */}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={2}
                container
                // justify="center"
                style={{ direction: "ltr", margin: " 10px" }}
              >
                <Box width="180px" mb="15px">
                  <Typography
                    variant="h6"
                    style={{ marginTop: "0px", marginBottom: "20px" }}
                  >
                    {t("shippingAddress")}
                  </Typography>
                  {/* {(firstName || lastName) && ( */}
                  <div>{firstName + " " + lastName}</div>
                  {/* )} */}

                  <Box
                    display="flex"
                    // justifyContent="center"
                    // mt={1}
                    alignItems="center"
                    style={{ direction: "ltr" }}
                  >
                    <LocationOnOutlinedIcon
                      //   className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />
                    <div>
                      {localStorage.i18nextLng === "en"
                        ? country?.name
                        : country?.arabicName}
                      , {city?.name}
                    </div>
                  </Box>
                  {street && (
                    <div>
                      {street} {t("signUp.street")}, {postalCode}
                    </div>
                  )}
                  <Box display="flex" alignItems="center" dir="ltr">
                    <PhoneIcon
                      className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />

                    <div style={{ direction: "ltr" }}>{phoneNumber}</div>
                  </Box>
                  {whatsNumber && (
                    <Box display="flex" alignItems="center">
                      <WhatsAppIcon
                        className={classes.marginRight}
                        fontSize={"small"}
                        color="secondary"
                      />
                      {/* <Typography>{organizer.whatsNumber}</Typography> */}
                      <div style={{ direction: "ltr" }}>{whatsNumber}</div>
                    </Box>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 3px",
                    }}
                  >
                    <MailIcon
                      className={classes.marginRight}
                      fontSize={"small"}
                      color="secondary"
                    />
                    {email}
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={2}
                container
                // justify="center"
                style={{ margin: "10px" }}
              >
                <Box width="180px" mb="5px">
                  <Typography
                    variant="h6"
                    //   style={{ marginTop: '0px', marginBottom: '20px' }}
                  >
                    {t("summaryPage.orderSummary")}
                  </Typography>
                  <div className={classes.dis2}>
                    <div style={{ width: "95%" }}>
                      <p className={classes.dis}>
                        <span>{t("subtotal")}</span> <span>JD {subTotal}</span>
                      </p>
                      <p className={classes.dis}>
                        {t("shipping")} <span>JD {ccyFormat(shipping)}</span>
                      </p>
                      <p
                        className={classes.dis}
                        style={{ fontWeight: "800", color: "orange" }}
                      >
                        <span>{t("total")}</span>
                        <span>JD {subTotal + shipping}</span>
                      </p>
                    </div>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={1}
                container
                justify="flex-end"
                alignItems="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // style={{ borderRadius: '10px', fontSize: '10px' }}
                  disabled={initialValues.orderItemStatus === 2}
                  size="small"
                >
                  {t("buttons.submit")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProviderOrderSettings;
