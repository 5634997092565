import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { fade, makeStyles, useTheme } from "@material-ui/core/styles";

import { Link, NavLink, withRouter } from "react-router-dom";
import { Avatar, Badge, Box, capitalize } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import DrawerNavigation from "../DrawerNavigation";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { useDispatch, useSelector, connect } from "react-redux";

import { getOrganizerProfile } from "./../../redux/organizer/actions";
import { getSellerById } from "../../redux/seller/actions";
import { getServiceProviderById } from "../../redux/serviceProvider/actions";
import { OrganizerDrawerItems } from "../../containers/OrganizerDashBoard/constants";
import { SellerDrawerItems } from "../../containers/SellerDashBoard/constants";
import { ProviderDrawerItems } from "../../containers/ServiceProviderDashBoard/constants";
import { useMemo } from "react";
import { useCallback } from "react";
// import connect from 'react-redux'
import Header from "./../Header/index";
import Logout from "../Logout";
import SwitchLanguage from "../LanguageDropdown";
import { useTranslation } from "react-i18next";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      width: `100%`,
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      //   marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    left: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "80px",
    overflowX: "auto",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  search: {
    position: "relative",
    borderRadius: "20px",
    border: "1px solid #dddcdc",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  responsiveMargin: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "65px",
    },
  },
  colo: { backgroundColor: "white", padding: "10px" },
}));

function SideBarDrawer(props) {
  const { window, children } = props;
  // console.log(DrawerItems)
  // debugger
  /////////////////redux props /////////////////
  const { organizerProfile, sellerProfile, providerProfile } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();
  const role = localStorage.userRole;

  const userId = localStorage.userId;
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    cityName: "",
    companyCertificate: "",
    countryName: "",
    email: "",
    lastName: "",
    phoneNumber: "",
    preCode: "",
    profileImage: "",
    socialMediaLinks: [],
    whatsNumber: "",
    companyName: "",
    representativeName: "",
  });
  const getDrawerItems = useCallback(() => {
    let items = [];
    switch (role) {
      case "Organizer":
        items = OrganizerDrawerItems;
        break;
      case "Seller":
        items = SellerDrawerItems;

        break;
      case "Provider":
        items = ProviderDrawerItems;
        break;

      default:
        break;
    }
    return items;
  }, [role]);
  const DrawerItems = getDrawerItems();

  useEffect(() => {
    switch (role) {
      case "Organizer":
        dispatch(getOrganizerProfile(userId));
        break;
      case "Seller":
        dispatch(getSellerById(userId));

        break;
      case "Provider":
        dispatch(getServiceProviderById(userId));
        break;

      default:
        break;
    }
    console.log("Api Calling");
    // role === 'organizer'
  }, []);

  useEffect(() => {
    switch (role) {
      case "Organizer":
        setUser(organizerProfile);
        localStorage.setItem("userAllInfo", JSON.stringify(organizerProfile));
        break;
      case "Seller":
        setUser(sellerProfile);
        localStorage.setItem("userAllInfo", JSON.stringify(sellerProfile));

        break;
      case "Provider":
        setUser(providerProfile);
        console.log(providerProfile, "providerProfile");
        localStorage.setItem("userAllInfo", JSON.stringify(providerProfile));

        break;
      default:
        break;
    }
  }, [sellerProfile, organizerProfile, providerProfile]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {console.log("Api Calling", user)}
      <div className={classes.responsiveMargin} />
      {/* <div className={classes.menuButton}>
        <SwitchLanguage />
      </div> */}
      <Divider />
      <Divider />
      <Box align="center" mt={4} mb={1}>
        <Avatar
          src={
            user.profileImage
              ? localStorage.imagePath + user.profileImage
              : "/static/images/avatar/1.jpg"
          }
          className={classes.large}
          alt={
            user?.firstName?.toUpperCase() || user?.companyName?.toUpperCase()
          }
        />
        <Typography variant="h6">
          {role === "Provider"
            ? user?.companyName
            : user?.firstName + " " + user?.lastName}
          {/* {role === 'Seller' && user.name} */}
        </Typography>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Box width="14px" m="0 2px" height="14px" style={{ color: "black" }}>
            <img
              src={`/assets/images/SVG/${role}_BW.svg`}
              width="100%"
              height="100%"
              style={{ color: "black" }}
            />
          </Box>
          <Typography
            style={{ margin: "0px 4px", fontSize: "0.85rem" }}
            // color="secondary"
          >
            {t("roles." + role)}
          </Typography>
        </div>
        {/* <Typography>{role}</Typography> */}

        <Box display="flex" justifyContent="center" mt={1} dir="ltr">
          <LocationOnOutlinedIcon fontSize="small" />
          <Typography style={{ fontSize: "0.85rem" }}>
            {localStorage.i18nextLng === "en"
              ? user?.country?.name
              : user?.country?.arabicName}
          </Typography>
          {/* <Typography>{user?.country?.name}</Typography> */}
        </Box>
      </Box>
      <DrawerNavigation DrawerItems={DrawerItems} />
      {/* <Box
        textAlign="center"
        mb={5}
        width="70%"
        display="flex"
        justifyContent="center"
        margin="auto"
      >
        <Logout fullWidth />
      </Box> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  console.log("user", user);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} currentUser={user} />
      {/* <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box width="200px">
            <img
              src="/assets/images/logo/logo.png"
              width="100%"
              height="100%"
              alt=""
            />
          </Box>

          <Box
            align="right"
            display="flex"
            justifyContent="flex-end"
            style={{ marginInlineStart: 'auto', marginRight: '20px' }}
          >
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="primary">
                <NotificationsIcon color="secondary" />
              </Badge>
            </IconButton>

            <Avatar src="/static/images/avatar/1.jpg" />
          </Box>
        </Toolbar>
      </AppBar> */}
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={
          props?.location?.pathname.includes("/Provider/manageOrders")
            ? `${classes.content} ${classes.colo}`
            : `${classes.content}`
        }
      >
        {children}
      </main>
    </div>
  );
}

SideBarDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const mapStateToProps = (state) => {
  // console.log('organizer Profile', state.organizerReducer.organizerProfile)

  return {
    organizerProfile: state.organizerReducer.organizerProfile,
    sellerProfile: state.sellerReducer.seller,
    providerProfile: state.serviceProviderReducer.provider,
  };
};

export default React.memo(connect(mapStateToProps)(SideBarDrawer));
