import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import tableIcons from "./../../styles/materialTableIcons";
import { Avatar, Link, Button } from "@material-ui/core";
import axios from "axios";
import BazarRequestInfoModal from "./../../components/BazarRequestInfoModal/index";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BookBooth from "./../BookBooth/index";
import ControlledAppModal from "./../ControlledAppModal";
import { setSnackbar } from "../../redux/Message/actions";
import { colors } from "../../styles/commonCss";
import { getAllCountries } from "./../../redux/address/actions";
import { handleLogout } from "../../utils/setLocalStorage";

const token = localStorage.token;
const RequestSellerTable = (props) => {
  const sellerId = localStorage.userId;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios
      .get(
        `Seller/GetSellerInvitation?SellerId=${sellerId}&PageSize=100&PageIndex=1`
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const [selectedBazar, setSelectedBazar] = useState(null);
  const handleOpenBookBoothModal = (bazarID) => {
    // setSelectedBazar(bazarID)
    setOpen(true);
  };

  const checkSellerAlreadyHasBooth = async (bazarID) => {
    setSelectedBazar(bazarID);
    try {
      const response = await axios.get(
        `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${bazarID}`
      );
      if (response.data.succeeded) {
        handleOpenBookBoothModal();
      } else if (!response.data.succeeded) {
        console.log(response.data, "sellll");
        dispatch(setSnackbar(true, "error", response.data.message));
      }
    } catch (error) {
      console.log("err", error.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const countries = useSelector((state) => state.addressReducer.countries);
  const handleBookBoothSubmit = (values, { setSubmitting, resetForm }) => {
    let newValues = { ...values };
    const categoriesIds = newValues.categories.map((cat) => cat.id);
    newValues.categories = categoriesIds;
    console.log(newValues);

    axios
      .post("Booth/CreateBooth", newValues)
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);

        dispatch(
          setSnackbar(true, "success", t("messages.Requestbookboothsent"))
        );

        handleClose();
      })
      .catch((error) => {
        console.log(error?.response);
        if (error?.response?.data.message)
          dispatch(true, "error", error?.response?.data.message);
        if (error?.response?.status === 401) {
          handleLogout();
          window.location.href = "/login";
        }
      });
  };
  const [openBazarInfoModal, setOpenBazarInfoModal] = useState(false);
  const [currentRow, setCurrentRow] = useState("");

  const handleOpenBazarInfoModal = (e, row) => {
    setCurrentRow(row.bazar);
    setOpenBazarInfoModal(true);
  };
  const handleCloseBazarInfoModal = () => {
    setOpenBazarInfoModal(false);
  };
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        style={{ padding: "20px 0", fontSize: "15px", overflowX: "scroll" }}
        title={t("sideMenu.bazarRequests")}
        columns={[
          {
            title: t("image"),
            field: "bazar.imageProfile",
            render: (row) => {
              return (
                <Avatar
                  alt={row.bazar.name}
                  src={
                    row.bazar?.imageProfile
                      ? localStorage.imagePath + row.bazar.imageProfile
                      : "/static/images/avatar/1.jpg"
                  }
                />
              );
            },
            cellStyle: {
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            title: t("bazarName"),
            field: "bazar.name",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <Link
                component="button"
                style={{
                  color: colors.link,
                  fontSize: "15px",
                  textDecoration: "underline",
                }}
                variant="body2"
                onClick={(e) => handleOpenBazarInfoModal(e, row)}
              >
                {row.bazar.name}
              </Link>
            ),
          },
          {
            title: t("organizerName"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return (
                <div>
                  {`${row.bazar.organizer.firstName} ${row.bazar.organizer.lastName}`}
                </div>
              );
            },
          },
          {
            title: t("date"),
            field: "date",
            render: (row) => (
              <div>{format(new Date(row.date + "Z"), "MM/dd/yyyy")}</div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("signUp.country"),
            field:
              localStorage.i18nextLng === "en"
                ? "bazar.county.name"
                : "bazar.county.arabicName",
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("actions"),
            field: "actions",
            render: (row) => (
              <Link
                component="button"
                style={{
                  color: colors.link,
                  fontSize: "15px",
                  textDecoration: "underline",
                }}
                variant="body2"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  checkSellerAlreadyHasBooth(row.bazar.id);
                }}
              >
                {t("buttons.bookBooth")}
              </Link>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
        ]}
        options={{
          search: false,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle: { backgroundColor: "#f1f1f1", textAlign: "center" },
          rowStyle: { textAlign: "center" },
          editCellStyle: { marginLeft: "20px" },
          sorting: false,
        }}
        data={(query) => {
          return new Promise((resolve, reject) => {
            const { page, pageSize } = query;
            let url = `Seller/GetSellerInvitation?SellerId=${sellerId}&PageSize=${pageSize}&PageIndex=${
              page + 1
            }`;
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(({ data }) => {
                const response = data;
                console.log(data, "toty");
                resolve({
                  data: response.data || response,
                  page: query.page,
                  totalCount: response.count || 1,
                });
              });
          });
        }}
      />
      <ControlledAppModal
        handleOpen={handleOpenBazarInfoModal}
        handleClose={handleCloseBazarInfoModal}
        open={openBazarInfoModal}
        style={{ maxWidth: "1100px" }}
      >
        <BazarRequestInfoModal
          organizer={currentRow.organizer}
          {...currentRow}
          role="Seller"
        />
      </ControlledAppModal>
      <ControlledAppModal open={open} handleClose={handleClose}>
        <div style={{ maxWidth: "600px" }}>
          <BookBooth
            bazarId={selectedBazar}
            countries={countries}
            // handleSubmit={handleBookBoothSubmit}
            handleCloseBookBoothModal={handleClose}
          />
        </div>
      </ControlledAppModal>
    </>
  );
};

export default RequestSellerTable;
