import {
  Box,
  Card,
  CardContent,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";

import React, { useState } from "react";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import IconButton from "@material-ui/core/IconButton";
import { Call, Email } from "@material-ui/icons";
import BazarCard from "../BazarCard";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  imgCard: {
    backgroundColor: "black",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "280px",
    height: "330px",
    // position: 'relative',
    // zIndex: 1,
  },
  infoCard: {
    // backgroundColor: 'blue',
    //width: '260px',
    //width: '245px',
    maxHeight: "160px",
    borderRadius: "15px",
    paddding: "10px 5px",
    // position: 'relative',
    zIndex: 2,
    // left: '41.5%',
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
  },
}));
const BoothCard = (props) => {
  const classes = useStyles();
  const { id } = props;

  return (
    <div style={{ cursor: "pointer" }}>
      <Link
        onClick={() =>
          props.history.push(`/buyer/boothPage/${id}?notBuyer=${true}`)
        }
      >
        <BazarCard booth={true} {...props} />
      </Link>
    </div>
  );
};

export default withRouter(BoothCard);
