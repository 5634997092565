import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import './styles.css'

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Navigation])

export default function TestCarousel({ children }) {
  const currentLang = localStorage.i18nextLng
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        navigation={true}
        dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
        // pagination={{
        //   clickable: true,
        // }}
        className="mySwiper"
        // centeredSlides={true}
        breakpoints={{
          '320': { slidesPerView: 1, spaceBetween: 20 },
          '425': { slidesPerView: 1, spaceBetween: 10 },

          '640': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {/* <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide> */}
        {children}
      </Swiper>
    </>
  )
}
