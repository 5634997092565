import React, { useEffect } from "react";
import DriverInfoCard from "./../../components/DriverInfoCard/index";
import { Box, Grid, Button } from "@material-ui/core";

import { useState } from "react";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import AddEditDriverModal from "./../../components/AddEditDriverModal/index";
import {
  addNewDriverAsync,
  deleteDriverAsync,
  editDriverAsync,
  getAllDriversAsync,
} from "./../../redux/serviceProvider/api";
import * as Yup from "yup";
import { phoneRegExp } from "../RegisterationPage/constants";
import ConfirmDeleteModalContent from "./../../components/ConfirmDeleteModalContent/index";
import { setSnackbar } from "./../../redux/Message/actions";
import { useTranslation } from "react-i18next";
import EmptyDriver from "../../components/EmptyDrive";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";

const DriversPage = () => {
  const providerId = localStorage.userId;
  const dispatch = useDispatch();
  const [drivers, setDrivers] = useState([]);
  const [mode, setMode] = useState("Add");
  const [open, setOpen] = useState(false);
  const [deleteDriverId, setDeleteDriverId] = useState("");
  const { t } = useTranslation();
  const [openConfirmDeletModal, setOpenConfirmDeletModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone: "",
    comment: "",
    providerId,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getAllDriversAsync(providerId);
      console.log(response);
      setDrivers(response.data);
    }
    fetchData();
  }, []);
  const handleOpenDeleteModal = (driverId) => {
    setOpenConfirmDeletModal(true);
    setDeleteDriverId(driverId);
  };
  const handleCloseDeleteModal = () => {
    setOpenConfirmDeletModal(false);
  };
  const handleDeleteConfirmed = async (driverId) => {
    console.log("deleted", driverId);
    handleCloseDeleteModal();
    /////////////////// call delet api //////////////////
    // handleDeleteProduct(id)
    try {
      await deleteDriverAsync(driverId);
      const newDrivers = drivers.filter((driver) => driver.id !== driverId);
      setDrivers(newDrivers);
      dispatch(
        setSnackbar(true, "success", t("messages.driverDeletedSuccessfuly"))
      );
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleAddButtonClicked = () => {
    setInitialValues({
      name: "",
      phone: "",
      comment: "",
      providerId,
    });
    setMode("Add");
    handleOpenModal();
  };
  const handleEditIconClicked = (selectedDriver) => {
    console.log(selectedDriver, "selectedDriver");
    debugger;
    // const selectedDriver = drivers.find((driver) => driver.id === driverId)
    setInitialValues(selectedDriver);
    setMode("Edit");
    handleOpenModal();
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "DriversPage");
    if (isValidPhoneNumber(values.phone) === false) {
      dispatch(setSnackbar(true, "error", t("validation.invalidPhone")));
    } else {
      handleCloseModal();
      try {
        let response = "";
        if (mode === "Add") {
          response = await addNewDriverAsync(values);
          debugger;
          setDrivers((drivers) => [...drivers, response]);
        } else {
          response = await editDriverAsync(values);
          console.log(response);
          const newDrivers = drivers.map((driver) => {
            if (driver.id === values.id) {
              return values;
            } else return driver;
          });
          setDrivers(newDrivers);
        }
      } catch (error) {
        console.log(error);
        console.log(error?.response);
      }
    }
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(15, t("mustBe15")).required(t("DriverRequired")),

    // phone: Yup.string()
    //   .matches(phoneRegExp, "Phone number is  Invalid")
    //   .required(t("validation.invalidPhone")),
  });
  return (
    <div>
      <ControlledAppModal
        handleClickOpen={handleOpenDeleteModal}
        open={openConfirmDeletModal}
        handleClose={handleCloseDeleteModal}
      >
        <ConfirmDeleteModalContent
          handleDeleteConfirmed={handleDeleteConfirmed}
          handleClose={handleCloseDeleteModal}
          productId={deleteDriverId}
        />
      </ControlledAppModal>
      <Grid
        container
        alignItems="flex-start"
        spacing={2}
        style={{ marginBottom: "10px" }}
      >
        <Grid container xs={6} item>
          <h2>{t("sideMenu.drivers")}</h2>
          <div style={drivers.length ? { margin: "20px" } : { margin: "0" }}>
            {drivers.length
              ? `${drivers.length} ${t("DriversPage.drivers")}`
              : ""}
          </div>
        </Grid>
        {drivers.length ? (
          <Grid container xs={6} item justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddButtonClicked}
            >
              {t("buttons.addNewDriver")}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      {drivers.map((driver) => (
        <div style={{ margin: "15px 0" }} key={driver.id}>
          <DriverInfoCard
            driver={driver}
            handleEditDriver={handleEditIconClicked}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        </div>
      ))}

      {drivers.length === 0 && (
        <Grid container justify="center" item xs={12}>
          <EmptyDriver handleAddButtonClicked={handleAddButtonClicked} />
        </Grid>
      )}
      {/* <DriverInfoCard /> */}
      <ControlledAppModal
        open={open}
        handleClickOpen={handleOpenModal}
        handleClose={handleCloseModal}
      >
        <AddEditDriverModal
          mode={mode}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </ControlledAppModal>
    </div>
  );
};

export default DriversPage;
