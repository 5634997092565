import React from "react";
import HomePage from "../HomePage";
import Home from "./../Home/index";
import OrganizerDashBoard from "../OrganizerDashBoard";
import SellerDashBoard from "../SellerDashBoard";
import ServiceProviderDashBoard from "../ServiceProviderDashBoard";
const DashBoard = (props) => {
  const role = props.match.params.role;
  console.log(role);

  return (
    <>
      {
        {
          Organizer: (
            <OrganizerDashBoard
              handleDrawerToggle={props.handleDrawerToggle}
              mobileOpen={props.mobileOpen}
            />
          ),
          Seller: (
            <SellerDashBoard
              handleDrawerToggle={props.handleDrawerToggle}
              mobileOpen={props.mobileOpen}
            />
          ),
          Provider: (
            <ServiceProviderDashBoard
              handleDrawerToggle={props.handleDrawerToggle}
              mobileOpen={props.mobileOpen}
            />
          ),
          Buyer: (
            <Home
              role={role}
              handleDrawerToggle={props.handleDrawerToggle}
              mobileOpen={props.mobileOpen}
            />
          ),
        }[role]
      }
    </>
  );
};

export default DashBoard;
