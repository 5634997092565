import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import DownloadLink from 'react-download-link'

import { Field, Form, Formik } from "formik";
import FormikSelect from "./../FormikSelect/index";
import FormikField from "./../FormikField/index";
import { socialMediaArray } from "../../containers/App/constants";
import { IconButton, MenuItem } from "@material-ui/core";

import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

import { FieldArray } from "formik";
import { connect } from "react-redux";
import { CheckboxWithLabel, Select } from "formik-material-ui";
import { getAllCountries } from "../../redux/address/actions";
import { makeStyles } from "@material-ui/core/styles";
import UploadButtons from "./../UploadButtons/index";
import { Link } from "react-router-dom";
import { uploadImageAsync } from "./../../service/image";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
export const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formikField: {
    margin: " 10px 0",
  },
  uploadField: {
    border: "1px ",
    borderColor: theme.palette.secondary,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  buyerImg: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: "0px",
    marginRight: "-440px",
    // display: 'none',
    // '[dir="rtl"]': {
    //   justifyContent: 'flex-start',
    // },
  },
  signInLink: {
    color: theme.palette.primary,
  },
}));
const ProfileForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { role, initialValues, handleSubmit, fieldNames, validationSchema } =
    props;

  const [certificateUpload, setCertificateUpload] = useState(false);
  console.log(initialValues);

  useEffect(() => {
    console.log("useEffect");
    props.getCountries();
  }, []);

  const getCountryCities = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };
  const getCountryName = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return localStorage.i18nextLng === "en" ? country.name : country.arabicName;
  };
  const selectFirstCity = (values) => {
    let city = getCountryCities(values.countryId)[0];
    console.log(city);
  };

  const handleCertificateUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const name = file.name;
    console.log(file);
    setCertificateUpload(true);

    try {
      const formDate = new FormData();
      formDate.set(name, file);
      const imageNewName = await uploadImageAsync(formDate);

      setFieldValue("companyCertificate", imageNewName);
      setFieldValue("certificateName", name);
      setCertificateUpload(false);
    } catch (error) {
      setCertificateUpload(false);

      console.log(error);
      console.log(error?.response?.data);
    }
  };
  const [phone, setPhone] = useState("");
  const [phoneWhats, setPhoneWhats] = useState("");
  return (
    <>
      <Grid container item lg={6} xs={12}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            validateForm,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            console.log(values, values.zoomLink);
            setPhone(values.phoneNumber);
            setPhoneWhats(values.whatsNumber);
            // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

            return (
              <Form autoComplete="false">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name={fieldNames ? fieldNames[0].name : "firstName"}
                      label={t(
                        fieldNames
                          ? t("signUp." + fieldNames[0].label)
                          : "signUp." + "firstName"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name={fieldNames ? fieldNames[1].name : "lastName"}
                      label={t(
                        fieldNames
                          ? t("signUp." + fieldNames[1].label)
                          : "signUp." + "lastName"
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="countryId"
                      label={t("signUp.country")}
                      items={props.countries}
                      value={values.countryId}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFieldValue("countryId", e.target.value);
                        setFieldValue(
                          "countryName",
                          getCountryName(e.target.value)
                        );
                        setFieldValue("cityId", "");
                        // selectFirstCity(values)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="cityId"
                      label={t("signUp.city")}
                      items={getCountryCities(values.countryId)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField name="street" label={t("signUp.street")} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name="postalCode"
                      label={t("signUp.postalCode")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneInput
                      placeholder={t("signUp.phoneNumber")}
                      value={phone}
                      onChange={(value) => {
                        let x = formatPhoneNumberIntl(value);
                        setPhone(x);
                        // values.phoneNumber = x
                        setFieldValue("phoneNumber", x || value);
                        console.log("values.phoneNumber", values.phoneNumber);
                      }}
                      // rules={{
                      //   required: true,
                      //   validate: isValidPhoneNumber,
                      // }}
                      style={{ direction: "ltr" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PhoneInput
                      placeholder={t("signUp.phoneWhats")}
                      value={phoneWhats}
                      onChange={(value) => {
                        let x = formatPhoneNumberIntl(value);
                        setPhoneWhats(x);
                        // values.whatsNumber = x
                        setFieldValue("whatsNumber", x || value);
                      }}
                      // rules={{
                      //   required: true,
                      //   validate: isValidPhoneNumber,
                      // }}
                      style={{ direction: "ltr" }}
                    />
                  </Grid>
                  {role !== "Buyer" && (
                    <Grid item xs={12}>
                      <Typography> {t("signUp.companyCertificate")}</Typography>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <UploadButtons
                          handleChange={(e) =>
                            handleCertificateUpload(e, setFieldValue)
                          }
                          type="Button"
                        />
                        {certificateUpload ? (
                          <div>Loading...</div>
                        ) : (
                          <>
                            <a
                              href={`https://api.ebazarak.com/api/File/GetCertificate?CertificateName=${values.companyCertificate}`}
                              download
                              target="_blank"
                            >
                              {values.certificateName}
                            </a>
                            {/* <div>{values.certificateName}</div> */}
                            {/* <DownloadLink
                            label="Download"
                            filename={values.certificateName}
                            exportFile={() =>
                              Promise.resolve(`${values.companyCertificate}`)
                            }
                          /> */}
                          </>
                        )}
                      </div>
                    </Grid>
                  )}
                  {role !== "Buyer" && (
                    <>
                      <Grid item xs={10}>
                        <Typography>{t("signUp.socialMediaTitle")}</Typography>
                      </Grid>
                      <Grid item container>
                        <FieldArray name="socialMediaLinks">
                          {({ push }) => (
                            <>
                              {values.socialMediaLinks.map((_, indx) => (
                                <Grid
                                  item
                                  container
                                  spacing={2}
                                  justify="space-between"
                                  alignItems="center"
                                  xs={12}
                                  md={10}
                                  key={indx}
                                >
                                  <Grid item xs={5} sm={3}>
                                    <Field
                                      component={Select}
                                      variant="outlined"
                                      name={`socialMediaLinks[${indx}].socialMediaType`}
                                      fullWidth
                                      size="small"
                                      type="number"
                                      style={{ height: "3.5rem" }}
                                    >
                                      {socialMediaArray.map((item, index) => (
                                        <MenuItem
                                          value={item.value}
                                          key={index}
                                        >
                                          {item.icon}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={7} sm={9}>
                                    <FormikField
                                      variant="outlined"
                                      fullWidth
                                      label={t("signUp.socialMediaLink")}
                                      name={`socialMediaLinks[${indx}].url`}
                                      type="text"
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                              <Grid item xs={2}>
                                <IconButton
                                  onClick={() =>
                                    push({ url: "", socialMediaType: "" })
                                  }
                                >
                                  <AddCircleOutlineRoundedIcon
                                    color="secondary"
                                    style={{
                                      fontSize: "50PX",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              {localStorage.userRole === "Seller" ? (
                                <>
                                  <Grid item xs={12}>
                                    <Typography>{t("Zoommeet")}:</Typography>
                                  </Grid>
                                  <Grid item xs={12} lg={11}>
                                    <FormikField
                                      name="zoomLink"
                                      label={t("signUp.zoomLink")}
                                    />
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </>
                  )}
                  {role === "Provider" ? (
                    <Grid item>
                      <Typography>{t("signUp.cashTitle")}</Typography>
                      <Field
                        name="acceptCashDelivery"
                        type="checkbox"
                        component={CheckboxWithLabel}
                        color="primary"
                        Label={{
                          label: t("signUp.acceptCashDelivery"),

                          className: errors.acceptCashDelivery
                            ? classes.errorColor
                            : undefined,
                        }}
                        // FormHelperText={
                        //   errors.acceptedTerms
                        //     ? 'you should accept terms'
                        //     : ''
                        // }
                      />
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {/* {t('signUp.save')} */}
                      {t("buttons.save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log(state.addressReducer.countries);
  return {
    countries: state.addressReducer.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getAllCountries()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
