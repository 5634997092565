import React, { useEffect } from 'react'
import PayButton from '../../PayButton'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import PaypalButtons from '../../PaypalButtons'
import { confirmBazarPaymentAsync } from './../../../redux/bazar/api'
import { setSnackbar } from '../../../redux/Message/actions'
import { useTranslation } from 'react-i18next'

const StepFivePayment = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const paymentTotal = {
    ProPlan: 1000,
    ContactUs: 2000,
  }
  const selectedPackage = useSelector(
    (state) => state.bazarReducer.bazarInfo.packagePlan,
  )
  const bazarId = useSelector((state) => state.bazarReducer.bazarInfo.id)
  console.log(bazarId)
  // dispatch(setSnackbar(true, 'success', 'Payment Done Successfuly!'))

  const handlePaymentSuccess = async (data) => {
    try {
      console.log('Payment Done Succesfuly')

      const paymentObj = {
        orderId: data.orderID,
        payerId: data.payerID,
        id: bazarId,
        userId: localStorage.userId,
      }

      console.log(paymentObj)
      await confirmBazarPaymentAsync(paymentObj)
      dispatch(setSnackbar(true, 'success', t('messages.successPayment')))
      // setRefresh(refresh + 1)
    } catch (e) {
      console.log(e)
    }
  }
  console.log('selectedPackage', selectedPackage)

  const total = '1000'
  const token = localStorage.token

  return (
    <>
      {/* {selectedPackage && ( */}
      <Box width={'100%'} height="49vh">
        <PaypalButtons total={total} onSuccess={handlePaymentSuccess} />
      </Box>
      {/* )} */}
    </>
  )
}

export default StepFivePayment
