import { Button, Grid, makeStyles, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormikSelect from "./../FormikSelect/index";
import FormikField from "./../FormikField/index";
import { connect } from "react-redux";
import { getAllCountries } from "../../redux/address/actions";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";

export const useStyles = makeStyles(() => ({
  f: {
    display: "none",
  },
  v: {
    display: "block",
    margin: "2%",
    height: "fit-content",
    width: "fit-content",
    ["@media (max-width: 350px)"]: {
      maxWidth: "290px",
    },
  },
  root: {
    maxWidth: "100%",
    padding: "3%",
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "#FCFCFC",
  },
  back: {
    backgroundColor: "#FCFCFC",
    marginBottom: "1rem",
    width: "100%",
    height: "fit-content",
    ["@media (max-width:780px)"]: {
      width: "100%",
    },
  },
}));
const CheckForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initialValues, handleSubmit, validationSchema, flag } = props;
  useEffect(() => {
    props.getCountries();
  }, []);
  const getCountryCities = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };
  const getCountryName = (countryId) => {
    const country = props.countries.find((country) => country.id === countryId);
    return localStorage.i18nextLng === "en" ? country.name : country.arabicName;
  };
  const selectFirstCity = (values) => {
    let city = getCountryCities(values.countryId)[0];
  };
  const [phone, setPhone] = useState("");
  const [phoneWhats, setPhoneWhats] = useState("");
  return (
    <>
      <Grid
        container
        item
        lg={props.buyer ? 12 : 6}
        xs={12}
        className={flag ? `${classes.f} ${classes.v}` : `${classes.f}`}
      >
        <div className={classes.back}>
          <Card className={classes.root}>{t("cartPage.shipmentAddress")}</Card>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            console.log(initialValues, values);
            setPhone(values.phoneNumber);
            setPhoneWhats(values.whatsNumber);
            return (
              <Form autoComplete="false">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name={"firstName"}
                      label={t("signUp." + "firstName")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name={"lastName"}
                      label={t("signUp." + "lastName")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="countryId"
                      label={t("signUp.country")}
                      items={props.countries}
                      value={values.countryId}
                      onChange={(e) => {
                        setFieldValue("countryId", e.target.value);
                        setFieldValue(
                          "countryName",
                          getCountryName(e.target.value)
                        );
                        {
                          console.log(e.target.value, "countryName");
                        }
                        setFieldValue("cityId", "");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikSelect
                      name="cityId"
                      label={t("signUp.city")}
                      items={getCountryCities(values.countryId)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField name="street" label={t("signUp.street")} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      name="postalCode"
                      label={t("signUp.postalCode")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      required
                      name="phoneNumber"
                      label={t("signUp.phoneNumber")}
                    />
                    {/* <PhoneInput
                      placeholder={t("phoneNumber")}
                      value={phone}
                      onChange={(value) => {
                        let x = formatPhoneNumberIntl(value);
                        setPhone(x);
                        values.phoneNumber = x;
                        console.log("values.phoneNumber", values.phoneNumber);
                      }}
                      rules={{
                        required: true,
                        validate: isValidPhoneNumber,
                      }}
                      style={{ direction: "ltr" }}
                    /> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikField
                      required
                      name="whatsNumber"
                      label={t("signUp.phoneWhats")}
                    />
                    {/* <PhoneInput
                      placeholder={t("secondnumber")}
                      value={phoneWhats}
                      onChange={(value) => {
                        let x = formatPhoneNumberIntl(value);
                        setPhoneWhats(x);
                        values.whatsNumber = x;
                      }}
                      rules={{
                        required: true,
                        validate: isValidPhoneNumber,
                      }}
                      style={{ direction: "ltr" }}
                    /> */}
                  </Grid>
                  <Grid container item xs={12} justify="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ width: "40%", padding: "1%" }}
                      id="neonShadow"
                    >
                      {t("buttons.submit")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    countries: state.addressReducer.countries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getAllCountries()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckForm);
