import packageEnum from "../../../enums/Package";

export const packages = [
  {
    title: "Free",
    content: {
      price: "0 JD",
      description: "Enjoy an umlimited numbers of bazars",
      max: "Maximum 10 Sellers",
    },
    type: "FreePlan",
    id: packageEnum.free,
  },
  {
    title: "Pro",
    content: {
      price: "1000 JD",
      description:
        "Enjoy an umlimited numbers of bazars with advanced analytics",
      max: "Maximum 50 Sellers",
    },
    type: "ProPlan",
    id: packageEnum.pro,
  },
  {
    title: "Contact Us",
    content: {
      price: "?",
      description: "If you want to invite more than 50 Sellers, Contact us",
      max: "More than 50 Sellers",
    },
    type: "ContactUsPlan",
    id: packageEnum.contactUs,
  },
];
