import React, { useEffect, useState } from "react";
import { ProviderDrawerItems } from "./constants";
import SideBarDrawer from "./../../components/sideBarDrawer/index";
import ProviderDashboardBazarsSection from "./../../components/ProviderDashboardBazarsSection/index";
import { getProviderBazarsAsync } from "../../redux/serviceProvider/api";
import EmptyBazar from "./../../components/EmptyBazars/index";
import { Grid } from "@material-ui/core";
import { handleLogout } from "../../utils/setLocalStorage";

const ServiceProviderDashBoard = (props) => {
  const providerId = localStorage.userId;
  const [bazars, setBazars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusArrs, setStatusArrs] = useState({
    Active: [],
    CommingSoon: [],
    Finished: [],
  });

  useEffect(() => {
    let newStatusArrs = {
      Active: [],
      CommingSoon: [],
      Finished: [],
    };
    setLoading(true);
    const fetchData = async () => {
      let response = "";
      try {
        response = await getProviderBazarsAsync(providerId);
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error?.response?.status === 401) {
          handleLogout();
          window.location.href = "/login";
        }
      }
      console.log(response, "response");
      const bazars = response.data;
      setBazars(response.data);
      bazars?.forEach((bazar) => {
        switch (bazar.status) {
          case "Active":
            newStatusArrs.Active.push(bazar);
            break;
          case "Finished":
            newStatusArrs.Finished.push(bazar);
            break;
          case "CommingSoon":
            newStatusArrs.CommingSoon.push(bazar);
            break;
          default:
            newStatusArrs = { ...statusArrs };
            break;
        }
      });
      console.log(newStatusArrs);
      setLoading(false);

      setStatusArrs(newStatusArrs);
    };
    fetchData();
  }, []);

  return (
    <>
      <SideBarDrawer
        DrawerItems={ProviderDrawerItems}
        role="Provider"
        handleDrawerToggle={props.handleDrawerToggle}
        mobileOpen={props.mobileOpen}
      >
        {bazars.length === 0 ? (
          <Grid container justify="center" item xs={12}>
            <EmptyBazar />
          </Grid>
        ) : (
          <div>
            <ProviderDashboardBazarsSection
              bazarStatus="Active"
              bazarsArr={statusArrs?.Active}
              loading={loading}
            />
            <ProviderDashboardBazarsSection
              bazarStatus="CommingSoon"
              bazarsArr={statusArrs?.CommingSoon}
              loading={loading}
            />
            <ProviderDashboardBazarsSection
              bazarStatus="Finished"
              bazarsArr={statusArrs?.Finished}
              loading={loading}
            />
          </div>
        )}
      </SideBarDrawer>
    </>
  );
};

export default ServiceProviderDashBoard;
