import React, { useState, useEffect } from "react";
import axios from "axios";
import BazarCardsBuyer from "../../components/BazarCardsBuyer";
import Header from "../../components/Header";
import BasicPagination from "../../components/Pagination/page";
import { useParams } from "react-router";
import { useRef } from "react";
import { Box, Container } from "@material-ui/core/index";
import { Button, Divider, NativeSelect } from "@material-ui/core";
import ControlledAppModal from "../../components/ControlledAppModal";
import BookBooth from "../../components/BookBooth/index";
import { getAllCountries } from "../../redux/address/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import MyDate from "../../components/Date/index";
import { Grid, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { setSnackbar } from "../../redux/Message/actions";
import SearchIcon from "@material-ui/icons/Search";
import Card from "../../components/BoothCard/boothcard";
import CircularProgress from "@material-ui/core/CircularProgress";
import BreadcrambBazar from "../../components/BreadcrumbBazar";
import Product from "../../components/BoothCard/CardBooth";
import HeaderAli from "../../components/NewHeaderSec/index";
import MobileMenu from "./../../components/MobileMenu/index";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0rem",
    marginTop: "4.3rem",
  },
  menuButton: {
    color: "black",
    display: "none",
    ["@media (max-width:600px)"]: {
      display: "flex",
    },
  },
  dis: {
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  width230: {
    ["@media (max-width:700px)"]: {
      minWidth: "248px",
      padding: "1px 6px",
    },
    ["@media (max-width:320px)"]: {
      minWidth: "230px",
      padding: "1px 6px",
    },
  },
  width205: {
    ["@media (max-width:700px)"]: {
      minWidth: "248px",
      padding: "1px 6px",
    },
    ["@media (max-width:320px)"]: {
      minWidth: "205px",
      padding: "1px 6px",
    },
  },
}));
const BazarPage = (props) => {
  const { t } = useTranslation();
  const CategoryRef = useRef();
  const PageRef = useRef();
  const classes = useStyles();
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  const role = localStorage.userRole;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [boothes, setBoothes] = useState([]);
  const [allBoothsProducts, setAllBoothsProducts] = useState([]);
  const [filteredboothes, setFilteredBoothes] = useState([]);
  const ParamsPag = localStorage.getItem("page");
  const ParamsBoothPag = localStorage.getItem("boothPage");

  const [currentPage, setCurrentPage] = useState(
    ParamsBoothPag || ParamsPag || 1
  );
  const [e, setE] = useState({});
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [openJoinBazar, setOpenJoinBazar] = useState(false);
  const [currentDispaly, setCurrentDisplay] = useState(
    ParamsBoothPag ? "booths" : ParamsPag ? "products" : "booths"
  ); //or booths
  const [filter, setFilter] = useState(
    localStorage.getItem("filter")
      ? localStorage.getItem("filter")
      : localStorage.getItem("CatId")
      ? localStorage.getItem("CatId")
      : 0
  );
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState(300);
  const [bazarHome, setBazarHome] = useState({
    name: "",
    county: { name: "", arabicName: "" },
    organizer: { firstName: "", lastName: "" },
    categories: [{ id: 0, name: "", arabicName: "" }],
    socialMediaLinks: [],
  });
  const id = useParams().id;
  const bazarid = useParams().bazarId || id;
  const pagesize = 20;
  let [currency, setCurrency] = useState("");
  let cat = [
    { id: 0, name: "All Categories", arabicName: "كل الفئات" },
    ...bazarHome?.categories,
  ];
  const countries = useSelector((state) => state.addressReducer.countries);

  const handleSearchChange = (event, value) => {
    fetchData(currentDispaly, 1, filter, event.target.value);
    setCurrentPage(1);
    setSearch(event.target.value);
  };

  const fetchData = (
    selectedDisplay = currentDispaly,
    pageIndex = currentPage,
    categoryId = filter,
    searchVal = search,
    maxVal = maxPrice
  ) => {
    debugger;
    setIsLoading(true);
    let url = "";
    url =
      selectedDisplay === "booths"
        ? `Buyer/GetBazarBooths?BazarId=${bazarid}&CategoryId=${categoryId}&ProductName=${searchVal}&PageSize=${pagesize}&PageIndex=${pageIndex}`
        : `Buyer/GetProductsForBuyer?BazarId=${bazarid}&CategoryId=${categoryId}&ProductName=${searchVal}&Min=0&Max=${maxVal}&PageSize=${pagesize}&PageIndex=${pageIndex}`;

    axios
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setBoothes(response.data.data);
        console.log(
          response.data.data,
          response.data.data[0].currency,
          "setBoothes"
        );
        setCurrency(response.data.data[0].currency);
        setCount(response.data.count);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getAllCountries());
    debugger;
    if (currentPage > Math.ceil(parseInt(count) / pagesize))
      fetchData(currentDispaly, 1, filter);
    fetchData(currentDispaly, currentPage, filter);
  }, []);

  useEffect(() => {
    axios.get(`Buyer/GetBazarHome?id=${bazarid}`).then((response) => {
      setBazarHome(response.data);
      // debugger;
      // if (response.data.status === "CommingSoon") {
      //   setTimeout(() => {
      //     setOpenJoinBazar(true);
      //   }, 1400);
      // }
    });
    handlePageChange(e, +currentPage);
    console.log(e, +currentPage, "oppo");
  }, []);

  const scrollPage = () => PageRef?.current?.scrollIntoView();
  const handlePageChange = (e, newPage) => {
    setE(e);
    setCurrentPage(newPage);
    fetchData(currentDispaly, newPage, filter);
    debugger;
    setTimeout(() => scrollPage(), 1000);
  };
  const handleBtnFilter = (categoryId) => {
    setFilter(categoryId.target.value);
    setCurrentPage(1);
    fetchData(currentDispaly, 1, categoryId.target.value);
    setTimeout(() => scroll(), 100);
  };

  const handleClickOpen = (bazarID) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const checkSellerAlreadyHasBooth = async () => {
    try {
      const response = await axios.get(
        `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${bazarid}`
      );
      if (response.data.succeeded) {
        handleClickOpen();
      } else if (!response.data.succeeded) {
        dispatch(setSnackbar(true, "error", response.data.message));
      }
    } catch (error) {
      console.log("err", error.message);
    }
  };
  const handleOpenJoinBazarPopup = () => {
    setOpenJoinBazar(true);
  };
  const handleCloseJoinBazarPopup = () => {
    setOpenJoinBazar(false);
  };

  const changeCurrentDisplayHandler = (e) => {
    if (e.target.value === "products") {
      window.localStorage.removeItem("boothPage");
    }
    if (e.target.value === "booths") {
      window.localStorage.removeItem("page");
      localStorage.setItem("boothPage", currentPage);
    }
    setCurrentPage(1);
    setCurrentDisplay(e.target.value);
    fetchData(e.target.value, 1);
  };
  const handlePriceFilter = (e) => {
    setCurrentPage(1);
    setMaxPrice(e.target.value);
    fetchData(currentDispaly, 1, filter, search, e.target.value);
  };
  const scroll = () => CategoryRef?.current?.scrollIntoView();

  let srcimg;
  if (bazarHome.status === "CommingSoon") {
    srcimg = "/assets/images/icons/ComingSoon.svg";
  } else if (bazarHome.status === "Active")
    srcimg = "/assets/images/icons/Active Now- Without Bg.svg";
  else if (bazarHome.status === "Finished")
    srcimg = "/assets/images/icons/Finished.svg";
  else if (bazarHome.status === "")
    srcimg = "/assets/images/bazars/big_bazar1.jpg";

  let logoimg;
  if (bazarHome.status === "CommingSoon") {
    logoimg = "/assets/images/icons/commingSoonLogo.jpg";
  } else if (bazarHome.status === "Active")
    logoimg = "/assets/images/icons/ActiveLogo.jpg";
  else if (bazarHome.imageProfile === null || bazarHome.status === "Finished")
    logoimg = "/assets/images/icons/Finished.jpg";
  else if (bazarHome.imageProfile === "")
    logoimg = "/assets/images/buyer/bazarlogo.png";

  return (
    <>
      {localStorage.setItem("filter", filter)}
      {console.log(bazarHome, "bazarHome")}
      {!props.modal ? (
        <Header
          user={user}
          home={true}
          srcCover={
            bazarHome?.coverPhoto
              ? `${localStorage.imagePath}${bazarHome.coverPhoto}`
              : srcimg
          }
          imgcover={bazarHome?.coverPhoto ? true : false}
        />
      ) : (
        ""
      )}

      <Container
        style={{ padding: "0" }}
        className={props.modal ? "" : classes.root}
        ref={PageRef}
      >
        <MobileMenu
          className={classes.menuButton}
          srcCover={
            bazarHome?.imageProfile
              ? `${localStorage.imagePath}${bazarHome.imageProfile}`
              : srcimg
          }
          bazarNam={bazarHome?.name}
          organizer={bazarHome?.organizer}
          country={
            localStorage.i18nextLng === "en"
              ? bazarHome?.county?.name
              : bazarHome?.county?.arabicName
          }
          description={bazarHome?.description}
          numofshops={bazarHome?.shopNo}
          viewsN={bazarHome?.viewsNum}
          srcLogo={
            bazarHome?.imageProfile
              ? `${localStorage.imagePath}${bazarHome.imageProfile}`
              : logoimg
          }
          imglogo={bazarHome?.imageProfile ? true : false}
          socialMediaLinks={bazarHome?.socialMediaLinks}
          status={bazarHome?.status}
          bazarStatus={bazarHome?.bazarStatus}
          start={bazarHome?.startDate}
          end={bazarHome?.endDate}
        />
      </Container>
      <Container className={classes.dis}>
        <BreadcrambBazar bazarNam={bazarHome?.name} />
      </Container>
      <>
        <HeaderAli
          disp={classes.dis}
          srcCover={
            bazarHome?.imageProfile
              ? `${localStorage.imagePath}${bazarHome.imageProfile}`
              : srcimg
          }
          bazarNam={bazarHome?.name}
          organizer={bazarHome?.organizer}
          country={
            localStorage.i18nextLng === "en"
              ? bazarHome?.county?.name
              : bazarHome?.county?.arabicName
          }
          description={bazarHome?.description}
          numofshops={bazarHome?.shopNo}
          viewsN={bazarHome?.viewsNum}
          srcLogo={
            bazarHome?.imageProfile
              ? `${localStorage.imagePath}${bazarHome.imageProfile}`
              : logoimg
          }
          imglogo={bazarHome?.imageProfile ? true : false}
          socialMediaLinks={bazarHome?.socialMediaLinks}
          status={bazarHome?.status}
          bazarStatus={bazarHome?.bazarStatus}
          start={bazarHome?.startDate}
          end={bazarHome?.endDate}
        />
        {/* <section className="bazars-cards"> */}
        <Container>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  border: "1px solid #F8B334",
                  backgroundColor: "#fff6ea",
                  width: "82%",
                  minHeight: "5rem",
                }}
              >
                <Grid item>
                  <TextField
                    onChange={handleSearchChange}
                    placeholder={t("lookingfor")}
                    value={search}
                    id="input-with-icon-textfield"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: " 0 5%", visibility: "hidden" }}
                />
                <Grid item>
                  {/* <FormControl> */}
                  <NativeSelect
                    className={classes.width230}
                    defaultValue={filter}
                    value={filter}
                    onChange={handleBtnFilter}
                  >
                    {cat.map((itm, i) => (
                      <option key={i} value={itm.id}>
                        {localStorage.i18nextLng === "en"
                          ? itm.name
                          : itm.arabicName}
                      </option>
                    ))}
                  </NativeSelect>
                  {/* </FormControl> */}
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: " 0 5%", visibility: "hidden" }}
                />
                <Grid item>
                  {/* <FormControl> */}
                  <NativeSelect
                    className={classes.width230}
                    defaultValue="booths"
                    value={currentDispaly}
                    inputProps={{
                      name: "age",
                      id: "uncontrolled-native",
                    }}
                    onChange={changeCurrentDisplayHandler}
                  >
                    <option value="products">{t("products")}</option>
                    <option value="booths">{t("booths")}</option>
                  </NativeSelect>
                  {/* </FormControl> */}
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: " 0 5%", visibility: "hidden" }}
                />
                {currentDispaly === "products" ? (
                  <>
                    <Grid item>
                      {/* <FormControl> */}
                      <NativeSelect
                        className={classes.width230}
                        defaultValue={maxPrice}
                        inputProps={{
                          name: "age",
                          id: "uncontrolled-native",
                        }}
                        value={maxPrice}
                        onChange={handlePriceFilter}
                      >
                        <option value={20}>0-20 {currency}</option>
                        <option value={50}>0-50 {currency}</option>
                        <option value={100}>0-100 {currency}</option>
                        <option value={150}>0-150 {currency}</option>
                        <option value={200}>0-200 {currency}</option>
                        <option value={250}>0-250 {currency}</option>
                        <option value={300}>0-300 {currency}</option>
                      </NativeSelect>
                      {/* </FormControl> */}
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ margin: " 0 5%", visibility: "hidden" }}
                    />
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </div>

            {isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "50px 0",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : boothes?.length > 0 ? (
              currentDispaly === "booths" ? (
                <>
                  {localStorage.setItem("boothPage", currentPage)}
                  <BazarCardsBuyer
                    currentPage={currentPage}
                    description={bazarHome?.description}
                    boothes={boothes}
                    CategoryRef={CategoryRef}
                    modalView={props.modal}
                  />
                </>
              ) : (
                // <div className="row">
                <div className="cardsContainer">
                  {boothes.map((item) => {
                    let x =
                      item?.productImage?.length > 0
                        ? `${localStorage.imagePath}${item?.productImage[0]?.url}`
                        : "/assets/images/product.png";
                    return (
                      <Product
                        currentPage={currentPage}
                        key={item.id}
                        offerPrice={item.offerPrice}
                        startOffer={item.startOffer}
                        endOffer={item.endOffer}
                        price={item.price}
                        currency={item.currency}
                        nam={item.name}
                        rate={item.averageRate}
                        productId={item.id}
                        srcProductImg={x}
                        isLoggedIn={localStorage.token ? true : false}
                        boothName={item.boothName}
                        boothLogo={item.boothLogo}
                        boothId={item.boothId}
                      />
                    );
                  })}
                </div>
              )
            ) : (
              <div
                style={{
                  padding: "2rem",
                  margin: "2rem",
                  textAlign: "center",
                  height: "2rem",
                }}
              >
                {currentDispaly === "booths" ? t("noBooths") : t("noProducts")}
              </div>
            )}
          </>
        </Container>
        {/* </section> */}
      </>
      {pagesize < count && (
        <BasicPagination
          pagesize={pagesize}
          count={count}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      )}
      <div style={{ marginBottom: "100px" }}></div>
      <ControlledAppModal open={open} handleClose={handleClose}>
        <div style={{ maxWidth: "600px" }}>
          <BookBooth
            bazarId={bazarHome?.id}
            countries={countries}
            // handleSubmit={handleBookBoothSubmit}
            handleCloseBookBoothModal={handleClose}
          />
        </div>
      </ControlledAppModal>
    </>
  );
};

export default BazarPage;
