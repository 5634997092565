import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'

export default function ActiveLastBreadcrumb() {
  const { t } = useTranslation()
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/dashboard/Organizer">
        {t('organizerDashboard.bazars')}
      </Link>
      <Typography color="textPrimary">{t('bazarHome.bazarInfo')}</Typography>
    </Breadcrumbs>
  )
}
