import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Button,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Select, TextField } from "formik-material-ui";
import React, { useState } from "react";
import axios from "axios";
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import differenceInCalendarDays from '@bit/date-fns.date-fns.difference-in-calendar-days'

import { useTranslation } from "react-i18next";
import { TimePicker, DatePicker } from "formik-material-ui-pickers";
import FormikField from "./../../FormikField/index";
import * as Yup from "yup";
import { socialMediaArray } from "../../../containers/App/constants";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import CategoriesDropDown from "./../../CategoriesDropDown";
import { differenceInDays } from "date-fns";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormikSelect from "./../../FormikSelect/index";
import { useEffect } from "react";
import { getAllCountries } from "../../../redux/address/actions";
import UploadButtons from "./../../UploadButtons/index";
import { uploadImageAsync } from "./../../../service/image";
const useStyles = makeStyles((theme) => ({
  nextArrow: {
    color: theme.palette.primary.dark,
    fontSize: "20px",
  },
  skipArrow: {
    color: theme.palette.text,
    fontSize: "20px",
  },
  darkPrimary: { color: theme.palette.primary.dark },
  mr: {
    marginRight: "1rem",
  },
}));

const StepOneBasicInfo = (props) => {
  const { t } = useTranslation();
  const currentLang = localStorage.i18nextLng;
  const classes = useStyles({ currentLang });
  const dispatch = useDispatch();
  const [bazarStatusTypes, setBazarStatusTypes] = useState([]);
  const { handleSubmit, bazarInfo } = props;
  const currencyTypes = [
    {
      id: 0,
      name: "USD",
    },
    {
      id: 1,
      name: "AED",
    },
    {
      id: 2,
      name: "JOD",
    },
  ];
  console.log("bazarInfo.bazarStatus", bazarInfo);
  const supposedEndDate = new Date();
  supposedEndDate.setDate(supposedEndDate.getDate() + 7);
  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const countries = useSelector((state) => state.addressReducer.countries);

  const initialValues = {
    bazarStatus: bazarInfo.bazarStatus || "",
    name: bazarInfo.name || "",
    description: bazarInfo.description || "",
    countryId: bazarInfo.county.id || "",
    cityId: bazarInfo?.city?.id || "",
    currancy: bazarInfo?.currancy || 0,
    // startDate: bazarInfo.startDate ? new Date(bazarInfo.startDate) : new Date(),
    startDate: bazarInfo.startDate
      ? new Date(bazarInfo.startDate + "Z")
      : new Date(),
    endDate: bazarInfo.endDate ? new Date(bazarInfo.endDate + "Z") : new Date(),
    rulesAndConditions: bazarInfo.rulesAndConditions || "",
    organizerId: localStorage.userId,
    // bazarDuration: bazarInfo.startDate
    //   ? differenceInDays(
    //       new Date(bazarInfo.endDate),
    //       new Date(bazarInfo.startDate)
    //     )
    //   : "",
    categories: bazarInfo.categories ? bazarInfo.categories : [],
    socialMediaLinks: bazarInfo.socialMediaLinks.map((socialLink) => ({
      url: socialLink.url,
      socialMediaType: socialLink.type,
    })) || [{ url: "", socialMediaType: 0 }],
    // owner: [{ type: 'shirt' }, { type: 'mug' }],
  };
  const validationSchema = Yup.object({
    bazarStatus: Yup.string().required(t("bazarstatusRequired")),
    name: Yup.string().required(t("validation.bazarNameRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    // cityId: Yup.string().required(t('validation.cityRequired')),
    // bazarDuration: Yup.number(t("validation.bazarDuration"))
    //   .min(1, t("validation.minDuration"))
    //   .max(60, t("validation.maxDuration"))
    //   .required(t("validation.durationRequired")),

    startDate: Yup.date().required(t("validation.startDateAndTime")),
    endDate: Yup.date().required(t("validation.startDateAndTime")),

    categories: Yup.array().min(1).required(),
  });
  const [rulesUpload, setRulesUpload] = useState(false);
  const getCountryCities = (countryId) => {
    const country = countries.find((country) => country.id === countryId);
    return country ? country.cities : [];
  };
  const getCountryName = (countryId) => {
    const country = countries.find((country) => country.id === countryId);
    return localStorage.i18nextLng === "en" ? country.name : country.arabicName;
  };
  useEffect(() => {
    axios
      .get(`Bazar/BazarStatusTypes`)
      .then((response) => {
        setBazarStatusTypes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleRulesUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const name = file.name;
    console.log(file);
    setRulesUpload(true);

    try {
      const formDate = new FormData();
      formDate.set(name, file);
      const imageNewName = await uploadImageAsync(formDate);
      setFieldValue("rulesAndConditions", name);
      setRulesUpload(false);
    } catch (error) {
      setRulesUpload(false);
      console.log(error);
      console.log(error?.response?.data);
    }
  };
  return (
    <Grid container style={{ marginBottom: "35px" }}>
      <Grid item xs={12} md={6}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            validateForm,
            isSubmitting,
            setFieldValue,
            touched,
            handleBlur,
          }) => {
            // console.log(touched)
            console.log(values, initialValues, "ere");
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form>
                  {/* <Grid container item xs={12} spacing={1} wrap="nowrap"> */}
                  {/* <Grid item xs={1}></Grid> */}
                  <Grid container item xs={12} spacing={3} alignItems="center">
                    <Grid item xs={12}>
                      <FormikField
                        name="name"
                        label={t("createBazar.step1Form.bazarName")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CategoriesDropDown
                        setFieldValue={setFieldValue}
                        error={
                          touched.categories && errors.categories
                            ? errors.categories
                            : null
                        }
                        value={values.categories}
                        blur={handleBlur}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <CountriesDropDown
                        name="countryId"
                        label={t("createBazar.step1Form.country")}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        name="countryId"
                        label={t("signUp.country")}
                        items={countries}
                        value={values.countryId}
                        onChange={(e) => {
                          setFieldValue("countryId", e.target.value);
                          setFieldValue(
                            "countryName",
                            getCountryName(e.target.value)
                          );
                          setFieldValue("cityId", "");
                          // selectFirstCity(values)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikSelect
                        name="cityId"
                        label={t("signUp.city")}
                        items={getCountryCities(values.countryId)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikSelect
                        name="currancy"
                        label={t("signUp.currency")}
                        items={currencyTypes}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      <Field
                        disabled
                        component={TextField}
                        name="currency"
                        variant="outlined"
                        label="Currency"
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <Field
                        component={DatePicker}
                        name="startDate"
                        label={t("createBazar.step1Form.startDate")}
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                        }}
                        minDate={new Date()}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Field
                        component={TimePicker}
                        name="startDate"
                        label={t("createBazar.step1Form.at")}
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={DatePicker}
                        name="endDate"
                        label={t("createBazar.step1Form.endDate")}
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                        }}
                        minDate={new Date()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TimePicker}
                        name="endDate"
                        label={t("createBazar.step1Form.at")}
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ margin: ".5rem 0" }}>
                        {t("bazarrStatus")}:
                      </Typography>

                      <div role="group" aria-labelledby="my-radio-group">
                        {bazarStatusTypes.length > 0 ? (
                          <>
                            {bazarStatusTypes
                              .filter((item) => item.name !== "NotSelected")
                              .map((itm) => (
                                <label
                                  className={
                                    localStorage.i18nextLng === "en"
                                      ? classes.mr
                                      : ""
                                  }
                                  key={itm.id}
                                >
                                  <Field
                                    error={errors.bazarStatus}
                                    type="radio"
                                    name="bazarStatus"
                                    value={"" + itm.name}
                                  />
                                  {itm.name}
                                </label>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <ErrorMessage
                        name="bazarStatus"
                        render={(msg) => (
                          <div
                            style={{
                              color: "#f44336",
                              fontSize: "0.75rem",
                              margin: "1px 14px",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      />
                    </Grid>
                    {/* {errors.bazarStatus && touched.bazarStatus ? (
                        <p
                          style={{
                            color: "#f44336",
                            fontSize: "0.75rem",
                            margin: "1px 14px",
                          }}
                        >
                          {errors.bazarStatus}
                        </p>
                      ) : (
                        ""
                      )} */}

                    {console.log(bazarStatusTypes, errors.bazarStatus, "tt")}
                    {/* <Grid item xs={12} md={6}>
                      <Field
                        component={DatePicker}
                        name="endDate"
                        label="End Date"
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TimePicker}
                        name="endDate"
                        label="At"
                        inputVariant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}
                    {/* <Grid
                      item
                      xs={12}
                      style={{ margin: "0", padding: "12px 12px 0" }}
                    >
                      <Typography color="secondary">
                        {t("createBazar.step1Form.Note")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ margin: "0", padding: "0 12px" }}
                    >
                      <FormikField
                        name="bazarDuration"
                        label={t("createBazar.step1Form.BazarDuration")}
                        type="number"
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography> {t("signUp.rulesAndConditions")}</Typography>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        <UploadButtons
                          handleChange={(e) =>
                            handleRulesUpload(e, setFieldValue)
                          }
                          type="Button"
                        />
                        {rulesUpload ? (
                          <div>Loading...</div>
                        ) : (
                          <>
                            <a
                              href={`https://api.ebazarak.com/api/File/GetCertificate?CertificateName=${values.rulesAndConditions}`}
                              download
                              target="_blank"
                            >
                              {values.rulesAndConditions}
                            </a>
                          </>
                        )}
                      </div>
                    </Grid>
                    <Grid item container>
                      <FieldArray name="socialMediaLinks">
                        {({ push }) => (
                          <>
                            {values.socialMediaLinks.map((_, indx) => (
                              <Grid
                                item
                                container
                                spacing={2}
                                justify="space-between"
                                alignItems="center"
                                xs={12}
                                md={10}
                                key={indx}
                              >
                                <Grid item xs={5} sm={3}>
                                  <Field
                                    component={Select}
                                    variant="outlined"
                                    name={`socialMediaLinks[${indx}].socialMediaType`}
                                    fullWidth
                                    size="small"
                                    type="number"
                                  >
                                    {socialMediaArray.map((item, index) => (
                                      <MenuItem value={item.value} key={index}>
                                        {item.icon}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </Grid>
                                <Grid item xs={7} sm={9}>
                                  <FormikField
                                    variant="outlined"
                                    fullWidth
                                    label={t("signUp.socialMediaLink")}
                                    name={`socialMediaLinks[${indx}].url`}
                                    type="text"
                                  />
                                </Grid>
                              </Grid>
                            ))}
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() =>
                                  push({ url: "", socialMediaType: "" })
                                }
                              >
                                {/* <Button
                                onClick={() =>
                                  push({ url: '', socialMediaType: '' })
                                }
                              >
                                ooo
                              </Button> */}
                                <AddCircleOutlineRoundedIcon
                                  color="secondary"
                                  style={{
                                    fontSize: "50PX",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={5}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        type="text"
                        name="description"
                        label={t("createBazar.step1Form.description")}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justify="flex-end"
                    style={{ marginTop: "10px" }}
                  >
                    <IconButton type="submit" className={classes.nextArrow}>
                      {t("buttons.next")}
                      {localStorage.i18nextLng === "en" ? (
                        <ArrowForwardIcon
                          className={
                            classes.nextArrow + " " + classes.arrowDirection
                          }
                        ></ArrowForwardIcon>
                      ) : (
                        ""
                      )}
                    </IconButton>
                  </Grid>

                  {/* </Grid> */}
                </Form>
              </MuiPickersUtilsProvider>
            );
          }}
          {/* {activeStep === 1 && ( */}

          {/* )} */}
        </Formik>
      </Grid>

      <Grid
        item
        md={6}
        container
        display="flex"
        flexDirction="column"
        justify="space-between"
        alignItems="flex-end"
      >
        <Hidden only={["xs", "sm"]}>
          {/* <Grid item md={6} xs={false} container alignItems="center"> */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            // height="100px"
          >
            <img
              src={`/assets/images/CreateBazarSteps/stepOne.svg`}
              width="100%"
            />
          </Box>
          {/* </Grid> */}
        </Hidden>
        <Grid item container justify="flex-end" style={{ marginTop: "9px" }}>
          <Button onClick={() => props.history.push("/dashBoard/Organizer")}>
            <Box display="flex" color="primary">
              <Typography className={classes.skipArrow} color="secondary">
                {t("createBazar.skip")}
              </Typography>
              {localStorage.i18nextLng === "en" ? (
                <ArrowForwardIcon
                  // className={classes.darkPrimary}
                  fontSize="large"
                  color="secondary"
                  className={classes.arrowDirection}
                />
              ) : (
                ""
              )}
            </Box>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(StepOneBasicInfo);
