import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import i18next from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'
import 'flag-icon-css/css/flag-icon.min.css'

// import useCommonStyle from '../../styles/generalCss'

const languages = [
  {
    name: 'eng',
    dir: 'ltr',
    countryCode: 'gb',
    code: 'en',
  },
  {
    name: 'ع',
    // src: Allemagne,
    countryCode: 'sa',
    code: 'ar',
    dir: 'rtl',
    textAlign: 'right',
  },
]

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  flagMargin: {
    marginRight: theme.spacing(2),
  },
  flagMarginAr: {
    direction: 'rtl',
    marginLeft: theme.spacing(2),
  },
  margin: {
    backgroundColor: (props) => (props.dir = 'ltr' ? 'blue' : 'red'),
  },
}))

function SwitchLanguage() {
  const currentLanguageCode = localStorage.i18nextLng || 'en'

  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

  const classes = useStyles()
  //   const Generalclasses = useCommonStyle()

  const { t } = useTranslation()

  const [langCode, setLangCode] = useState(currentLanguageCode)
  const [open, setOpen] = useState(false)
  const refreshPage = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name)
        })
      })
      window.location.reload()
    }
  }
  const handleLanguageChange = (e) => {
    // refreshPage()
    const selectedLang = e.target.value
    console.log(selectedLang)
    i18next.changeLanguage(e.target.value)
    setLangCode(selectedLang)
    window.location.reload()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    // debugger
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, langCode, t])

  return (
    <form autoComplete="off">
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          labelId="Language"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={langCode}
          native
          name="lanuageSwitcher"
          onChange={handleLanguageChange}
          dense="true"
          inputProps={{
            id: 'open-select',
          }}
          // onClick={refreshPage}
        >
          {languages.map((option, key) => (
            <>
              <span
                className={`flag-icon flag-icon-${option.countryCode} ${
                  currentLanguage.dir === 'ltr'
                    ? classes.flagMargin
                    : classes.flagMarginAr
                } ${classes.margin}`}
              ></span>
              <option
                value={option.code}
                key={key}
                style={{ textAlign: 'center' }}
              >
                {option.name}
              </option>
            </>
          ))}
        </Select>
      </FormControl>
      {/* <div className={Generalclasses.test}>testtttttttt</div> */}
    </form>
  )
}
export default SwitchLanguage
