import React from "react";
import AppModal from "../AppModal";
import { Box, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
const ShippmentAdressModal = (props) => {
  console.log(props);
  const { t } = useTranslation();
  const {
    user: {
      id,
      firstName,
      lastName,
      email,
      whatsNumber,
      phoneNumber,
      postalCode,
      street,
      city,
      country,
    },
  } = props;
  const body = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 80px",
        marginBottom: "50px",
        direction: "ltr",
      }}
    >
      <h3>{firstName + " " + lastName}</h3>

      <div>
        <Box display="flex" alignItems="center" dir="ltr">
          <MailIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          <Typography>{props.email || email}</Typography>
        </Box>
        <Box display="flex" alignItems="center" dir="ltr">
          <PhoneIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          <Typography>{phoneNumber}</Typography>
        </Box>
        {whatsNumber && (
          <Box display="flex" alignItems="center" dir="ltr">
            <WhatsAppIcon
              // className={classes.marginRight}
              className={"margin-right-s"}
              fontSize={"small"}
              color="primary"
            />
            {/* <Typography>{organizer.whatsNumber}</Typography> */}
            <Typography>{whatsNumber}</Typography>
          </Box>
        )}
      </div>

      <div>
        <Box display="flex" mt={1} mr={7.5}>
          <LocationOnOutlinedIcon
            className={"margin-right-s"}
            fontSize={"small"}
            color="primary"
          />
          {city && <Typography>{city?.name + " , "}</Typography>}
          {country && (
            <Typography>
              {localStorage.i18nextLng === "en"
                ? country?.name
                : country?.arabicName}
            </Typography>
          )}
        </Box>
        <div style={{ textAlign: "center" }}>
          {street} {t("signUp.street")}, {postalCode}
        </div>
      </div>
    </div>
  );
  return (
    <>
      <AppModal
        title=""
        titleStyle={{ margin: "8px auto" }}
        label={t("links.view")}
      >
        {body}
      </AppModal>
    </>
  );
};
ShippmentAdressModal.defaultProps = {
  user: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    whatsNumber: "",
    phoneNumber: "",
    postalCode: "",
    street: "",
    cityName: "",
    country: "",
  },
};
export default ShippmentAdressModal;
