import React from "react";
import socialMedia from "../../enums/socialMedia";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { YouTube } from "@material-ui/icons";

export const socialMediaArray = [
  {
    value: socialMedia.facebook,
    icon: <FacebookIcon style={{ color: "blue" }} />,
    type: "Facebook",
  },
  {
    value: socialMedia.twitter,

    type: "Twitter",
    icon: <TwitterIcon style={{ color: "#00acee	" }} />,
  },
  {
    value: socialMedia.instagram,
    icon: <InstagramIcon style={{ color: "#fb3958	" }} />,
    type: "Instgram",
  },
  {
    value: socialMedia.linkedIn,
    icon: <LinkedInIcon style={{ color: "#0e76a8	" }} />,
    type: "LinkedIn",
  },
  {
    value: socialMedia.youtube,
    icon: <YouTube style={{ color: "red	" }} />,
    type: "YouTube",
  },
];
const BazarSocailMediaIcons = ({ socialMediaLinks }) => {
  return (
    <>
      {socialMediaLinks.map((item, i) => {
        console.log(item.ur);
        return (
          item.url !== "" && (
            <a
              href={
                item.url.startsWith("http") ? item.url : "https://" + item.url
              }
              target="_blank"
              style={{ margin: "5px" }}
              key={i}
            >
              {
                socialMediaArray.find((socialMediaItem) => {
                  return (
                    socialMediaItem.type === item.type ||
                    socialMediaItem.value === item.type
                  );
                })?.icon
              }
            </a>
          )
        );
      })}
    </>
  );
};

export default BazarSocailMediaIcons;
