import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { setSnackbar } from "../../redux/Message/actions";
import { useDispatch } from "react-redux";
import SummaryCard from "./../../components/SummaryCard";
import Message from "../../components/MessageSummary";
import { useTranslation } from "react-i18next";
import axios from "axios";
const SummaryPage = () => {
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const orderId = useParams().orderId;
  const [orderD, setOrderD] = useState({
    buyer: { street: "", city: { name: "" }, country: { name: "" } },
  });
  useEffect(() => {
    axios.get(`Order/GetOrderInfo?OrderId=${orderId}`).then((response) => {
      setOrderD(response.data);
      console.log("order details", response.data);
    });
  }, []);
  const handleDeleteOrder = () => {
    axios.delete(`Order/DeleteOrder?OrderId=${orderId}`).then((response) => {
      dispatch(setSnackbar(true, "success", `${response.data.message}`));
      history.push(`/buyer/bazarPage/${orderD.bazarId}`);
    });
  };
  const role = localStorage.userRole;
  const { t } = useTranslation();

  return (
    <>
      {window.localStorage.removeItem("No.Items")}
      <Header />
      <div style={{ marginTop: "4.1rem" }}>
        <Message />
        {console.log(orderD, "order details")}
        <SummaryCard
          orderNumber={orderD.orderNumber}
          orderDate={orderD.orderDate}
          {...orderD}
          address={`${orderD?.street} ${t("street")},${orderD?.city?.name},${
            orderD?.country?.name
          }`}
          shippingPrice={orderD.shippingPrice}
          subTotal={orderD.subTotal}
          total={orderD.total}
          handleDeleteOrder={handleDeleteOrder}
          {...orderD}
          currency={orderD?.currency}
        />
        <Grid
          container
          item
          direction="row"
          style={{ margin: "2% 4%", cursor: "pointer", color: "orange" }}
          onClick={() => history.push(`/dashboard/${role ? role : "Buyer"}`)}
        >
          <ArrowBackIcon />
          <span>{t("cartPage.backToShop")} </span>
        </Grid>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default SummaryPage;
