import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import FormikField from "./../FormikField/index";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckboxWithLabel } from "formik-material-ui";
import { InputAdornment, TextField } from "@material-ui/core";
import LoginWithGoogle from "./../LoginWithGoogle/index";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buyerImg: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: "0px",
    marginRight: "-440px",
  },
  ff: {
    padding: " 0 6rem",
  },
  sd: {
    marginRight: "17rem",
    ["@media (max-width:1024px)"]: {
      marginRight: "6rem",
    },
    ["@media (max-width:780px)"]: {
      marginRight: "28rem",
    },
    ["@media (max-width:500px)"]: {
      marginRight: "4rem",
    },
  },
});

const LoginForm = (props) => {
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit, initialValues, validationSchema } = props;
  console.log("initialValues", initialValues, props.modal);
  return (
    <Grid container>
      <Grid item sx={12}>
        <Typography
          variant="h5"
          style={
            props.modal
              ? { fontWeight: "600", textAlign: "right" }
              : { fontWeight: "600" }
          }
          className={
            localStorage.i18nextLng === "ar" && !props.modal ? classes.sd : ""
          }
        >
          {t("signIn.SignInIntoYourAccount")}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={!props.modal ? 4 : 0}
        // spacing={4}
        direction="row"
        wrap="nowrap"
        justify="center"
      >
        {/* <Grid item xs={1}></Grid> */}
        <Box marginTop={5} width={1}>
          <Box mb={3}>
            <Typography color="error">{props.error}</Typography>
          </Box>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, errors, validateForm, isSubmitting }) => {
                return (
                  <Form autoComplete="false" style={{ width: "100%" }}>
                    <Grid item xs={12}>
                      <FormikField name="username" label={t("signUp.email")} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikField
                        variant="outlined"
                        name="password"
                        type={showHidePassword ? "text" : "password"}
                        label={t("signUp.password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <i
                                className={
                                  localStorage.i18nextLng === "ar"
                                    ? `${classes.ff} fas fa-eye`
                                    : "fas fa-eye"
                                }
                                style={{
                                  color: "#F8B334",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  changeShowHidePassword(!showHidePassword)
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Field
                          name="rememberMe"
                          type="checkbox"
                          component={CheckboxWithLabel}
                          color="primary"
                          Label={{ label: t("signIn.rememberMe") }}
                          // style={{ direction: 'rtl' }}
                        />

                        <Link to="/forgetPassword">
                          <Typography color="error">
                            {t("signIn.forgetPassword")}
                          </Typography>
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        {t("signIn.submit")}
                      </Button>
                      {!props.modal && (
                        <Box
                          mt={3}
                          justifyContent="center"
                          display="flex"
                          // flexDirection={
                          //   localStorage.i18nextLng === 'ar'
                          //     ? 'row-reverse'
                          //     : 'row'
                          // }
                        >
                          <Typography>
                            {t("signIn.dontHaveAccount")}{" "}
                          </Typography>
                          <Link
                            to="/signUp"
                            className={classes.signInLink}
                            style={{ textDecoration: "none", margin: "0 10px" }}
                          >
                            <Typography color="primary">
                              {t("signUp.signUp")}
                            </Typography>
                          </Link>
                        </Box>
                      )}

                      {props.modal && (
                        <>
                          <Box textAlign="center" m={1}>
                            {t("or")}
                          </Box>
                          <Box width={1}>
                            <LoginWithGoogle />
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Box>
        {/* <Grid item xs={1}></Grid> */}
      </Grid>
    </Grid>
  );
};

export default LoginForm;
