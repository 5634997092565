export const SellerDrawerItems = [
  {
    path: "/dashBoard/Seller",
    itemName: "mybooth",
  },
  {
    path: "/Seller/products",
    itemName: "myGallery",
  },
  {
    path: "/Seller/payments",
    itemName: "payments",
  },
  {
    path: "/Seller/profile",
    itemName: "profile",
  },
  {
    path: "/Seller/bazarRequests",
    itemName: "bazarRequests",
  },
  {
    path: "/Seller/CommingSoon",
    itemName: "joinBazar",
  },

  // {
  //   path: '/help',
  //   itemName: 'help',
  // },
];
