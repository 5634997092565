import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import BazarSocailMediaIcons from "../BazarSocialMediaIcons";
import FBShareButton from "../FBShareButton";
import { withRouter } from "react-router-dom";
import { Avatar, Button, IconButton } from "@material-ui/core";
import ClipboardCopy from "./../CopyTo/index";
import "./booth.css";
const NewBoothSec = ({
  boothNam,
  seller = { firstName: "", lastName: "" },
  boothCategories,
  handleBtnFilter,
  srcLogo,
  description,
  handleZoom,
  handleZoomScroll,
  socialMediaLinks,
  filter,
  zoomUrl,
  flag,
  location,
  srcCover,
  country,
  viewsN,
  dispNone,
  open,
  disp,
}) => {
  const { t } = useTranslation();
  return (
    <section
      className={open ? `bazar-page ${disp}` : `bazar-page ${disp} ${dispNone}`}
    >
      <section className="bazar-info">
        <div className="container">
          <div className="bazar-card">
            <div
              className="row"
              // style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-6 my-3">
                <div className="card">
                  <div className="row no-gutters align-items-center">
                    <div className="col-md-4">
                      <img
                        className="card-img"
                        src={srcCover}
                        alt="srcCover"
                        style={{ height: "200px" }}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{boothNam}</h5>
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  localStorage.i18nextLng === "en"
                    ? "col-lg-6 my-3"
                    : `bazarAR col-lg-6 my-3`
                }
              >
                <div className="more-details">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tbody>
                        <tr style={{ textAlign: "start" }}>
                          {/* <th scope="row">{t("location")}:</th>
                          <td>{country}</td> */}
                          <th scope="row">{t("roles.Seller")}:</th>
                          <td>{seller?.firstName + " " + seller?.lastName}</td>
                        </tr>
                        <tr style={{ textAlign: "start" }}>
                          {/* <th></th>
                          <td></td> */}
                          <th scope="row">{t("signUp.email")}:</th>
                          <td>{seller?.email}</td>
                        </tr>
                        <tr style={{ textAlign: "start", color: "#F8B334" }}>
                          {/* <th></th>
                          <td></td> */}
                          <th scope="row">{t("viewsN")}:</th>
                          <td>{viewsN}</td>
                        </tr>
                        {/*  <tr style={{ textAlign: "start" }}>
                          <th scope="row">{t("signUp.phoneNumber")}:</th>
                          <td style={{ direction: "ltr" }}>
                            {seller.phoneNumber}
                          </td>
                        </tr>
                         <tr style={{ textAlign: "start" }}>
                          {seller?.whatsNumber ? (
                            <>
                              <th scope="row">{t("signUp.phoneWhats")}:</th>
                              <td style={{ direction: "ltr" }}>
                                {seller.whatsNumber}
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {socialMediaLinks.length ? (
                      <>
                        <BazarSocailMediaIcons
                          socialMediaLinks={socialMediaLinks}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <FBShareButton
                      url={
                        "https://www..ebazarak.com" +
                        location.pathname
                      }
                    />
                    <ClipboardCopy
                      copyText={
                        "https://www..ebazarak.com" +
                        location?.pathname
                      }
                    />
                    <span className="zooom">
                      <Button
                        onClick={handleZoomScroll}
                        variant="outlined"
                        style={{
                          color: "#2d8cff",
                          borderColor: "#2d8cff",
                          margin: ".5rem",
                        }}
                      >
                        {t("boothPage.zoomTime")}
                      </Button>
                      <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          flag === "Active" && zoomUrl
                            ? window.open(zoomUrl)
                            : ""
                        }
                      >
                        <Avatar src="/assets/images/SVG/Zoom.svg" />
                      </IconButton>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default withRouter(NewBoothSec);
