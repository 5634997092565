import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizerBazars } from "../../redux/organizer/actions";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";

const Dropdown = ({
  items,
  label,
  handleChange,
  value = "",
  styleObj,
  variant = "standard",
  home,
  className = "",
  flag,
}) => {
  console.log(value, items, label, "iittmm");
  // const organizerId = localStorage.userId
  // const dispatch = useDispatch()
  const { t } = useTranslation();
  // useEffect(() => {
  //   dispatch(getOrganizerBazars(organizerId))
  // }, [])
  // const bazars = useSelector((state) => state.organizerReducer.bazars)

  return (
    <FormControl
      variant="outlined"
      style={{ minWidth: "120px", margin: "4px" }}
      className={"standard"}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        fullWidth
        value={value}
        onChange={handleChange}
        label={label}
        color="primary"
        style={home && { backgroundColor: "white" }}
        // className={className}
      >
        <MenuItem value={""}>{t("homePage.none")}</MenuItem>

        {items?.map((item, index) => (
          <MenuItem value={item.id} key={item.id}>
            {!flag
              ? item.name || item.countryName
              : localStorage.i18nextLng === "en"
              ? item?.name
              : item?.arabicName}
            {console.log(item, item.countryName, "ii")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
