import {
  REMOVE_FROM_CART,
  ADD_TO_CART,
  CHANGE_CART_PRODUCT_QUANTITY,
} from "./../actionTypes";
const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};
const cartReducer = (state = initialState, action) => {
  let cartItems = [...state.cartItems];
  let bazarItems = action.bazarItems;

  let product = action.product;
  switch (action.type) {
    case ADD_TO_CART:
      debugger;
      let bazarAlreadyExist = false;
      let alreadyExist = false;
      cartItems.forEach((item) => {
        if (item.bazarId === bazarItems.bazarId) {
          bazarAlreadyExist = true;
        }
      });
      if (!bazarAlreadyExist) {
        cartItems.push({ ...bazarItems, items: [product] });
      } else {
        let items = cartItems.find(
          (itm) => itm.bazarId === bazarItems.bazarId
        )?.items;
        items?.forEach((item) => {
          debugger;
          if (item.productId === product.productId) {
            alreadyExist = true;
          }
        });
        if (!alreadyExist) {
          items?.push(product);
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      console.log(cartItems, "After");
      return { ...state, cartItems };

    case REMOVE_FROM_CART: {
      let index = cartItems.findIndex((itm) => itm.bazarId === action.bazarId);
      debugger;
      let itemss = cartItems[index].items.filter(
        (item) => item.productId !== action.productId
      );
      cartItems[index] = { ...cartItems[index], items: itemss };
      debugger;
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      console.log(cartItems);
      return { ...state, cartItems };
    }
    case CHANGE_CART_PRODUCT_QUANTITY: {
      let index = cartItems.findIndex((itm) => itm.bazarId === action.bazarId);

      cartItems[index].items.forEach((item) => {
        if (item.productId === action.productId) {
          debugger;
          item.quantity = action.count;
        }
      });
      debugger;
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      console.log(cartItems);
      return { ...state, cartItems };
    }
    default:
      return state;
  }
};
export default cartReducer;
