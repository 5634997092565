import React from "react";
import { makeStyles, Card, CardContent, Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    maxWidth: "86%",
    margin: "0.5% 8%",
    padding: "1%",
    boxShadow: "none",
  },
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dis2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "28%",
    ["@media (max-width:580px)"]: {
      width: "90%",
    },
  },
  wid: {
    minWidth: "14rem",
    ["@media (max-width:900px)"]: {
      minWidth: "5rem",
    },
  },
});

const BackOrCopmplete = ({
  handleBack,
  handleCompleteOrder,
  total,
  bazarId,
  basketId,
  currency,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = localStorage.token;
  return (
    <>
      <Card className={classes.root}>
        <div className={classes.dis}>
          <Grid
            container
            item
            direction="row"
            style={{ marginTop: "2%", cursor: "pointer" }}
            onClick={() => handleBack(bazarId)}
          >
            <ArrowBackIcon />
            <span>{t("cartPage.backToBazar")} </span>
          </Grid>
          <CardContent className={classes.dis2}>
            <div style={{ width: "95%" }}>
              <p className={classes.dis}>
                {t("subtotal")}{" "}
                <span>
                  {currency} {total}
                </span>
              </p>
            </div>

            <Button
              variant="contained"
              onClick={() => handleCompleteOrder(basketId)}
              className={localStorage.i18nextLng === "en" ? "" : classes.wid}
            >
              {t("cartPage.completeTheOrder")}
            </Button>
          </CardContent>
        </div>
      </Card>
    </>
  );
};

export default BackOrCopmplete;
