import { GET_COUNTRIES } from '../actionTypes'
const initialState = {
  countries: [],
}
const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return { ...state, countries: action.payload }

    default:
      return state
  }
}
export default addressReducer
