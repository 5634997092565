import React, { useState } from 'react'
import { Grid, Link, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ResetPassword2 = ({ handleForgetPasswordAsync }) => {
  const { t } = useTranslation()

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justify="center" // style={{ marginTop: '50px' }}
    >
      <Grid item xs={12}>
        <h2>{t('forgetPassword.emailSentHeader')}</h2>
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        // height="50vh"
      >
        <Grid item xs={12}>
          <p>{t('forgetPassword.emailSentDescription')}</p>
        </Grid>
        <Grid item xs={12}>
          {t('forgetPassword.didntRecieve')}
          <Link
            variant="body1"
            style={{
              marginLeft: '6px',
              color: 'orange',
              cursor: 'pointer',
            }}
            onClick={() => handleForgetPasswordAsync()}
          >
            {t('forgetPassword.resend')}
          </Link>
        </Grid>
      </Box>
    </Grid>
  )
}

export default ResetPassword2
