import {
  ACCEPT_PROVIDER_BAZAR_REQUEST,
  DECLINE_PROVIDER_BAZAR_REQUEST,
  EDIT_PROVIDER_PROFILE,
  GET_ALL_SERVICE_PROVIDERS,
  GET_ALL_SERVICE_PROVIDERS_BY_BAZARID,
  GET_PROVIDER_BAZARS_REQUEST,
} from "../actionTypes";
import { GET_PROVIDER_BY_ID } from "./../actionTypes";
const initialState = {
  allServiceProviders: [],
  allServiceProvidersById: [],
  provider: {
    companyName: "",
    representativeName: "",
    email: "",
    whatsNumber: "",
    phoneNumber: "",
    cityId: "",
    countryId: "",
    countryName: "",
    postalCode: "",
    street: "",
    companyCertificate: "",
    certificateName: "",
    acceptCashDelivery: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
  },
  bazarRequests: [],
  bazarRequestsCount: "",
  loadingBazarRequests: false,
  editProfile: { editing: false },
  providerBazars: [],
};
const serviceProvider = (state = initialState, action) => {
  let allServiceProviders = [...state.allServiceProviders];
  let allServiceProvidersById = [...state.allServiceProvidersById];
  let provider = { ...state.provider };

  let bazarRequests = [...state.bazarRequests];
  let bazarRequestsCount = state.bazarRequestsCount;
  let bazarId = "";

  switch (action.type) {
    case GET_ALL_SERVICE_PROVIDERS:
      return {
        ...state,
        allServiceProviders: action.payload,
      };
    case GET_ALL_SERVICE_PROVIDERS_BY_BAZARID:
      debugger;
      console.log(action.payload, "byid");
      return {
        ...state,
        allServiceProvidersById: action.payload,
      };
    case GET_PROVIDER_BY_ID:
      const newProvider = action.payload;
      provider = {
        ...newProvider,
        countryId: newProvider?.country?.id || newProvider.countryId,
        countryName:
          localStorage.i18nextLng === "en"
            ? newProvider?.country?.name
            : newProvider?.country?.arabicName || newProvider.countryName,
        socialMediaLinks:
          newProvider.socialMediaLinks.length > 0
            ? newProvider.socialMediaLinks.map((socialLink) => ({
                url: socialLink.url,
                socialMediaType: socialLink.type,
              }))
            : [{ url: "", socialMediaType: 0 }],
      };
      return {
        ...state,
        provider,
        editProfile: { editing: false },
      };
    case EDIT_PROVIDER_PROFILE:
      provider = action.payload;
      debugger;

      return {
        ...state,
        provider,
        editProfile: { editing: true },
      };
    case GET_PROVIDER_BAZARS_REQUEST:
      const { count, data } = action.payload;
      bazarRequests = data;
      bazarRequestsCount = count;
      return {
        ...state,
        bazarRequests,
        bazarRequestsCount,
      };
    case ACCEPT_PROVIDER_BAZAR_REQUEST:
      bazarId = action.payload;
      console.log("Before filter", bazarRequests);
      bazarRequests.forEach((request) =>
        request.id === bazarId ? (request.providerResponse = 1) : ""
      );
      console.log("after filter", bazarRequests);
      return {
        ...state,
        bazarRequests,
      };
    case DECLINE_PROVIDER_BAZAR_REQUEST:
      bazarId = action.payload;
      console.log("Before filter", bazarRequests);
      // bazarRequests = bazarRequests.filter((request) => request.id !== bazarId)
      bazarRequests.forEach((request) =>
        request.id === bazarId ? (request.providerResponse = 2) : ""
      );
      console.log("after filter", bazarRequests);
      return {
        ...state,
        bazarRequests,
      };
    case "LOADING_BAZAR_REQUEST":
      return {
        ...state,
        loadingBazarRequests: action.value,
      };
    case "PROVIDER_PROFILE_UNMOUNT":
      return {
        ...state,

        editProfile: { editing: false },
      };
    default:
      return state;
  }
};
export default serviceProvider;
