import MaterialTable from "material-table";
import React from "react";
import tableIcons from "./../../styles/materialTableIcons";

import axios from "axios";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const token = localStorage.token;
const PaymentHistory = () => {
  const sellerId = localStorage.userId;

  const { t } = useTranslation();

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        style={{ padding: "20px 0", fontSize: "15px", overflowX: "scroll" }}
        title={t("paymentHistory")}
        columns={[
          {
            title: t("bazarName"),
            field: "bazar.name",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => <div>{row.bazarName}</div>,
          },
          {
            title: t("BazarDate"),
            field: "date",
            render: (row) => (
              <div>{format(new Date(row.bazarDate + "Z"), "MM/dd/yyyy")}</div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("organizerName"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return <div>{`${row.organizerName}`}</div>;
            },
          },
          {
            title: t("type"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return <div>{`${row.payType}`}</div>;
            },
          },

          {
            title: t("payDate"),
            field: "payDate",
            render: (row) => (
              <div>{format(new Date(row.payDate + "Z"), "MM/dd/yyyy")}</div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("amount"),
            field: "amount",
            render: (row) => {
              return <div>{`${row.amount}`}</div>;
            },
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("plan"),
            field: "plan",
            render: (row) => {
              return <div>{`${row.paymentPlan}`}</div>;
            },
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
        ]}
        options={{
          search: false,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle: { backgroundColor: "#f1f1f1", textAlign: "center" },
          rowStyle: { textAlign: "center" },
          editCellStyle: { marginLeft: "20px" },
          sorting: false,
        }}
        data={(query) => {
          return new Promise((resolve, reject) => {
            const { page, pageSize } = query;
            let url = `Seller/GetPaymentHistory?SellerId=${sellerId}&PageSize=${pageSize}&PageIndex=${
              page + 1
            }`;
            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                // const response = data;
                console.log(response, "ddd");
                resolve({
                  data: response.data.data || response,
                  page: query.page,
                  totalCount: response.data.count || 1,
                });
              });
          });
        }}
      />
    </>
  );
};

export default PaymentHistory;
