import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

import { allBazarStatus } from './../../containers/App/constants'
import Box from '@material-ui/core/Box'
import SellerBoothCard from '../SellerBoothCard'
import { Link } from 'react-router-dom'
import BazarCard from '../BazarCard'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  incompleteAlert: {
    backgroundColor: '#00000014',
    borderLeft: '4px solid orange',
    padding: '20px 10px',
    fontSize: '20px',
    margin: '30px 0',
  },
  incompleteAlertRight: {
    backgroundColor: '#00000014',
    borderRight: '4px solid orange',
    padding: '20px 10px',
    fontSize: '20px',
    margin: '30px 0',
  },
  divider: { margin: '60px 0' },
  statusDropDown: { margin: '60px 0' },
}))

const ProviderDashboardBazarsSection = ({
  bazarStatus,
  bazarsArr,
  loading,
}) => {
  const ar = localStorage.i18nextLng === 'ar' ? true : false
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      <Box className={classes.incompleteAlert}>
        <Box display="flex" alignItems="center">
          {/* <Avatar src="/assets/images/Logo/bazar_logo.png" /> */}
          <Typography style={{ marginLeft: '10px' }}>
            {/* {bazarsArr.length ? boothsArr[0].bazarName + ' - ' : ''} */}
            {t(allBazarStatus[bazarStatus].title)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.incompleteBazars}>
        <Grid
          container
          spacing={2}
          justify="flex-start"
          alignItems="center"
          align="center"
        >
          {loading ? (
            <Grid
              container
              justify="center"
              style={{
                textAlign: 'center',
                marginTop: '10px',
                width: '100%',
              }}
            >
              <CircularProgress color="primary" />
            </Grid>
          ) : bazarsArr.length > 0 ? (
            bazarsArr.map((bazar) => (
              <Grid
                item
                xs={12}
                md={4}
                style={{ margin: '70px 0px' }}
                key={bazar.id}
              >
                {/* <SellerBoothCard status={bazarStatus} booth={booth} /> */}
                {/* <Link to={``}> */}
                <BazarCard
                  {...bazar}
                  bazarStatus={bazar.status}
                  role="Provider"
                />
                {/* </Link> */}
              </Grid>
            ))
          ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
              {t('noData')}
            </div>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default ProviderDashboardBazarsSection
