import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    left: "29%",
    top: "10%",
    overflowY: "auto",
  },
  marginTop: {
    marginTop: "15px",
  },
}));

export default function DeclineModal({
  handleOpen,
  handleClose,
  handleDescriptionChange,
  handleCheckBoxChange,
  decriptionValue,
  handleModalSubmit,
  reasonsArr,
  open,
  error,
  x,
}) {
  console.log(x, "xx");
  const classes = useStyles();
  const { t } = useTranslation();
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle)
  // const [open, setOpen] = React.useState(false)

  const body = (
    <div
      className={classes.paper}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h3 id="simple-modal-title">Select a reason to decline</h3>
      {/* <p id="simple-modal-description"> */}
      <FormControl
        // error={error}
        component="fieldset"
        className={classes.formControl}
      >
        {/* <FormLabel>Select a reason for Decline</FormLabel> */}
        <FormGroup>
          {reasonsArr.map((reason) => (
            <FormControlLabel
              key={reason.id}
              control={
                <Checkbox
                  checked={reason.checked}
                  onChange={handleCheckBoxChange}
                  color="primary"
                  value={reason.id}
                />
              }
              label={reason.reason}
            />
          ))}
        </FormGroup>
        {error && (
          <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
        )}
      </FormControl>
      {x ? (
        <TextField
          id="outlined-multiline-flexible"
          label={t("description")}
          multiline
          rowsMax={8}
          value={decriptionValue}
          onChange={handleDescriptionChange}
          variant="outlined"
          className={classes.marginTop}
        />
      ) : (
        ""
      )}
      <Box width="100%" display="flex" justifyContent="center" mt={3}>
        <Button
          onClick={handleModalSubmit}
          color="primary"
          variant="contained"
          // style={{ width: '50%', textAlign: 'center' }}
        >
          Sumbit
        </Button>
        <div style={{ margin: "1rem" }}></div>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          // style={{ width: '50%', textAlign: 'center' }}
        >
          Cancel
        </Button>
      </Box>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
