import * as React from "react";
import { styled, useTheme, makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NewBoothSec from "../NewBoothSec";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: "black",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  dispNone: {
    display: "none",
  },
}));

export default function MobileBooth({
  boothNam,
  seller = { firstName: "", lastName: "" },
  boothCategories,
  handleBtnFilter,
  srcLogo,
  description,
  handleZoom,
  handleZoomScroll,
  socialMediaLinks,
  filter,
  zoomUrl,
  flag,
  location,
  srcCover,
  country,
  viewsN,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={classes.menuButton}
    >
      <Box open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {boothNam}
          </Typography>
        </Toolbar>
      </Box>
      <Box>
        <NewBoothSec
          boothNam={boothNam}
          seller={seller}
          boothCategories={boothCategories}
          handleBtnFilter={handleBtnFilter}
          srcLogo={srcLogo}
          description={description}
          handleZoom={handleZoom}
          handleZoomScroll={handleZoomScroll}
          socialMediaLinks={socialMediaLinks}
          filter={filter}
          zoomUrl={zoomUrl}
          flag={flag}
          location={location}
          srcCover={srcCover}
          country={country}
          viewsN={viewsN}
          dispNone={classes.dispNone}
          open={open}
        />
      </Box>
    </Box>
  );
}
