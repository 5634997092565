import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography,
  Switch,
} from "@material-ui/core";
import "./boothProduct.css";
import { setSnackbar } from "./../../redux/Message/actions";
import tableIcons from "./../../styles/materialTableIcons";
import MaterialTable from "material-table";
import { colors } from "../../styles/commonCss";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import axios from "axios";
import { useParams } from "react-router";
import ControlledAppModal from "./../../components/ControlledAppModal/index";
import { Link } from "react-router-dom";
import ProductDetailsPage from "./../ProductDetailPage/index";
import { useTranslation } from "react-i18next";
import { getBoothHome } from "./../../redux/booth/actions";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmDeleteModalContent from "./../../components/ConfirmDeleteModalContent/index";
import {
  deleteBoothProductAsync,
  deleteActiveProduct,
  searchInBoothsByProduct,
  getSellerBoothProducts,
} from "./../../redux/seller/api";
import { useEffect } from "react";
import MouseOverPopover from "./../../components/PopoverOnHover/index";

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: colors.link,
    textDecoration: "underline",
    cursor: "pointer",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
}));
const cellStyle = { width: "fit-content", textAlign: "center" };

const statusIndicator = {
  Accepted: { title: "accepted", color: "green" },
  Rejected: { title: "rejected", color: "red" },
  Draft: { title: "draft", color: "gray" },
  Pending: { title: "pending", color: "#18b8b8fa" },
};
const SellerBoothProductsTable = (props) => {
  const classes = useStyles();
  const tableRef = React.createRef();
  const [checked, setChecked] = React.useState(false);
  const [x, setX] = useState([]);

  const { t } = useTranslation();
  const boothId = useParams().id;
  const sellerBoothpage = useParams().sellerBoothpage;

  const dispatch = useDispatch();
  const [openAddProduct, setOpenAddProduct] = useState(false);

  const [productId, setProductId] = useState();
  const [productToDeleteId, setProductTodeleteId] = useState("");
  const [openViewProduct, setOpenViewProduct] = useState(false);
  const [deleted, setDeleted] = useState("");
  const [currentPage, setCurrentPage] = useState(
    sellerBoothpage ? sellerBoothpage : 0
  );
  useEffect(() => {
    if (deleted === true) {
      tableRef.current.onQueryChange({
        page: currentPage,
      });
      setDeleted(false);
    }
  }, [deleted]);
  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [checked]);
  const handleOpenAddProductModal = () => {
    hideBooth
      ? dispatch(setSnackbar(true, "error", "Your Booth is hidden", 4000))
      : setOpenAddProduct(true);
  };
  const handleCloseAddProducModal = () => {
    setOpenAddProduct(false);
  };

  const handleViewBoothProduct = (productId) => {
    setProductId(productId);
    handleopenViewProductModal();
  };
  const handleopenViewProductModal = () => {
    setOpenViewProduct(true);
  };
  const handleCloseViewProduct = () => {
    setOpenViewProduct(false);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    setProductTodeleteId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // tableRef.current.onQueryChange()
  };
  const handleDeleteConfirmed = async (productId) => {
    console.log("deleted");
    try {
      await deleteBoothProductAsync(productId);
      setDeleted(true);
      // alert('Delete')
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
    handleClose();
  };
  const handleActiveDelete = async (e, productId) => {
    debugger;
    try {
      setChecked(e.target.checked);
      tableRef.current.onQueryChange();
      await deleteActiveProduct(productId);
    } catch (error) {
      console.log(error);
    }
  };
  const booth = useSelector((state) => state.boothReducer.booth);
  const statusOfbooth = booth?.bazarStatus;
  const hideBooth = booth?.isHidden;
  console.log(booth, "opo");
  useEffect(() => {
    dispatch(getBoothHome(boothId));
  }, []);
  // useEffect(() => {
  //   tableRef.current.onQueryChange({
  //     page: currentPage,
  //   });
  // }, [tableRef]);

  return (
    <>
      <Grid>
        {console.log(currentPage, "cvcv")}
        <ControlledAppModal
          handleClickOpen={handleOpen}
          open={open}
          handleClose={handleClose}
        >
          <ConfirmDeleteModalContent
            handleDeleteConfirmed={handleDeleteConfirmed}
            handleClose={handleClose}
            productId={productToDeleteId}
          />
        </ControlledAppModal>

        <Grid container justify="space-between" wrap="wrap" alignItems="center">
          {/* <Grid item xs={12} md={6}> */}
          <div style={{ width: "60%" }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ marginBottom: ".5rem" }}
            >
              <Link
                style={{ color: "gray" }}
                onClick={() =>
                  props.history.push(`/Seller/boothHome/${boothId}`)
                }
              >
                {booth.name}
              </Link>
              <Typography className="special">{t("products")}</Typography>
            </Breadcrumbs>
          </div>
          <Box style={{ marginBottom: "1rem" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenAddProductModal()}
              // disabled={hideBooth ? true : false}
            >
              + {t("buttons.addProduct")}
            </Button>
          </Box>
        </Grid>

        <ControlledAppModal
          open={openViewProduct}
          handleClickOpen={handleViewBoothProduct}
          handleClose={handleCloseViewProduct}
          // title={' You can add new product Or exist product'}
          // titleStyle={{ textAlign: 'center' }}
        >
          <div style={{ maxWidth: "800px" }}>
            <ProductDetailsPage productId={productId} />
          </div>
        </ControlledAppModal>
        <ControlledAppModal
          open={openAddProduct}
          handleClickOpen={handleCloseAddProducModal}
          handleClose={handleCloseAddProducModal}
          // title={' You can add new product Or exist product'}
          // titleStyle={{ textAlign: 'center' }}
        >
          <div>
            <Grid item xs={12} container justify="center">
              <h3>{t("boothProducts.addNewOrExistProduct")}</h3>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="space-around"
              direction="column"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  props.history.push(`/Seller/booth/${boothId}/AddProduct`)
                }
              >
                {t("buttons.addNewProduct")}
              </Button>
              <h4>{t("or")}</h4>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  props.history.push(`/Seller/products/${boothId}`)
                }
                // onClick={() => props.history.push(`/Seller/products`)}
              >
                {t("buttons.addExistProduct")}
              </Button>
            </Grid>
          </div>
        </ControlledAppModal>

        <Grid>
          <MaterialTable
            title={t("products")}
            tableRef={tableRef}
            icons={tableIcons}
            style={{ padding: "20px 0", overflowX: "scroll" }}
            columns={[
              {
                title: t("productId"),
                // field: "productId",
                render: (row) => {
                  return <Box>{row.id}</Box>;
                },
                cellStyle,
              },

              {
                title: t("image"),
                field: "pictureUri",
                sorting: false,
                render: (row) => {
                  return (
                    <Box width="70px" height="50px">
                      <img
                        // alt={row.seller ? row.seller.name : ''}
                        src={
                          row?.productImage[0]?.url
                            ? `${localStorage.imagePath}${row.productImage[0]?.url}`
                            : "/assets/images/product.png"
                        }
                        //   width='100%'
                        //   height='100%'
                        className="img"
                      />
                    </Box>
                  );
                },
                cellStyle: {
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: "none",
                },
              },
              {
                title: t("name"),
                field: "name",
                sorting: false,
                cellStyle,
              },
              {
                title: t("price"),
                field: "price",

                cellStyle,
                render: (row) => (
                  <div>
                    {row.currency} {row.price}
                  </div>
                ),
              },
              {
                title: t("offer"),
                field: "offerPrice",
                sorting: false,
                cellStyle,
                render: (row) => (
                  <div>
                    {row.offerPrice
                      ? row.currency + " " + row.offerPrice
                      : "__"}
                  </div>
                ),
              },
              {
                title: t("category"),
                sorting: false,
                field:
                  localStorage.i18nextLng === "en"
                    ? "categoryName"
                    : "categoryNameAr",
                cellStyle,
              },
              {
                title: t("quantity"),
                field: "quantity",
                sorting: false,
                cellStyle,
              },

              {
                title: t("actions"),
                field: "actions",
                sorting: false,
                render: (row) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <>
                      {/* {row.bazarStatus === 'Active' && (
                        <MouseOverPopover text={"can't edit Bazar is Active"}>
                          x
                        </MouseOverPopover>
                      )} */}
                      <Link
                        //   style={{ color: '#03a9f4' }}
                        // className={classes.linkStyle}
                        to={`/Seller/booth/${boothId}/EditProduct/${row.productId}`}
                        color="secondary"
                        className={
                          row.bazarStatus === "Finished"
                            ? classes.disabled
                            : classes.linkStyle
                        }
                        disabled={true}
                        onClick={localStorage.setItem(
                          "ProductsTablePage",
                          currentPage
                        )}
                        // className={classes.linkStyle}
                      >
                        {t("links.edit")}
                      </Link>
                      {/* <Link
                        style={{ color: '#03a9f4' }}
                        to={'/Organizer/Bazar/settings/' + row.id}
                        color="secondary"
                        className={
                          row.status !== 'CommingSoon' ? classes.disabled : ''
                        }
                      >
                        {t('links.edit')}
                      </Link> */}
                      <div
                        style={{
                          width: "1px",
                          backgroundColor: "lightgray",
                          height: "30px",
                          margin: "0 5px",
                        }}
                      ></div>
                    </>
                    <div
                      onClick={() => {
                        // handleOpen(row.id)
                        handleViewBoothProduct(row.id);
                        // console.log(row);
                      }}
                      className={classes.linkStyle}
                      // style={{
                      //   color: '#03a9f4',
                      //   textDecoration: 'underline',
                      // }}
                    >
                      {t("links.view")}
                    </div>
                    {/* <Link
                        style={{ color: '#03a9f4' }}
                        to={'/bazar/' + row.id}
                        className={row.status === 'Finished' && classes.disabled}
                      >
                        Upgrade
                      </Link> */}
                  </Box>
                ),
              },
              {
                title: t("status"),
                field: "status",
                cellStyle,
                sorting: false,
                render: (row) => {
                  // console.log(row.status);
                  return (
                    <Box display="flex" alignItems="center">
                      <FiberManualRecordIcon
                        // fontSize="small"
                        style={{
                          color: statusIndicator[row.status]?.color,
                          margin: " 0px 10px",
                          fontSize: "12px",
                        }}
                      />
                      {t(
                        "productStatus." + statusIndicator[row.status]?.title
                      ) || row.status}
                    </Box>
                  );
                },
              },
              {
                title: statusOfbooth === "Active" ? t("hideProduct") : "",
                cellStyle,
                sorting: false,
                render: (row) => {
                  // console.log(row);
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {row.bazarStatus !== "Active" ? (
                        <IconButton
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            margin: "0 12px",
                          }}
                          onClick={() => handleOpen(row.id)}
                        >
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                      ) : (
                        <Switch
                          color="primary"
                          checked={row.isHide}
                          onChange={(e) => handleActiveDelete(e, row.id)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                      {row.status === "Pending" && (
                        <div style={{ marginTop: "5px" }}>
                          <MouseOverPopover
                            text={
                              <div style={{ marginTop: "5px", width: "300px" }}>
                                {t("infoMessages.pendingProductInBooth")}
                              </div>
                            }
                          >
                            <InfoOutlinedIcon />
                          </MouseOverPopover>
                        </div>
                      )}
                      {row.status === "Rejected" && (
                        <div style={{ marginTop: "5px" }}>
                          <MouseOverPopover
                            text={
                              <div style={{ margin: "5px", width: "300px" }}>
                                {row.declineReason}
                              </div>
                            }
                          >
                            <InfoOutlinedIcon />
                          </MouseOverPopover>
                        </div>
                      )}
                    </div>
                  );
                },
              },
            ]}
            options={{
              search: true,
              paginationType: "stepped",
              pageSize: 10,
              pageSizeOptions: [10],
              headerStyle: { backgroundColor: "#f1f1f1", textAlign: "center" },
              rowStyle: { borderBottom: "1px solid lightgray" },
              doubleHorizontalScroll: true,
              sorting: true,
            }}
            data={(query) => {
              return new Promise(async (resolve, reject) => {
                const { page, pageSize, search, orderBy, orderDirection } =
                  query;
                let ascORdes = orderDirection === "desc" ? 1 : 2;
                console.log(query, "qurey");
                debugger;
                let url;
                if (orderBy?.title === "Product ID" && search) {
                  url = await searchInBoothsByProduct(
                    +boothId,
                    pageSize,
                    +page + 1,
                    search,
                    ascORdes,
                    0
                  );
                } else if (search && orderBy?.title === "Price") {
                  url = await searchInBoothsByProduct(
                    +boothId,
                    pageSize,
                    +page + 1,
                    search,
                    0,
                    ascORdes
                  );
                } else if (orderBy && !search) {
                  url =
                    orderBy?.title === "Price"
                      ? await getSellerBoothProducts(
                          +boothId,
                          pageSize,
                          +page + 1,
                          0,
                          ascORdes
                        )
                      : await getSellerBoothProducts(
                          +boothId,
                          pageSize,
                          +page + 1,
                          ascORdes,
                          0
                        );
                } else {
                  url =
                    !orderBy && !search
                      ? await getSellerBoothProducts(
                          +boothId,
                          pageSize,
                          +page + 1,
                          0,
                          0
                        )
                      : await searchInBoothsByProduct(
                          +boothId,
                          pageSize,
                          +page + 1,
                          search,
                          0,
                          0
                        );
                }
                console.log(url, "opop");
                resolve({
                  data: url.data || url,
                  page: query.page,
                  totalCount: url.count || 1,
                });
              });
            }}
            onChangePage={setCurrentPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SellerBoothProductsTable;

//  `Seller/GetSellerBoothProducts?BoothId=${boothId}&PageSize=${pageSize}&PageIndex=${
//     +page + 1
//   }`
// `Seller/SearchInBoothsByProductName?BoothId=${boothId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${
//   +page + 1
// }`
