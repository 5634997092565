import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import "./Carousel.css";
import CountryCard from "./CountryCard.js/index";
import { Container } from "@material-ui/core";

const CountriesCardCarousel = ({
  countries,
  handleConuntryFilterChange,
  selectedCountry,
}) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 2 },
    // { width: 1200, itemsToShow: 2, itemsToScroll: 2 },
    // { width: 768, itemsToShow: 3 },
    // { width: 1200, itemsToShow: 4 },
  ];
  const { t } = useTranslation();
  const change = (currentItemObject, currentPageIndex) => {
    console.log(currentItemObject);
  };
  const handleClick = (id) => {
    if (selectedCountry === id) handleConuntryFilterChange(null, "");
    else handleConuntryFilterChange(null, id);
  };
  const [countryWithBazar, setcountryWithBazar] = useState([]);
  useEffect(() => {
    axios
      .get(`Address/GetCountryWithBazarNumber`)
      .then((response) => {
        setcountryWithBazar(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Container>
      <h1 style={{ textAlign: "center", marginBottom: "50px" }}>
        {t("homePage.findBazarByCountry")}
      </h1>
      <div className="carousel-container">
        {countryWithBazar.length > 0 && (
          <Carousel
            breakPoints={breakPoints}
            focusOnSelect={true}
            onChange={change}
          >
            {countryWithBazar.map((item) =>
              // <div key={item.id}>{item.name}</div>
              {
                return (
                  <div
                    onClick={() => handleClick(item.id)}
                    className={selectedCountry === item.id ? "activee" : ""}
                  >
                    <CountryCard
                      key={item.id}
                      // className={'active'}
                      img={item.name + ".jpg"}
                      name={item.name}
                      no={item.bazarNumber}
                    />
                    {console.log(item, "no.")}
                  </div>
                );
              }
            )}
          </Carousel>
        )}
        {/* <div className="circle-icon-contianer">
          <p>
            <i className="fas fa-chevron-left"></i>
          </p>
        </div> */}
        {/* <div className="countryCard">
          <img src="/assets/images/countries/doha.jpg" className="img" />
          <div className="img-overlay"></div>
          <div style={{ position: 'absolute', color: 'white' }}>
            International
          </div>
        </div> */}
        {/* <div className="countryCard">
          <img src="/assets/images/countries/Egypt.jpg" className="img" />
          <div className="img-overlay"></div>
          <div
            style={{
              position: 'absolute',
              color: 'white',
              backgroundColor: '#00000080',
              padding: '10px 20px',
            }}
          >
            Egypt
          </div>
        </div> */}
        {/* <CountryCard img="Egypt.jpg" name="Egypt" />
        <CountryCard img="doha.jpg" name="Qatar" />
        <CountryCard />
        <CountryCard /> */}
        {/* <div className="circle-icon-contianer">
          <p>
            <i className="fas fa-chevron-right"></i>
          </p>
        </div> */}
      </div>
    </Container>
  );
};
export default CountriesCardCarousel;
