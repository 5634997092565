import socialMedia from "./../../../enums/socialMedia";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { YouTube } from "@material-ui/icons";

export const socialMediaArray = [
  {
    value: socialMedia.facebook,
    icon: <FacebookIcon style={{ color: "blue" }} />,
    type: 0,
  },
  {
    value: socialMedia.twitter,

    type: 1,
    icon: <TwitterIcon style={{ color: "#00acee	" }} />,
  },
  {
    value: socialMedia.instagram,
    icon: <InstagramIcon style={{ color: "#fb3958	" }} />,
    type: 2,
  },
  {
    value: socialMedia.youtube,
    icon: <YouTube style={{ color: "red	" }} />,
    type: 3,
  },
  {
    value: socialMedia.linkedIn,
    icon: <LinkedInIcon style={{ color: "#0e76a8	" }} />,
    type: 4,
  },
];

// export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
