import React from "react";
import "../Carousel.css";
import { useTranslation } from "react-i18next";
const CountryCard = ({ name, img, no }) => {
  const { t } = useTranslation();
  const currentLanguageCode = localStorage.i18nextLng || "en";

  return (
    <div className="countryCard">
      <img src={"/assets/images/countries/" + img} className="img" />
      <div className="img-overlay"></div>
      <div
        style={{
          position: "absolute",
          color: "white",
          backgroundColor: "#00000080",
          padding: "10px 20px",
          textAlign: "center",
        }}
      >
        {name}
        <div
          style={{
            display: "block",
            direction: currentLanguageCode === "en" ? "ltr" : "rtl",
          }}
        >
          {no}
          {t("ProviderManage.bazar")}
        </div>
      </div>
    </div>
  );
};

export default CountryCard;
