import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors, textalign } from "../../styles/commonCss";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "./../../components/lib/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { useParams } from "react-router";
import {
  Edit,
  Store,
  LocationOn,
  AccessTime,
  LocalMall,
  Share,
} from "@material-ui/icons";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import BoothCard from "../../components/BoothCard";
import IconButton from "@material-ui/core/IconButton";
import BazarStatusIndicator from "../../components/BazarStatusIndicator";
import { Link } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { connect, useDispatch } from "react-redux";
import {
  getBazarById,
  uploadBazarAvatarImg,
  uploadBazarCoverImg,
} from "../../redux/bazar/actions";
import CardMedia from "@material-ui/core/CardMedia";
import { withRouter } from "react-router";
import UploadButtons from "./../UploadButtons/index";
import ControlledAppModal from "./../ControlledAppModal/index";
import axios from "axios";
import { useTranslation } from "react-i18next";
import MyDate from "./../Date";
import { format } from "date-fns";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BazarPage from "./../../containers/BazarPage/index";
import BoothPage from "./../../containers/BoothPage/index";
import fileChangedHandler from "./../../containers/ImgUploader/index";
import { handleLogout } from "../../utils/setLocalStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: "0 auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  textAlignRight: {
    textAlign: textalign.right,
  },
  textAlignCenter: {
    textAlign: textalign.center,
  },
  button: {
    margin: theme.spacing(1),
  },
  InWrap: {
    padding: theme.spacing(1),
  },
  dashboardlogo: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "2rem",
    left: "calc(50 % - 56px)",
    //border: '2px solid',
    //borderColor: '#F8B334',
  },
  dvDashboardlogo: {
    display: "flex",
    justifyContent: "center",
    top: "-55px",
    position: "absolute",
    width: "100%",
  },
  settingIcon: { display: "flex", justifyContent: "flex-end" },
  gridDashboardlogo: {
    position: "relative",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridShortInfo: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },

  gridShortInfoItem: {
    display: "flex",
    padding: "10px",
  },
  statsIcons: {
    width: "100%",
    minHeight: "50px",
  },
  salesIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  RequestsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  EarningsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },

  gridroot: {
    flexGrow: 1,
  },

  GridItemList: {
    marginBottom: "50px",
  },
  coverCameraContainer: {
    width: "200px",
    height: "50px",
    position: "absolute",
    right: "0px",
    backgroundColor: " #c5bebea3",
    bottom: "70px",
    display: "flex",
    alignItems: "center",
  },
  dis: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
  mar: {
    marginLeft: "60px",
  },
}));
const HomeBasicInfo = (props) => {
  console.log(props, "ppp");
  const location = useLocation();
  const classes = useStyles();
  const token = localStorage.token;
  // console.log(props.bazar, "props.bazar");
  const {
    // bazar: {
    id,
    name,
    county,
    imageProfile,
    categories,
    startDate,
    endDate,
    shopNo,
    selles,
    status,
    coverPhoto,
    // },
  } = props.bazar;
  const [imageType, setImageType] = useState("");
  // console.log(name, startDate, endDate, cities)
  const bazarId = id;
  // const bazarid = useParams().id;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [openView, setOpenView] = useState(false);
  const [openBoothView, setOpenBoothView] = useState(false);
  const handleUploadLogoImg = (imag, type) => {
    console.log(type);
    const image = imag;
    console.log(image);

    const formDate = new FormData();
    formDate.set("image", image);
    // debugger
    if (type === "Logo")
      dispatch(uploadBazarAvatarImg(formDate)).then(() => {
        setTimeout(() => handleOpen(), 2000);
      });
    else
      dispatch(uploadBazarCoverImg(formDate)).then(() => {
        setTimeout(() => handleOpen(), 2000);
      });
    setImageType(type);
  };

  const submitImage = async () => {
    const obj = { bazarId: id };

    imageType === "Logo"
      ? (obj.imageProfile = imageProfile)
      : (obj.coverPhoto = coverPhoto);
    try {
      debugger;
      const token = localStorage.token;
      const res = await axios.put("Bazar/EditBazarInformation", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      dispatch(getBazarById(id));
    }
    handleClose();
  };

  const handleCancelImageUpload = () => {
    dispatch(getBazarById(id));
    handleClose();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleViewPage = () => {
    handleopenView();
  };
  const handleopenView = () => {
    console.log(id);
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };
  const handleopenBoothView = () => {
    console.log(id);
    setOpenBoothView(true);
  };
  const handleCloseBoothView = () => {
    setOpenBoothView(false);
  };
  const userRole = localStorage.userRole;
  return (
    // <></>
    <>
      <Grid item xs={8} className={classes.textAlignRight}>
        <Typography component="div">
          <Box fontSize="h6.fontSize" m={1}>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleopenView}
              >
                <VisibilityIcon />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() =>
                  props.history.push(`/Organizer/manageOrders/${bazarId}`)
                }
              >
                {t("buttons.manageOrders")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() =>
                  props.history.push(`/Organizer/bazar/${bazarId}/Sellers`)
                }
              >
                {t("buttons.sellers")}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Edit>Edit</Edit>}
              >
                Edit
              </Button> */}
            </div>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className="" container justify="center">
        <div
          style={{
            background: "url(/assets/images/bazars/big_bazar1.jpg)",
            width: "100%",
            height: "400px",
            backgroundSize: "cover",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <img
            // src="/assets/images/bazars/big-grand-bazaar2.jpg"
            alt=""
            // width="100%"
            // height="400px"
            src={
              coverPhoto
                ? `${localStorage.imagePath}${coverPhoto}`
                : "/static/images/avatar/1.jpg"
            }
            className="img"
          />
          <div className={classes.coverCameraContainer}>
            <Box position="absolute" zIndex="1000">
              <UploadButtons
                type="Camera"
                handleChange={(e) =>
                  fileChangedHandler(e, "Cover", null, handleUploadLogoImg)
                }
                name="bazarCover"
              />
            </Box>
            <div className={classes.mar}>{t("uploaYourCover")}</div>
          </div>
        </div>
        {/* <div className={classes.bazarBackgroundCover}></div> */}
      </Grid>
      <Grid item xs={12} className={classes.gridDashboardlogo}>
        <div className={classes.dvDashboardlogo}>
          <Avatar
            alt={name[0].toUpperCase()}
            className={classes.dashboardlogo}
            src={
              imageProfile
                ? `${localStorage.imagePath}${imageProfile}`
                : "/static/images/avatar/1.jpg"
            }
          />
          <Box
            width="50px"
            // height="50px"
            // bgcolor="primary"
            // border="2px solid black"
            bgcolor="#00000ed"
            position="absolute"
            bottom="-2px"
            marginRight="76px"
            // left="2px"
          >
            <div style={{ display: "flex", background: "#00000ed" }}>
              <UploadButtons
                type="Camera"
                handleChange={(e) =>
                  fileChangedHandler(e, "Logo", null, handleUploadLogoImg)
                }
                name="bazarLogo"
              />
              {/* <Button variant="outlined" size="small">
                Save
              </Button> */}
            </div>
          </Box>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.settingIcon}>
          <IconButton
            onClick={() =>
              props.history.push(`/Organizer/Bazar/settings/${bazarId}`)
            }
          >
            <SettingsIcon color="primary" style={{ textAlign: "right" }} />
          </IconButton>
        </div>
      </Grid>
      <Grid
        item
        sm={8}
        xs={11}
        container
        justify="flex-end"
        style={{ marginTop: "20px" }}
      >
        <BazarStatusIndicator
          bazarStatus={status}
          // style={{ position: 'absolute', left: '50px' }}
        />
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Typography component="div">
          <Box fontSize="h6.fontSize" m={1} paddingTop="5px">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: '105px',
                position: "relative",
              }}
            >
              <div style={{ textAlign: "center" }}>{name}</div>
            </div>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textAlignCenter}>
        <Typography component="span">
          <Box className={classes.centerFlex}>
            <LocationOn fontSize="small" />
            {/* {cities.map((city) => (
              <span key={city.id} style={{ marginRight: '3px' }}>
                {city.name}
              </span>
            ))} */}
            {localStorage.i18nextLng === "en"
              ? county?.name
              : county?.arabicName}
          </Box>
        </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        className={classes.textAlignCenter}
        container
        justify="center"
        style={{ margin: "0 auto" }}
      >
        <Box paddingTop="10px">
          {categories.map((cat) => (
            <Chip
              key={cat.id}
              label={
                localStorage.i18nextLng === "en" ? cat.name : cat.arabicName
              }
              variant="outlined"
              color="primary"
              className={categories.length > 1 && "margin-right-s"}
              style={{ margin: "4px 2px" }}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.gridShortInfo}>
        {/* <Box
          component="span"
          fontSize="h15.fontSize"
          className={classes.gridShortInfoItem}
        >
          <Store fontSize="small" />
          <span className="font-size-md">{shopNo}</span>
        </Box>
        <Box
          component="span"
          fontSize="h15.fontSize"
          className={classes.gridShortInfoItem}
        >
          <LocalMall fontSize="small" />
          <span className="font-size-md">{selles}</span>
        </Box> */}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.textAlignCenter}
        justify="center"
      >
        <Box component="span" fontSize="h15.fontSize" className={classes.dis}>
          {startDate ? (
            <>
              <MyDate
                ndate={format(new Date(startDate + "Z"), "dd-MM-yyyy")}
                style={{ direction: "ltr" }}
              />
              <div style={{ margin: "1rem" }}> {t("to")} </div>
              <MyDate ndate={format(new Date(endDate + "Z"), "dd-MM-yyyy")} />
            </>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.textAlignRight}>
        {/* <Button
          variant="outlined"
          className={classes.button}
          endIcon={<Share>Share</Share>}
        >
          Share
        </Button> */}
        {/* <div
          className="fb-share-button"
          data-href={`https://www..ebazarak.com${location.pathname}`}
          data-layout="button"
          data-size="small"
        >
          <a
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
            className="fb-xfbml-parse-ignore"
          >
            Share
          </a>
        </div> */}
      </Grid>
      <ControlledAppModal
        open={open}
        handleClickOpen={handleOpen}
        handleClose={handleClose}
      >
        <>
          {imageType === "Logo" && <h3>Save Uploaded logo image </h3>}
          {imageType === "Cover" && <h3>Save Uploaded cover image </h3>}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button variant="contained" color="primary" onClick={submitImage}>
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancelImageUpload}
            >
              Cancel
            </Button>
          </div>
        </>
      </ControlledAppModal>
      <ControlledAppModal
        open={openView}
        handleClickOpen={handleViewPage}
        handleClose={handleCloseView}
      >
        <div style={{ maxWidth: "1024px" }}>
          <BazarPage bazarId={bazarId} modal={true} />
          {/* {console.log(bazarid, bazarId)} */}
        </div>
      </ControlledAppModal>
      {/* <ControlledAppModal
        open={openView}
        handleClickOpen={handleViewPage}
        handleClose={handleCloseView}
      >
        <div style={{ maxWidth: '800px' }}>
          <BoothPage boothId={id} modal={true} />
          {console.log('booid', id)}
        </div>
      </ControlledAppModal> */}
    </>
  );
};

export default withRouter(HomeBasicInfo);
