import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router";

const ScrollToTop = (props) => {
  const pathName = props.location.pathname;
  // console.log(pathName)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // console.log(pathName)
  }, [pathName]);
  return null;
};

export default withRouter(ScrollToTop);
