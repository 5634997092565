import axios from "axios";
import store from "../store";
// const token = store.getState().loginReducer.token
export const getSellerByIdAsync = async (id) => {
  const token = localStorage.token;

  const response = await axios.get(`Seller/GetSellerInfo?SellerId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};

export const getSellerBoothsAsync = async (
  sellerId,
  pageIndex = 1,
  pageSize = 20
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerBooths?SellerId=${sellerId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getSellerPendingBoothsAsync = async (
  sellerId,
  pageIndex = 1,
  pageSize = 20
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetPendingBoothsOfSeller?SellerId=${sellerId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getSellerProductAsync = async (
  sellerId,
  pageIndex = 1,
  pageSize = 9
) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerProduccts?SellerId=${sellerId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const searchInsellerProductsByNameAsync = async (
  sellerId,
  search,
  pageIndex = 1,
  pageSize = 9
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/SearchInProductsOfSeller?SellerId=${sellerId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getSellerProductsFilteredByBoothCategoryAsync = async (
  sellerId,
  search,
  boothId,
  pageIndex = 1,
  pageSize = 9
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetProductsOfSellerFilterByCategory?SellerId=${sellerId}&BoothId=${boothId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getSellerBoothOrdersAsync = async (
  boothId,
  pageIndex = 1,
  pageSize = 9
) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerMangeOrder?BoothId=${boothId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getSellerBoothOrdersByOrderNoAsync = async (
  boothId,
  OrderNumber,
  pageIndex = 1,
  pageSize = 9
) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerMangeOrderByOrderNumber?BoothId=${boothId}&OrderNumber=${OrderNumber}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const editSellerProfileAsync = async (sellerInfo) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.put(`Seller/EditSellerProfile`, sellerInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const deleteBoothProductAsync = async (boothProductId) => {
  debugger;
  const response = await axios.delete(
    `Seller/DeleteProductInBooth?BoothProductId=${boothProductId}`
  );
  return response.data;
};
export const deleteActiveProduct = async (boothProductId) => {
  debugger;
  const response = await axios.put(
    `Seller/HideProduct?BoothProductId=${boothProductId}`
  );
  return response.data;
};
export const getSellerBoothsToPayAsync = async (sellerId) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.get(
    `Seller/GetSellerBoothsToPay?SellerId=${sellerId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const confirmBoothPaymentAsync = async (paymentInfo) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.post(
    `Payment/ConfirmBoothPayment`,
    paymentInfo,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const deletePendingBoothAsync = async (boothId) => {
  const response = await axios.post(`Booth/DeleteBooth?BoothId=${boothId}`);
  debugger;
  return response.data;
};

export const getSellerBoothProducts = async (
  boothId,
  pageSize,
  pageIndex,
  Id,
  Price
) => {
  debugger;
  const token = localStorage.token;
  const response = await axios.get(
    `Seller/GetSellerBoothProducts?BoothId=${boothId}&Id=${Id}&Price=${Price}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return response.data;
};
export const searchInBoothsByProduct = async (
  boothId,
  pageSize,
  pageIndex,
  Id,
  Price
) => {
  debugger;
  const token = localStorage.token;
  const response = await axios.get(
    `Seller/SearchInBoothsByProductName?BoothId=${boothId}&ProductName=o&Id=${Id}&Price=${Price}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  console.log(response.data);
  return response.data;
};
