import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  st: {
    flexBasis: "20%",
    ["@media (max-width:1024px)"]: {
      flexBasis: "25%",
    },
    ["@media (max-width:768px)"]: {
      flexBasis: "35%",
    },
    ["@media (max-width:500px)"]: {
      flexBasis: "50%",
    },
  },
}));
const HomeBoothCard = (props) => {
  const { t } = useTranslation();
  const { CategoryImgs, cat } = props;
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState(0);
  const imgCat = CategoryImgs.find((img) => img.id === cat.id)?.catImg;

  return (
    // <div className="col-lg-4 col-sm-6 my-3">
    <Grid
      item
      className={classes.st}
      style={{ padding: "1px", cursor: "pointer" }}
      xs={6}
      sm={4}
      md={3}
      onClick={() => {
        history.push("/buyer/bazarPage/1262");
        localStorage.setItem("CatId", cat.id);
      }}
    >
      <div className="bazar-booths-card">
        <div className="card-img">
          <div class="card  text-white">
            <img class="card-img" src={imgCat} alt="Card image" />
            <div
              class="card-img-overlay"
              style={{ backgroundColor: "rgb(34 34 34 / 13%)" }}
            >
              <h5 class="card-title">
                {localStorage.i18nextLng === "en" ? cat.name : cat.arabicName}
              </h5>
              <a>{t("buttons.showMore")}</a>
            </div>
          </div>
        </div>
      </div>
    </Grid>
    // </div>
  );
};

export default HomeBoothCard;
