import axios from "axios";

const organizerId = localStorage.userId;

export const getOrganizerProfileAsync = async (organizerId) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Organizer/GetOrganizerProfile?organizerId=${organizerId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getOrganizerBazarsAsync = async (organizerId) => {
  const token = localStorage.token;
  const response = await axios.get(
    `Organizer/GetOrganizerBazars?organizerId=${organizerId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response.data);
  return response.data;
};
export const deleteBazarAsync = async (bazarId) => {
  const token = localStorage.token;
  debugger;
  const response = await axios.put("Bazar/DeleteBazar?BazarId=" + bazarId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getOrganizerBoothRequest = async (pageIndex, pageSize) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Organizer/GetOrganizerBoothRequests?organizerId=${organizerId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getOrganizerBoothRquestsAsync = async (page, pageSize, filter) => {
  debugger;
  const token = localStorage.token;
  let url = !filter
    ? `Organizer/GetOrganizerBoothRequests?organizerId=${
        localStorage.userId
      }&PageSize=${pageSize}&PageIndex=${page + 1}`
    : `Organizer/GetBoothRequestsByBazarName?BazarId=${filter}&PageSize=${pageSize}&PageIndex=${
        page + 1
      }`;
  // debugger
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const acceptBoothRequestAsync = async (boothId) => {
  const token = localStorage.token;

  const response = await axios.put(
    `Organizer/AcceptBoothReuest?BoothId=${boothId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const declineBoothRequestAsync = async (declineObj) => {
  const token = localStorage.token;
  console.log("declineObj:", declineObj);
  debugger;
  const response = await axios.put(
    `/Organizer/DeclineBoothRequest`,
    declineObj,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
export const getOrganizerOrdersAsync = async (
  page,
  pageSize,
  search,
  filter
) => {
  let url = "";
  // debugger
  const token = localStorage.token;
  if (filter) {
    if (search) {
      url = `Organizer/GetOrdersInBazarByOrderNumber?BazarId=${filter}&OrderNumber=${search}&PageSize=${pageSize}&PageIndex=${page}`;
    } else
      url = `Organizer/GetOrdersByBazar?BazarId=${filter}&PageSize=${pageSize}&PageIndex=${page}`;
  } else {
    ////// no filter //////////
    if (search) {
      url = `Organizer/GetOrdersByOrderNumber?OrderNumber=${search}&PageSize=${pageSize}&PageIndex=${page}`;
    } else
      url = `Organizer/GetOrganizerOrders?organizerId=${localStorage.userId}&PageSize=${pageSize}&PageIndex=${page}`;
  }
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response.data);
  return response.data;
};

export const getBoothInBazar = async (
  bazarId,
  pageSize,
  currentPage,
  Selles = 0,
  Requests = 0,
  CityId,
  CategoryId,
  search
) => {
  debugger;
  const token = localStorage.token;
  let url = `Organizer/GetBoothInBazarWithoutHide?BazarId=${bazarId}`;
  if (search) {
    url += `&BoothName=${search}`;
  }
  if (CityId) {
    url += `&CityId=${CityId}`;
  }
  if (CategoryId) {
    url += `&CategoryId=${CategoryId}`;
  }
  if (Selles) {
    url += `&Selles=${Selles}`;
  } else if (Requests) {
    url += `&Requests=${Requests}`;
  }
  url += `&PageSize=${pageSize}`;
  url += `&PageIndex=${currentPage + 1}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response.data;
};

// catch (err) {
//   console.trace(err);
//   console.log(err.response?.data);
//   debugger;
//   alert(err.message + "\r\n\r\nSee console for more info.");
// }

// export const getBazarBoothsAsync = async (
//   bazarId,
//   pageSize = 6,
//   pageIndex = 1,
// ) => {
//   const response = await axios.get(
//     `Organizer/GetBazarBooths?BazarId=${bazarId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
//     bazarId,
//   )
//   return response.data
// }
