import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const BreadcrambBazar = ({ bazarNam }) => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <section className="secondSec">
      <div className="breadcrambs">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.localStorage.removeItem("boothPage");
            history.push("/");
          }}
        >
          {t("home")}
        </span>
        /<span className="special">{bazarNam}</span>
      </div>
    </section>
  );
};

export default BreadcrambBazar;
