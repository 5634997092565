import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { handleLogout } from "../../utils/setLocalStorage";

import {
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "@material-ui/icons/Search";
import { useRef } from "react";
import ProductsRequestsCardList from "./../../components/ProductsRequestsCardList/index";
import DeclineProductReasonsModal from "../../components/DeclineProductReasonsModal";
import OrganizerBazarsDropdown from "../../components/OrganizerBazarsDropdown";
import DeclineModal from "./../../components/DeclineModal/index";
import { declineReasonsArr } from "./constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  marginTop: { marginTop: "40px" },
  searchStyling: {
    width: "300px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },
  formControl: { width: "140px" },
}));

const OrganizerProductsRequests = (props) => {
  const classes = useStyles();
  const PageRef = useRef();
  const params = useParams();
  const boothId = params.boothId;
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = React.useState("");
  const [count, setCount] = useState(9);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [reject, setReject] = React.useState(false);
  const [filteredBazarName, setFilteredBazarName] = useState("");

  const pageSize = 12;
  const organizerId = localStorage.userId;

  /////////////DECLINE MODAL //////////////////////////
  const [reasons, setReasons] = useState([...declineReasonsArr]);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [selectedBoothProductId, setSelectedBoothProductId] = useState("");
  let x = declineReasonsArr[4].checked;
  useLayoutEffect(() => {
    setTimeout(function () {
      fetchData(page, search, filteredBazarName, checked, reject);
    }, 1000);
  }, [boothId]);

  const fetchData = async (currentPage, search, filter, checked, reject) => {
    // console.log('fetch')
    const token = localStorage.token;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let response;
      if (checked) {
        if (filter) {
          response = await axios.get(
            `Organizer/GetOrganizerPendingProducts?OrganizerId=${organizerId}&BazarId=${filter}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
          if (search) {
            response = await axios.get(
              `Organizer/GetOrganizerPendingProducts?OrganizerId=${organizerId}&ProductName=${search}&BazarId=${filter}&PageSize=${pageSize}&PageIndex=${currentPage}`,
              config
            );
          }
        } else if (search) {
          response = await axios.get(
            `Organizer/GetOrganizerPendingProducts?OrganizerId=${organizerId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
          if (filter) {
            response = await axios.get(
              `Organizer/GetOrganizerPendingProducts?OrganizerId=${organizerId}&ProductName=${search}&BazarId=${filter}&PageSize=${pageSize}&PageIndex=${currentPage}`,
              config
            );
          }
        } else {
          response = await axios.get(
            `Organizer/GetOrganizerPendingProducts?OrganizerId=${organizerId}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
        }
      } else if (reject) {
        let rejectUrl = "Organizer/GetOrganizerRejectedProducts?OrganizerId=";
        if (filter) {
          response = await axios.get(
            `${rejectUrl}${organizerId}&BazarId=${filter}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
          if (search) {
            response = await axios.get(
              `${rejectUrl}${organizerId}&BazarId=${filter}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
              config
            );
          }
        } else if (search) {
          response = await axios.get(
            `${rejectUrl}${organizerId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
          if (filter) {
            response = await axios.get(
              `${rejectUrl}${organizerId}&BazarId=${filter}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
              config
            );
          }
        } else {
          response = await axios.get(
            `${rejectUrl}${organizerId}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
        }
      } else if (filter) {
        if (search) {
          response = await axios.get(
            `Organizer/GetOrganizerProductsByProductFilterByBazar?BazarId=${filter}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
        } else {
          response = await axios.get(
            `Organizer/GetOrganizerProductsFilterByBazar?BazarId=${filter}&PageSize=${pageSize}&PageIndex=${currentPage}`,
            config
          );
        }
      } else {
        if (!search) {
          response = boothId
            ? await axios.get(
                `Organizer/GetAllBoothProducts?BoothId=${boothId}&PageSize=${pageSize}&PageIndex=${currentPage}`,
                config
              )
            : await axios.get(
                `Organizer/GetAllOrganizerProducts?OrganizerId=${organizerId}&PageSize=${pageSize}&PageIndex=${currentPage}`,
                config
              );
        } else {
          response = boothId
            ? await axios.get(
                `Organizer/GetBoothProductsByProductName?BoothId=${boothId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
                config
              )
            : await axios.get(
                `Organizer/GetBoothProductsByProductName?OrganizerId=${organizerId}&ProductName=${search}&PageSize=${pageSize}&PageIndex=${currentPage}`,
                config
              );
        }
      }
      console.log(response);

      const { data, count } = response.data;
      setProducts(data);
      console.log(response.data, "dddd");
      setCount(count);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
  const fetchFilteredData = async (filterName) => {
    setFilteredBazarName(filterName);
    fetchData(page, search, filterName);
    // if (filterName) {
    //   const response = await axios.get(
    //     `Organizer/GetBoothProductsByBazarName?BazarName=${filterName}&PageSize=${pageSize}&PageIndex=${page}`,
    //   )
    //   const { data, count } = response.data
    //   setProducts(data)
    //   setCount(count)
    // } else fetchData(page, search)
  };
  const scrollPage = () => PageRef?.current?.scrollIntoView();
  const handleChange = (event, newPage) => {
    console.log(newPage);
    fetchData(newPage, search, filteredBazarName, checked, reject);
    setPage(newPage);
    setTimeout(() => scrollPage(), 1000);
  };

  const handleSearchChange = (event, value) => {
    fetchData(1, event.target.value, filteredBazarName, checked, reject);
    setPage(1);
    setSearch(event.target.value);
  };
  const handleFilterChange = (e) => {
    console.log(e.target.value);
    setFilteredBazarName(e.target.value);
    fetchFilteredData(e.target.value);
  };

  const handleAcceptRequest = async (boothProductId) => {
    let newproducts = [...products];
    try {
      newproducts = newproducts.map((product) => {
        if (product.id === boothProductId) {
          product.status = "Accepted";
        }
        return product;
      });
      //

      axios.put(
        `/Organizer/AcceptBoothProduct?BoothProductId=${boothProductId}`
      );
    } catch (error) {
      console.log(error);
      newproducts = newproducts.filter((product) =>
        product.id === boothProductId ? (product.status = "Pending") : ""
      );
    }
    setProducts(newproducts);
  };
  const handleDeclineRequest = async (boothProductId) => {
    handleOpenDeclineModal();

    setSelectedBoothProductId(boothProductId);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCheckBoxChange = (e) => {
    let newReasonsArr = [...reasons];
    const checkedId = +e.target.value;
    console.log("checkedId", checkedId);
    newReasonsArr = newReasonsArr.map((item) => {
      if (item.id === checkedId) {
        console.log(item);
        item.checked = !item.checked;
        console.log(item);
        setError("");
      }
      return item;
    });
    console.log(newReasonsArr);
    setReasons(newReasonsArr);
  };

  const handleOpenDeclineModal = () => {
    setOpen(true);
  };

  const handleCloseDeclineModal = () => {
    // setDescription('')
    setReasons(declineReasonsArr);
    setOpen(false);
  };

  const postDeclineObject = async (declineObject) => {
    let newproducts = [...products];
    // let product = newproducts[selectedBoothProductId]
    console.log(declineObject);
    try {
      const response = await axios
        .put(`/Organizer/DeclineBoothProduct`, declineObject)
        .then(() => {
          newproducts = newproducts.filter(
            (product) => product.id !== selectedBoothProductId
          );
          setProducts(newproducts);
        });
      console.log(response);
    } catch (error) {
      console.log(error);
      // newproducts.push(product)
    }
  };
  const handleModalSubmit = async () => {
    let declineReason = [];
    let reasonSelected = false;

    reasons.map((item) => {
      console.log(item, "imt");
      if (item.reason === "Other ") {
        x = true;
      }
      if (item.checked === true) {
        if (item.reason !== "Other ") {
          declineReason.push(item.reason);
        } else {
          declineReason.push(description);
        }
        reasonSelected = true;
      }
    });

    if (!reasonSelected) {
      setError("You should Select Reason");
    } else {
      declineReason = declineReason.join(",");
      const declineObj = {
        boothProductId: selectedBoothProductId,
        declineReason,
        declineDescription: description,
      };
      setReasons(declineReasonsArr);
      handleCloseDeclineModal();
      console.log(declineObj);
      await postDeclineObject(declineObj);
    }
  };

  const handleCheckChange = (e) => {
    setChecked(e.target.checked);
    setReject(false);
    fetchData(1, search, filteredBazarName, e.target.checked, false);
    setPage(1);
  };

  const handleRejectedProducts = (e) => {
    setChecked(false);
    setReject(e.target.checked);
    fetchData(1, search, filteredBazarName, false, e.target.checked);
    setPage(1);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h3 ref={PageRef}>{t("products")}</h3>
      </Grid>
      {props?.location?.pathname === "/Organizer/organizerProductsRequests" ? (
        <Grid
          container
          item
          xs={12}
          style={{
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                value={checked}
                onChange={(e) => handleCheckChange(e)}
                color="primary"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("pendingProducts")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={reject}
                value={reject}
                onChange={(e) => handleRejectedProducts(e)}
                color="primary"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("rejectedProducts")}
          />
        </Grid>
      ) : (
        ""
      )}
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        wrap="wrap"
        spacing={1}
      >
        {/* <Grid item xs={12} md={6}> */}
        <TextField
          className={classes.searchStyling}
          // variant="outlined"
          onChange={handleSearchChange}
          value={search}
          id="input-with-icon-textfield"
          // label="Products"
          placeholder={t("SearchforProduct")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        {!boothId && (
          <OrganizerBazarsDropdown
            classes={classes}
            handleChange={handleFilterChange}
            value={filteredBazarName}
          />
        )}
      </Grid>
      {products.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
            height: "max-content",
          }}
        >
          <Box width="180px">
            <img
              src="/assets/images/SVG/shipping.svg"
              width="100%"
              height="100%"
            />
          </Box>
          <div>{t("donotproduct")}</div>
        </div>
      ) : (
        <>
          <Grid item xs={12}>
            <ProductsRequestsCardList
              products={products}
              handleAcceptRequest={handleAcceptRequest}
              handleDeclineRequest={handleDeclineRequest}
              role="Organizer"
            />
          </Grid>
          {products.length > 0 && (
            <Grid item xs={12} container justify="center">
              <div className={classes.marginTop}>
                <Pagination
                  count={Math.ceil(count / pageSize)}
                  color="primary"
                  size="small"
                  page={page}
                  onChange={handleChange}
                />
              </div>
            </Grid>
          )}
        </>
      )}

      {/* <DeclineProductReasonsModal
        handleOpen={handleOpenDeclineModal}
        handleClose={handleCloseDeclineModal}
        open={open}
      /> */}
      <DeclineModal
        handleOpen={handleOpenDeclineModal}
        handleClose={handleCloseDeclineModal}
        handleDescriptionChange={handleDescriptionChange}
        descriptionValue={description}
        open={open}
        handleModalSubmit={handleModalSubmit}
        handleCheckBoxChange={handleCheckBoxChange}
        reasonsArr={reasons}
        error={error}
        x={x}
      />
    </Grid>
  );
};

export default OrganizerProductsRequests;
