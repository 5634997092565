import { Button } from "@material-ui/core/index";
import { useState } from "react";

export default function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <input
        type="text"
        value={copyText}
        readOnly
        style={{ display: "none" }}
      />
      {/* Bind our handler function to the onClick button property */}
      <Button
        variant="contained"
        onClick={handleCopyClick}
        size="small"
        style={{
          backgroundColor: "#F8B334",
          color: " white",
          margin: " 1rem .5rem",
          height: "1.5rem",
        }}
      >
        <span style={{ fontSize: ".8rem" }}>
          {isCopied ? "Copied" : "Copy Link"}
        </span>
      </Button>
    </div>
  );
}
