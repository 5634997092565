import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useHistory } from "react-router";

const Product = ({
  price,
  nam,
  rate,
  srcProductImg,
  // notBuyer,
  productId,
  // flag,
  isLoggedIn,
  currentPage,
  boothName,
  boothLogo,
  boothId,
  offerPrice,
  startOffer,
  endOffer,
  currency,
}) => {
  const checkDate = () => {
    if (
      offerPrice &&
      new Date() >= new Date(startOffer + "Z") &&
      new Date() <= new Date(endOffer + "Z")
    ) {
      return true;
    } else return false;
  };
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const userRole = localStorage.userRole;
  return (
    <div className="cardmy">
      <Card className={classes.root}>
        <Link
          onClick={() => {
            window.localStorage.removeItem("page");
            history.push(
              `/buyer/productDetailsPage/${productId}/${currentPage}`
            );
            window.location.reload();
            window.scrollTo(0, 0);
          }}
        >
          <CardMedia
            className={classes.media}
            image={srcProductImg}
            title={nam}
          />
        </Link>
        <CardContent>
          <div className={classes.cardContent}>
            <Typography
              gutterBottom
              // variant="body2"
              component="h2"
              style={{ maxWidth: "66%", fontSize: "16px", fontWeight: "600" }}
            >
              {nam.length >= 15 ? nam.slice(0, 15).concat("...") : nam}
            </Typography>
            <Typography gutterBottom component="h5" style={{ color: "orange" }}>
              <span
                className={checkDate() ? classes.textD : ""}
                style={
                  checkDate()
                    ? {
                        color: "black",
                      }
                    : {}
                }
              >
                {currency} {price}
              </span>
              <span>
                {checkDate() ? (
                  <>
                    {currency} {offerPrice}
                  </>
                ) : (
                  ""
                )}
              </span>
            </Typography>
          </div>
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          <Link to={`/buyer/boothPage/${boothId}`} className={classes.link}>
            <Avatar
              alt={nam}
              src={localStorage.imagePath + boothLogo}
              className={classes.mr5}
              sx={{ width: 30, height: 30 }}
            />
            <Typography
              dangerouslySetInnerHTML={{ __html: boothName }}
              color="Secondary"
              component="h5"
              style={{ textDecoration: "underline" }}
            />
          </Link>
          <Link to={`/buyer/productDetailsPage/${productId}/${currentPage}`}>
            {/* {isLoggedIn ? ( */}
            <IconButton aria-label="Add to Cart">
              <AddShoppingCart />
            </IconButton>
            {/* ) : (
              <span style={{ color: "orange" }}>{t("buttons.showMore")}</span>
            )} */}
          </Link>
        </CardActions>
      </Card>
    </div>
  );
};

export default Product;
