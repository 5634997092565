import React, { useEffect, useState } from "react";
import SellerHeader from "../../components/SellerHeader";

import CountriesCardCarousel from "../../components/CountriesCardCarousel/index";
import ContactUs from "../../components/ContactUs";
import BazarsListing from "../../components/BazarsListing/index";
import "./sellerHome.css";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Card,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppBazars, getAllBuyerBazars } from "../../redux/bazar/actions";

import AppPagination from "../../components/Pagination";

import { getAllComingSoonBazars } from "./../../redux/bazar/actions";
import { getAllCountries } from "./../../redux/address/actions";
import ControlledAppModal from "../../components/ControlledAppModal";
import BookBooth from "./../../components/BookBooth/index";
import { getSellerById } from "./../../redux/seller/actions";
import axios from "axios";
import { setSnackbar } from "../../redux/Message/actions";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import QuestionsAccordion from "./../../components/QuestionsAccordion/index";
// import { useStyles } from '../../components/ProfileForm'
import { makeStyles } from "@material-ui/core";
import Testimonials from "./../../components/Testimonials";
import { TestimonialsItems } from "../../components/Testimonials/constants";
const useStyles = makeStyles((theme) => ({
  activeRole: {
    backgroundColor: "#f8b334",
    color: "white",
  },
  rolesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // width: '14%',
    backgroundColor: "#ffa50012;",
    padding: "10px 0px 10px 10px",
    // marginTop: '-28px',
    flexWrap: "wrap",
    position: "fixed",
    top: "100px",
    right: "0px",
  },
  roleCard: {
    padding: "20px 14px",
    margin: "40px -30px",
    textAlign: "center",
    transform: "rotate(-90deg)",
    cursor: "pointer",
    "&:hover": {
      // cursor: 'poniter',
    },
  },
  root: {
    maxWidth: "100%",
    padding: "3%",
    textAlign: "center",
    boxShadow: "none",
    backgroundColor: "#F9F9F9",
    margin: "3rem 0",
  },
  back: {
    backgroundColor: "#F9F9F9",
    width: "100%",
  },
  imgg: {
    width: "3.5rem",
    margin: ".8rem",
  },
  disp: {
    display: "flex",
    marginTop: "3rem",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1%",
    flexWrap: "wrap",
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
}));

const HomePage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const isLoggedIn = localStorage.token;
  const pageSize = 9;
  const [page, setPage] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedBazar, setSelectedBazar] = useState(null);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [hasBooth, setHasBooth] = useState();
  const [activeStatus, setActiveStatus] = useState();

  const bazarListContainerRef = useRef();

  const role = localStorage.userRole;

  // const role = ''
  // console.log(role)

  const allAppBazars = useSelector((state) => state.bazarReducer.allAppBazars);
  const allAppBazarsCount = useSelector(
    (state) => state.bazarReducer.allAppBazarsCount
  );
  // console.log(allAppBazars)
  // console.log(allAppBazarsCount)

  const countries = useSelector((state) => state.addressReducer.countries);
  const loading = useSelector((state) => state.bazarReducer.homeLoading);

  useEffect(() => {
    dispatch(getAllCountries());
    if (role === "Buyer") {
      axios
        .get("Buyer/GetBuyerAddress?BuyerId=" + localStorage.userId, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) =>
          localStorage.setItem("userAllInfo", JSON.stringify(response.data))
        );
    }

    // dispatch(getSellerById(localStorage.userId))
    fetchData(page, search, selectedCountry, pageSize);
  }, []);

  const fetchData = (page, search, selectedCountry, pageSize, activeStatus) => {
    if (role === "Seller" && props?.location?.pathname === "/Home/Seller") {
      dispatch(getAllComingSoonBazars(page, search, selectedCountry, pageSize));
      dispatch(getSellerById(localStorage.userId));
    } else if (role === "Buyer")
      dispatch(getAllBuyerBazars(page, search, selectedCountry, pageSize));
    else dispatch(getAllAppBazars(page, search, selectedCountry, pageSize));
  };
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    fetchData(newPage, search, selectedCountry, pageSize);
  };
  const handleConuntryFilterChange = (e, country = "") => {
    const countryId = e ? e.target.value : country;
    debugger;
    // const countryId = e?.target?.value
    console.log(countryId);

    setSelectedCountry(countryId);

    fetchData(1, search, countryId, pageSize);
    setPage(1);
    setTimeout(() => executeScroll(), 500);
  };
  const handleSearchChange = (e) => {
    let newSearch = e.target.value;
    console.log(newSearch);

    debugger;
    setSearch(newSearch);
    // setTimeout(() => executeScroll(), 1000)
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlestatusChange = (statusValue) => {
    if (statusValue === activeStatus) setActiveStatus("");
    else {
      setActiveStatus(statusValue);

      setTimeout(executeScroll, 100);
    }
    console.log(statusValue);
  };
  // console.log(loading, 'LOADING..')

  // const handleBookBoothSubmit = (values, { setSubmitting, resetForm }) => {
  //   let newValues = { ...values }
  //   const categoriesIds = newValues.categories.map((cat) => cat.id)
  //   newValues.categories = categoriesIds
  //   console.log(newValues)

  //   axios
  //     .post('Booth/CreateBooth', newValues)
  //     .then((response) => {
  //       console.log(response.data)
  //       setSubmitting(false)

  //       dispatch(
  //         setSnackbar(true, 'success', t('messages.Requestbookboothsent')),
  //       )

  //       handleClose()
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       console.log(error?.response)
  //       if (error?.response?.data.message)
  //         dispatch(true, 'error', error?.response?.data.message)
  //     })
  // }
  // console.log('selectedCountry222', selectedCountry)
  const onSearchIconClick = () => {
    fetchData(1, search, selectedCountry, pageSize);
    setPage(1);
    setTimeout(executeScroll, 100);
  };
  const executeScroll = () => bazarListContainerRef?.current?.scrollIntoView();
  const handleClickOpen = (bazarID) => {
    // setSelectedBazar(bazarID);
    setOpen(true);
  };

  useEffect(() => {
    if (selectedBazar) {
      axios
        .get(
          `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${selectedBazar}`
        )
        .then((response) => {
          if (response.data.succeeded) {
            handleClickOpen();
          } else if (!response.data.succeeded) {
            console.log(response.data, "sellll");
            dispatch(setSnackbar(true, "error", response.data.message));
          }
        });
    }
  }, [selectedBazar]);

  const checkSellerAlreadyHasBooth = async (bazarID) => {
    setSelectedBazar(bazarID);
    try {
      const response = await axios.get(
        `Seller/IsSellerHasBooth?SellerId=${localStorage.userId}&BazarId=${bazarID}`
      );
      if (response.data.succeeded) {
        handleClickOpen();
      } else if (!response.data.succeeded) {
        console.log(response.data, "sellll");
        dispatch(setSnackbar(true, "error", response.data.message));
      }
    } catch (error) {
      console.log("err", error.message);
    }
  };

  return (
    <>
      {/* <QuestionsAccordion /> */}
      {window.localStorage.removeItem("page")}
      {window.localStorage.removeItem("boothPage")}
      {window.localStorage.removeItem("radiobtn")}
      {window.localStorage.removeItem("ProductsTablePage")}
      <SellerHeader
        handleCountryChange={handleConuntryFilterChange}
        selectedCountry={selectedCountry}
        handleSearchChange={handleSearchChange}
        searchValue={search}
        countries={countries}
        onSearchIconClick={onSearchIconClick}
        role={role}
      />
      <CountriesCardCarousel
        countries={countries}
        handleConuntryFilterChange={handleConuntryFilterChange}
        selectedCountry={selectedCountry}
      />
      <Container>
        {loading ? (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: "50px 0",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : allAppBazars.length > 0 ? (
          <>
            <BazarsListing
              bazarsArr={allAppBazars}
              role={isLoggedIn ? role : ""}
              handleOpenBookBoothModal={checkSellerAlreadyHasBooth}
              handleCloseBookBoothModal={handleClose}
              bazarListContainerRef={bazarListContainerRef}
            />
            <ControlledAppModal open={open} handleClose={handleClose}>
              <div style={{ maxWidth: "600px" }}>
                <BookBooth
                  bazarId={selectedBazar}
                  countries={countries}
                  handleCloseBookBoothModal={handleClose}
                />
              </div>
            </ControlledAppModal>
          </>
        ) : (
          <Typography
            style={{
              textAlign: "center",
              textColor: "black",
              padding: "50px 0",
            }}
          >
            {t("noBazars")}
          </Typography>
        )}
      </Container>
      <Grid item xs={12} container justify="center" position="relative">
        <>
          {/* {role !== 'Seller' && (
            <div className={classes.rolesContainer}>
              {[
                { statusVal: 1, name: 'Active' },
                { statusVal: 2, name: 'CommingSoon' },
                { statusVal: 3, name: 'Finished' },
              ].map((status) => (
                <Paper
                  key={status.statusVal}
                  className={
                    activeStatus === status.statusVal
                      ? classes.roleCard + ' ' + classes.activeRole
                      : classes.roleCard
                  }
                  onClick={() => handlestatusChange(status.statusVal)}
                >
                
                  {status.name}
                </Paper>
              ))}
            </div>
          )} */}
        </>
        {allAppBazars.length > 0 ? (
          <AppPagination
            count={Math.ceil(allAppBazarsCount / pageSize)}
            page={page}
            handlePageChange={handlePageChange}
          />
        ) : (
          ""
        )}
      </Grid>
      <h3 style={{ textAlign: "center" }}>{t("testimonials")}</h3>
      <Container className={classes.disp}>
        {TestimonialsItems.map((item, index) => (
          <Testimonials
            value={item.value}
            key={index}
            nam={item.nam}
            imgsrc={item.imgsrc}
            job={item.job}
            text={item.text}
            margin={item.margin}
          />
        ))}
      </Container>
      <div className={classes.back}>
        <Card className={classes.root}>
          <h2>{t("follow")}</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCrLtOsV32yxgEPxbc6ZbBtA/featured"
            >
              <img
                className={classes.imgg}
                src="/assets/images/icons/yotube.svg"
                alt="yotube"
              />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/ebazarak_website?utm_medium=copy_link"
            >
              <img
                className={classes.imgg}
                src="/assets/images/icons/insta.svg"
                alt="insta"
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/eBazarakcom-100210322137343"
            >
              <img
                className={classes.imgg}
                src="/assets/images/icons/facebook.svg"
                alt="facebook"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/e-bazark/?viewAsMember=true"
            >
              <img
                className={classes.imgg}
                src="/assets/images/icons/linkedin.svg"
                alt="linkedin"
              />
            </a>
          </div>
          <ContactUs cm={true} />
        </Card>
      </div>
    </>
  );
};

export default HomePage;
