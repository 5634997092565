import { USER_LOGIN, LOGIN_ERROR } from "../actionTypes";
import { setUserInfoTolocalStorage } from "./../../utils/setLocalStorage";
const initialState = {
  logedIn: false,
  userInfo: "",
  errors: [],
  token: localStorage.token || "",
  userRole: localStorage.userRole,
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      debugger;
      const userInfo = action.payload;
      setUserInfoTolocalStorage(userInfo);
      return {
        ...state,
        userInfo: userInfo,
        logedIn: true,
        errors: "",
        token: userInfo.token,
        userRole: userInfo.roles[0],
      };
    case LOGIN_ERROR:
      debugger;
      return {
        ...state,
        errors: action.payload,
        logedIn: false,
      };
    default:
      return state;
  }
};
export default loginReducer;
