import Resizer from "react-image-file-resizer";

const fileChangedHandler = (e, setFieldValue, index, func) => {
  let fileInput = false;
  if (e.target.files[0]) {
    fileInput = true;
  }
  if (fileInput) {
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        1100,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri, "uri");
          func(uri, setFieldValue, index);
        },
        "file",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  }
};
export default fileChangedHandler;
