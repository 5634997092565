import { Grid } from '@material-ui/core'
import React from 'react'
import ProductRequestCard from '../ProductRequestCard'

const ProductsRequestsCardList = ({
  products,
  handleAcceptRequest,
  handleDeclineRequest,
  role,
}) => {
  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid
          container
          item
          xs={12}
          // sm={6}
          lg={4}
          key={product.id}
          justify="center"
        >
          <ProductRequestCard
            {...product}
            role={role}
            handleAcceptRequest={handleAcceptRequest}
            handleDeclineRequest={handleDeclineRequest}
          />
        </Grid>
      ))}
    </Grid>
  )
}
ProductsRequestsCardList.defaultProps = {
  products: [],
}

export default ProductsRequestsCardList
