import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import UserProfileSection from "./../../components/UserProfileSection/index";
import { phoneRegExp } from "../App/constants";
import { editSellerProfile } from "./../../redux/seller/actions";
import { editProviderProfile } from "../../redux/serviceProvider/actions";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";

const ProviderProfile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [image, setImage] = useState("");
  //   const organizerId = localStorage.userId
  const [countryName, setCountryName] = useState("");
  const provider = useSelector(
    (state) => state.serviceProviderReducer.provider
  );
  const editProfile = useSelector(
    (state) => state.serviceProviderReducer.editProfile
  );
  console.log(editProfile);
  const initialValues = provider;

  useEffect(() => {
    setImage(provider.profileImage || "");
  }, [provider]);

  useEffect(() => {
    debugger;
    if (editProfile.editing) props.history.push("/dashBoard/Provider");
  }, [editProfile]);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .max(25, t("validation.nameLength25"))
      .required(t("validation.companyNameRequired")),
    representativeName: Yup.string().required(
      t("validation.representativeNameRequired")
    ),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    // phoneNumber: Yup.string()
    //   .matches(phoneRegExp, t('validation.invalidPhone'))
    //   .required(t('validation.phoneRequired')),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
  });

  useEffect(() => {
    return () => {
      console.log("Cleared");
      dispatch({ type: "PROVIDER_PROFILE_UNMOUNT" });
    };
  }, []);
  const handleProfileImgChange = (event) => {
    const newImage = event.target?.files?.[0];
    console.log(newImage);
    const formData = new FormData();
    formData.set(newImage.name, newImage);
    ////////////////////////// Call UPLOAD IMG API //////////////
    console.log(newImage);
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    values.profileImage = image;
    debugger;
    // if (isValidPhoneNumber(values.phoneNumber) === false) {
    //   dispatch(setSnackbar(true, "error", "please enter valid number"));
    // } else {
    dispatch(editProviderProfile(values));
    setTimeout(() => {
      // resetForm({})
      // props.history.push('/dashBoard/Provider')
    }, 4000);
    // }
  };
  console.log(initialValues, provider, "initialValues");
  const fieldNames = [
    { name: "companyName", label: t("companyName") },
    { name: "representativeName", label: t("representativeName") },
  ];
  return (
    <UserProfileSection
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      countryName={
        localStorage.i18nextLng === "en"
          ? provider?.country?.name
          : provider?.country?.arabicName
      }
      handleProfileImgChange={handleProfileImgChange}
      image={image}
      role="Provider"
      fieldNames={fieldNames}
      setImage={setImage}
    />
  );
};

export default ProviderProfile;
