import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Breadcrumbs,
  Typography,
  Link,
} from "@material-ui/core";
import FormikField from "../FormikField";
import CatDropDown from "./../CategoriesDropDown/index";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from "formik-material-ui-pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FormikMultiLineField from "./../FormikMultiLineField/index";
import FormikSelect from "../FormikSelect";
import { TextField, Select } from "formik-material-ui";
import UploadButtons from "./../UploadButtons/index";
import { uploadImageAsync } from "./../../service/image";
import { useDispatch, useSelector } from "react-redux";
import { getCateories } from "./../../redux/catalog/actions";
import { getBazarCategories } from "../../redux/bazar/actions";
import { setSnackbar } from "../../redux/Message/actions";
import { getBoothHome } from "../../redux/booth/actions";
import CloseIcon from "@material-ui/icons/Close";
import MouseOverPopover from "./../PopoverOnHover/index";
import { useTranslation } from "react-i18next";
import { getBazarByBoothIdAsync } from "../../redux/bazar/api";
import { colors as appColors } from "../../styles/commonCss";
import { Divider } from "@material-ui/core/index";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useHistory } from "react-router";
import fileChangedHandler from "./../../containers/ImgUploader/index";

const useStyles = makeStyles((theme) => ({
  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "15px 20px ",
    width: "100%",
    margin: "30px 0",
    display: "flex",
  },
  addPhotoContainer: {
    width: "190px",
    height: "220px",
    border: "1px solid lightgray",
    position: "relative",
  },
  disp: {
    display: "flex",
    // ['@media (max-width:680px)']: {
    //   flexDirection: 'column',
    // },
  },
  mt: {
    marginTop: "50px",
    ["@media (max-width:780px)"]: {
      marginTop: "0px",
    },
  },
  input: {
    margin: "0 .5rem",
  },
}));
const AddProductForm = ({
  initialValues,
  boothId,
  validationSchema,
  bazarId,
  submitProduct,
  existProduct,
  boothBazar,
  location,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const productStatus = [
    { id: 1, name: t("productStatus.active") },
    { id: 0, name: t("productStatus.draft") },
  ];
  const colors = [
    { id: "red", name: "red" },
    { id: "black", name: "black" },
    { id: "blue", name: "blue" },
    { id: "pink", name: "pink" },
    { id: "gray ", name: "gray" },
    { id: "brown ", name: "brown" },
    { id: "orange", name: "orange" },
    { id: "purple", name: "purple" },
    { id: "silver", name: "silver" },
    { id: "maroon ", name: "maroon " },
    { id: "gold ", name: "gold " },
    { id: "blue ", name: "blue" },
    { id: "green ", name: "green" },
    { id: "white ", name: "white" },
    { id: "yellow", name: "yellow" },
    { id: "navy", name: "navy" },
    { id: "beige", name: "beige" },
    { id: "ivory", name: "ivory" },
    { id: "#E3BC9A", name: "nude" },
    { id: "#faf9f6", name: "off-white" },
    { id: "#ffdb58", name: "mustard" },
  ];
  const booth = useSelector((state) => state.boothReducer.booth);
  useEffect(() => {
    // debugger
    !boothId ? dispatch(getCateories()) : dispatch(getBoothHome(boothId));
  }, []);
  console.log(booth, "boooo");
  const categories = useSelector((state) => {
    return boothId
      ? state.boothReducer.booth.boothCategories
      : state.catalogReducer.categories;
    // return state.catalogReducer.categories
  });

  const handleImgaeUpload = async (imag, setFieldValue, index) => {
    debugger;
    const img = imag;
    const name = img?.name;
    setFieldValue(`productColor[${index}].imageName`, img?.name);
    try {
      const formDate = new FormData();
      formDate.set(name, img);
      const imagePath = await uploadImageAsync(formDate, index);
      setFieldValue(`productColor[${index}].url`, imagePath);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };
  const handleProductImageUpload = async (imag, setFieldValue, index) => {
    // const img = e.target.files[0];
    console.log(index, "poii");
    const img = imag;
    console.log("image", imag);
    const name = img?.name;
    try {
      const formDate = new FormData();
      formDate.set(name, img);
      const imageName = await uploadImageAsync(formDate, index);
      setFieldValue(`productImage[${index}].url`, imageName);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
    }
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    let submitValues = JSON.parse(JSON.stringify(values));

    submitValues.sellerId = localStorage.userId;
    if (boothId && !existProduct) submitValues.boothId = +boothId;
    if (!boothId) delete submitValues.status;
    if (existProduct) submitValues.boothId = +boothId;

    debugger;
    // oihcg;
    // submitValues.productColor = submitValues.productColor.filter(
    //   (product) => product.color !== '' && product.quantity !== '',
    // )

    submitValues.productColor.forEach((color) => {
      color.quantity = +color.productSize.reduce((acc, current) => {
        current.quantity = +current.quantity;
        return acc + +current.quantity;
      }, 0);
    });

    let totalColorsQuantity = submitValues.productColor.reduce(
      (acc, color) => acc + color.quantity,
      0
    );
    if (totalColorsQuantity !== 0) {
      if (totalColorsQuantity !== values.quantity) {
        dispatch(
          setSnackbar(
            true,
            "error",
            t("messages.TotalQuantityequalcolorquantities")
          )
        );
        setSubmitting(false);
        return;
      } else console.log("Doneeeeeeeeeeeeeeeeeeeeee");
    }

    submitValues.productImage.filter((img) => img.url !== "");

    submitValues.productColor = submitValues.productColor.filter(
      (color) => color.color !== ""
    );
    submitValues.productColor.forEach(
      (color) =>
        (color.productSize = color.productSize.filter(
          (sizeObj) => sizeObj.size !== ""
        ))
    );

    console.log(submitValues, "submitValues");
    await submitProduct(submitValues, setSubmitting);
    // setSubmitting(false)
    // history.push(`/Seller/booth/${boothId}/products`);
  };

  return (
    <div style={{ width: "100%" }}>
      {boothId ? (
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: ".5rem" }}>
          <Link
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() => history.push(`/Seller/boothHome/${boothId}`)}
          >
            {booth.name}
          </Link>
          <Typography className="special">{t("buttons.addProduct")}</Typography>
        </Breadcrumbs>
      ) : (
        ""
      )}
      <Grid container>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            validateForm,
            isSubmitting,
            handleReset,
            setFieldValue,
          }) => {
            console.log(values, initialValues, "ere");

            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form autoComplete="false" style={{ width: "100%" }}>
                  <Grid item container xs={12} spacing={2}>
                    <h3 className={classes.basicInfoTitle}>
                      <Box width="20px" ml="-0.5px" mr="10px" height="20px">
                        <img
                          src={`/assets/images/SVG/images.svg`}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      {t("addProductForm.productImages")}
                    </h3>
                  </Grid>

                  <Grid container spacing={2}>
                    <FieldArray name="productImage">
                      {({ push, remove }) => (
                        <>
                          <Grid item>
                            <div
                              style={{
                                width: "190px",
                                height: "220px",
                                border: "1px solid lightgray",
                                fontSize: "100px",
                                color: "white",
                                backgroundColor: "orange",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                push({
                                  url: "",
                                })
                              }
                            >
                              +
                            </div>
                          </Grid>

                          {values.productImage.map((val, index) => (
                            <Grid item>
                              {console.log(val, "valll", index)}
                              <div className={classes.addPhotoContainer}>
                                <Box position="absolute">
                                  {/* <UploadButtons
                                    type="Camera"
                                    handleChange={(e) =>
                                      handleProductImageUpload(
                                        e,
                                        setFieldValue,
                                        index,
                                      )
                                    }
                                  /> */}
                                  <Link
                                    className={classes.input}
                                    variant="contained"
                                    component="label"
                                    size="small"
                                  >
                                    Upload File
                                    <input
                                      type="file"
                                      hidden
                                      multiple
                                      onChange={(e) =>
                                        fileChangedHandler(
                                          e,
                                          setFieldValue,
                                          index,
                                          handleProductImageUpload
                                        )
                                      }
                                    />
                                  </Link>
                                  <IconButton
                                    onClick={() => remove(index)}
                                    // style={{ top: "-45px", left: "164px" }}
                                  >
                                    <MouseOverPopover
                                      text={t("addProductForm.deleteImage")}
                                    >
                                      <CloseIcon
                                        style={{ position: "unset" }}
                                      />
                                    </MouseOverPopover>
                                  </IconButton>
                                </Box>
                                {/* <Box
                                  position="absolute"
                                  zIndex="1"
                                  top="30%"
                                  left="20%"
                                  background
                                >
                                  jjjjj
                                </Box> */}
                                {val?.url === "" ? (
                                  ""
                                ) : (
                                  <img
                                    src={
                                      localStorage.imagePath +
                                      values.productImage[index].url
                                    }
                                    className="img"
                                    style={{ zIndex: 2 }}
                                  />
                                )}
                              </div>
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid></Grid>
                  <Grid item container xs={12}>
                    {/* <section id="basic-info"> */}
                    <Grid item container xs={12}>
                      <h3 className={classes.basicInfoTitle}>
                        <Box width="20px" ml="-0.5px" mr="10px" height="20px">
                          <img
                            src={`/assets/images/SVG/product info.svg`}
                            width="100%"
                            height="100%"
                          />
                        </Box>
                        {t("addProductForm.basicInformation")}
                      </h3>
                    </Grid>
                    <Grid item container xs={12} spacing={3}>
                      <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        lg={boothId ? 4 : 3}
                        justify="center"
                      >
                        <FormikField
                          name="name"
                          label={t("addProductForm.productName")}
                        />
                      </Grid>
                      {console.log(location, "loc")}
                      {location === "/Seller/AddProduct" ? (
                        ""
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          md={6}
                          lg={boothId ? 4 : 3}
                          justify="center"
                          alignItems="center"
                        >
                          {/* <CatDropDown
                          name="categoryId"
                          label={t('category')}
                          setFieldValue={setFieldValue}
                        /> */}

                          <FormikSelect
                            name="categoryId"
                            items={categories}
                            label={t("category")}
                          />
                          {/* <FormikField name="name" label={t('category')} fullwidth /> */}
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        lg={boothId ? 4 : 3}
                        justify="center"
                      >
                        <FormikField
                          name="quantity"
                          label={t("quantity")}
                          type="number"
                        />
                      </Grid>
                      {boothId ? (
                        <>
                          <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            // lg={boothId ? 4 : 3}
                            lg={3}
                            justify="center"
                          >
                            <FormikField
                              name="price"
                              label={t("price")}
                              type="number"
                              currency={true}
                              currencyOfBooth={booth?.currency}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={3}
                            // lg={4}
                            justify="center"
                          >
                            <FormikField
                              name="offerPrice"
                              label={t("addProductForm.offerPrice")}
                              type="number"
                              currency={true}
                              currencyOfBooth={booth?.currency}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={3}
                            // lg={4}
                            justify="center"
                            style={{ marginTop: "10px" }}
                          >
                            <Field
                              component={DatePicker}
                              name="startOffer"
                              label={t("addProductForm.startOfferDate")}
                              inputVariant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CalendarTodayIcon />
                                  </InputAdornment>
                                ),
                              }}
                              minDate={booth.startOfBazar}
                              maxDate={booth.endOfBazar}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            style={{ marginTop: "10px" }}
                          >
                            <Field
                              component={TimePicker}
                              name="startOffer"
                              label={t("createBazar.step1Form.at")}
                              inputVariant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <AccessTimeIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item container xs={12} md={3} lg={6}></Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={3}
                            // lg={4}
                            justify="center"
                            style={{ marginTop: "10px" }}
                          >
                            <Field
                              component={DatePicker}
                              name="endOffer"
                              label={t("addProductForm.endOfferDate")}
                              inputVariant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CalendarTodayIcon />
                                  </InputAdornment>
                                ),
                              }}
                              // minDa minDate={minStartDate}
                              minDate={values.startOffer}
                              maxDate={booth.endOfBazar}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            style={{ marginTop: "10px" }}
                          >
                            <Field
                              component={TimePicker}
                              name="endOffer"
                              label={t("createBazar.step1Form.at")}
                              inputVariant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <AccessTimeIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      <Grid
                        item
                        container
                        xs={12}
                        // md={6}
                        lg={8}
                        justify="center"
                      >
                        <FormikMultiLineField
                          name="description"
                          label={t("description")}
                        />
                      </Grid>
                    </Grid>

                    {/* </section> */}
                  </Grid>
                  {boothId ? (
                    <Grid container>
                      <h3 className={classes.basicInfoTitle}>
                        <Box width="20px" ml="-0.5px" mr="10px" height="20px">
                          <img
                            src={`/assets/images/SVG/product status.svg`}
                            width="100%"
                            height="100%"
                          />
                        </Box>
                        {t("addProductForm.productStatus")}
                      </h3>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ paddingLeft: "20px" }}
                      >
                        <Grid item xs={12}>
                          <h2>{t("status")}</h2>
                        </Grid>
                        <Grid item xs={12}>
                          <p style={{ width: "50%" }}>
                            {t("addProductForm.activeDraftDescription")}{" "}
                            <span
                              style={{
                                color: appColors.darkOrgange,
                                fontWeight: "bold",
                                fontSize: "0.9rem",
                              }}
                            >
                              {t("productStatus.draft")}
                            </span>
                          </p>
                        </Grid>
                        <Grid item xs={3}>
                          <FormikSelect
                            name="productStatus"
                            items={productStatus}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid item container xs={12} spacing={2}>
                    <h3 className={classes.basicInfoTitle}>
                      <Box width="20px" ml="-0.5px" mr="10px" height="20px">
                        <img
                          src={`/assets/images/SVG/deliver.svg`}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      {t("addProductForm.shipmentPackageInformation")}
                    </h3>
                    <Grid item container xs={12} md={6} lg={3}>
                      <FormikField
                        name="weight"
                        label={t("addProductForm.weight")}
                        type="number"
                      />
                    </Grid>
                    <Grid item container xs={12} md={6} lg={3}>
                      <FormikField
                        name="lenght"
                        label={t("addProductForm.length")}
                        type="number"
                      />
                    </Grid>{" "}
                    <Grid item container xs={12} md={6} lg={3}>
                      <FormikField
                        name="widht"
                        label={t("addProductForm.width")}
                        type="number"
                      />
                    </Grid>
                    <Grid item container xs={12} md={6} lg={3}>
                      <FormikField
                        name="height"
                        label={t("addProductForm.height")}
                        type="number"
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={2}>
                    <h3 className={classes.basicInfoTitle}>
                      <Box width="20px" ml="-0.5px" mr="10px" height="20px">
                        <img
                          src={`/assets/images/SVG/product info.svg`}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      {t("addProductForm.moreDetails")}
                    </h3>
                  </Grid>

                  {/* <Grid item container style={{ position: 'relative' }} xs={12}> */}
                  <FieldArray name="productColor">
                    {({ push, remove }) => (
                      <>
                        {values.productColor.map((_, indx) => (
                          <>
                            <Grid item style={{ marginTop: "-30px" }}>
                              <MouseOverPopover
                                text={`${t("buttons.delete")} ${
                                  values.productColor[indx].color
                                } ${t("cartPage.color")}`}
                              >
                                <IconButton onClick={() => remove(indx)}>
                                  <CloseIcon />
                                </IconButton>
                              </MouseOverPopover>
                            </Grid>
                            <Grid
                              item
                              container
                              spacing={2}
                              justify="space-between"
                              wrap="wrap"
                              alignItems="baseline"
                              xs={12}
                              md={12}
                              key={indx}
                              style={{ margin: "20px 0" }}
                              // className={classes.disp}
                            >
                              <Grid
                                // container
                                item
                                xs={9}
                                sm={3}
                                // alignItems="center"
                                // justify="center"
                              >
                                <FormikSelect
                                  name={`productColor[${indx}].color`}
                                  label={t("addProductForm.color")}
                                  items={colors}
                                  colors={true}
                                />
                              </Grid>

                              <Grid container item xs={12} sm={6} spacing={2}>
                                <FieldArray
                                  name={`productColor[${indx}].productSize`}
                                >
                                  {({ push, remove }) => (
                                    <>
                                      {values.productColor[
                                        indx
                                      ].productSize.map((size, sizeIndex) => (
                                        <>
                                          <Grid
                                            item
                                            container
                                            spacing={1}
                                            // className={classes.disp}
                                            xs={12}
                                          >
                                            <Grid item xs={5}>
                                              <FormikField
                                                name={`productColor[${indx}].productSize[${sizeIndex}].size`}
                                                label={t("addProductForm.size")}
                                              />
                                            </Grid>
                                            <Grid item xs={6}>
                                              <FormikField
                                                name={`productColor[${indx}].productSize[${sizeIndex}].quantity`}
                                                label={t("quantityOfColor")}
                                              />
                                              <p>
                                                {t(
                                                  "messages.TotalQuantityequalcolorquantities"
                                                )}
                                              </p>
                                              {/* <Field
                                              type={type}
                                              name={`productColor[${indx}].productSize[${sizeIndex}].quantity`}
                                              label={t("quantity")}
                                              component={TextField}
                                              variant={'outlined'}
                                              fullWidth
                                              required={true}
                                            /> */}
                                            </Grid>
                                            <Grid item xs={1}>
                                              <MouseOverPopover
                                                text={`${t(
                                                  "addProductForm.deleteSize"
                                                )}`}
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    remove(sizeIndex)
                                                  }
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </MouseOverPopover>
                                            </Grid>
                                          </Grid>
                                        </>
                                      ))}
                                      <Grid
                                        item
                                        xs={12}
                                        // style={{
                                        //   position: 'absolute',
                                        //   top: '-100',
                                        // }}
                                      >
                                        <Button
                                          onClick={() =>
                                            push({ size: "", quantity: "" })
                                          }
                                          variant="outlined"
                                          color="primary"
                                        >
                                          {t("addProductForm.addSize")}
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                                </FieldArray>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={2}
                                // container
                                alignItems="flex-start"
                              >
                                {/* <Grid item> */}
                                <UploadButtons
                                  btnText={t("image")}
                                  type="Link"
                                  handleChange={(e) =>
                                    fileChangedHandler(
                                      e,
                                      setFieldValue,
                                      indx,
                                      handleImgaeUpload
                                    )
                                  }
                                  linkText="image"
                                  name={"productColorImg" + indx}
                                />

                                <div style={{ marginLeft: "10px" }}>
                                  {values.productColor[indx].url}
                                </div>
                                {/* </Grid> */}
                              </Grid>
                              {/* <Grid item>
                              <MouseOverPopover
                                text={`Delete  ${values.productColor[indx].color} Color`}
                              >
                                <IconButton onClick={() => remove(indx)}>
                                  <CloseIcon />
                                </IconButton>
                              </MouseOverPopover>
                            </Grid> */}

                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                            </Grid>
                          </>
                        ))}

                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.mt}
                            onClick={() =>
                              push({
                                color: "",
                                url: "",
                                quantity: "",
                                productSize: [
                                  {
                                    size: "",
                                    quantity: "",
                                  },
                                ],
                              })
                            }
                          >
                            {t("addProductForm.addColor")}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                  {/* </Grid> */}
                  <Grid item container justify="center">
                    <Button
                      style={{ textTransform: "capitalize" }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting ? true : false}
                    >
                      {t("addProductForm.saveAndAdd")}
                    </Button>
                  </Grid>
                </Form>
              </MuiPickersUtilsProvider>
            );
          }}
        </Formik>
      </Grid>
    </div>
  );
};

export default AddProductForm;
