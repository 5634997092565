import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
const RelatedCard = ({
  price,
  nam,
  srcProductImg,
  boothName,
  boothLogo,
  productId,
  currentPage,
  boothId,
  currency,
}) => {
  const history = useHistory();
  return (
    <div className="col-lg-3 col-sm-6 my-3">
      <a>
        <div className="item card h-100">
          <div
            className="item-img"
            onClick={() => {
              history.push(
                `/buyer/productDetailsPage/${productId}/${currentPage}`
              );
              window.location.reload();
              window.scrollTo(0, 0);
            }}
          >
            <img src={srcProductImg} alt="product" />
          </div>
          <div className="item-info">
            <p style={{ maxWidth: "80%" }}> {nam}</p>
            <p>
              {price} {currency}
            </p>
          </div>
          <div
            onClick={() => history.push(`/buyer/boothPage/${boothId}`)}
            className="item-brand"
            style={
              localStorage.i18nextLng === "en" ? {} : { textAlign: "right" }
            }
          >
            <img
              src={
                boothLogo
                  ? localStorage.imagePath + boothLogo
                  : "/assets/images/buyer/bazarlogo.png"
              }
              alt="boothLogo"
            />
            <span>{boothName}</span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default RelatedCard;
