import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  productContainer: {
    width: '400px',
    display: 'flex',
    alignItems: 'center',

    // justifyContent: 'space-between',
  },
  productImg: {
    height: '65px',
    width: '65px',
    display: 'flex',
    justifyContent: 'space-between ',
  },
  productName: {
    // fontSize: '18px',
    fontWeight: 'bold',
  },
}))

const ProductInfo = ({
  boothName,
  categoryId,
  categoryName,
  description,
  id,
  name,
  productImage,
  price,
  productId,
  seller,
  status,
  role,
}) => {
  console.log(productImage)
  const classes = useStyles()
  return (
    <div className={classes.productContainer}>
      <Box className={classes.productImg} mr={5}>
        <img
          width="100%"
          height={'100%'}
          // src={'https://i.pravatar.cc/300'}
          // src={pictureUri || '/assets/images/shops/shirt.png'}
          src={
            productImage[0]?.url
              ? localStorage.imagePath + productImage[0]?.url
              : '/assets/images/product.png'
          }
        />
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'space-between',
        }}
      >
        <h3 className={classes.productName}>{name}</h3>
        <div>
          <div color="inherit">{categoryName}</div>
          {role !== 'Seller' && (
            <Typography color="inherit">{seller?.name}</Typography>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductInfo
