import React from 'react'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'

const FormikMultiLineField = ({ name, label, variant, rowsNo }) => {
  return (
    <Field
      component={TextField}
      variant={variant || 'outlined'}
      fullWidth
      multiline
      rows={rowsNo || 4}
      type="text"
      name={name}
      label={label}
    />
  )
}

export default FormikMultiLineField
