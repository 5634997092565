import { Button } from "@material-ui/core";
import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  MuiButtonContainedSizeSmall: {
    padding: "0px 6px",
  },
  MuiButtonLabel: { fontSize: "14px" },
}));

const FBShareButton = ({ url, tit, desc, imag }) => {
  let encodedURL = encodeURI(url);
  let title = encodeURI(tit);
  let descr = encodeURI(desc);
  let img = encodeURI(imag);
  console.log(url, tit, desc, imag, "enc");
  const classes = useStyle();
  return (
    <Button
      variant="contained"
      // color="secondary"
      className={
        classes.MuiButtonContainedSizeSmall + " " + classes.MuiButtonLabel
      }
      startIcon={<FacebookIcon fontSize="10px" id="fb" />}
      style={{
        color: "white",
        backgroundColor: "blue",
        fontSize: "1rem",
        marginLeft: "15px",
      }}
      size="small"
    >
      <a
        href={`https://facebook.com/sharer/sharer.php?u=${encodedURL}`}
        target="_blank"
        style={{ textDecoration: "none", color: "white", fontSize: "0.8rem" }}
      >
        Share
      </a>
    </Button>
  );
};

export default FBShareButton;
// export default class FBShareButton extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       url: this.props.url,
//     }
//   }
//   // const classes=useStyle()
//   render() {}
// }https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcrunchify.com%2Flist-of-all-social-sharing-urls-for-handy-reference-social-media-sharing-buttons-without-javascript%2F
// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.jumia.com.eg%2Far%2Fdefacto-woman-ecru-knitted-knitted-trousers-21588540.html%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dpdpshare&quote=%D9%85%D8%B4%D8%A7%D9%87%D8%AF%D8%A9%20%D8%A7%D9%84%D9%85%D9%86%D8%AA%D8%AC%20%D8%B9%D9%84%D9%8A%20%D8%AC%D9%88%D9%85%D9%8A%D8%A7
// https://wordpress.com/press-this.php?u=[post-url]&t=[post-title]&s=[post-desc]&i=[post-img]
// https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&display=popup&ref=plugin&src=share_button
// https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=http%3A%2F%2Febazarakweb.azurewebsites.net%2Fbuyer%2FproductDetailsPage%2F1683%2F1&display=popup&ref=plugin&src=share_button
// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ostoncodecypher.com%2Fdetails%3Fid%3D119&title=setup_your_chromebook_for_web_development
