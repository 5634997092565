import axios from "axios";

export const RegisterWithGoogle = async (userInfo) => {
  const response = await axios.post("ExternalLogin/ExternalLogin", userInfo);
  debugger;
  console.log(response.data);
  return response.data;
};
export const organizerRegisterAsync = async (organizer) => {
  const response = await axios.post("Register/RegisterAsOrganizer", organizer);
  return response.data;
};
export const sellerRegisterAsync = async (seller) => {
  const response = await axios.post("Register/RegisterAsSeller", seller);
  debugger;
  return response.data;
};
export const buyerRegisterAsync = async (buyer) => {
  const response = await axios.post("Register/RegisterAsBuyer", buyer);
  return response.data;
};
export const serviceProviderRegisterAsync = async (serviceProvider) => {
  const response = await axios.post(
    "Register/RegisterAsProvider",
    serviceProvider
  );
  return response.data;
};
