import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, IconButton } from '@material-ui/core/index'
import { Box } from '@material-ui/core/index'
import PhoneIcon from '@material-ui/icons/Phone'
import EditRoundedIcon from '@material-ui/icons/EditRounded'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'relative',
    backgroundColor: '#d3d3d33b',
    padding: '20px',
  },
  marginX: { margin: '10px 0' },
  marginRight: { marginRight: '5px' },
  editIcon: { position: 'absolute', top: '5px', right: '60px' },
  deleteIcon: { position: 'absolute', top: '5px', right: '20px' },
}))
const DriverInfoCard = ({
  handleEditDriver,
  driver,
  handleOpenDeleteModal,
}) => {
  const classes = useStyles()
  const { id, name, phone, comment } = driver

  return (
    <div className={classes.cardContainer}>
      <IconButton
        className={classes.deleteIcon}
        // style={{ display: 'flex', justifyContent: 'flex-start' }}
        onClick={() => handleOpenDeleteModal(id)}
      >
        <DeleteOutlineIcon color="error" />
      </IconButton>
      <IconButton
        className={classes.editIcon}
        onClick={() => {
          handleEditDriver(driver)
        }}
      >
        <EditRoundedIcon fontSize={'small'} />
      </IconButton>
      <Typography>{name}</Typography>
      <Box display="flex" alignItems="center" className={classes.marginX}>
        <PhoneIcon
          className={classes.marginRight}
          color="secondary"
          fontSize="small"
        />
        <span style={{ direction: 'ltr' }}>{phone}</span>
      </Box>
      <div>{comment}</div>
    </div>
  )
}

export default DriverInfoCard
