import {
  DELETE_PENDING_BOOTH,
  DELETE_SELLER_PENDING_BOOTH,
  GET_SELLER_BOOTHS,
  GET_SELLER_BY_ID,
  GET_SELLER_PENDING_BOOTHS,
  LOADING_SELLER_BOOTHS,
  LOADING_SELLER_PENDING_BOOTH,
} from "../actionTypes";
import {
  deletePendingBoothAsync,
  editSellerProfileAsync,
  getSellerBoothsAsync,
  getSellerByIdAsync,
  getSellerPendingBoothsAsync,
} from "./api";
import { EDIT_SELLER_PROFILE } from "./../actionTypes";
import { handleLogout } from "../../utils/setLocalStorage";
export const getSellerById = (sellerId) => {
  return async (dispatch) => {
    try {
      const result = await getSellerByIdAsync(sellerId);
      console.log(result);
      dispatch(getSellerByIdSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
};
const getSellerByIdSuccess = (seller) => {
  return {
    type: GET_SELLER_BY_ID,
    payload: seller,
  };
};
// getSellerBoothsAsync

export const getSellerBooths = (sellerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_SELLER_BOOTHS, payload: true });
      const result = await getSellerBoothsAsync(sellerId);
      console.log(result);
      dispatch({ type: LOADING_SELLER_BOOTHS, payload: false });

      dispatch(getSellerBoothsSuccess(result));
    } catch (error) {
      dispatch({ type: LOADING_SELLER_BOOTHS, payload: false });

      console.log(error);
    }
  };
};
const getSellerBoothsSuccess = (booths) => {
  return {
    type: GET_SELLER_BOOTHS,
    payload: booths,
  };
};
export const getSellerPendingBooths = (sellerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_SELLER_PENDING_BOOTH, payload: true });
      const result = await getSellerPendingBoothsAsync(sellerId);
      console.log(result);
      dispatch({ type: LOADING_SELLER_PENDING_BOOTH, payload: false });

      dispatch(getSellerPendingBoothsSuccess(result));
    } catch (error) {
      dispatch({ type: LOADING_SELLER_PENDING_BOOTH, payload: false });

      console.log(error);
    }
  };
};
const getSellerPendingBoothsSuccess = (booths) => {
  return {
    type: GET_SELLER_PENDING_BOOTHS,
    payload: booths,
  };
};
export const editSellerProfile = (sellerInfo) => {
  return async (dispatch) => {
    try {
      const result = await editSellerProfileAsync(sellerInfo);
      console.log(result);
      dispatch(editSellerProfileSuccess(sellerInfo));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const editSellerProfileSuccess = (sellerInfo) => {
  return {
    type: EDIT_SELLER_PROFILE,
    payload: sellerInfo,
  };
};
export const deletePendingBooth = (boothId) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await deletePendingBoothAsync(boothId);
      console.log(result);
      dispatch(deletePendingBoothSuccess(boothId));
    } catch (error) {
      debugger;
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const deletePendingBoothSuccess = (boothId) => {
  return {
    type: DELETE_SELLER_PENDING_BOOTH,
    payload: boothId,
  };
};
