import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'

import React from 'react'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'

const useStyles = makeStyles((theme) => ({
  imgCard: {
    backgroundColor: 'black',
    borderRadius: '20px',
    width: '260px',
    height: '300px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '230px',
      height: '300px',
    },
  },
  infoCard: {
    width: '210px',
    borderRadius: '15px',
    paddding: '10px 5px',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      width: '180px',
    },
  },
  CardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
const BazarCardBuyer = ({ name, city, coverImage, boothLogo }) => {
  const classes = useStyles()
  const url = boothLogo
    ? `${localStorage.imagePath}${boothLogo}`
    : '/assets/images/buyer/bazarlogo.png'
  return (
    <div
      style={{
        position: 'relative',
        width: 'max-content',
        margin: '30px 30px',
      }}
    >
      <div
        className={classes.imgCard}
        style={{
          backgroundImage: `url(${url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* <img
          src={
            boothLogo
              ? `${localStorage.imagePath}${boothLogo}`
              : "/assets/images/buyer/bazarlogo.png"
          }
          style={{
            borderRadius: "20px",
            // width: '290px',
            // backgroundColor: '#e4e1e1',
            width: "100%",
            height: "100%",
            // objectFit: "cover",
          }}
          alt="cardImg"
        /> */}
      </div>
      <Box position="absolute" top="75%" left="9%">
        <Card className={classes.infoCard}>
          <CardContent align="center" className={classes.CardContent}>
            <Typography
              align="center"
              color="primary"
              style={{
                marginBottom: '10px',
                fontSize: '18px',
              }}
            >
              {name}
            </Typography>
            <Box display="flex" alignItems="center" my="5px" dir="ltr">
              <LocationOnOutlinedIcon color="primary" />
              <Typography align="center">{city}</Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}

export default BazarCardBuyer
