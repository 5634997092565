import React, { useState } from 'react'
import ChipInput from 'material-ui-chip-input'
import { makeStyles, TextField } from '@material-ui/core'
import './inputchip.css'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles((theme) => ({
  chip: {
    width: '100%',
    marginBottom: '10%',
  },
  btn: {
    marginTop: '4%',
    width: 'fitcontent',
  },
  'WAMuiChipInput-chip-107': {
    backgroundColor: 'orange',
  },
}))
const ChipInputt = ({ handleChangee, handltext, handleDeleteChip, text }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          // margin: "1% 18%",
          flexDirection: 'column',
        }}
      >
        <div style={{ marginTop: '20px' }}>
          {t('inviteSeller.pressEnterToAddSellerEmail')}
        </div>
        <ChipInput
          className={classes.chip}
          onChange={(chip) => {
            handleChangee(chip)
            console.log(chip, 'chip')
          }}
          newChipKeyCodes={[32, 13, 188]}
          // onBlur={(e) => {
          //   handleChangee(e.target.value, true)
          //   console.log(e.target.value, 'chip')
          // }}
          blurBehavior="add"
          // onKeyDown={(e) => console.log(e.target.value, 'rrr')}
          onDelete={(chip, index) => handleDeleteChip(chip, index)}
          label={t('inviteSeller.enterSellers')}
        />
        <TextField
          rows={10}
          id="outlined-basic"
          label={t('inviteSeller.WriteMessage')}
          variant="outlined"
          multiline
          value={text}
          onChange={(e) => handltext(e)}
        />
      </div>
    </>
  )
}

export default ChipInputt
