import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import CheckForm from "../../components/CheckFormik";
import OrderDetails from "../../components/OrderDetails";
import BtnImg from "../../components/BtnImg";
import ControlledAppModal from "../../components/ControlledAppModal";
import { makeStyles, Button } from "@material-ui/core";
import PaypalButtons from "../../components/PaypalButtons";
import { useParams, useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import * as Yup from "yup";
import { phoneRegExp } from "../App/constants";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useRef } from "react";
import ccyFormat from "../../utils/ccyFormatFun";
const useStyles = makeStyles({
  dis: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ["@media (max-width:780px)"]: {
      flexDirection: "column",
    },
  },
  imgs: {
    width: "12rem",
    height: "4rem",
  },
  btn: {
    backgroundColor: "white",
    margin: "1% 4%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  marg: {
    margin: "1rem 3.5rem",
    ["@media (max-width:580px)"]: {
      margin: "1rem",
    },
  },
});
const ConfirmOrderPage = () => {
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  const history = useHistory();
  const location = useLocation();
  console.log("location:", location.pathname);
  const { t } = useTranslation();
  let userId = localStorage.getItem("userId");
  const formRef = useRef();
  const dispatch = useDispatch();
  const orderId = useParams().orderId;
  const classes = useStyles();
  const [flag, setflag] = useState(false);
  const [bazarId, setbazarId] = useState(0);
  const [open, setOpen] = useState("");
  const [order, setOrder] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([{ id: "", name: "" }]);
  const [orderItems, setorderItems] = useState([]);
  const [shippingArea, setShippingArea] = useState(0);
  const [loadingShipping, setLoadingShipping] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    cityId: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
  });
  const editPay = (nam) => {
    let item = paymentMethod.find((itm) => itm.name === nam);
    let id = item.id;
    debugger;
    axios
      .put(`Buyer/ChoosePaymentMethod`, {
        orderId: orderId,
        orderPaymentMethod: id,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlebtnClick = (e) => {
    handleScroll();
    setflag(!flag);
    editPay(e.currentTarget.name);
  };
  const handleOpenPaymentModal = (e) => {
    setOpen(true);
    editPay(e.currentTarget.name);
  };
  const handleClosePaymentModal = () => {
    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(`Buyer/OrderPaymentMethodTypes?BazarId=${bazarId}`)
      .then((response) => {
        debugger;
        setPaymentMethod(response.data);
        console.log(response.data, "tttt");
      });
  }, [bazarId]);
  useEffect(() => {
    axios.get(`Buyer/GetBuyerAddress?BuyerId=${userId}`).then((response) => {
      const { data } = response;
      console.log(data, "ddddd");
      const profileValues = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        profileImage: data.profileImage,
        email: data.email,
        countryId: data?.country?.id,
        cityId: data?.city?.id,
        street: data?.street,
        postalCode: data?.postalCode,
        phoneNumber: data?.phoneNumber,
        whatsNumber: data?.whatsNumber,
      };
      setInitialValues(profileValues);
    });
  }, []);
  useEffect(() => {
    axios.get(`Order/GetOrderInfo?OrderId=${orderId}`).then((response) => {
      setOrder(response.data);
      console.log(response.data, "orderrr");
      setorderItems(response.data.orderItems);
      setbazarId(response.data.bazarId);
      setLoadingShipping(false);
      console.log("response.data.bazarId:", response.data.bazarId);
    });
  }, [shippingArea]);

  const handlePaymentSuccess = (data) => {
    try {
      const paymentObj = {
        orderId: data.orderID,
        payerId: data.payerID,
        id: orderId,
        userId: localStorage.userId,
      };
      axios.post(`Payment/ConfirmOrderPayment`, paymentObj).then((response) => {
        history.replace(`/buyer/summaryPage/${orderId}`);
        console.log(response.data);
        handleClosePaymentModal();
        dispatch(setSnackbar(true, "success", t("messages.successPayment")));
      });
    } catch (e) {
      console.log(e);
      dispatch(setSnackbar(true, "success", t("messages.somethingWentWrong")));
    }
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("mustBe15"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
    phoneNumber: Yup.string()
      .nullable()
      .required(t("validation.phoneRequired")),
    // .matches(phoneRegExp, t("validation.invalidPhone")),
    whatsNumber: Yup.string()
      .nullable()
      .required(t("validation.phoneRequired")),
    // .matches(phoneRegExp, t("validation.invalidPhone")),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values, "vcvc,whatsNumber");
    // if (
    //   isValidPhoneNumber(values.phoneNumber) === false ||
    //   isValidPhoneNumber(values.whatsNumber) === false
    // ) {
    //   dispatch(setSnackbar(true, "error", t("validation.invalidPhone")));
    // } else {
    axios
      .put(
        `Buyer/EditBuyerAddress?BuyerId=${userId}&OrderId=${orderId}`,
        values,
        {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        }
      )
      .then((response) => {
        history.replace(`/buyer/summaryPage/${orderId}`);
      });
    // }
    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
  };
  const handleChangeShippingArea = (e) => {
    console.log(e.target.value, "e.target");
    setShippingArea(e.target.value);
    // const shippingObj = { orderId: +orderId, orderlocation: +e.target.value };
    // console.log(shippingObj, "e.target");
    // setLoadingShipping(true);
    // try {
    //   debugger;
    //   axios.put(`Buyer/ChooseOrderLocation`, shippingObj).then((response) => {
    //     // setOrder(response.data)
    //     setShippingArea(e.target.value);
    //     // setorderItems(response.data.orderItems)
    //     // setbazarId(response.data.bazarId)
    //     console.log("response:orderItems", response);
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const scroll = () => formRef?.current?.scrollIntoView();
  const handleScroll = () => setTimeout(() => scroll(), 1000);
  return (
    <>
      <Header />
      <div style={{ marginTop: "6.1rem" }} ref={formRef}>
        <div className={classes.dis}>
          <OrderDetails
            orderItems={orderItems}
            Shipping={order.shippingPrice}
            subTotal={order.subTotal}
            total={order.total}
            shippingArea={shippingArea}
            handleChangeShippingArea={handleChangeShippingArea}
            loadingShipping={loadingShipping}
            {...order}
          />
          <CheckForm
            flag={flag}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={handleSubmit}
          />
        </div>

        {shippingArea > 0 ? (
          <div
            style={{
              marginBottom: "80px",
            }}
          >
            <h4
              style={{
                display: "flex",
              }}
              className={classes.marg}
            >
              {t("choosePaymentMethod")}:
            </h4>
            <div className="disFlex">
              {/* <Button
                name="Paypal"
                variant="contained"
                className={classes.btn}
                onClick={handleOpenPaymentModal}
                style={{
                  maxWidth: "fit-content",
                }}
              >
                <img
                  src={"/assets/images/paymentMethod/paypal.svg"}
                  alt="paypal"
                  className={classes.imgs}
                />
              </Button> */}
              {paymentMethod[0].name === "Cash" &&
              (shippingArea === "1" || shippingArea === "2") ? (
                <BtnImg
                  src={"/assets/images/paymentMethod/cash.svg"}
                  flag={flag}
                  handlebtnClick={(e) => handlebtnClick(e)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <ControlledAppModal
          open={open}
          handleClickOpen={handleOpenPaymentModal}
          handleClose={handleClosePaymentModal}
        >
          <div
            style={{
              width: "25rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PaypalButtons
              total={ccyFormat(+order.total * 1.4088)}
              onSuccess={handlePaymentSuccess}
            />
          </div>
          {/* 1 JOD = 1.4088 USD */}
        </ControlledAppModal>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default ConfirmOrderPage;
