import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { parseISOWithOptions } from "date-fns/fp";
import { useDispatch, useSelector } from "react-redux";
import { getCateories } from "../../redux/catalog/actions";
import { getBazarCategories } from "../../redux/bazar/actions";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const CatDropDown = (props) => {
  const dispatch = useDispatch();
  const { categoryType, bazarId, IdCatBooth, IdCatBazar } = props;
  const { t } = useTranslation();
  {
    console.log(props, props.label, "io");
  }
  useEffect(() => {
    categoryType !== "Bazar"
      ? dispatch(getCateories())
      : dispatch(getBazarCategories(bazarId));

    props.setFieldValue(props.value);
  }, []);

  let categories = useSelector((state) => {
    return categoryType === "Bazar"
      ? state.bazarReducer.bazarCategories
      : state.catalogReducer.categories;
  });
  categories = [{ id: 0, name: "All", arabicName: "الكل" }, ...categories];
  // console.log(categories, "caaaaaaaaaaat");
  const handleDelete = (e) => {
    console.log(e, "hello");
  };
  return (
    <Autocomplete
      // style={{ marginBottom: '0' }}
      multiple
      id="categories"
      options={categories}
      getOptionLabel={(option) =>
        localStorage.i18nextLng === "en" ? option?.name : option?.arabicName
      }
      // + " " + option.arabicName
      // defaultValue={[
      //   { id: 1, type: 'shirt' },
      //   { id: 2, type: 'btngan' },
      // ]}
      disableClearable={IdCatBooth || IdCatBazar ? true : false}
      filterSelectedOptions
      ChipProps={
        {
          deleteIcon:
            IdCatBooth || IdCatBazar ? (
              <span style={{ display: "none" }} />
            ) : (
              // <span />
              <HighlightOffIcon style={{ color: "gray" }} />
              // <i style={{ marginTop: ".5rem" }} className="fas fa-trash-alt"></i>
            ),
        }
        // { onDelete: (e) => handleDelete(e.target) })
      }
      onChange={(e, value) => {
        let arr = categories.filter((itm) => itm.id !== 0);
        let lastElement = value[value.length - 1];
        if (lastElement?.id === 0) props.setFieldValue("categories", arr);
        else {
          props.setFieldValue("categories", value);
        }
        // props.setFieldValue("categories", value);
      }}
      value={props.value}
      // defaultValue={props.value}
      // onBlur={props.blur}
      getOptionSelected={(option, value) => {
        // console.log(option, value, "option and value");
        // if (JSON.stringify(option) === JSON.stringify(value)) {
        //   catFound.push(option);
        // }
        // return option.id === value.id
        return JSON.stringify(option) === JSON.stringify(value);
      }}
      renderInput={(params) => {
        return (
          <>
            {/* {console.log(params)} */}
            <TextField
              onBlur={props.blur}
              name={props.name}
              {...params}
              variant="outlined"
              label={props.label}
              placeholder={t("createBazar.step1Form.categories")}
              error={props.error ? true : false}
              helperText={props.error}
            />
          </>
        );
      }}
      fullWidth
    />
  );
};

export default CatDropDown;
// G-C4GBTDJPVN
// STREAM ID: 3041619760
