import {
  Container,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import HomeBoothCard from "../../components/HomeBoothCard";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import ContactUs from "../../components/ContactUs";
import { Link } from "react-router-dom";
import { TestimonialsItems } from "../../components/Testimonials/constants";
import Testimonials from "../../components/Testimonials";
import { useTranslation } from "react-i18next";
import { boothesArr, CategoryImgs } from "./constants";
import { useHistory } from "react-router";
import axios from "axios";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  imgWidth: { width: "100%", cursor: "pointer", clipPath: "circle()" },
}));

const Home = (props) => {
  const theme = useTheme();
  const [allAppBazars, setAllAppBazars] = useState([]);
  const [bazarHome, setBazarHome] = useState({
    name: "",
    county: { name: "", arabicName: "" },
    organizer: { firstName: "", lastName: "" },
    categories: [{ id: 0, name: "", arabicName: "" }],
    socialMediaLinks: [],
  });
  const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    axios.get(`Bazar/GetAllBazars?PageSize=10&PageIndex=1`).then((response) => {
      setAllAppBazars(response?.data?.data);
    });

    axios.get(`Buyer/GetBazarHome?id=1262`).then((response) => {
      setBazarHome(response.data);
    });
  }, []);

  return (
    <section className="home-page">
      {window.localStorage.removeItem("page")}
      {window.localStorage.removeItem("boothPage")}
      {window.localStorage.removeItem("radiobtn")}
      {window.localStorage.removeItem("ProductsTablePage")}
      {window.localStorage.removeItem("filter")}
      {window.localStorage.removeItem("CatId")}
      <Header />
      <main className="marginTopp">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 my-3 order-2 order-md-1">
              <div className="main-txt changeHeader">
                <header>
                  <h3 className="d-flex">eBazarak.com</h3>
                </header>
                <article>
                  <p className="d-flex">{t("eBazarkDesc")}</p>
                  <button
                    onClick={
                      () =>
                        // localStorage.token?
                        history?.push("/buyer/bazarPage/1262/1")
                      // : history.push("/register/buyer")
                    }
                    className="hvr-float-shadow d-flex  justify-content-center main-img"
                  >
                    {t("startShopping")} !
                  </button>
                </article>
              </div>
            </div>
            <div className="col-md-6 my-3 order-1 order-md-2 main-img">
              <div>
                <img
                  className="w-100"
                  src="/assets/images/home/main-img2.png"
                  // src="/assets/images/icons/Vector2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {allAppBazars
        .filter((itm) => itm.id === 1262)
        .map((baz) => (
          <section className="aman-bazar">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-5 col-md-6 my-3">
                  <img
                    //   className="w-100"
                    className={classes.imgWidth}
                    src={localStorage.imagePath + baz?.imageProfile}
                    alt="imageProfile"
                    onClick={() => history.push("/buyer/bazarPage/1262/1")}
                  />
                </div>
                <div class="col-md-6 my-3">
                  <div className="bazar-txt" style={{ width: "100%" }}>
                    <header>
                      <h3
                        style={{
                          marginRight: "auto",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={() => history.push("/buyer/bazarPage/1262/1")}
                      >
                        {baz?.name}
                      </h3>
                    </header>
                    <article>
                      <p className="d-flex">
                        <img
                          src="/assets/images/icons/calendar.png"
                          width="18px"
                          alt=""
                        />
                        {baz?.startDate ? (
                          <span className="mx-2">
                            {format(
                              new Date(baz.startDate + "Z"),
                              "dd-MM-yyyy"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                        <strong>{t("to")}</strong>
                        {baz?.endDate ? (
                          <span className="mx-2">
                            {format(new Date(baz.endDate + "Z"), "dd-MM-yyyy")}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>

                      <p className="d-flex">
                        <Link to="/buyer/bazarPage/1262/1">{t("visitUs")}</Link>
                        <span className="d-flex align-items-center">
                          <LocationOnOutlinedIcon style={{ margin: "0 2px" }} />
                          <span>
                            {localStorage.i18nextLng === "en"
                              ? baz?.county.name
                              : baz?.county.arabicName}
                          </span>
                        </span>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}

      <section className="bazar-booths bg-light">
        <Container>
          <header>
            <h3 className={classes.textCenter}>
              {t("createBazar.step1Form.categories")}
            </h3>
          </header>
          <article>
            <Grid container spacing={1}>
              {bazarHome.categories.map((cat, i) => (
                <HomeBoothCard key={i} cat={cat} CategoryImgs={CategoryImgs} />
              ))}
            </Grid>
          </article>
        </Container>
      </section>
      <section className="testimonials">
        <Container>
          <header>
            <h3 className={classes.textCenter}>{t("testmonials")}</h3>
          </header>
          <article>
            <Grid container spacing={2} justify="center">
              {TestimonialsItems.map((item, index) => (
                <Grid item lg={3} xs={12} sm={6} container justify="center">
                  <Testimonials
                    value={item.value}
                    key={index}
                    nam={item.nam}
                    imgsrc={item.imgsrc}
                    job={item.job}
                    text={item.text}
                    margin={item.margin}
                    textEn={item.textEn}
                  />
                </Grid>
              ))}
            </Grid>
          </article>
        </Container>
      </section>
      <ContactUs />
    </section>
  );
};

export default Home;
