import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import { Form, Formik } from 'formik'
import FormikField from './../FormikField/index'

import * as Yup from 'yup'
import { Link } from 'react-router-dom'

const ResetPassword1 = ({ handleForgetPassword }) => {
  const { t } = useTranslation()

  //   const handleSubmit = (values, { setSubmiting }) => {
  //     console.log(values)
  //     handleForgetPassword(values, setSubmiting)
  //   }
  const initialValues = { email: '' }
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.emailRequired')),
  })
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <h2>{t('signIn.forgetPassword')}</h2>
        <p>{t('forgetPassword.forgetPasswordIntruction')}</p>
      </Grid>
      <Formik
        initialValues={initialValues}
        //   enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleForgetPassword}
      >
        {({ values, errors }) => {
          // console.log(values)
          // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

          return (
            <Form
              autoComplete="false"
              style={{
                width: '100%',
                // display: 'flex',
                // alignItems: 'center',
              }}
            >
              <Grid item xs={12} md={8} style={{ margin: '60px 0' }}>
                <FormikField name="email" label={t('signUp.email')} />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                container
                justify="center"
                alignItems="center"
              >
                <Button color="primary" variant="contained" type="submit">
                  {t('forgetPassword.resetPassword')}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: '20px' }}
              >
                {t('forgetPassword.remeberPassword')}
                <Link
                  to="/login"
                  style={{
                    marginLeft: '8px',
                    color: 'orange',
                  }}
                >
                  Sign In
                </Link>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default ResetPassword1
