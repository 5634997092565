import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Box, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { socialMediaArray } from "./../Registeration/OrganizerSignUpForm/constants";
import Link from "@material-ui/core/Link";
import { colors } from "../../styles/commonCss";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: `9%`,
    left: `32%`,
    width: "40%",

    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    maxHeight: 520,
    overflowY: "auto",
    // [theme.breakpoints.down('sm')]: {

    // },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      top: `9%`,
      left: `10%`,
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: "5px",
  },
  avatarSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "5px 20px ",
  },
  marginRight: {
    margin: "0 12px",
  },
  marginLeft: {
    marginLeft: "12px",
  },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  linkColor: { color: colors.link },
}));

export default function UserInfoModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render

  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const {
    user: {
      id,
      firstName,
      lastName,
      profileImage,
      email,
      whatsNumber,
      phoneNumber,
      cityName,
      country,
      postalCode,
      street,
      companyCertificate,
      certificateName,
      socialMediaLinks = [],
    },
    role,
    label,
  } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const currentLang = localStorage.i18nextLng;
  const body = (
    <div className={classes.paper}>
      <div className={classes.avatarSection}>
        <h3
          className={classes.textCenter}
          id="simple-modal-title"
          style={{
            display: "flex",
            flexDirection: currentLang === "ar" ? "row-reverse" : "row",
          }}
        >
          {/* <div>{t('roles.' + role)} </div> <div> {t('info')}</div> */}
          <div>{t(role + "Info")} </div>
        </h3>
        <Avatar
          alt={firstName?.toUpperCase()}
          src={
            profileImage
              ? localStorage.imagePath + profileImage
              : "/static/images/avatar/1.jpg"
          }
          className={classes.large}
        />
        <Typography>{firstName + " " + lastName}</Typography>
        <div>
          <Box
            display="flex"
            justifyContent="center"
            mt={1}
            alignItems="center"
          >
            <LocationOnOutlinedIcon
              //   className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>
              {localStorage.i18nextLng === "en"
                ? country?.name
                : country?.arabicName}
            </Typography>
          </Box>
        </div>
      </div>
      <div>
        <h3 className={classes.basicInfoTitle}>{t("basicInfo")}</h3>
        <div>
          <Box display="flex" alignItems="center" dir="ltr">
            <MailIcon
              className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>{email}</Typography>
          </Box>

          <Box display="flex" alignItems="baseline">
            <LocationOnOutlinedIcon
              color="secondary"
              className={classes.marginRight}
              fontSize={"small"}
              style={{ marginTop: "2px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>
                {localStorage.i18nextLng === "en"
                  ? country?.name
                  : country?.arabicName}
              </Typography>
              <div style={{ display: "flex" }}>
                <Typography>{cityName + " "}</Typography>
                <div style={{ margin: "0 5px" }}>,</div>
                <Typography>{street} street</Typography>
              </div>
              <Typography>{postalCode}</Typography>
            </div>
          </Box>
          {companyCertificate ? (
            <Box display="flex" alignItems="center">
              <CardMembershipIcon
                color="secondary"
                className={classes.marginRight}
                fontSize={"small"}
                style={{ marginTop: "2px" }}
              />
              <Typography>{certificateName}</Typography>
              <a
                href={`https://api.ebazarak.com/api/File/GetCertificate?CertificateName=${companyCertificate}`}
                download
                target="_blank"
                style={{
                  margin: "0 20px",
                  color: colors.link,
                  cursor: "pointer",
                }}
              >
                {t("download")}
              </a>
            </Box>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <h3 className={classes.basicInfoTitle}>{t("contactInfo")}</h3>
      </div>

      <div>
        <Box display="flex" alignItems="center" dir="ltr">
          <PhoneIcon
            className={classes.marginRight}
            fontSize={"small"}
            color="secondary"
          />
          <Typography>{phoneNumber}</Typography>
        </Box>
      </div>
      {whatsNumber && (
        <div>
          <Box display="flex" alignItems="center" mt={1} dir="ltr">
            <WhatsAppIcon
              className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>{whatsNumber}</Typography>
          </Box>
        </div>
      )}

      <>
        {socialMediaLinks.length
          ? socialMediaLinks.map((socialLink, index1) => (
              <Box display="flex" alignItems="center" mt={1} key={index1}>
                {socialMediaArray.map((item) => {
                  if (item.type === socialLink.type) {
                    return (
                      <>
                        {item.icon}{" "}
                        <Typography
                          className={classes.marginLeft}
                          key={item.value}
                        >
                          {socialLink.url}
                        </Typography>
                      </>
                    );
                  } else return "";
                })}
                {/* <FacebookIcon
                  className={classes.marginRight}
                  fontSize={'small'}
                  color="secondary"
                />
                <Typography>01123567896</Typography> */}
              </Box>
            ))
          : ""}
      </>
      {/* <div>
        <Box display="flex" alignItems="center" mt={1}>
          <InstagramIcon
            className={classes.marginRight}
            fontSize={'small'}
            color="secondary"
          />
          <Typography>01123567896</Typography>
        </Box>
      </div> */}
    </div>
  );

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={handleOpen}
        style={{ color: colors.link, fontSize: "15px" }}
      >
        {label}
      </Link>
      {/* <Button type="button" variant="link" onClick={handleOpen}>
        View
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
