// Add days to a new instance of JavaScript Date
export function addDays(date, days) {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}
// iso Format 2021-04-07T14:14:34.633Z
export const convertDateToIsoFormat = (date) => {
  return new Date(date).toISOString()
  // date = new Date(date)
  // return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}
