import { Grid } from "@material-ui/core";
import React from "react";
import Box from "@material-ui/core/Box";
import BazarCard from "../BazarCard";
import { Link } from "react-router-dom";

const BazarsListing = ({
  bazarsArr,
  role,
  handleOpenBookBoothModal,
  handleCloseBookBoothModal,
  bazarListContainerRef,
}) => {
  return (
    <Box itemRef={bazarListContainerRef} ref={bazarListContainerRef}>
      <Grid
        container
        spacing={4}
        justify="flex-start"
        alignItems="center"
        align="center"
      >
        {bazarsArr.map((bazar) =>
          bazar.status !== "InComplete" ? (
            <Grid
              item
              xs={12}
              md={4}
              style={{ marginBottom: "70px" }}
              key={bazar.id}
            >
              <Link to={`/buyer/bazarPage/` + bazar.id}>
                <BazarCard
                  bazarStatus={bazar.status}
                  {...bazar}
                  role={role}
                  handleOpenBookBoothModal={handleOpenBookBoothModal}
                  habdleCloseBookBooth={handleCloseBookBoothModal}
                />
              </Link>
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>
    </Box>
  );
};

export default BazarsListing;
