import { GET_COUNTRIES } from "../actionTypes";
import * as addressApi from "./api";

export const getAllCountries = () => {
  return async (dispatch) => {
    try {
      const countries = await addressApi.getAllCountriesAsync();
      dispatch(getAllCountriesSuccess(countries));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const getAllCountriesSuccess = (countries) => {
  return {
    type: GET_COUNTRIES,
    payload: countries,
  };
};
