import {
  ACCEPT_BOOTH_REQUEST,
  DECLINE_BOOTH_REQUEST,
  DELETE_BAZAR,
  GET_ALL_ORGANIZER_BAZARS,
  GET_BAZAR_BOOTHS,
  GET_ORGANIZER_BOOTH_REQUESTS,
  GET_ORGANIZER_PROFILE,
  LOADING_ORGANIZER_BAZARS,
} from "../actionTypes";
import { setSnackbar } from "../Message/actions";
import * as organizerApi from "./api";
import { handleLogout } from "../../utils/setLocalStorage";
export const getOrganizerProfile = (userId) => {
  return async (dispatch) => {
    try {
      const result = await organizerApi.getOrganizerProfileAsync(userId);
      console.log(result);
      dispatch(getOrganizerProfileSuccess(result));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const getOrganizerProfileSuccess = (organizerInfo) => {
  return {
    type: GET_ORGANIZER_PROFILE,
    payload: organizerInfo,
  };
};
export const getOrganizerBazars = (organizerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADING_ORGANIZER_BAZARS, payload: true });
      const result = await organizerApi.getOrganizerBazarsAsync(organizerId);
      console.log(result);
      dispatch({ type: LOADING_ORGANIZER_BAZARS, payload: false });

      dispatch(getOrganizerBazarsSuccess(result));
    } catch (error) {
      dispatch({ type: LOADING_ORGANIZER_BAZARS, payload: false });
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      // console.log(error?.response?.data)
      //   dispatch(registerError(error?.response?.data.error))
    }
  };
};
const getOrganizerBazarsSuccess = (bazars) => {
  return {
    type: GET_ALL_ORGANIZER_BAZARS,
    payload: bazars,
  };
};

export const deleteBazar = (bazarId) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await organizerApi.deleteBazarAsync(bazarId);
      console.log(result);
      debugger;
      dispatch(deleteBazarSuccess(bazarId));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      debugger;
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(deleteBazarError(error?.response?.data.error))
    }
  };
};
const deleteBazarSuccess = (bazarId) => {
  return { type: DELETE_BAZAR, payload: bazarId };
};
/////////////////////////////////////////////////////
export const getOrganizerBoothRequests = (page, pageSize, filter) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOADING", value: true });
      const result = await organizerApi.getOrganizerBoothRquestsAsync(
        page,
        pageSize,
        filter
      );

      console.log(result);
      dispatch({ type: "LOADING", value: false });
      dispatch(getOrganizerBoothRequestsSuccess(result));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      // console.log(error?.response)
      // console.log(error?.response?.data.error)
      // dispatch(deleteBazarError(error?.response?.data.error))
    }
  };
};
const getOrganizerBoothRequestsSuccess = (result) => {
  return {
    type: GET_ORGANIZER_BOOTH_REQUESTS,
    count: result.count,
    data: result.data,
  };
};

export const acceptBoothRequest = (boothId) => {
  return async (dispatch) => {
    try {
      const result = await organizerApi.acceptBoothRequestAsync(boothId);
      debugger;
      console.log(result);
      dispatch(setSnackbar(true, "success", result.message, 5000));
      dispatch(acceptBoothRequestSuccess(boothId));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      //  if (error?.response?.status===4000)
      //   dispatch({
      //     type: SET_SNACKBAR,
      //     snackbarOpen:true,
      //     snackbarType:'error',
      //     snackbarMessage,
      //   })
    }
  };
};
const acceptBoothRequestSuccess = (boothId) => {
  return {
    type: ACCEPT_BOOTH_REQUEST,
    payload: boothId,
  };
};

export const declineBoothRequest = (declineObj) => {
  return async (dispatch) => {
    try {
      debugger;
      const result = await organizerApi.declineBoothRequestAsync(declineObj);
      console.log(result, "rrrrr");
      console.log(declineObj, "تبع");

      dispatch(declineBoothRequestSuccess(declineObj));
    } catch (error) {
      console.log(error);
      // console.log(error?.response)
      // console.log(error?.response?.data.error)
      // dispatch(deleteBazarError(error?.response?.data.error))
    }
  };
};
const declineBoothRequestSuccess = (declineObj) => {
  return {
    type: DECLINE_BOOTH_REQUEST,
    payload: declineObj.boothId,
  };
};
