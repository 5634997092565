import { SET_SNACKBAR } from '../actionTypes'

const initialState = {
  snackbarOpen: false,
  snackbarType: 'success',
  snackbarMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { snackbarOpen, snackbarMessage, snackbarType, duration } = action
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage,
        duration,
      }
    default:
      return state
  }
}
