import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  dis: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "20rem",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
});
const EmptyPayment = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.dis}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img src="/assets/images/empty/Payment.svg" alt="emptyPayment" />
        </Grid>
        <Grid container item justify="center" style={{ fontSize: ".8rem" }}>
          <span style={{ textAlign: "center", margin: "2rem 0" }}>
            {t("addPayment")}
          </span>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          style={{
            margin: "1% 0%",
            cursor: "pointer",
            color: "orange",
            fontSize: "1rem",
          }}
        >
          <Button color="primary" variant="contained">
            <Link
              to="/Home/Seller"
              style={{ textDecoration: "none", color: "white" }}
            >
              {t("Join")}
            </Link>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmptyPayment;
