import React from 'react'

import { Box, Typography } from '@material-ui/core'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import PhoneIcon from '@material-ui/icons/Phone'
import MailIcon from '@material-ui/icons/Mail'
// import ControlledAppModal from './../ControlledAppModal/index'

import ControlledAppModal from './../../ControlledAppModal/index'
import BuyerInfoModalContent from './BuyerModalContent'

const BuyerInfoModal = ({ handleOpen, handleClose, open, user }) => {
  return (
    <>
      <ControlledAppModal
        handleClickOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        // title=""
        // contentStyle={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   padding: '8px 80px',
        //   marginBottom: '50px',
        // }}
        titleStyle={{ margin: '8px auto' }}
      >
        <BuyerInfoModalContent {...user} />
      </ControlledAppModal>
    </>
  )
}
// BuyerInfoModal.defaultProps = {
//   user: {
//     id: '',
//     firstName: '',
//     lastName: '',
//     email: '',
//     whatsNumber: '',
//     phoneNumber: '',
//     postalCode: '',
//     street: '',
//     cityName: '',
//     countryName: '',
//   },
// }
export default BuyerInfoModal
