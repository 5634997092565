import axios from "axios";
// const token = localStorage.token
export const createBazarAsync = async (bazarInfo) => {
  const token = localStorage.token;

  const response = await axios.post("Bazar/CreateBazar", bazarInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const addServiceProviderAsync = async (deliveryInfo) => {
  const token = localStorage.token;
  debugger;
  const response = await axios.put("Bazar/AddProviderToBazar", deliveryInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const addPaymentPlanAsync = async (paymentPlanInfo) => {
  const token = localStorage.token;

  const response = await axios.put("Bazar/AddPaymentToBazar", paymentPlanInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const choosePackageAsync = async (packageInfo) => {
  // debugger
  const token = localStorage.token;

  const response = await axios.put("Bazar/AddPackageToBazar", packageInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const confirmBazarPaymentAsync = async (paymentInfo) => {
  const token = localStorage.token;

  const response = await axios.post("Payment/ConfirmPayment", paymentInfo, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response);
  return response.data;
};

export const getBazarByIdAsync = async (bazarId) => {
  const token = localStorage.token;

  const response = await axios.get("Bazar/GetBazarToEdit?id=" + bazarId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // debugger
  return response.data;
};
export const editBazarAsync = async (values) => {
  const token = localStorage.token;
  debugger;
  const response = await axios.put("Bazar/EditBazarSetting", values, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getBazarHomeAsync = async (id) => {
  const token = localStorage.token;

  const response = await axios.get(`Bazar/GetBazarHome?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getBazarBoothsAsync = async (
  bazarId,
  pageIndex = 1,
  pageSize = 9
) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Organizer/getBazarBooths?BazarId=${bazarId}&PageSize=${pageSize}&PageIndex=${pageIndex}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getAllAppBazarsAsync = async (
  pageIndex = 1,
  search,
  filter,
  pageSize = 9
) => {
  const token = localStorage.token;

  let url = "";
  if (search || filter) {
    if (search && filter)
      url = `Bazar/FilterBazarByNameAndCountry?BazarName=${search}&CountryId=${filter}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!search)
      url = `Bazar/FilterBazarByNameAndCountry?CountryId=${filter}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!filter)
      url = `Bazar/FilterBazarByNameAndCountry?BazarName=${search}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
  } else url = `Bazar/GetAllBazars?PageSize=${pageSize}&PageIndex=${pageIndex}`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getAllComingSoonBazarsAsync = async (
  pageIndex = 1,
  search,
  filter,
  pageSize = 9
) => {
  const token = localStorage.token;

  let url = "";
  if (search || filter) {
    if (search && filter)
      url = `Bazar/FilterComingSoonBazarByNameAndCountry?BazarName=${search}&CountryId=${filter}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!search)
      url = `Bazar/FilterComingSoonBazarByNameAndCountry?CountryId=${filter}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!filter)
      url = `Bazar/FilterComingSoonBazarByNameAndCountry?BazarName=${search}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
  } else
    url = `Bazar/GetComingSoonBazars?PageSize=${pageSize}&PageIndex=${pageIndex}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getAllBuyerBazarsAsync = async (
  pageIndex = 1,
  search,
  filter,
  pageSize = 9
) => {
  const token = localStorage.token;
  debugger;
  let url = "";
  if (search || filter) {
    if (search && filter)
      url = `Buyer/GetBazarsForBuyerByName?CountryId=${filter}&Name=${search}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!search)
      url = `Buyer/GetBazarsForBuyerByName?CountryId=${filter}&PageSize=${pageSize}&PageIndex=${pageIndex}`;
    else if (!filter) url = `Buyer/GetBazarsForBuyerByName?Name=${search}`;
  } else
    url = `Buyer/GetAllBazarsForBuyer?PageSize=${pageSize}&PageIndex=${pageIndex}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const getBazarCategoriessAsync = async (bazarId) => {
  const token = localStorage.token;

  const response = await axios.get(
    `Bazar/GetBazarCategories?BazarId=${bazarId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const getBazarByBoothIdAsync = async (boothId) => {
  const response = await axios.get(
    `Seller/GetBazarByBoothId?BoothId=${boothId}`
  );
  return response.data;
};
