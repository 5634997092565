import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  textD: {
    textDecorationLine: "line-through",
    marginRight: "10px",
  },
  root: {
    // maxWidth: 345, original width style
    maxWidth: "100%",
    minHeight: "390px",
    backgroundSize: "cover",
    /////////////////////////
    top: " 0px",
    position: " relative",
    textDecoration: " none",
    zIndex: " 0",
    overflow: " hidden",

    "&:hover": {
      transition: "all 0.2s ease-out",
      boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
      top: "-4px",
    },
    "&:before": {
      position: "absolute",
      zIndex: "-1",
      top: "-16px",
      right: "-16px",
      background: "#00838d",
      height: "32px",
      width: "32px",
      transform: "scale(2)",
      transformOrigin: "50% 50%",
      transition: "transform 0.15s ease-out",
    },
    "&:hover:before": {
      transform: "scale(2.15)",
    },
  },
  media: {
    height: "250px",
    paddingTop: "56.25%", // 16:9
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  mr5: {
    marginRight: ".5rem",
  },
  marg: {
    marginRight: "1rem",
  },
  margF: {
    marginLeft: "1rem",
  },
  marg2F: {
    marginLeft: "3rem",
  },
  newSec: {
    display: "flex",
    justifyContent: "center",
    padding: "2rem",
    backgroundColor: "#fff6ea",
    marginBottom: "3rem",
  },
  newcard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width: 900px)"]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  tablecard: {
    flex: "0 0 400px",

    ["@media (max-width: 900px)"]: {
      flex: "0",
      overflowX: "scroll",
      maxWidth: "320px",
    },
  },
}));
