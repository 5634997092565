import React from "react";
import { Form, Formik } from "formik";
import Dropdown from "../Dropdown";
import { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import FormikSelect from "../FormikSelect";
import { editOrderItemSettingsAsync } from "../../redux/serviceProvider/api";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import { useTranslation } from "react-i18next";

const RowForm = ({ initialValues, drivers, statusArr }) => {
  const { t } = useTranslation();

  const [driver, setDriver] = useState("");
  const handleDriverChange = (e) => {
    setDriver(e.target.value);
  };
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    try {
      const response = await editOrderItemSettingsAsync(values);
      // setTimeout(() => resetForm({}), 2000);

      dispatch(setSnackbar(true, "success", t("messages.orderProductEdited")));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      //   validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        validateForm,
        isSubmitting,
        handleReset,
        setFieldValue,
      }) => {
        // console.log(values)
        // console.log(registeredSuccessfuly, 'registeredSuccessfuly')

        return (
          <Form
            autoComplete="false"
            style={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4} container justify="center" alignItems="center">
              {/* <Dropdown
                items={[{ name: 'ddd', id: 1 }]}
                label="Select Driver"
                handleChange={handleDriverChange}
                value={driver}
                variant="standrd"
              /> */}
              <Box width="80px" mb="15px">
                <FormikSelect
                  name="driverId"
                  label={t("driver")}
                  variant="standard"
                  items={drivers}
                />
              </Box>
            </Grid>
            <Grid item xs={4} container justify="center">
              <Box width="80px" mb="15px">
                <FormikSelect
                  name="orderItemStatus"
                  label={t("stats")}
                  variant="standard"
                  items={statusArr}
                />
              </Box>

              {/* <FormControl
                variant="standard"
                style={{ width: '80px', marginRight: '10px' }}
              >
                <InputLabel htmlFor="Language">{t("stats")}</InputLabel>
                <Select
                  labelId="Language"
                  value={driver}
                  //   name={'actions' + row.id}
                  onChange={(e) => handleDriverChange(e, {})}
                  inputProps={{
                    id: 'open-select',
                  }}
                  //   label="Action"
                >
                  {' '}
                  <MenuItem value="">
                    <em>none</em>
                  </MenuItem>
                  {['Accept', 'Decline', 'Message'].map((option, key) => (
                    <MenuItem value={option} key={key}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={4} container justify="center" alignItems="flex-end">
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                style={{ borderRadius: "15px", fontSize: "10px" }}
                size="small"
              >
                {t("buttons.submit")}
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RowForm;
