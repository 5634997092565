// import React from 'react'
// import { ErrorMessage, Field } from 'formik'
// import MenuItem from '@material-ui/core/MenuItem'
// import { Select } from 'formik-material-ui'
// import { InputLabel } from '@material-ui/core/index'

// import './FormikSelect.css'
// import { FormHelperText } from '@material-ui/core/index'

// const FormikSelect = ({ name, items, label }) => {
//   console.log(label)
//   return (
//     <>
//       <Field component={Select} variant="outlined" name={name} fullWidth error>
//         {/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
//         {items.map((item, index) => (
//           <MenuItem value={item.id} key={item.id}>
//             {item.name}
//           </MenuItem>
//         ))}
//       </Field>
//       <FormHelperText>
//         <ErrorMessage name={name} />
//       </FormHelperText>
//     </>
//   )
// }

// export default FormikSelect

import React from "react";
import { Field } from "formik";

import { TextField } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";
import { de } from "date-fns/locale";

const FormikSelect = ({
  name,
  label,
  type = "text",
  required = false,
  variant = "outlined",
  items,
  provider,
  colors,
  ...rest
}) => {
  return (
    <div className="FormikField">
      <Field
        style={{ minWidth: "5rem" }}
        type={type}
        name={name}
        id={name}
        label={label}
        component={TextField}
        select
        variant={variant}
        fullWidth
        required={required}
        {...rest}
        // defaultValue={''}
      >
        {items?.map((item, index) => {
          let sd;
          // debugger;
          if (
            name === "countryId" ||
            name === "cityId" ||
            name === "cityID" ||
            name === "categoryId"
          ) {
            if (localStorage.i18nextLng === "en") {
              sd = item.name;
            } else sd = item.arabicName;
          }
          return (
            <MenuItem value={item.id} key={item.id}>
              {/* {console.log(item, name, "iteeeeeeeeeeeeeeeeeeeeeeeeem")} */}
              <div style={{ display: "flex", alignItems: "center" }}>
                {colors && (
                  <span
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      background: item.id,
                      margin: "0 6px",
                    }}
                  ></span>
                )}
                <span style={{ color: "black" }}>
                  {/* {console.log(item, "iioopp", item.companyName, name)} */}
                  {sd}
                  {name !== "countryId" &&
                  name !== "cityID" &&
                  name !== "cityId" &&
                  name !== "categoryId"
                    ? item.name
                    : ""}
                  {item.companyName
                    ? `${item.companyName} /  ${
                        localStorage.i18nextLng === "en"
                          ? item.country?.name
                          : item.country?.arabicName
                      } /   ${item.cityName}`
                    : ""}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Field>
    </div>
  );
};

export default FormikSelect;
