import React from "react";
import { makeStyles, Button } from "@material-ui/core";
const useStyles = makeStyles({
  imgs: {
    width: "12rem",
    height: "4rem",
  },
  btn: {
    backgroundColor: "white",
    margin: "1% 4%",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  s: {
    width: "1rem",
    height: "1rem",
    top: "rem",
    position: "relative",
    top: "-2rem",
    left: "1.2rem",
    display: "none",
  },
  onclick: {
    display: "inline",
  },
});
const BtnImg = ({ src, handlebtnClick, flag }) => {
  const classes = useStyles();
  return (
    <Button
      style={{
        maxWidth: "fit-content",
      }}
      name="Cash"
      variant="contained"
      className={classes.btn}
      onClick={handlebtnClick}
    >
      <img src={src} alt="paypal" className={classes.imgs} />
      <img
        src="/assets/images/paymentMethod/s.svg"
        alt="s"
        className={flag ? `${classes.s} ${classes.onclick}` : `${classes.s}`}
      />
    </Button>
  );
};

export default BtnImg;
