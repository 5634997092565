import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Typography from '@material-ui/core/Typography'

import { colors } from '../../styles/commonCss'
import { Box, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    margin: '50px auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  content: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentText: {
    margin: '10px 0',
  },

  header: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  selected: {
    backgroundColor: colors.selectedItem,
  },
}))
const PackageCard = (props) => {
  const {
    id,
    type,
    title,
    content,
    handleSelectedPackageChange,
    selectedPackage,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const getSelected = () => {
    if (selectedPackage === type) {
      if (type === 'FreePlan') return t('buttons.selected')
      else return t('buttons.purchased')
    } else
      return type !== 'FreePlan' ? t('buttons.purchase') : t('buttons.select')
  }
  return (
    <>
      <Card className={classes.root}>
        <CardHeader title={title} className={classes.header} />

        <CardContent
          className={
            selectedPackage === type
              ? classes.selected + ' ' + classes.content
              : classes.content
          }
        >
          <Box paddingY={3} paddingX={2} color="textSecondary" align="center">
            <Typography
              variant="h4"
              color="textSecondary"
              className={classes.contentText}
            >
              {content.price}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align={'center'}
              className={classes.contentText}
            >
              {content.description}
            </Typography>
            <Typography className={classes.contentText}>
              {content.max}
            </Typography>
          </Box>
          {/* {selectedPackage === type ? (
            selectionInputHandler(type)
          ) : ( */}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSelectedPackageChange(id, type)}
            >
              {/* {type !== 'FreePlan' ? 'Purchase' : 'Select'} */}
              {getSelected()}
              {/* {type === 'FreePlan' && selectedPackage === type
                ? 'Selected'
                : 'purchased'} */}
            </Button>
          </Box>
          {/* )} */}
        </CardContent>
      </Card>
    </>
  )
}

export default PackageCard
