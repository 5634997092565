import {
  GET_BOOTH_HOME,
  GET_BOOTH_PRODUCTS,
  RESET_BOOTH_HOME,
  UPLOAD_AVATAR_IMG,
  UPLOAD_COVER_IMG,
} from './../actionTypes'

const initialState = {
  booth: {
    id: '',
    name: '',
    logo: null,
    coverImage: null,
    description: null,
    selles: 0,
    status: '',
    bazarId: '',
    bazarName: '',
    bazarLogo: null,
    startOfBazar: '',
    endOfBazar: '',
    bazarStatus: '',
    numOfOrders: 0,
    numOfProducts: 0,
    numOfProductRequests: 0,
    cityId: '',
    countryId: '',
    boothCity: '',
    boothCountry: '',
    boothStreet: '',
    isHidden: false,
    boothCategories: [],
    sellerAvailableTimes: [],
  },
  imageUpload: null,
  boothProducts: [],
  boothProductsCount: '',
}
const boothReducer = (state = initialState, action) => {
  let booth = { ...state.booth }
  let boothProducts = [...state.boothProducts]
  let imageUpload = state.imageUpload

  switch (action.type) {
    case GET_BOOTH_HOME:
      booth = action.payload ? action.payload : state.booth
      return {
        ...state,
        booth,
      }
    case GET_BOOTH_PRODUCTS:
      boothProducts = [...action.payload.data]
      const boothProductsCount = action.payload.count
      debugger
      return {
        ...state,
        boothProducts,
        boothProductsCount,
      }
    case UPLOAD_AVATAR_IMG:
      booth.logo = action.payload

      return {
        ...state,
        booth,
        imageUpload: { cover: false, logo: true },
      }
    case UPLOAD_COVER_IMG:
      booth.coverImage = action.payload

      return {
        ...state,
        booth,
        imageUpload: { cover: true, logo: false },
      }
    case RESET_BOOTH_HOME:
      localStorage.step = 0
      return {
        ...state,
        booth: {
          id: '',
          name: '',
          logo: null,
          coverImage: null,
          description: null,
          selles: 0,
          status: '',
          bazarId: '',
          bazarName: '',
          bazarLogo: null,
          startOfBazar: '',
          endOfBazar: '',
          bazarStatus: '',
          numOfOrders: 0,
          numOfProducts: 0,
          numOfProductRequests: 0,
          cityId: '',
          countryId: '',
          boothCity: '',
          boothCountry: '',
          boothStreet: '',
          isHidden: false,
          boothCategories: [],
          sellerAvailableTimes: [],
        },
      }
    default:
      return state
  }
}
export default boothReducer
