import * as providerApi from "./api";
import * as actionTypes from "../actionTypes";
import { EDIT_PROVIDER_PROFILE } from "./../actionTypes";
import { handleLogout } from "../../utils/setLocalStorage";

export const getAllServiceProviders = () => {
  return async (dispatch) => {
    try {
      const result = await providerApi.getAllServicePrvidersAsync();
      console.log(result, "getAllServiceProviders");
      dispatch(getAllServiceProvidersSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      //   dispatch(registerError(error?.response?.data.error))
    }
  };
};
const getAllServiceProvidersSuccess = (providers) => {
  return {
    type: actionTypes.GET_ALL_SERVICE_PROVIDERS,
    payload: providers,
  };
};
export const getProviderByBazarCountry = (BazarId) => {
  return async (dispatch) => {
    try {
      const result = await providerApi.getProviderByBazarCountryAsync(BazarId);
      console.log(result, "getAllServiceProviders");
      dispatch(getProviderByBazarCountrySuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
};
const getProviderByBazarCountrySuccess = (providers) => {
  return {
    type: actionTypes.GET_ALL_SERVICE_PROVIDERS_BY_BAZARID,
    payload: providers,
  };
};
export const getServiceProviderById = (providerId) => {
  return async (dispatch) => {
    try {
      const result = await providerApi.getServiceProviderByIdAsync(providerId);
      console.log(result);
      // debugger
      dispatch(getServiceProviderByIdSuccess(result));
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const getServiceProviderByIdSuccess = (providerProfile) => {
  return {
    type: actionTypes.GET_PROVIDER_BY_ID,
    payload: providerProfile,
  };
};
export const getProviderBazarsRequests = (providerId, pageIndex, pageSize) => {
  return async (dispatch) => {
    try {
      // debugger
      dispatch({ type: "LOADING_BAZAR_REQUEST", value: true });
      const result = await providerApi.getProviderBazarsRequestsAsync(
        providerId,
        pageIndex,
        pageSize
      );
      console.log(result);
      debugger;
      dispatch({ type: "LOADING_BAZAR_REQUEST", value: false });
      dispatch(getProviderBazarsRequestsSuccess(result));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
      //   dispatch(registerError(error?.response?.data.error))
    }
  };
};
const getProviderBazarsRequestsSuccess = (bazarRequests) => {
  return {
    type: actionTypes.GET_PROVIDER_BAZARS_REQUEST,
    payload: bazarRequests,
  };
};
export const acceptBazarRequest = (bazarId) => {
  return async (dispatch) => {
    try {
      const result = await providerApi.acceptBazarRequestAsync(bazarId);

      console.log(result);
      // debugger
      dispatch(acceptBazarRequestSuccess(bazarId));
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(deleteBazarError(error?.response?.data.error))
    }
  };
};
const acceptBazarRequestSuccess = (bazarId) => {
  return {
    type: actionTypes.ACCEPT_PROVIDER_BAZAR_REQUEST,
    payload: bazarId,
  };
};
export const declineBazarRequest = (bazarId) => {
  return async (dispatch) => {
    try {
      const result = await providerApi.declineBazarRequestAsync(bazarId);

      console.log(result);

      dispatch(declineBazarRequestSuccess(bazarId));
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      console.log(error?.response);
      // console.log(error?.response?.data.error)
      // dispatch(deleteBazarError(error?.response?.data.error))
    }
  };
};
const declineBazarRequestSuccess = (bazarId) => {
  return {
    type: actionTypes.DECLINE_PROVIDER_BAZAR_REQUEST,
    payload: bazarId,
  };
};
export const editProviderProfile = (providerInfo) => {
  return async (dispatch) => {
    try {
      const result = await providerApi.editProviderProfileAsync(providerInfo);
      console.log(result);
      dispatch(editProviderProfileSuccess(providerInfo));
    } catch (error) {
      console.log(error?.response);
      if (error?.response?.status === 401) {
        handleLogout();
        window.location.href = "/login";
      }
    }
  };
};
const editProviderProfileSuccess = (providerInfo) => {
  return {
    type: EDIT_PROVIDER_PROFILE,
    payload: providerInfo,
  };
};
