import React from "react";
import { Field } from "formik";

import "./FormikField.css";
import { TextField } from "formik-material-ui";
import { InputAdornment } from "@material-ui/core";

const FormikField = ({
  name,
  label,
  type = "text",
  required = false,
  variant = "outlined",
  currencyOfBooth,
  currency,
  ...rest
}) => {
  return (
    <div className="FormikField">
      <Field
        // disabled={disabled || false}
        style={{ minWidth: "5rem" }}
        type={type}
        name={name}
        id={name}
        label={label}
        component={TextField}
        variant={variant}
        fullWidth
        required={required}
        InputProps={
          currency
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {currencyOfBooth}
                  </InputAdornment>
                ),
              }
            : {}
        }
        // startAdornment={<InputAdornment position="start">JD</InputAdornment>}
        {...rest}
      />
    </div>
  );
};

export default FormikField;
