import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import UserProfileSection from "./../../components/UserProfileSection/index";
import { phoneRegExp } from "../App/constants";
import { editSellerProfile } from "./../../redux/seller/actions";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/Message/actions";
import Header from "../../components/Header";

import axios from "axios";
import { capitalize, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  marginX: { margin: "30px 0" },
  marginRight: { marginRight: " 5px", marginTop: "10px" },
  break: {
    width: "100%",
    ["@media (max-width:350px)"]: {
      width: "50%",
      wordWrap: "break-word",
    },
  },
  formPadding: {
    marginTop: "50px",
    marginBottom: "50px",
    padding: "60px 90px",
    ["@media (max-width:768px)"]: {
      padding: "20px 20px",
    },
  },
}));
const BuyerProfile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  //   const organizerId = localStorage.userId
  const [countryName, setCountryName] = useState("");
  //   const buyer = localStorage.userAllInfoss
  const [buyer, setBuyer] = useState();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryId: "",
    cityId: "",
    street: "",
    postalCode: "",
    phoneNumber: "",
    whatsNumber: "",
    profileImage: "",
    preCode: "",
    socialMediaLinks: [{ url: "", socialMediaType: 0 }],
  });
  const [image, setImage] = useState(initialValues.profileImage);

  useEffect(() => {
    axios
      .get("Buyer/GetBuyerProfile?BuyerId=" + localStorage.userId, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        debugger;
        const { data } = response;
        const profileValues = {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          profileImage: data.profileImage,
          email: data.email,
          countryId: data.country.id,
          countryName:
            localStorage.i18nextLng === "en"
              ? data.country.name
              : data.country.arabicName,
          cityId: data.city.id,
          street: data.street,
          postalCode: data.postalCode,
          phoneNumber: data.phoneNumber,
          whatsNumber: data.whatsNumber,

          preCode: data.preCode,
        };
        console.log(profileValues);
        setInitialValues(profileValues);
        setBuyer(data);
        setImage(data.profileImage);
      });
  }, []);

  useEffect(() => {
    setImage(initialValues.profileImage || "");
  }, [initialValues]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, t("validation.nameLength"))
      .required(t("validation.firstNameRequired")),
    lastName: Yup.string().required(t("validation.lastNameRequired")),
    email: Yup.string()
      .email(t("validation.invalidEmail"))
      .required(t("validation.emailRequired")),
    // phoneNumber: Yup.string()
    //   .matches(phoneRegExp, t("validation.invalidPhone"))
    //   .required(t("validation.phoneRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.number().required(t("validation.cityRequired")),
    street: Yup.string().required(t("validation.streetRequired")),
  });
  const handleProfileImgChange = (event) => {
    const newImage = event.target?.files?.[0];
    console.log(newImage);
    const formData = new FormData();
    formData.set(newImage.name, newImage);
    ////////////////////////// Call UPLOAD IMG API //////////////
    console.log(newImage);
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    values.profileImage = image;
    debugger;
    // if (isValidPhoneNumber(values.phoneNumber) === false) {
    //   dispatch(setSnackbar(true, 'error', 'please enter valid number'))
    // } else {
    //   dispatch(editSellerProfile(values))
    axios.put("Buyer/EditBuyerProfile?BuyerId=" + localStorage.userId, values, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    });

    setTimeout(() => {
      // resetForm({})
      props.history.push("/");
    }, 4000);
    // }
  };
  console.log(initialValues, "initialValues");
  return (
    <>
      <Header />
      <div className={classes.formPadding}>
        <Typography
          variant="h6"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          {" "}
          {capitalize(t("sideMenu.profile"))}
        </Typography>

        <UserProfileSection
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          countryName={initialValues.countryName}
          handleProfileImgChange={handleProfileImgChange}
          image={image}
          setImage={setImage}
          role="Buyer"
        />
      </div>
    </>
  );
};

export default BuyerProfile;
