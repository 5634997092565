import MaterialTable from "material-table";
import React from "react";
import tableIcons from "./../../styles/materialTableIcons";

const headerStyle = {
  textAlign: "center",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};

const UnControlledTable = ({
  data,
  columns,
  shoWTitle = true,
  title,
  search = true,
  toolbar = true,
  padding = "20px 0",
  doubleHorizontalScroll = true,
  tableRef,
  localization,
  justify,
  sorting = false,
  ...rest
}) => {
  const token = localStorage.token;

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        style={{
          padding: padding,
          overflowX: "scroll",
          justifyContent: justify,
        }}
        title={title}
        localization={localization}
        columns={columns}
        options={{
          toolbar: toolbar,
          search: search,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle,
          doubleHorizontalScroll: doubleHorizontalScroll,
          sorting: sorting,
          showTitle: shoWTitle,
          pageSizeOptions: [10],
        }}
        data={data}
        {...rest}
      />
    </>
  );
};

export default UnControlledTable;
