import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(8),
    },
  },
  page: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5%",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#F8B334",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgba(251, 144, 39)",
      color: "#ffffff",
    },
  },
}));

const BasicPagination = ({
  pagesize,
  count,
  handlePageChange,
  currentPage,
}) => {
  const classes = useStyles();
  console.log(
    currentPage,
    Math.ceil(parseInt(count) / pagesize),
    "BasicPagination "
  );
  return (
    <div className={classes.root}>
      <Pagination
        className={`${classes.ul} ${classes.page}`}
        count={Math.ceil(parseInt(count) / pagesize)}
        style={{ color: "#F8B334" }}
        onChange={handlePageChange}
        page={+currentPage}
      />
    </div>
  );
};

export default BasicPagination;
