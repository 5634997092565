import * as React from "react";
import { styled, useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HeaderAli from "../../components/NewHeaderSec/index";
import { useLocation } from "react-router";
const drawerWidth = "320px";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: "black",
    marginTop: "1rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  dispNone: {
    display: "none",
  },
}));

export default function MobileMenu({
  bazarNam,
  organizer,
  country,
  numofshops,
  description,
  srcLogo,
  socialMediaLinks,
  status,
  location,
  imglogo,
  bazarStatus,
  srcCover,
  start,
  end,
  viewsN,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      className={classes.menuButton}
    >
      <Card>
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            style={{ textAlign: "center" }}
            color="text.secondary"
          >
            {t("orderViaWats")}
            <span style={{ color: "orange", direction: "ltr" }}>
              {t("+966 56 121 1068")}
            </span>
          </Typography>
        </CardContent>
      </Card>
      <Box open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ display: "flex" }}
          >
            <Avatar src={srcCover} style={{ margin: "0 8px" }} />
            {bazarNam}
          </Typography>
        </Toolbar>
      </Box>
      <Box>
        <HeaderAli
          bazarNam={bazarNam}
          organizer={organizer}
          country={country}
          numofshops={numofshops}
          description={description}
          srcLogo={srcLogo}
          socialMediaLinks={socialMediaLinks}
          status={status}
          location={location}
          imglogo={imglogo}
          bazarStatus={bazarStatus}
          srcCover={srcCover}
          start={start}
          end={end}
          viewsN={viewsN}
          dispNone={classes.dispNone}
          open={open}
        />
      </Box>
    </Box>
  );
}
