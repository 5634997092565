import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";
import tableIcons from "./../../styles/materialTableIcons";
import { colors } from "../../styles/commonCss";
import { useTranslation } from "react-i18next";
import OrderItemsModal from "./../../components/ManageOrdersSection/partials/OrderItemsModal/index";
import Link from "@material-ui/core/Link";
import {
  getSellerBoothOrdersAsync,
  getSellerBoothOrdersByOrderNoAsync,
  getSellerOrdersAsync,
} from "../../redux/seller/api";
import { Typography, Breadcrumbs, Grid } from "@material-ui/core";
import { getBoothHome } from "./../../redux/booth/actions";
import BuyerInfoModal from "./../../components/ManageOrdersSection/partials/BuyerInfoModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmptyOrder from "./../../components/EmptyOrder/index";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  disabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
  upgrade: {
    marginLeft: "10px",
    color: colors.link,
    textDecoration: "underline",
    cursor: "pointer",
  },
  orderButton: {
    color: colors.link,
    fontSize: "15px",
    textDecoration: "underline",
  },
  dis: {
    display: "flex",
    justifyContent: "center",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
  chartSize: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    margin: "15px 40px 60px 40px",
  },
}));
const headerStyle = {
  textAlign: "center",
  backgroundColor: "#f1f1f1",
  fontWeight: "500",
};

const SellerManageOrdersTable = (props) => {
  const tableRef = React.createRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //   const [filterBazarId, setFilterBazarId] = useState('')
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openBuyerModal, setOpenBuyerModal] = useState(false);
  const [dataa, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [user, setUser] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [earning_sales, setEarning_Sales] = useState([]);

  const sellerId = localStorage.userId;
  const boothId = useParams().boothId;
  console.log(boothId);

  const handleOpenOrderModal = (e, rowData) => {
    console.log(rowData, "row");
    setOrderDetails(rowData);
    setOpenOrderModal(true);
  };

  const handleCloseOrderModal = () => {
    setOpenOrderModal(false);
  };
  const handleOpenBuyerModal = (e, rowData) => {
    setUser(rowData.buyer);

    setOpenBuyerModal(true);
  };

  const handleCloseBuyerModal = () => {
    setOpenBuyerModal(false);
  };

  const booth = useSelector((state) => state.boothReducer.booth);
  console.log(booth, "opo");
  useEffect(() => {
    dispatch(getBoothHome(boothId));
  }, []);
  useEffect(() => {
    axios
      .get(`Seller/GetEarningSalesToSeller?BoothId=${boothId}`)
      .then((response) => {
        setEarning_Sales(response.data);
      });
  }, []);
  const body = (
    <Grid container className={classes.dis} xs={12}>
      <EmptyOrder />
    </Grid>
  );
  let sum = 0;
  let x = dataa?.map((itm) => {
    sum = sum + itm?.earning;
    return sum;
  });
  return (
    <>
      <OrderItemsModal
        handleOpen={handleOpenOrderModal}
        handleClose={handleCloseOrderModal}
        open={openOrderModal}
        orderNo={orderDetails.orderNumber}
        orderItems={orderDetails.orderItems}
        shippingPrice={orderDetails.shippingPrice}
        subTotal={orderDetails.subTotal}
        total={orderDetails.total}
        role="Seller"
      />
      <BuyerInfoModal
        label={t("links.view")}
        user={user}
        handleClose={handleCloseBuyerModal}
        handleOpen={handleOpenBuyerModal}
        open={openBuyerModal}
      />
      <Grid item xs={12}>
        <Typography variant="h6" className={"margin-top-50"}>
          {t("stats")}
        </Typography>
      </Grid>

      <Grid
        container
        xs={12}
        justify="center"
        style={{ marginBottom: "50px" }}
        spacing={4}
      >
        <Grid item>
          <div className={classes.chartSize}>
            <img src="/assets/images/charts/order.svg" width="100%" />
            <div style={{ fontSize: "16px", marginTop: "-10%" }}>
              {t("order.orders")}
            </div>
            <div>
              {/* {booth.numOfOrders} */}
              {count}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.chartSize}>
            <img src="/assets/images/charts/selles.svg" width="100%" />
            <div style={{ fontSize: "16px", marginTop: "-10%" }}>
              {t("sales")}
            </div>
            <div>{earning_sales?.selles}</div>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.chartSize}>
            <img src="/assets/images/charts/earnings.svg" width="100%" />
            <div style={{ fontSize: "16px" }}>{t("earning")}</div>
            <div>{earning_sales?.earning}</div>
          </div>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: "1rem" }}>
        <Link
          style={{ color: "gray", cursor: "pointer" }}
          onClick={() => props.history.push(`/Seller/boothHome/${boothId}`)}
        >
          {booth.name}
        </Link>
        <Typography className="special">{t("buttons.manageOrders")}</Typography>
      </Breadcrumbs>
      <MaterialTable
        tableRef={tableRef}
        style={{
          fontSize: "15px",
          // overflowX: 'auto',
          minWidth: "650px",
          display: "block",
          width: "100%",
          overflowX: "scroll",
          // borderCollapse: 'collapse',
          // width: '100%',
        }}
        icons={tableIcons}
        title={t("buttons.manageOrders")}
        localization={{
          body: {
            emptyDataSourceMessage: body,
          },
        }}
        columns={[
          {
            title: t("order.orderNo"),
            field: "orderNumber",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return (
                <>
                  <Link
                    component="button"
                    style={{
                      color: colors.link,
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                    variant="body2"
                    onClick={(e) => handleOpenOrderModal(e, row)}
                  >
                    {row.orderNumber}
                  </Link>
                </>
              );
            },
          },
          {
            title: t("order.orderTotal"),
            type: "numeric",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <div>
                {row.currency} {row.total}
              </div>
            ),
          },
          {
            title: t("earning"),
            field: "earning",
            type: "numeric",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <div>
                {row.currency} {row.earning}
              </div>
            ),
          },

          {
            title: t("date"),
            field: "orderDate",
            render: (row) => (
              <div>{format(new Date(row.orderDate), "MMMM dd, yyyy ")}</div>
            ),
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("status"),
            field: "status",
            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("buyerInfo"),
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => {
              return (
                <>
                  {
                    <Link
                      component="button"
                      style={{
                        color: colors.link,
                        fontSize: "15px",
                        textDecoration: "underline",
                      }}
                      variant="body2"
                      onClick={(e) => handleOpenBuyerModal(e, row)}
                    >
                      {t("links.view")}
                    </Link>
                  }
                </>
              );
            },
          },
        ]}
        options={{
          search: true,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle,
          doubleHorizontalScroll: true,
          tableLayout: "auto",
          sorting: false,
        }}
        data={(query) => {
          return new Promise(async (resolve, reject) => {
            const { page, pageSize, search } = query;

            try {
              const result = !search
                ? await getSellerBoothOrdersAsync(
                    boothId,
                    page + 1,
                    pageSize
                    // search,
                    // filterBazarId,
                  )
                : await getSellerBoothOrdersByOrderNoAsync(
                    boothId,
                    search,
                    page + 1,
                    pageSize
                  );
              console.log(result.data, "rowbuu");
              debugger;
              resolve({
                data: result.data,
                page: query.page,
                totalCount: result.count || 1,
              });
              setCount(result.count);
              // const x = await getSellerBoothOrdersAsync(boothId, 1, 1000);
              // setData(x?.data);
            } catch (error) {
              console.log(error);
              console.log(error?.response);
              resolve({
                data: [],
                page: query.page,
                totalCount: 1,
              });
            }
          });
        }}
      />
    </>
  );
};

export default SellerManageOrdersTable;
