import React, { useEffect, useState } from "react";

import BoothHomeBasicInfo from "./../../components/BoothHomeBasicInfo/index";

import { Grid, Typography, Box } from "@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";
import { textalign } from "../../styles/commonCss";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { getBoothHome } from "./../../redux/booth/actions";
import { getAllCountries } from "../../redux/address/actions";
import { convertDateToIsoFormat } from "../../utils/dateUtils";
import {
  editBoothSettingsAsync,
  getBoothCategoryByProductAsync,
} from "../../redux/booth/api";
import { setSnackbar } from "../../redux/Message/actions";

import BoothProductsSection from "./../../components/BoothProducts/index";
import EditBoothSettings from "../../components/EditBoothSettings";
import ControlledAppModal from "./../../components/ControlledAppModal/index";

import * as Yup from "yup";
import { RESET_BOOTH_HOME } from "./../../redux/actionTypes";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: "0 auto",
  },
  InWrap: {
    padding: theme.spacing(1),
  },
  dashboardlogo: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "2rem",
    left: "calc(50 % - 56px)",
    //border: '2px solid',
    //borderColor: '#F8B334',
  },
}));
const BoothHome = (props) => {
  const boothId = useParams().id;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  console.log(boothId);
  const dispatch = useDispatch();

  const handleOpenEditModal = () => {
    setOpen(true);
  };
  const handleCloseEditModal = () => {
    setOpen(false);
  };

  // const [count, setCount] = useState(9)

  const classes = useStyles();

  //   const pageSize = 2
  const booth = useSelector((state) => state.boothReducer.booth);
  const imageUpload = useSelector((state) => state.boothReducer.imageUpload);
  let {
    id,
    name,
    logo,
    coverImage,
    description,
    selles,
    status,
    bazarId,
    bazarName,
    bazarLogo,
    startOfBazar,
    endOfBazar,
    bazarStatus,
    numOfOrders,
    numOfProducts,
    numOfProductRequests,
    cityId,
    countryId,
    boothCity,
    boothCountry,
    boothStreet,
    isHidden,
    boothCategories,
    earning,
    sellerAvailableTimes,
  } = booth;
  console.log(booth);

  const editSettingInitialValues = {
    name: name,
    categories: boothCategories,
    boothId: id,
    street: boothStreet,
    countryId: countryId,
    cityId: cityId,
    description: description || "",
    availableTime:
      sellerAvailableTimes?.length > 0
        ? sellerAvailableTimes.map((day) => {
            // debugger
            return {
              dateTime: new Date(day.dateTime),
              startTime: new Date(day.startAvailable + "Z"),
              endTime: new Date(day.endAvailable + "Z"),
            };
          })
        : [
            {
              dateTime: startOfBazar,
              startTime: new Date(startOfBazar),
              endTime: new Date(startOfBazar),
            },
          ],
  };
  // debugger
  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.bazarNameRequired")),
    countryId: Yup.string().required(t("validation.countryRequired")),
    cityId: Yup.string().required(t("validation.cityRequired")),
    categories: Yup.array().min(1).required(),
  });

  useEffect(() => {
    dispatch(getBoothHome(boothId));
    dispatch(getAllCountries());
    // props.getBazarBooths(bazarId, page, pageSize)
  }, []);
  useEffect(async () => {
    let result = await getBoothCategoryByProductAsync(boothId);
    let categoriesId = result.map((cat) => cat.id);
    console.log(categoriesId, "categoriesId");
    if (categoriesId?.length > 0) {
      let editedObj = { ...editSettingInitialValues, categories: categoriesId };
      try {
        const result = await editBoothSettingsAsync(editedObj);
      } catch (error) {
        console.log(error);
      }
    }
  }, [boothId]);
  useEffect(() => {
    return () => {
      console.log("cleared");
      dispatch({ type: RESET_BOOTH_HOME });
    };

    // props.getBazarBooths(bazarId, page, pageSize)
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    let submitValues = JSON.parse(JSON.stringify(values));
    debugger;
    // if (
    //   submitValues.availableTime[0].startTime !==
    //   submitValues.availableTime[0].endTime
    // ) {
    // submitValues.availableTime = submitValues.availableTime.map((day) => ({
    //   dateTime: new Date(day.dateTime),
    //   startTime: new Date(day.startTime),
    //   endTime: new Date(day.endTime),
    // }))
    // } else submitValues.availableTime = null;
    // debugger;
    const categoriesIds = values.categories.map((cat) => cat.id);
    submitValues.categories = categoriesIds;
    console.log(submitValues, "reee");

    try {
      const result = await editBoothSettingsAsync(submitValues);
      setSubmitting(false);
      handleCloseEditModal();
      setSnackbar(true, "success", t("messages.boothEditedSuccessfuly"));
      console.log(result);
      dispatch(getBoothHome(id));
    } catch (error) {
      console.log(error);
      console.log(error?.response);
    }
  };
  return (
    <>
      <div className={classes.root}>
        <Grid container className={classes.InWrap}>
          <Grid item xs={12}>
            {/* <Breadcrumbs /> */}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5">{t("boothHome.boothInfo")}</Typography>
          </Grid>
          <BoothHomeBasicInfo
            boothCategories={boothCategories}
            booth={booth}
            boothId={boothId}
            openEditModal={handleOpenEditModal}
            imageUpload={imageUpload}
          />

          {description && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">{t("description")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <Box
                    padding="20px"
                    width="100%"
                    // border="1px solid gray"
                    // my={3}
                  >
                    {/* <Card
                      style={{ padding: '20px', backgroundColor: '##000000de' }}
                    > */}
                    {/* {'description'} */}
                    {description}
                    {/* </Card> */}
                  </Box>
                </Typography>
              </Grid>
            </>
          )}
          {/* <Box mx={5} width={'100%'}> */}

          <BoothProductsSection boothId={boothId} />
          {/* </div> */}
        </Grid>
      </div>
      <ControlledAppModal
        handleClose={handleCloseEditModal}
        handleClickOpen={handleOpenEditModal}
        open={open}
        title={t("BoothSettings")}
        titleStyle={{ textAlign: "center" }}
      >
        <EditBoothSettings
          categoryType="Bazar"
          bazarId={bazarId}
          minStartDate={new Date(startOfBazar)}
          maxEndDate={new Date(endOfBazar)}
          initialValues={editSettingInitialValues}
          handleSubmit={handleSubmit}
          validationSchema={validationSchema}
        />
      </ControlledAppModal>
    </>
  );
};

export default BoothHome;
