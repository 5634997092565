import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import ProductInfo from "./../ProductInfo/index";

const styles = makeStyles(() => ({ table: { minWidth: 700 } }));

const OrderItemsModalContent = ({
  orderItems,
  shippingPrice = 0.2,
  subTotal,
  total,
  role,
  orderNo,
}) => {
  console.log(
    orderItems,
    "OrderItemsModalContentuuu",
    orderItems,
    subTotal,
    total,
    role,
    orderNo
  );
  const classes = styles();
  const TAX_RATE = 0.07;

  function ccyFormat(num) {
    debugger;
    return num ? num.toFixed(2) : num;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
  }

  // function subtotal(items) {
  //   return items
  //     .map(({ product, units }) => product.price * units)
  //     .reduce((sum, i) => sum + i, 0)
  // }

  // const invoiceSubtotal = subtotal(orderItems)
  // const invoiceTaxes = TAX_RATE * invoiceSubtotal
  const { t } = useTranslation();
  // const invoiceTotal = shippingPrice + invoiceSubtotal
  const body = (
    <div className={classes.paper}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            {/* <TableRow>
                </TableRow> */}
            <TableRow>
              <TableCell align="left">{t("products")}</TableCell>
              {/* <TableCell>Desc</TableCell> */}
              <TableCell align="center">{t("productId")} </TableCell>
              {(localStorage.userRole === "Seller" ||
                localStorage.userRole === "Buyer") && (
                <>
                  <TableCell align="center">{t("cartPage.color")} </TableCell>
                  <TableCell align="center">{t("cartPage.size")}</TableCell>
                </>
              )}
              {localStorage.userRole === "Organizer" && (
                <TableCell align="center">{t("sellerName")} </TableCell>
              )}
              <TableCell align="center">{t("cost")} </TableCell>
              <TableCell align="center">{t("qty")}</TableCell>
              <TableCell align="center">{t("total")}</TableCell>
              {localStorage.userRole !== "Provider" && (
                <TableCell align="center">{t("status")}</TableCell>
              )}
              <TableCell align="center">{t("cartPage.orderDetails")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map(
              ({
                product,
                units,
                id,
                color,
                size,
                orderItemStatusName,
                description,
              }) => (
                <TableRow key={id}>
                  {console.log(orderItemStatusName, "orderItemStatusName")}
                  <TableCell>
                    <ProductInfo {...product} />
                  </TableCell>
                  <TableCell align="center">{product.id}</TableCell>
                  {localStorage.userRole === "Organizer" && (
                    <TableCell align="center">
                      {product?.seller?.firstName +
                        " " +
                        product?.seller?.lastName}
                    </TableCell>
                  )}
                  {(localStorage.userRole === "Seller" ||
                    localStorage.userRole === "Buyer") && (
                    // <TableCell align="center">{product.id}</TableCell>
                    <>
                      <TableCell align="center">{color}</TableCell>
                      <TableCell align="center">{size || ""}</TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    {orderItems[0].currency} {ccyFormat(product.price)}
                  </TableCell>
                  <TableCell align="center">{units}</TableCell>
                  <TableCell align="center">
                    <div style={{ display: "flex" }}>
                      {orderItems[0]?.product?.currency}{" "}
                      {ccyFormat(product.price * units)}
                    </div>
                  </TableCell>
                  {localStorage.userRole !== "Provider" && (
                    <TableCell align="center">{orderItemStatusName}</TableCell>
                  )}
                  <TableCell align="center">{description}</TableCell>
                </TableRow>
              )
            )}
            {localStorage.userRole === "Seller" ? (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={2}>{t("order.orderTotal")}</TableCell>
                <TableCell align="center">
                  {orderItems[0].currency} {ccyFormat(subTotal)}
                </TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>{t("subtotal")}</TableCell>
                  <TableCell align="center">
                    {orderItems[0].currency} {ccyFormat(subTotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{t("shipping")}</TableCell>

                  <TableCell align="center">
                    {orderItems[0].currency} {ccyFormat(shippingPrice)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{t("bill")}</TableCell>
                  <TableCell align="center">
                    {orderItems[0].currency} {ccyFormat(total)}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return <>{body}</>;
};

export default OrderItemsModalContent;
