import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import tableIcons from "./../../styles/materialTableIcons";
import {
  Avatar,
  Box,
  Button,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
} from "@material-ui/core";
import * as mui from "@material-ui/core";
import { colors } from "../../styles/commonCss";
import { Link } from "react-router-dom";
import axios from "axios";
import UserInfoModal from "./../../components/UserInfoModal/index";
import { useDispatch, useSelector } from "react-redux";
import { getSellerById } from "./../../redux/seller/actions";
// import connect from "./../../components/CreateBazar/StepFourChoosePackage/index";
import ControlledAppModal from "../../components/ControlledAppModal";
import InviteSeller from "./../../components/InviteSellerModalContent/index";
import { useTranslation } from "react-i18next";
import { getBazarHome } from "../../redux/bazar/actions";

const useStyles = makeStyles((theme) => ({
  dis: {
    display: "flex",
    justifyContent: "center",
    ["@media (max-width:780px)"]: {
      justifyContent: "flex-start",
    },
    ["@media (max-width:422px)"]: {
      justifyContent: "flex-start",
    },
  },
}));
const token = localStorage.token;
const SellerListTable = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const bazarId = 14
  const classes = useStyles();
  const bazarId = props.match.params.id;
  console.log(bazarId, "id1");
  console.log(props, "p1");
  const getUserById = (sellerId) => {
    dispatch(getSellerById(sellerId));
  };
  const getBazarById = (bazarId) => {
    // console.log(sellerId)
    dispatch(getBazarHome(bazarId));
  };
  const seller = useSelector((state) => state.sellerReducer.seller);
  const bazar = useSelector((state) => state.bazarReducer.bazarHome);
  const [openIN, setOpenIN] = useState(false);
  const handleOpenInvitation = () => {
    setOpenIN(true);
  };
  const handleCloseInvitation = () => {
    setOpenIN(false);
  };
  const [count, setCount] = useState(null);
  useEffect(() => {
    getBazarById(bazarId);
  }, []);
  const body = (
    <Grid container className={classes.dis} xs={12}>
      <Grid container justify="center">
        <Grid container item justify="center">
          <img
            src="/assets/images/empty/Seller.svg"
            alt="emptyBazar"
            style={{ width: "150px" }}
          />
        </Grid>
        <Grid
          container
          item
          justify="center"
          style={{ margin: "1px 0", fontSize: ".8rem" }}
        >
          <span style={{ textAlign: "center", margin: "1rem" }}>
            {t("inviteSellerNow")}
          </span>
        </Grid>
        <Grid container item justify="center">
          <Button
            variant="contained"
            onClick={handleOpenInvitation}
            color="primary"
            style={{ marginBottom: "10px", textAlign: "end" }}
          >
            {t("inviteSeller.Invite")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
          <Link
            style={{ color: "gray" }}
            onClick={() =>
              props.history.push(`/Organizer/bazarHome/${bazarId}`)
            }
          >
            {bazar.name}
          </Link>
          <Typography className="special">{t("buttons.sellers")}</Typography>
        </Breadcrumbs>
        {bazar.status === "CommingSoon" && (
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ marginBottom: "1rem" }}
          >
            <Button
              variant="contained"
              onClick={handleOpenInvitation}
              color="primary"
              style={{ marginBottom: "10px", textAlign: "end" }}
            >
              {t("inviteSeller.Invite")}
            </Button>
          </Box>
        )}
      </Box>
      <MaterialTable
        icons={tableIcons}
        style={{ padding: "20px 0", fontSize: "15px", overflowX: "scroll" }}
        localization={{
          toolbar: {
            searchPlaceholder: t("searchForSeller"),
          },
          body: {
            emptyDataSourceMessage: body,
          },
        }}
        title={`${t("buttons.sellers")} (${count})`}
        columns={[
          {
            title: t("image"),
            field: "profileImage",
            render: (row) => {
              return (
                <Avatar
                  alt={row.seller ? row.seller.name : ""}
                  src={
                    row.seller?.profileImage
                      ? localStorage.imagePath + row.seller.profileImage
                      : "/static/images/avatar/1.jpg"
                  }
                />
              );
            },
            cellStyle: {
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            title: t("name"),
            field: "seller.name",

            cellStyle: { width: "fit-content", textAlign: "center" },
            render: ({ seller: { firstName, lastName } }) => (
              <div>{firstName + " " + lastName}</div>
            ),
          },
          {
            title: t("email"),
            field: "seller.email",

            cellStyle: { width: "fit-content", textAlign: "center" },
          },
          {
            title: t("signUp.city"),
            field:
              localStorage.i18nextLng === "en"
                ? "seller.cityName"
                : "seller.cityNameAr",
            cellStyle: { width: "fit-content", textAlign: "center" },
            // render: ({ seller: { firstName, lastName } }) => (
            //   <div>{firstName + ' ' + lastName}</div>
            // ),
          },
          {
            title: t("signUp.phoneNumber"),
            field: "seller.phoneNumber",
            cellStyle: {
              width: "fit-content",
              textAlign: "center",
              direction: "ltr",
            },
          },

          {
            title: t("sideMenu.booth"),
            // field: "name",
            cellStyle: { width: "fit-content", textAlign: "center" },
            render: (row) => (
              <mui.Link
                component="button"
                variant="body2"
                style={{ color: colors.link, fontSize: "15px" }}
                onClick={() =>
                  props.history.push(
                    `/buyer/boothPage/${row.id}?notBuyer=${true}`
                  )
                }
              >
                {row.name}
              </mui.Link>
            ),
          },
          {
            title: t("actions"),
            field: "actions",
            render: (row) => (
              <UserInfoModal
                user={row.seller}
                role={"Seller"}
                label={t("links.view")}
                style={{ color: "black" }}
              />
            ),

            cellStyle: { width: "fit-content", textAlign: "center" },
          },
        ]}
        options={{
          search: true,
          paginationType: "stepped",
          pageSize: 10,
          headerStyle: { backgroundColor: "#f1f1f1", textAlign: "center" },
          rowStyle: { textAlign: "center" },
          editCellStyle: { marginLeft: "20px" },
          sorting: false,
          pageSizeOptions: [10],
        }}
        data={(query) => {
          return new Promise((resolve, reject) => {
            const { page, pageSize, search } = query;

            let url = !search
              ? `Organizer/GetBazarSeller?BazarId=${bazarId}&PageSize=${pageSize}&PageIndex=${
                  page + 1
                }`
              : `Seller/SearchBySellerName?SellerName=${search}&BazarId=${bazarId}&PageSize=${pageSize}&PageIndex=${
                  page + 1
                }`;

            axios
              .get(url, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(({ data }) => {
                const response = data;
                setCount(data.count);
                console.log(data, "fgfg");
                resolve({
                  data: response.data || response,
                  page: query.page,
                  totalCount: response.count || 1,
                });
              });
          });
        }}
      />
      <ControlledAppModal
        handleClickOpen={handleOpenInvitation}
        open={openIN}
        handleClose={handleCloseInvitation}
      >
        <InviteSeller handleClose={handleCloseInvitation} inviteID={bazarId} />
      </ControlledAppModal>
    </>
  );
};

export default SellerListTable;
