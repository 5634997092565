import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colors, textalign } from "../../styles/commonCss";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "./../../components/lib/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BoothCard from "../../components/BoothCard";
import { Link } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { getBazarBooths, getBazarHome } from "../../redux/bazar/actions";
import HomeBasicInfo from "../../components/HomeBasicInfo";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: "0 auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bazarBackgroundCover: {
    background: "url(/assets/images/bazars/active_bazar.jpg)",
    width: "100%",
    minHeight: "400px",
    backgroundSize: "cover",
    borderRadius: "10px",
  },
  textAlignRight: {
    textAlign: textalign.right,
  },
  textAlignCenter: {
    textAlign: textalign.center,
  },
  button: {
    margin: theme.spacing(1),
  },
  InWrap: {
    padding: theme.spacing(1),
  },
  dashboardlogo: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: "2rem",
    left: "calc(50 % - 56px)",
    //border: '2px solid',
    //borderColor: '#F8B334',
  },
  dvDashboardlogo: {
    display: "flex",
    justifyContent: "center",
    top: "-55px",
    position: "absolute",
    width: "100%",
  },
  settingIcon: { display: "flex", justifyContent: "flex-end" },
  gridDashboardlogo: {
    position: "relative",
  },
  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridShortInfo: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
  },

  gridShortInfoItem: {
    display: "flex",
    padding: "10px",
  },
  statsIcons: {
    width: "100%",
    minHeight: "50px",
  },
  salesIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  RequestsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },
  EarningsIcon: {
    background: "url(/assets/images/icons/test.png)",
  },

  gridroot: {
    flexGrow: 1,
  },

  GridItemList: {
    marginBottom: "50px",
  },
  chartSize: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    margin: "15px 40px",
    // display: 'flex',
    // justifyContent: 'center',
  },
  earningChart: {
    // height: '185px',
  },
}));

const BazarHome = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  // const [booths, setbooths] = useState([])
  // const [count, setCount] = useState(9)
  console.log(props, "bazz");
  const pageSize = 9;
  const { t } = useTranslation();
  const PageRef = useRef();
  const bazarId = props.match.params.id;
  const { bazar, bazarBooths, boothsCount } = props;
  const { description, earning, selles, request } = bazar;
  console.log(description, earning, selles, request, "Bazarrrr Info");
  console.log(bazarId);
  const scrollPage = () => PageRef?.current?.scrollIntoView();
  const handlePageChange = (event, newPage) => {
    // fetchData(newPage, search)
    props.getBazarBooths(bazarId, newPage, pageSize);

    setPage(newPage);
    setTimeout(() => scrollPage(), 1000);
  };

  useEffect(() => {
    props.getBazarHome(bazarId);
    props.getBazarBooths(bazarId, page, pageSize);
  }, []);
  return (
    <div className={classes.root}>
      <Grid container className={classes.InWrap}>
        <Grid item xs={12}>
          <Breadcrumbs />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">{t("bazarHome.bazarInfo")}</Typography>
        </Grid>
        {bazar && <HomeBasicInfo bazar={bazar} />}

        {description && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">{t("description")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Box
                  padding="20px"
                  width="100%"
                  // border="1px solid gray"
                  my={3}
                >
                  {description}
                </Box>
              </Typography>
            </Grid>
          </>
        )}
        <div ref={PageRef}></div>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="baseline"
          className={"margin-top-50"}
        >
          <Grid>
            <Typography variant="h6">
              <Box marginBottom="15px">{t("bazarHome.booths")}</Box>
            </Typography>
          </Grid>
          <Grid>
            <Link
              // to={}
              style={{ color: colors.link, cursor: "pointer" }}
              onClick={() =>
                props.history.push(`/Organizer/Bazar/${bazarId}/Booths`)
              }
            >
              {t("viewAll")}
            </Link>
          </Grid>
        </Grid>
        {/* <div className={'margin-top-50'}> */}
        <Grid
          container
          item
          spacing={2}
          // justify="flex-start"
          direction="row"
          xs={12}
          alignItems="center"
          align="center"
        >
          {bazarBooths.map((booth) => {
            console.log(bazarBooths);
            return (
              <Grid
                item
                container
                justify="center"
                // xs={12}
                md={4}
                style={{ marginBottom: "70px" }}
                key={booth.id}
              >
                <BoothCard bazarStatus={"booth"} {...booth} />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} container justify="center">
          <div className={"margin-top-30"}>
            <Pagination
              count={Math.ceil(boothsCount / pageSize)}
              color="primary"
              size="small"
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </Grid>
        {/* </div> */}
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  console.log(state.bazarReducer.bazarBooths, "bazarBooths");
  return {
    bazar: state.bazarReducer.bazarHome,
    bazarBooths: state.bazarReducer.bazarBooths,
    boothsCount: state.bazarReducer.bazarBoothsCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBazarHome: (id) => dispatch(getBazarHome(id)),
    getBazarBooths: (bazarId, currentPage, pageSize) =>
      dispatch(getBazarBooths(bazarId, currentPage, pageSize)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BazarHome);
