import React, { useState } from "react";
import DeclineModal from "../DeclineModal";
import { useDispatch } from "react-redux";
import { declineBoothRequest } from "../../redux/organizer/actions";

const declineReasonsArr = [
  {
    id: 1,
    checked: false,
    reason: "Your booth location is not appropriate for this bazar",
  },
  {
    id: 2,
    checked: false,
    reason: "Your booth description is not appropriate for this bazar",
  },
  {
    id: 3,
    checked: false,
    reason:
      "Organizer could not contact you through the number you have provided",
  },
  { id: 4, checked: false, reason: "Other " },
];

const DeclineBoothReasonsModal = (props) => {
  // const [open, setOpen] = useState(true)
  const { handleOpen, handleClose, declineBoothId, open } = props;

  console.info("opeeen", open);
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [reasons, setReasons] = useState([...declineReasonsArr]);
  const [description, setDescription] = useState("");
  let x = declineReasonsArr[3].checked;
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCheckBoxChange = (e) => {
    let newReasonsArr = [...reasons];
    const checkedId = +e.target.value;
    console.log("checkedId", checkedId);
    newReasonsArr = newReasonsArr.map((item) => {
      if (item.id === checkedId) {
        console.log(item);
        item.checked = !item.checked;
        console.log(item);
        setError("");
      }
      return item;
    });
    console.log(newReasonsArr);
    setReasons(newReasonsArr);
  };

  const handleModalSubmit = async () => {
    let declineReason = [];
    let reasonSelected = false;

    reasons.map((item) => {
      console.log(item, "imt");
      if (item.reason === "Other ") {
        x = true;
      }
      if (item.checked === true) {
        if (item.reason !== "Other ") {
          declineReason.push(item.reason);
        } else {
          declineReason.push(description);
        }
        reasonSelected = true;
      }
    });

    if (!reasonSelected) {
      setError("You should Select Reason");
    } else {
      declineReason = declineReason.join(",");

      const declineObj = {
        boothId: declineBoothId,
        declineReason,
        declineDescription: description,
      };

      handleClose();
      console.log(declineObj);
      dispatch(declineBoothRequest(declineObj));
      // await postDeclineObject(declineObj)
    }
  };

  return (
    <DeclineModal
      handleOpen={handleOpen}
      handleClose={() => {
        setReasons([
          {
            id: 1,
            checked: false,
            reason: "Your booth location is not appropriate for this bazar",
          },
          {
            id: 2,
            checked: false,
            reason: "Your booth description is not appropriate for this bazar",
          },
          {
            id: 3,
            checked: false,
            reason:
              "Organizer could not contact you through the number you have provided",
          },
          { id: 4, checked: false, reason: "Other " },
        ]);
        handleClose();
      }}
      handleDescriptionChange={handleDescriptionChange}
      descriptionValue={description}
      open={open}
      handleModalSubmit={handleModalSubmit}
      handleCheckBoxChange={handleCheckBoxChange}
      reasonsArr={reasons}
      error={error}
      x={x}
    />
  );
};

export default DeclineBoothReasonsModal;
