import React from 'react'
import { Button, capitalize } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
const NoData = ({
  imgWidth = '180px',
  src,
  msg,
  onClickFn,
  btnText,
  btnIcon,
}) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        height: 'max-content',
      }}
    >
      <Box width={imgWidth}>
        <img src={src} width="100%" height="100%" />
      </Box>
      <div>{t(msg)}</div>
      <Button
        style={{ marginTop: '40px' }}
        variant="contained"
        color="primary"
        onClick={onClickFn}
      >
        {btnIcon} {capitalize(t(btnText))}
      </Button>
    </div>
  )
}

export default NoData
