import React, { useEffect } from 'react'
import './App.css'
import 'flag-icon-css/css/flag-icon.min.css'
import { useTranslation } from 'react-i18next'
import SwitchLanguage from '../../components/LanguageDropdown/index'

import Registeration from '../RegisterationPage'
import { Box } from '@material-ui/core'
import ReactGA from 'react-ga'
import { Route, Switch, Redirect, useLocation } from 'react-router'
import SelectRolePage from '../SelectRolePage'
import SideBarDrawer from '../../components/sideBarDrawer'
import { OrganizerDrawerItems } from '../OrganizerDashBoard/constants'
import LoginPage from './../LoginPage/index'
import CreateBazar from '../CreateBazar'

import DashBoard from './../DashBoards/index'
import BazarPage from './../BazarPage'
import BoothPage from './../BoothPage'
import ProductDetailsPage from './../ProductDetailPage'
import ProductsCartPage from './../ProductCartPage'
import ConfirmOrderPage from '../ConfirmOrderPage'

import BazarsTable from '../OrganizerBazarsTable'
import BazarSettings from '../BazarSettings'
import BoothRequests from '../OrganizerBoothRequests'
import PaymentHistory from '../../components/PaymentHistory'
import SellerListTable from './../SellerListTable/index'

import BoothList from '../BoothList'

import OrganizerProductsRequests from './../OrganizerProductsRequests/index'
import ScrollToTop from '../../components/ScrollToTopComponent'
import BazarHome from '../BazarHome'

import OrganizerManageOrdersTable from './../OrganizerManageOrdersTable/index'

import OrganizerProfile from './../OrganizerProfile/index'
import { SellerDrawerItems } from '../SellerDashBoard/constants'
import HomePage from '../HomePage'
import Home from '../Home'
import { useState } from 'react'
import BoothHome from './../BoothHome/index'
import SellerAddProduct from './../SellerAddProduct/index'
import SellerProducts from './../SellerProducts/index'
import SellerBoothProductsTable from '../SellerBoothProductsTable'
import SellerManageOrdersTable from './../SellerManageOrdersTable/index'

import SellerProfile from './../SellerProfile/index'
import SellerPaymets from './../SellerPamenyts/index'

import ProviderBazarsRequests from './../ProviderBazarsRequests/index'

import DriversPage from './../DriversPage/index'
import ProviderProfile from './../ProviderProfile/index'
import ProviderManageOrders from '../ProviderManageOrders'

import ProviderOrderDetails from './../ProviderOrderDetails/index'

import { getImageUrlAsync } from './../../redux/booth/api'
import { useSelector } from 'react-redux'

import SummaryPage from './../SummaryPage/index'
import ResetPassword from '../ResetPassword'
import ForgetPassword from './../ForgetPassword/index'
import InviteSeller from '../../components/InviteSellerModalContent'
import RequestSellerTable from './../../components/RequestSellerTable'
import TestCarousel from './../../components/TestCarousel/index'

import JoinBazar from '../JoinBazar'

import BuyerProfile from './../BuyerProfile/index'
import Help from '../Help'
import BuyerOrders from './../BuyerOrders/index'
import CustomizedSnackbars from './../../components/Snackbar/index'
import NewFooter from './../../components/NewFooter'
function App() {
  const { t } = useTranslation()
  // const userRole = localStorage.userRole

  const { pathname } = useLocation()

  // const userRole = useSelector((state) => state.loginReducer.userRole)
  const userRole = localStorage.userRole
  useEffect(() => {
    // axios.get('https://api.color.pizza/v1/').then((res) => console.log(res))
    // console.log(namedColors)
    ReactGA.initialize('UA-207308803-1')
    // ReactGA.initialize("UA-212327279-1");
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    <>
      <CustomizedSnackbars />
      {/* <div style={{ marginTop: '20px' }}>
        <TestCarousel />
      </div> */}
      {/* <div style={{ marginTop: '50px' }}>
        <SwitchLanguage />
      </div> */}
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path="/" exact component={Home} />
        <Route path="/HomePagee" exact component={HomePage} />
        <Route path="/signUp" exact component={SelectRolePage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/forgetPassword" component={ForgetPassword} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/Home" exact component={Home} />
        <Route path="/Home/:role" component={Home} />
        <Route path="/buyer/bazarPage/:bazarId/:pag" component={BazarPage} />
        <Route
          path="/buyer/bazarPage/:bazarId"
          exact
          component={BazarPage}
          // render={(props) => <BazarPage {...props} />}
        />
        <Route path="/buyer/boothPage/:boothId/:pag" component={BoothPage} />
        <Route path="/buyer/boothPage/:boothId" component={BoothPage} />
        <Route path="/buyer/boothPage" component={BoothPage} />
        <Route
          path="/buyer/productDetailsPage/:productId/:pag"
          component={ProductDetailsPage}
        />
        <Route
          path="/buyer/productDetailsPage/:productId"
          component={ProductDetailsPage}
        />
        <Route path="/buyer/cartPage" component={ProductsCartPage} />
        <Route
          path="/buyer/confirmOrderPage/:orderId"
          component={ConfirmOrderPage}
        />
        <Route path="/buyer/summaryPage/:orderId" component={SummaryPage} />

        <Route path="/register/:role" component={Registeration} />
        <Route path="/createBazar/:id" component={CreateBazar} />
        <Route path="/createBazar" component={CreateBazar} />
        <Route path="/help" component={Help} />
        {/* <Route path="/dashBoard/:role" component={DashBoard} /> */}
        {/* <Route path="/dashBoard">
          {userRole ? <Redirect to={'/dashboard/' + userRole} /> : <HomePage />}
        </Route> */}

        <Route path="/dashBoard/:role" component={DashBoard} />
        {userRole === 'Buyer' && (
          <Switch>
            <Route path="/Buyer/profile" exact component={BuyerProfile} />
            <Route path="/Buyer/orders" exact component={BuyerOrders} />
          </Switch>
        )}
        {userRole === 'Provider' && (
          <SideBarDrawer role="Provider">
            <Switch>
              <Route
                path="/Provider/bazarRequests"
                component={ProviderBazarsRequests}
              />
              <Route path="/Provider/drivers" component={DriversPage} />
              <Route path="/Provider/profile" component={ProviderProfile} />
              <Route
                path="/Provider/manageOrders/:bazarId"
                component={ProviderManageOrders}
              />
              <Route
                path="/Provider/manageOrders"
                component={ProviderManageOrders}
              />
              <Route
                path="/Provider/orderDetails/:orderId"
                component={ProviderOrderDetails}
              />
            </Switch>
          </SideBarDrawer>
        )}
        {userRole === 'Seller' && (
          <SideBarDrawer DrawerItems={SellerDrawerItems} role="Seller">
            <Switch>
              <Route path="/Seller/bazarHome/:id" component={BazarHome} />
              <Route
                path="/Seller/bazarRequests"
                component={RequestSellerTable}
              />
              <Route path="/Seller/CommingSoon" component={JoinBazar} />
              <Route
                path="/Seller/paymentHistory"
                component={PaymentHistory}
                exact
              />
              <Route
                path="/Seller/booth/:id/products/:sellerBoothpage"
                exact
                component={SellerBoothProductsTable}
              />
              <Route
                path="/Seller/booth/:id/products"
                exact
                component={SellerBoothProductsTable}
              />
              <Route path="/Seller/boothHome/:id" exact component={BoothHome} />
              <Route
                path="/Seller/EditProduct/:productId"
                exact
                component={SellerAddProduct}
              />
              <Route
                path="/Seller/booth/:boothId/EditProduct/:productId"
                exact
                component={SellerAddProduct}
              />
              <Route
                path="/Seller/booth/:boothId/AddProduct"
                exact
                component={SellerAddProduct}
              />
              {/* <Route
                path="/Seller/boothHome/AddProduct/:boothId"
                exact
                component={SellerAddProduct}
              /> */}

              <Route
                path="/Seller/AddProduct"
                exact
                component={SellerAddProduct}
              />
              <Route
                path="/Seller/products/:boothId"
                exact
                component={SellerProducts}
              />
              <Route path="/Seller/products" exact component={SellerProducts} />
              <Route
                path="/Seller/manageOrders/:boothId"
                exact
                component={SellerManageOrdersTable}
              />

              <Route path="/Seller/profile" exact component={SellerProfile} />
              <Route path="/Seller/payments" exact component={SellerPaymets} />
            </Switch>
          </SideBarDrawer>
        )}

        {userRole === 'Organizer' && (
          <SideBarDrawer DrawerItems={OrganizerDrawerItems} role="Organizer">
            <Switch>
              <Route path="/Organizer/Profile" component={OrganizerProfile} />
              <Route
                path="/Organizer/boothRequests"
                component={BoothRequests}
              />
              <Route path="/Organizer/Bazars" component={BazarsTable} />
              <Route
                path="/Organizer/manageOrders/:bazarId"
                component={OrganizerManageOrdersTable}
              />
              <Route
                exact
                path="/Organizer/manageOrders"
                component={OrganizerManageOrdersTable}
              />
              <Route
                exact
                path="/Organizer/bazar/:id/Sellers"
                component={SellerListTable}
              />
              <Route path="/Organizer/bazarHome/:id" component={BazarHome} />
              <Route
                exact
                path="/Organizer/Bazar/:id/Booths"
                exact
                component={BoothList}
              />
              <Route
                exact
                path="/Organizer/Bazar/settings/:id"
                component={BazarSettings}
              />

              <Route
                path="/Organizer/organizerProductsRequests/:boothId"
                component={OrganizerProductsRequests}
              />
              <Route
                path="/Organizer/organizerProductsRequests"
                component={OrganizerProductsRequests}
              />
            </Switch>
          </SideBarDrawer>
        )}
        <Redirect to="/" />
      </Switch>
      <ScrollToTop />
      {pathname === '/' || pathname.startsWith('/buyer') ? <NewFooter /> : ''}
    </>
  )
}

export default App
