import { GET_ALL_CATEGORIES } from '../actionTypes'
const initialState = {
  categories: [],
}
const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return { ...state, categories: action.payload }

    default:
      return state
  }
}
export default catalogReducer
