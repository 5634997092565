import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import AppModal from "../../../../components/AppModal";
import { colors } from "../../../../styles/commonCss";
import { Divider, Grid, Typography } from "@material-ui/core/index";
import OrderModalBody from "../../../../components/S/OrderModalBody";

// import '../../../App/App.css'
import OrderItemsModalContent from "./../OrderItemsModalContent/index";
import ControlledAppModal from "./../../../ControlledAppModal/index";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  divider: {
    margin: "20px 0px",
  },
  marginX: {
    margin: "10px 0",
  },
});

const OrderItemsModal = ({
  orderNo,
  orderItems,
  subTotal,
  total,
  shippingPrice,
  handleOpen,
  handleClose,
  open,
  role,
}) => {
  debugger;
  console.log("Order Items", orderItems);
  console.log(orderNo);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <ControlledAppModal
      title={t("order.orderNo") + ":" + orderNo}
      contentStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px 0px",
        marginBottom: "50px",
        // width: '80%',
      }}
      titleStyle={{ margin: "0px", backgroundColor: "#ecebeb" }}
      label={orderNo}
      buttonStyle={{
        color: colors.link,
        fontSize: "15px",
        textDecoration: "underline",
      }}
      handleClickOpen={handleOpen}
      handleClose={handleClose}
      open={open}
    >
      <Box>
        <OrderItemsModalContent
          orderItems={orderItems}
          shippingPrice={shippingPrice}
          subTotal={subTotal}
          total={total}
          role={role}
        />
      </Box>
    </ControlledAppModal>
  );
};

export default OrderItemsModal;
