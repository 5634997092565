import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Link, makeStyles } from "@material-ui/core";
import { colors } from "../../styles/commonCss";

const styles = (theme) => ({
  root: {
    margin: " 0 auto",
    padding: theme.spacing(2),
    // borderRadius: '20px',
    // padding: '0 60px',
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: { width: "800px", backgroundColor: "red" },
  myStyle: { padding: "20px" },
});
const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: "50px",
    width: "100%",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AppModal({
  title,
  children,
  buttonStyle,
  buttonClassName,
  titleStyle,
  contentStyle,
  label,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link
        component="button"
        variant="body2"
        onClick={handleClickOpen}
        style={
          buttonStyle || {
            color: colors.link,
            fontSize: "15px",
            textDecoration: "underline",
          }
        }
        className="view-link"
      >
        {label}
      </Link>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // className={classes}
        style={{ width: "100%" }}
        maxWidth="lg"
        // fullScreen
        // contentStyle={{ width: '100%', maxWidth: 'none' }}
      >
        <div>
          <DialogTitle
            id="customized-dialog-title"
            style={titleStyle || { margin: "0px" }}
            onClose={handleClose}
          >
            {title}
          </DialogTitle>
          <DialogContent className={classes.myStyle}>{children}</DialogContent>
          {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
        </div>
      </Dialog>
    </div>
  );
}
