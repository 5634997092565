import "./productdetails.css";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import Header from "./../../components/Header";
import { useParams } from "react-router";
import axios from "axios";
import ReviewCard from "./../../components/ReviewCard";
import ReviewModal from "./../../components/ReviewModal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import ProductInfo from "../../components/ProductInfo";
import "bootstrap/dist/css/bootstrap.min.css";
import RelatedProductSec from "../../components/RelatedProductSec";
import { ADD_TO_CART } from "../../redux/actionTypes";

function creatImg(original, thumbnail) {
  return { original, thumbnail };
}

const useStyles = makeStyles({
  bttn: {
    color: "orange",
    border: "1px solid orange",
    marginLeft: "75%",
    marginBottom: ".5rem",
    ["@media (max-width:768px)"]: {
      marginLeft: "66%",
    },
    ["@media (max-width:500px)"]: {
      marginLeft: "25%",
    },
  },
});

const ProductDetailsPage = (props) => {
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  console.log(props, "prprops");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  let userId = localStorage.getItem("userId");
  const isLoggedIn = localStorage.token;
  const userRole = localStorage.userRole;
  const today = new Date(Date.now()).toISOString();
  const productid = useParams().productId || props.productId;

  const [productInfo, setProductInfo] = useState({
    seller: { firstName: "" },
    productColor: [{ color: "", id: "" }],
    productImage: [],
    bazarName: "",
    categoryId: null,
    id: null,
  });
  const [favorite, setFavorite] = useState(false);
  const [color, setColor] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [size, setSize] = useState(0);
  const [reviewID, setReviewID] = useState("");
  const [desc, setDesc] = useState("");
  const [count, setCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [field, setField] = useState("");
  const pagg = useParams().pag;
  const [currentPage, setCurrentPage] = useState(1);

  //

  const pagesize = 100;
  const [open, setOpen] = React.useState(false);

  const outBooth = props.outBooth;
  const handleOpen = () => {
    // userRole === "Organizer"
    //   ? dispatch(setSnackbar(true, "error", t("CanotaddReview"), 4000))
    //   : setOpen(true);
    if (!isLoggedIn) {
      dispatch(
        setSnackbar(true, "error", t("messages.loginToAddReview"), 4000)
      );
    } else setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const url = outBooth
      ? `Seller/GetProductDetailsByProductId?ProductId=${productid}`
      : `Buyer/GetProductInfo?BoothProductId=${productid}`;
    axios
      .get(url)
      .then((response) => {
        setProductInfo(response.data);
        console.log(response.data, "ProductInfo");
        setReviewID(response.data.productId);
        setColor(
          response.data.productColor[0] ? response.data.productColor[0].id : ""
        );
        setSize(
          response.data.productColor.length > 0
            ? response.data.productColor[0]?.productSize[0].id
            : ""
        );
        console.log("response.data.productId", response.data.productId);
      })
      .catch((error) => {
        console.log(error, "productId");
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `Buyer/GetRelatedProductsByCategory?CategoryId=${productInfo?.categoryId}&BazarId=${productInfo?.bazarId}`
      )
      .then((response) => {
        setRelatedProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productInfo]);
  useEffect(() => {
    // debugger
    if (reviewID)
      axios
        .get(
          `Review/GetReviews?ProductId=${reviewID}&PageSize=${pagesize}&PageIndex=${currentPage}`
        )
        .then((response) => {
          // debugger
          setReviews(response.data.data);
          setCount(response.data.count);
          console.log(response.data.data, "rev");
        })
        .catch((error) => {
          console.log(error?.response);
        });
  }, [count, rating, reviewID]);

  const handleColor = (event) => {
    const selectedColor = +event;
    const colorSizes = productInfo.productColor?.find(
      (color) => color?.id === selectedColor
    ).productSize;
    setColor(selectedColor);
    setSize(colorSizes[0]?.id);
  };
  const handleSize = (siz) => {
    setSize(siz.id);
  };
  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleAddReview = () => {
    setOpen(false);
    if (field && rating) {
      axios
        .post(`Review/AddReview`, {
          userID: userId,
          productID: reviewID,
          date: today,
          content: field,
          rate: rating,
        })
        .then((res) => {
          debugger;
          setReviews(res.data.data);
          setCount(res.data.count);
          dispatch(setSnackbar(true, "success", t("messages.reviewAdded")));
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    }
    axios
      .get(
        `Review/GetReviews?ProductId=${reviewID}&PageSize=${pagesize}&PageIndex=${currentPage}`
      )
      .then((response) => {
        setReviews(response.data.data);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
    setField("");
    setRating(0);
  };
  let history = useHistory();

  const addToCartAsync = () => {
    axios
      .post("Buyer/AddToBasket", {
        buyerId: userId,
        productId: productid,
        colorId: color || null,
        sizeId: size || null,
        description: desc || "",
      })
      .then((res) => {
        // debugger
        console.log(res.data, "add pasket");
        history.push(`/buyer/cartPage`);
        dispatch(setSnackbar(true, "success", t("messages.addedToCart")));
      })
      .catch((err) => {
        // debugger
        console.log(err);
        dispatch(setSnackbar(true, "error", t("messages.failTryAgain")));
      });
  };
  const handleAddFront = () => {
    let item = {
      productId: productInfo.id,
      productName: productInfo.name,
      catalogItemId: productInfo.id,
      currency: productInfo.currency,
      productCatogory: productInfo.categoryName,
      unitPrice: checkDate() ? productInfo.offerPrice : productInfo.price,
      quantity: 1,
      color: color || null,
      size: size || null,
      sellerName:
        productInfo.seller.firstName + " " + productInfo.seller.lastName,
      description: desc || "",
      productImage: productInfo.productImage,
      isDeleted: false,
      isEmpty: false,
      categoryId: productInfo.categoryId,
      totalQuantity: productInfo.quantity,
    };
    let bazarItems = {
      bazarId: productInfo.bazarId,
      bazarName: productInfo.bazarName,
      currency: productInfo.currency,
      bazarLogo: productInfo.bazarLogo,
      items: [],
    };
    dispatch({ type: ADD_TO_CART, bazarItems: bazarItems, product: item });
  };
  const handleAddToCard = () => {
    localStorage.removeItem("filter");
    const colorSizes = productInfo.productColor?.length
      ? productInfo.productColor.find((itemColor) => itemColor.id === color)
          .productSize
      : [];
    if (isLoggedIn && userRole === "Buyer") {
      if (colorSizes.length !== 0) {
        if (size) {
          addToCartAsync();
        } else
          dispatch(setSnackbar(true, "error", t("messages.selectSize"), 3000));
      } else {
        addToCartAsync();
      }
    } else if (isLoggedIn) {
      dispatch(setSnackbar(true, "error", t("messages.loginAsBuyer"), 4000));
    } else {
      if (colorSizes.length !== 0) {
        if (size) {
          handleAddFront();
          history.push(`/buyer/cartPage`);
          dispatch(setSnackbar(true, "success", t("messages.addedToCart")));
        } else
          dispatch(setSnackbar(true, "error", t("messages.selectSize"), 3000));
      } else {
        handleAddFront();
        history.push(`/buyer/cartPage`);
        dispatch(setSnackbar(true, "success", t("messages.addedToCart")));
      }
    }
  };
  const productImgs = [
    ...productInfo?.productImage,
    ...productInfo?.productColor,
  ].filter((itm) => itm.url !== "");
  /*start handle go back of browser*/
  const [locationKeys, setLocationKeys] = useState([]);
  useEffect(() => {
    return history.listen((location) => {
      debugger;
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else if (!locationKeys[0] || locationKeys?.length === 0) {
          setLocationKeys((keys) => [location.key, ...keys]);
          window.history.go(-1);
        } else if (locationKeys[0]) {
          setLocationKeys((keys) => [location.key, ...keys]);
          window.history.go(location.key);
        }
        console.log(locationKeys, location.key, "keyy");
      }
    });
  }, [locationKeys]);
  /*end handle go back of browser*/
  const checkDate = () => {
    if (
      productInfo.offerPrice &&
      new Date() >= new Date(productInfo.startOffer + "Z") &&
      new Date() <= new Date(productInfo.endOffer + "Z")
    ) {
      return true;
    } else return false;
  };
  return (
    <>
      {localStorage.setItem("page", pagg)}
      {!props.productId && <Header />}
      <div
        className={
          history?.location?.pathname.includes("/buyer/productDetailsPage")
            ? "productdetail"
            : "productdetailWithModal"
        }
      >
        <section className="breadcrumb-sec">
          <div className="container">
            <header
              style={
                localStorage.i18nextLng === "en" ? {} : { textAlign: "right" }
              }
            >
              <h3>{t("productDetails.productDetails")}</h3>
            </header>
            <article>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      onClick={() => {
                        window.localStorage.removeItem("page");
                        window.localStorage.removeItem("boothPage");
                        history.push("/");
                      }}
                    >
                      {t("home")}
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      onClick={() => {
                        window.localStorage.removeItem("page");
                        history.push(
                          `/buyer/bazarPage/${productInfo?.bazarId}`
                        );
                      }}
                    >
                      {productInfo.bazarName}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <a
                      onClick={() => {
                        window.localStorage.removeItem("page");
                        history.push(
                          `/buyer/boothPage/${productInfo?.boothId}`
                        );
                      }}
                    >
                      {productInfo.boothName}
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {productInfo.name}
                  </li>
                </ol>
              </nav>
            </article>
          </div>
        </section>
        <ProductInfo
          product={productInfo}
          count={count}
          color={color}
          setColor={setColor}
          handleColor={handleColor}
          handleSize={handleSize}
          S={size}
          handleAddToCard={handleAddToCard}
          buyer={!props.productId ? true : false}
          productImgs={productImgs}
          creatImg={creatImg}
          handleDesc={handleDesc}
          favorite={favorite}
        />
        <section className="reviews-section">
          <div className="container">
            <header>
              <h3>{t("productDetails.reviews")}</h3>
              {reviews?.length > 0 ? (
                <button onClick={handleOpen}>
                  {t("productDetails.addYourReview")}
                </button>
              ) : (
                ""
              )}
            </header>
            <article>
              {reviews?.length > 0 ? (
                reviews.map((itm, i) => (
                  <ReviewCard
                    key={i}
                    name={itm.buyer.firstName}
                    date={itm.date}
                    content={itm.content}
                    rate={itm.rate}
                    profileImage={itm?.buyer?.profileImage}
                  />
                ))
              ) : (
                <div className="no-review-card">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-10">
                      <div className="no-review-content text-center">
                        <img src="/assets/images/icons/no-review.png" alt="" />
                        <p> {t("cartPage.noReviews")}</p>
                        {!props.productId && (
                          <button onClick={handleOpen}>
                            {t("productDetails.addYourReview")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </article>
          </div>
          {isLoggedIn && (
            <ReviewModal
              rating={rating}
              setRating={setRating}
              field={field}
              setField={setField}
              handleClose={handleClose}
              open={open}
              handleAddReview={handleAddReview}
            />
          )}
        </section>
        <RelatedProductSec
          products={relatedProducts}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default ProductDetailsPage;
