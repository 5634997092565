import React from "react";
import ResetPassword1 from "../../components/ResetPassword1/index";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Container, IconButton, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Grid } from "@material-ui/core/index";
import ResetPassword2 from "../../components/ResetPassword2";
import { setSnackbar } from "../../redux/Message/actions";
import { Typography, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ResetPassword3 from "../../components/ResetPassword3";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "15%",
    ["@media (max-width:900px)"]: {
      width: "25%",
    },
    ["@media (max-width: 600px)"]: {
      width: "35%",
    },
    ["@media (max-width: 425px)"]: {
      width: "55%",
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = query.get("token");
  const email = query.get("email");

  const handleResetPassword = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    const resetObj = { ...values };
    resetObj.email = email;
    resetObj.token = token;
    debugger;
    axios.post("ResetPassword", resetObj).then((response) => {
      console.log(response);

      setSubmitting(false);
      resetForm({});
      dispatch(
        setSnackbar(true, "success", t("messages.passwordResetedSuccessfuly"))
      );
      history.push("/login");
    });
  };
  return (
    <div>
      <Box margin="20px" className={classes.root}>
        <RouterLink to="/">
          <img src="/assets/images/logo/logo.png" width="100%" />
        </RouterLink>
      </Box>
      <Container style={{ marginTop: "65px" }}>
        <Grid item container wrap="nowrap" spacing={3}>
          <Grid item xs={12} md={6}>
            <ResetPassword3 handleResetPassword={handleResetPassword} />
          </Grid>

          <Hidden only={["xs", "sm"]}>
            <Grid item md={6} xs={false}>
              <img
                src={`/assets/images/ResetPassword/ForgetPassword.svg`}
                className="img"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default ResetPassword;
