import React, { useEffect, useState } from "react";
import Header from "./../../components/Header";
import { useHistory } from "react-router-dom";
import UserShoppingCard from "./../../components/UserCartShopping";
import CartTitle from "./../../components/CartTitle";
import BackOrCopmplete from "./../../components/BackOrComplete";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "./../../redux/Message/actions";
import axios from "axios";
import EmptyCard from "./../../components/EmptyCart/index";
import { useTranslation } from "react-i18next";
import {
  CHANGE_CART_PRODUCT_QUANTITY,
  REMOVE_FROM_CART,
} from "../../redux/actionTypes";
import LoginPage from "../LoginPage";
import ControlledAppModal from "../../components/ControlledAppModal/index";
import { Box, Typography } from "@material-ui/core";
import RegisterationPage from "../RegisterationPage";

const ProductsCartPage = () => {
  const user = localStorage.userAllInfo
    ? JSON.parse(localStorage.userAllInfo)
    : "";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoggedIn = localStorage.token;
  const userRole = localStorage.userRole;
  let userId = localStorage.getItem("userId");
  const [cart, setCart] = useState([{ items: [] }]);
  const [deleted, setDeleted] = useState({});
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const [Q, setQ] = useState(0);
  const token = useSelector((state) => state.loginReducer.token);
  const accountCreated = useSelector(
    (state) => state.registerReducer.accountCreated
  );

  const localCartItems = useSelector((state) => state.cartReducer.cartItems);
  useEffect(() => {
    debugger;
    if (isLoggedIn && userRole === "Buyer") {
      axios
        .get(`Basket/GetBasketItems?BuyerId=${userId}`)
        .then((response) => {
          debugger;
          setCart(response.data);
        })
        .catch((err) => {
          debugger;
          console.log(err);
          dispatch(setSnackbar(true, "error", t("messages.failTryAgain")));
        });
    } else if (!isLoggedIn) {
      setCart(localCartItems);
    } else if (isLoggedIn && userRole !== "Buyer") {
      dispatch(setSnackbar(true, "error", t("messages.loginAsBuyer")));
    }
  }, [deleted, Q, localCartItems, accountCreated, token]);
  useEffect(() => {
    debugger;
    setOpenCheckoutModal(false);
  }, [accountCreated, token]);

  let total = 0;
  const sum = (items) => {
    total = 0;
    for (var i = 0; i < items.length; i++) {
      total = total + items[i].quantity * items[i].unitPrice;
    }
    return total;
  };

  const handleOpenCheckoutModal = () => {
    setOpenCheckoutModal(true);
  };
  const handlecloseCheckoutModal = () => {
    setOpenCheckoutModal(false);
    setOpenLogin(false);
    setOpenRegister(false);
  };
  const handleToggleSignUpLogin = () => {
    setOpenLogin(!openLogin);
    setOpenRegister(!openRegister);
  };

  const handleChangeCounter = (id, count, productId, bazarId) => {
    if (isLoggedIn && userRole === "Buyer") {
      axios
        .put(`Basket/SetQuantitiesAsync?basketItemId=${id}&quantitiy=${count}`)
        .then((response) => {
          console.log(response, "setQ(count);", count);
          setQ(count);
        })
        .catch((err) => {
          alert(t("cartPage.maxmunQuantityAvailable"));
        });
    } else if (!isLoggedIn) {
      // setCart(localCartItems);
      dispatch({
        type: CHANGE_CART_PRODUCT_QUANTITY,
        productId,
        bazarId,
        count,
      });
    } else if (isLoggedIn && userRole !== "Buyer") {
      dispatch(setSnackbar(true, "error", t("messages.loginAsBuyer")));
    }
  };
  let history = useHistory();
  const handleBack = (bazarId) => {
    history.push(`/buyer/bazarPage/${bazarId}`);
  };
  const handleCompleteOrder = (basketId) => {
    let orderId;
    if (isLoggedIn && userRole === "Buyer") {
      axios
        .post(`Order/CreateOrder?basketId=${basketId}`)
        .then((response) => {
          orderId = response.data;
          history.push(`/buyer/confirmOrderPage/${orderId}`);
          // dispatch(
          //   setSnackbar(true, 'success', t('messages.orderCompletedSuccessfuly')),
          // )
        })
        .catch((err) => {
          console.log(err);
          dispatch(setSnackbar(true, "error", t("messages.failTryAgain")));
        });
    } else if (isLoggedIn && userRole !== "Buyer") {
      dispatch(setSnackbar(true, "error", t("messages.loginAsBuyer"), 4000));
    } else if (!isLoggedIn) {
      setOpenLogin(true);
      handleOpenCheckoutModal(true);
    }
  };

  const handleDeleteBasketItem = (id, productId, bazarId) => {
    if (isLoggedIn && userRole === "Buyer") {
      axios
        .put(`/Basket/DeleteProductFromBasket?basketItemId=${id}`)
        .then((response) => {
          setDeleted(response.data);
          let item = cart.items.find((itm) => itm.basketItemId === id);
          let Index = cart.items.indexOf(item);
          cart.items.splice(Index, 1);
          dispatch(
            setSnackbar(
              true,
              "success",
              t("messages.productDeletedSuccessfuly")
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!isLoggedIn)
      dispatch({
        type: REMOVE_FROM_CART,
        productId: productId,
        bazarId: bazarId,
      });
  };
  let allbazarsItems = [];
  let items;
  // console.log(cart, "Carttt");
  return (
    <>
      <Header />
      {cart.length > 0
        ? cart.map((cartt) => {
            // if (cartt.length > 0)
            allbazarsItems = [...allbazarsItems, ...cartt.items];
            items = allbazarsItems.length;
            localStorage.setItem("No.Items", items);
            console.log(allbazarsItems, "len");
            return (
              <>
                {console.log(cart, "Carttt")}
                <div style={{ marginTop: "7.5rem" }}>
                  {cartt?.items?.length > 0 ? (
                    <>
                      {console.log(cartt, "bazar logoooo")}
                      <CartTitle
                        bazarName={cartt.bazarName}
                        bazarLogo={cartt.bazarLogo}
                        bazarId={cartt.bazarId}
                        handleBack={handleBack}
                      />
                      {cartt.items
                        .filter((itm) => itm.isDeleted === false)
                        .map((itm, i) => (
                          <UserShoppingCard
                            key={i}
                            productName={itm.productName}
                            currency={itm.currency}
                            productCatogory={itm.productCatogory}
                            productImage={itm.productImage}
                            unitPrice={itm.unitPrice}
                            color={itm.color}
                            size={itm.size}
                            basketItemId={itm.basketItemId}
                            handleChangeCounter={handleChangeCounter}
                            handleDeleteBasketItem={handleDeleteBasketItem}
                            id={itm.productId}
                            quantity={itm.quantity}
                            isEmpty={itm.isEmpty}
                            totalQuantity={itm.totalQuantity}
                            description={itm.description}
                            bazarId={cartt.bazarId}
                          />
                        ))}
                      <BackOrCopmplete
                        handleBack={handleBack}
                        handleCompleteOrder={handleCompleteOrder}
                        basketId={cartt.id}
                        bazarId={cartt.bazarId}
                        total={sum(cartt.items)}
                        currency={cartt.currency}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <ControlledAppModal
                  open={openCheckoutModal}
                  handleClickOpen={handleOpenCheckoutModal}
                  handleClose={handlecloseCheckoutModal}
                >
                  <>
                    <div style={{ maxWidth: "600px" }}>
                      {openLogin && (
                        <>
                          {" "}
                          <LoginPage modal={true} />
                          <Box
                            mt={3}
                            mb={5}
                            justifyContent="center"
                            display="flex"
                          >
                            <Typography>
                              {t("signIn.dontHaveAccount")}{" "}
                            </Typography>

                            <Typography
                              color="primary"
                              style={{ margin: "0px 7px", cursor: "pointer" }}
                              onClick={() => handleToggleSignUpLogin()}
                            >
                              {t("signUp.signUp")}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {openRegister && (
                        <div>
                          <RegisterationPage role="buyer" modal={true} />
                          <Box mt={3} justifyContent="center" display="flex">
                            Already a memeber ?
                            <Typography
                              color="primary"
                              onClick={() => handleToggleSignUpLogin()}
                            >
                              {" "}
                              Sign in
                            </Typography>
                          </Box>
                        </div>
                      )}
                    </div>
                  </>
                </ControlledAppModal>
              </>
            );
          })
        : ""}
      {allbazarsItems.length === 0 ? <EmptyCard /> : ""}
    </>
  );
};

export default ProductsCartPage;
