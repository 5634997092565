import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import tableIcons from '../../styles/materialTableIcons'
import { Link } from 'react-router-dom'
import RequestInfoModal from './../../components/RequestInfoModal/index'
import { useTranslation } from 'react-i18next'
const RequestActionsDropDown = ({
  handleAcceptRequest,
  handleDeclineRequest,
  id,
  message,
  providerResponse,
}) => {
  const [user, setUser] = useState('')
  const { t } = useTranslation()
  // const actionsArr = ['Accept', 'Decline', 'Message']
  const actionsArr = [
    { name: 'accept', value: 'Accept' },
    { name: 'decline', value: 'Decline' },
  ]
  const [action, setAction] = useState(actionsArr[providerResponse - 1] || '')
  debugger
  const handleActionChange = (e, user) => {
    const selectedAction = e.target.value
    setAction(selectedAction)
    switch (selectedAction) {
      case 'Accept':
        /// call accept booth request api
        handleAcceptRequest(id)

        break
      case 'Decline':
        //// call Declin booth reuest api
        handleDeclineRequest(id)
        break

      default:
        break
    }
    setAction('')
  }
  return (
    <>
      <FormControl
        variant="standard"
        style={{ width: '80px', marginRight: '10px' }}
      >
        {!action && <InputLabel htmlFor="Language">{t('action')}</InputLabel>}
        <Select
          labelId="Language"
          value={action}
          //   name={'actions' + row.id}

          onChange={(e) => handleActionChange(e, {})}
          inputProps={{
            id: 'open-select',
          }}
          //   label="Action"
          disabled={+providerResponse === 1 ? true : false}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {actionsArr.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              {t('buttons.' + option.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default RequestActionsDropDown
