import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import "./test.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "350px",
    maxHeight: "18rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "5rem 2rem",
    overflow: "visible",
  },
  media: {
    width: "7rem",
    height: "7rem",
    borderRadius: "50%",
    margin: "-4rem 0 0",
    objectFit: "cover",
  },
  br: {
    display: "none",
    ["@media (max-width:780px)"]: {
      display: "block",
      width: "2rem",
      height: "4rem",
    },
  },
}));

export default function Testimonials({
  nam,
  imgsrc,
  job,
  text,
  textEn,
  margin,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentLang = localStorage.i18nextLng;

  return (
    <div className="testimonails-card card h-100" style={{ width: "100%" }}>
      <div className="card-body">
        <p>{currentLang === "ar" ? text : textEn}</p>
      </div>
      <div className="card-footer">
        <div className="user-info">
          <img
            src={imgsrc}
            alt=""
            style={{
              borderRadius: "50%",
              width: "100px",
              height: "100px",
              objectFit: "cover",
            }}
          />
          <strong>{nam}</strong>
          <span>{t("roles." + job)}</span>
        </div>
      </div>
    </div>
  );
}
{
  /* <Card className={classes.root}>
        <img src={imgsrc} alt="person" className={classes.media} />
        <CardContent
          style={{
            padding: "5px",
          }}
        >
          <Typography
            style={{
              fontFamily: "'Tajawal', sans-serif",
            }}
            component="h4"
          >
            {nam}
          </Typography>
        </CardContent>
        <Typography
          variant="body2"
          component="p"
          style={{ fontFamily: "'Tajawal', sans-serif", fontWeight: "600" }}
        >
          {job}
        </Typography>
        <CardContent>
          <Typography
            className="txtt"
            variant="body2"
            color="textSecondary"
            component="p"
            style={{
              fontFamily: "'Tajawal', sans-serif",
              margin: "0 1rem",
            }}
          >
            {text}
          </Typography>
        </CardContent>
      </Card> */
}
