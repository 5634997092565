import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  makeStyles,
  IconButton,
  Container,
  Link,
  TextField,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import FBShareButton from "../FBShareButton";
import { Share, FiberManualRecord, CheckCircle } from "@material-ui/icons";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Rating, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  format,
  isEqual,
  isBefore,
  isAfter,
  min,
  max,
  subMinutes,
  isFuture,
} from "date-fns";
import ImageGallery from "react-image-gallery";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import { FavoriteIcon, FavoriteBorderIcon } from "@material-ui/icons";
import ClipboardCopy from "./../CopyTo/index";
const useStyles = makeStyles((theme) => ({
  disCol: {
    display: "flex",
    flexDirection: "column",
  },
  disRow: { display: "flex" },
  disContainer: {
    display: "flex",
    ["@media (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
  // bbtn: { color: '#eee', background: '#000' },
  bbtn: { color: "#000", border: "2px solid orange" },
  buttonCounter: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
  },
  buttonAddCart: {
    textAlign: "center",
    textTransform: "none",
    backgroundColor: "#F8B334",

    width: "65%",
    "&:hover": {
      backgroundColor: "#F8B111",
    },
  },
  c: {
    textAlign: "center",
    marginTop: "6rem",
    ["@media (max-width:600px)"]: {
      marginTop: "1rem",
    },
  },
  colorSelected: {
    // border: ".5px solid black",
    margin: "5% 0% 5% 5%",
    "&:focus": {
      background: "transparent",
    },
    "&:hover": {
      background: "transparent",
    },
  },
  textD: {
    textDecorationLine: "line-through",
    marginRight: "1rem",
    fontWeight: "400",
  },
  margin: {
    margin: ".5rem",
  },
  mr: {
    marginRight: "1rem",
  },
  anchorStyle: {
    // textDecoration: 'underline',
    // color: 'orange',
    margin: "0 5px",
    "&:hover": {
      cursor: "pointer",
      // color: 'orangered',
    },
  },
  root: {
    border: "2px solid red",
    "Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  textAlign: {
    textAlign: "right",
    color: "black",
  },
  di: {
    display: " flex",
    alignItems: " center",
  },
}));
const ProductInfo = ({
  id,
  product,
  count,
  color,
  handleColor,
  handleSize,
  handleAddToCard,
  S,
  buyer,
  creatImg,
  productImgs,
  handleDesc,
  favorite,
  currency,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const checkDate = () => {
    let s = max([new Date(), new Date(product.startOffer + "Z")]);
    let e = min([new Date(), new Date(product.endOffer + "Z")]);
    if (
      product.offerPrice &&
      new Date() >= new Date(product.startOffer + "Z") &&
      new Date() <= new Date(product.endOffer + "Z")
    ) {
      return true;
    } else return false;
  };
  console.log(
    checkDate(),
    new Date(product.startOffer + "Z"),
    new Date(product.endOffer + "Z"),
    new Date()
  );

  document.head.querySelector('meta[property="og:image"]').content =
    localStorage.imagePath + product?.productImage[0]?.url;
  document.title = product?.name;

  document.head.querySelector('meta[name="description"]').content =
    product?.description;

  return (
    <section className="product-details-page">
      <section className="products-details">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 my-3"
              style={{ backgroundColor: "#fcfcfc" }}
            >
              <div className="slider-section">
                {productImgs.length ? (
                  <ImageGallery
                    className="w-100"
                    items={productImgs.map((itm) => {
                      return creatImg(
                        `${localStorage.imagePath}${itm.url}`,
                        `${localStorage.imagePath}${itm.url}`
                      );
                    })}
                    showPlayButton={false}
                    // showFullscreenButton={false}
                  />
                ) : (
                  <img
                    src="/assets/images/product.png"
                    className="image-gallery-image"
                    className="w-100"
                  />
                )}
              </div>
            </div>

            <div className="col-lg-5 my-3">
              <div
                className="product-details"
                style={
                  localStorage.i18nextLng === "en" ? {} : { textAlign: "right" }
                }
              >
                <div className="product-title">
                  <h5 style={{ maxWidth: "80%" }}> {product.name}</h5>
                  <>
                    <h6 className={classes.di}>
                      <span
                        className={checkDate() ? classes.textD : ""}
                        style={
                          checkDate()
                            ? {
                                color: "black",
                              }
                            : {}
                        }
                      >
                        {product.currency} {product.price}
                      </span>
                      <span>
                        {checkDate() ? (
                          <>
                            {product.currency} {product.offerPrice}
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </h6>
                  </>
                </div>

                <div className="product-rating-info">
                  <div className="product-id">
                    <p>
                      {t("productId")} : {product.id}
                    </p>
                  </div>
                  <div className="rating">
                    <Rating
                      name="read-only"
                      value={Math.ceil(+product.averageRate)}
                      readOnly
                    />
                    <ClipboardCopy
                      copyText={
                        "https://www..ebazarak.com" +
                        location?.pathname
                      }
                    />
                  </div>
                  <p>{product.description}</p>
                </div>
                <div className="item-color-size">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px ",
                    }}
                  >
                    {product.productColor.length ? (
                      <>
                        <Typography className={classes.margin}>
                          <strong> {t("productDetails.colors")}:</strong>
                        </Typography>
                        {product.productColor.map((c, i) => (
                          <div
                            style={{
                              width: "48px",
                              height: "35px",
                              margin: "0 5px",
                              backgroundColor: c.color,
                              border:
                                c.id === color
                                  ? "3px solid orange"
                                  : "1px solid silver",
                            }}
                            onClick={() => handleColor(c.id)}
                          ></div>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {product.productColor.map((col, i) => {
                      return col.id === color
                        ? product.productColor[i].productSize.length > 0 && (
                            <>
                              <div
                                className={classes.margin}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  className={
                                    localStorage.i18nextLng === "en"
                                      ? classes.mr
                                      : ""
                                  }
                                >
                                  <strong>{t("productDetails.sizes")}:</strong>{" "}
                                  &nbsp;
                                  {console.log(
                                    localStorage.i18nextLng,
                                    " document.body.dir "
                                  )}
                                </Typography>
                                {product.productColor[i].productSize.map(
                                  (size, i) => (
                                    <Button
                                      key={i}
                                      variant="contained"
                                      className={
                                        size.id !== S
                                          ? `${classes.buttonSizes}`
                                          : `${classes.buttonSizes} ${classes.bbtn}`
                                      }
                                      onClick={() => handleSize(size)}
                                    >
                                      {size.size}
                                    </Button>
                                  )
                                )}
                              </div>
                            </>
                          )
                        : "";
                    })}
                  </div>
                </div>
                <div className="item-details">
                  <div
                    className={`${classes.disCol}`}
                    style={{ marginBottom: "1rem" }}
                  >
                    {product.widht || product.lenght || product.height ? (
                      <p className={classes.margin}>
                        {t("dimentions")} :&nbsp;
                        {product.widht ? (
                          <span>
                            {t("addProductForm.width")}:{product.widht}
                          </span>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;
                        {product.lenght ? (
                          <span>
                            {t("addProductForm.length")}:{product.lenght}
                          </span>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;
                        {product.height ? (
                          <span>
                            {t("addProductForm.height")}:{product.height}
                          </span>
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;
                      </p>
                    ) : (
                      ""
                    )}
                    <p className={classes.margin}>
                      {t("addProductForm.weight")}:&nbsp;{product.weight}
                    </p>
                    <Divider
                      style={{
                        background: "#c8c8c8",
                        margin: "1rem 0",
                        height: "0.5px",
                      }}
                      variant="middle"
                    />
                  </div>
                </div>
                {/* <!-- Start Product Details--> */}
                <div
                  className="item-details"
                  style={
                    localStorage.i18nextLng === "en"
                      ? {}
                      : { textAlign: "right" }
                  }
                >
                  {+product.quantity > 0 ? (
                    <>
                      <h5>{t("itemDetails")}</h5>
                      <TextField
                        id="filled-multiline-static"
                        placeholder={t("Addorderdetails")}
                        multiline
                        rows={3}
                        variant="outlined"
                        onChange={handleDesc}
                        style={{ width: "100%" }}
                      />
                    </>
                  ) : (
                    <Typography style={{ color: "red" }}>
                      <div>{t("cartPage.outOfStock")}</div>
                      {t("cartPage.currentlyUnavailable")}
                    </Typography>
                  )}
                  {/* <div className="personalization-btn">
                    <button>
                      <img src="/assets/images/icons/add.png" width="25px" alt="" />
                      <span className="mx-2">Add Personalization </span>
                    </button>
                  </div> */}
                  <div className="add-cart-btn" style={{ display: "flex" }}>
                    <button
                      className="hvr-float-shadow"
                      onClick={handleAddToCard}
                      disabled={!buyer || +product.quantity <= 0}
                    >
                      <ShoppingCartIcon
                        style={{ margin: "2px", color: "white" }}
                      />
                      <span className="mx-2"> {t("buttons.addToCart")}</span>
                    </button>
                    {/* <button
                      className="hvr-float-shadow"
                      style={{ marginLeft: "1rem", width: "5rem" }}
                    >
                      {favorite ? (
                        <i class="fas fa-heart"></i>
                      ) : (
                        <i class="far fa-heart"></i>
                      )}
                      {console.log(favorite)}
                    </button> */}
                  </div>
                  {/* <!-- Start Product Brand Details--> */}
                  <div
                    className="brand-details"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.localStorage.removeItem("page");
                      history.push(`/buyer/boothPage/${product?.boothId}`);
                    }}
                  >
                    <div className="logo">
                      <img
                        src={
                          product?.boothLogo
                            ? `${localStorage.imagePath}${product?.boothLogo}`
                            : "/assets/images/Booths/boothLogo.jpg"
                        }
                        alt="boothLogo"
                      />
                    </div>
                    <div className="brand-info">
                      <strong>
                        {product?.seller?.firstName +
                          " " +
                          product?.seller?.lastName}
                      </strong>
                      <span>{product.boothName}</span>
                      <a>{product?.seller?.phoneNumber}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ProductInfo;
