import React from "react";
import { Typography } from "@material-ui/core/index";
import { Grid, Box, Button } from "@material-ui/core/index";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FacebookIcon from "@material-ui/icons/Facebook";

import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";

import { socialMediaArray } from "./../Registeration/OrganizerSignUpForm/constants";
import Link from "@material-ui/core/Link";
import { colors } from "../../styles/commonCss";
import Chip from "@material-ui/core/Chip";
import { blue, red } from "@material-ui/core/colors";
import ControlledAppModal from "./../ControlledAppModal/index";
import DateRangeIcon from "@material-ui/icons/DateRange";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    [theme.breakpoints.down("md")]: {
      width: "350px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: "5px",
  },

  basicInfoTitle: {
    color: theme.palette.text.secondary,
    backgroundColor: "#f1f1f1",
    padding: "5px 20px ",
  },
  marginRight: {
    marginRight: "12px",
  },
  marginLeft: {
    marginLeft: "12px",
  },
  marginX: { margin: "20px 0" },
  boldFont: { fontWeight: "bold" },
  iconColor: {
    color: theme.palette.text.secondary,
  },
  linkColor: { color: colors.link },

  acceptBtn: {
    fontSize: 14,
    border: "1px solid",
    borderColor: blue[800],
    color: blue[700],
    fontWeight: "bold",
    textTransform: "capitalize",
    // marginBottom: '0.875em',
  },
  declineBtn: {
    fontSize: 14,
    border: "1px solid",
    color: red[500],
    borderColor: red[500],
    fontWeight: "bold",
    textTransform: "capitalize",
  },
}));
const BazarRequestInfoModal = ({
  organizer,
  id = 1,
  name,
  categories = [],
  endDate,
  description,
  maxSeller,
  handleAcceptRequest,
  handleDeclineRequest,
  providerResponse,
  providerMessage,
  startDate,
  shipmentDetails,
  shipmentDetailsFile,
}) => {
  const userRole = localStorage.userRole;
  debugger;
  const classes = useStyles();
  const { t } = useTranslation();
  console.log(organizer, "eenndd");
  return (
    <Grid container direction="column">
      <div className={classes.paper}>
        <h3 className={classes.basicInfoTitle}>{t("bazarHome.bazarInfo")}</h3>
        <Grid container xs={12} item className={classes.marginX}>
          <Grid item xs={12} md={12}>
            <div className={classes.boldFont}>
              {t("createBazar.step1Form.categories")}{" "}
            </div>
            {categories?.length > 0 && (
              <Box paddingTop="10px">
                {categories.map((cat) => (
                  <Chip
                    key={cat.id}
                    label={
                      localStorage.i18nextLng === "en"
                        ? cat.name
                        : cat.arabicName
                    }
                    variant="outlined"
                    color="primary"
                    className={categories.length > 1 && "margin-right-s"}
                    style={{ margin: "5px 3px" }}
                  />
                ))}
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.marginX}
            style={{ marginTop: 0 }}
          >
            <div className={classes.boldFont}>
              {t("ProviderBazar.currency")} :
            </div>
            JD {t("ProviderBazar.dollar")}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} container className={classes.marginX}>
          <Grid item xs={12} className={classes.boldFont}>
            {t("ProviderBazar.start")}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {format(new Date(endDate), 'dd-MM-yyyy')} */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <DateRangeIcon className={classes.marginRight} />
              {format(new Date(startDate + "Z"), "dd-MM-yyyy")}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {format(new Date(endDate), 'dd-MM-yyyy')} */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <QueryBuilderIcon className={classes.marginRight} />
              {format(new Date(startDate + "Z"), "h:mm a")}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} container className={classes.marginX}>
          <Grid item xs={12} className={classes.boldFont}>
            {t("ProviderBazar.End")}
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {format(new Date(endDate), 'dd-MM-yyyy')} */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <DateRangeIcon className={classes.marginRight} />
              {format(new Date(endDate + "Z"), "dd-MM-yyyy")}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {format(new Date(endDate), 'dd-MM-yyyy')} */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <QueryBuilderIcon className={classes.marginRight} />
              {format(new Date(endDate + "Z"), "h:mm a")}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.marginX}>
          <div className={classes.boldFont}>
            {" "}
            {t("ProviderBazar.maxSellerNumber")} :
          </div>
          {maxSeller} {t("buttons.sellers")}
        </Grid>
        {description && (
          <Grid
            item
            xs={12}
            md={12}
            container
            className={classes.marginX}
            spacing={1}
          >
            <Grid item xs={12} className={classes.boldFont}>
              {t("description")}
            </Grid>
            <Grid item xs={12}>
              {description}
            </Grid>
          </Grid>
        )}
        {/* <Box display="flex" alignItems="baseline">
        <LocationOnOutlinedIcon
          color="secondary"
          className={classes.marginRight}
          fontSize={'small'}
          style={{ marginTop: '2px' }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>countryName</Typography>
        </div>
      </Box> */}
        <div>
          <h3 className={classes.basicInfoTitle}>
            {t("ProviderBazar.organizerInfo")}
          </h3>
        </div>
        <div
          className={classes.boldFont}
          style={{ marginBottom: "10px", direction: "ltr" }}
        >
          {organizer.firstName + " " + organizer.lastName}
        </div>
        <Box display="flex" alignItems="center" dir="ltr">
          <MailIcon
            className={classes.marginRight}
            fontSize={"small"}
            color="secondary"
          />
          <Typography>{organizer.email}</Typography>
        </Box>
        <div>
          <Box display="flex" mt={1} alignItems="center" dir="ltr">
            <LocationOnOutlinedIcon
              className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>
              {localStorage.i18nextLng === "en"
                ? organizer?.country?.name
                : organizer?.country?.arabicName}
            </Typography>
          </Box>
        </div>
        <div>
          <Box display="flex" alignItems="center" dir="ltr">
            <PhoneIcon
              className={classes.marginRight}
              fontSize={"small"}
              color="secondary"
            />
            <Typography>{organizer.phoneNumber}</Typography>
          </Box>
        </div>
        {organizer.whatsNumber && (
          <div>
            <Box display="flex" alignItems="center">
              <WhatsAppIcon
                className={classes.marginRight}
                fontSize={"small"}
                color="secondary"
              />
              <Typography>{organizer.whatsNumber}</Typography>
            </Box>
          </div>
        )}

        {userRole !== "Seller" && (
          <>
            {providerMessage ? (
              <div>
                <h3 className={classes.basicInfoTitle}>{t("message")}</h3>
                <div
                  className={classes.boldFont}
                  style={{ marginBottom: "10px" }}
                >
                  {providerMessage}
                </div>
              </div>
            ) : (
              ""
            )}
            {userRole === "Provider" && shipmentDetails && (
              <>
                <h3 className={classes.basicInfoTitle}>
                  {t("ProviderBazar.providerInfo")}
                </h3>
                <div>
                  <h4>{t("shipmentPriceDetails")}</h4>

                  <div style={{ marginBottom: "10px" }}>
                    {" "}
                    {shipmentDetails &&
                      shipmentDetails
                        .split(/\r\n|\r|\n/g)
                        .map((line) => <div>{line}</div>)}
                  </div>
                </div>
                {shipmentDetailsFile && (
                  <div>
                    <h4>{t("attachedFile")}</h4>
                    <span> {shipmentDetailsFile}</span>
                    <a
                      // href={`https://api.ebazarak.com/api/File/GetCertificate?CertificateName=${shipmentDetailsFile}`}
                      href={`${localStorage.imagePath}${shipmentDetailsFile}`}
                      download
                      target="_blank"
                      style={{ margin: "0 10px" }}
                    >
                      {t("download")}
                    </a>
                  </div>
                )}
              </>
            )}

            {providerResponse === 0 && (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Box display="flex" my={2}>
                  <Button
                    variant="outlined"
                    className={classes.acceptBtn}
                    onClick={() => handleAcceptRequest(id)}
                    style={{ marginRight: "20px" }}
                  >
                    {t("buttons.accept")}
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.declineBtn}
                    onClick={() => handleDeclineRequest(id)}
                  >
                    {t("buttons.decline")}
                  </Button>
                </Box>
              </div>
            )}
          </>
        )}
      </div>
    </Grid>
  );
};

export default BazarRequestInfoModal;
