export const ProviderDrawerItems = [
  {
    path: '/dashBoard/Provider',
    itemName: 'bazars',
  },
  {
    path: '/Provider/manageOrders',
    itemName: 'manageOrders',
  },
  {
    path: '/Provider/drivers',
    itemName: 'drivers',
  },
  {
    path: '/Provider/bazarRequests',
    itemName: 'bazarRequests',
  },
  {
    path: '/Provider/profile',
    itemName: 'profile',
  },
  // {
  //   path: '/Provider/messages',
  //   itemName: 'Messages',
  // },

  // {
  //   path: '/help',
  //   itemName: 'help',
  // },
]
