import React from "react";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Container } from "@material-ui/core/index";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SwitchLanguage from "./../LanguageDropdown/index";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { MenuItem, Menu } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { handleLogout } from "../../utils/setLocalStorage";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "white",
      width: `100%`,
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      //   marginLeft: drawerWidth,
    },
  },
  menuButton: {
    color: "black",
    // marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  displayLogout: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileDropdown: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: "80px",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  dis: { display: "none" },
  imgCover: {
    // width: "100%",
    height: "25rem",
    // objectFit: "cover",
    ["@media (max-width:600px)"]: {
      maxWidth: "100%",
      objectFit: "cover",
    },
  },
  imgover: {
    maxWidth: "100%",
    height: "25rem",
    objectFit: "cover",
    backgroundColor: "#F6F6F6",
    ["@media (max-width:600px)"]: {
      maxWidth: "100%",
      objectFit: "cover",
      backgroundColor: "white",
    },
  },
  iconMarginRight: {
    // margin: '0 5px',

    marginRight: "8px",
  },
  iconMarginLeft: {
    marginLeft: "8px",
  },

  navItemsContainer: {
    marginInlineStart: "auto",
    // marginRight: '20px',
    // marginLeft: '20px',
    color: "black",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginLeft: "0px",
    },
  },
  headerLogo: {
    width: "150px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "120px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  card: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    backgroundColor: "orange",
    color: "white",
    position: "absolute",
    right: "11%",
    fontSize: "1rem",
    top: "-.1rem",
  },
}));
const Header = ({
  handleDrawerToggle,
  history,
  location,
  srcCover,
  cond,
  imgcover,
  currentUser,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const role = localStorage.userRole;
  const isLoggedIn = localStorage.token;
  const currentLanguageCode = localStorage.i18nextLng || "en";
  const items = localStorage.getItem("No.Items");

  // debugger
  const home =
    location.pathname === "/" || location.pathname.startsWith("/buyer")
      ? true
      : false;
  const [user, setUser] = useState(
    !currentUser && role !== "Buyer" && localStorage.userAllInfo
      ? JSON.parse(localStorage.userAllInfo)
      : currentUser
      ? currentUser
      : ""
  );

  // debugger
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (role === "Buyer")
      axios
        .get("Buyer/GetBuyerProfile?BuyerId=" + localStorage.userId, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          // debugger;
          const { data } = response;
          setUser(data);
        });
  }, [localStorage.token]);
  useEffect(() => {
    // debugger;
    if (
      role !== "Buyer" &&
      (currentUser?.firstName || currentUser?.companyName)
    ) {
      // debugger;
      setUser(currentUser);
    }
  }, [currentUser]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        {/* <Container> */}
        <Toolbar
          style={{
            marginTop: "8px",
            dispaly: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {isLoggedIn && role !== "Buyer" && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={
                  !home
                    ? handleDrawerToggle
                    : () => {
                        role !== "Buyer" && history.push("/dashboard/" + role);
                      }
                }
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Box
              // width="130px"
              // style={{ cursor: 'pointer' }}
              // onClick={() => history.push(`/dashboard/${role ? role : 'Buyer'}`)}
              onClick={() => history.push(`/`)}
              // ml="10px"
              // mr="10px"
              className={classes.headerLogo}
            >
              <img
                src="/assets/images/logo/logoBeta.png"
                width="100%"
                height="100%"
                className="img"
                alt=""
              />
            </Box>
          </div>

          {/* <IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="primary">
              <NotificationsIcon color="secondary" />
            </Badge>
          </IconButton> */}
          <Box
            align="right"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              marginInlineStart: "auto",
              marginRight: "20px",
              color: "black",
              cursor: "pointer",
              // flexFlow: 'wrap-reverse',
              marginBottom: "1%",
            }}
          >
            {!isLoggedIn ? (
              <>
                <Box>
                  <SwitchLanguage />
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    history.push("/login");
                    window.location.reload();
                  }}
                >
                  {t("buttons.login")}
                </Button>
                <IconButton
                  style={{
                    margin: "0 5px",
                    marginTop: "2px",
                    padding: "12px 0",
                  }}
                  onClick={() => history.push("/buyer/cartPage")}
                >
                  <ShoppingCartIcon color="primary" />
                  {+items > 0 ? (
                    <div className={classes.card}>{items}</div>
                  ) : (
                    ""
                  )}
                </IconButton>
              </>
            ) : (
              <>
                {/* {home && (
                  <Box
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '10px' }}
                    className={classes.displayLogout}
                  >
                    <Logout />
                  </Box>
                )} */}

                {role !== "Buyer" ? (
                  <>
                    <Box m="0 10px">
                      <SwitchLanguage />
                    </Box>
                    <Box
                      // align="right"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{
                        // marginInlineStart: 'auto',
                        // marginRight: '20px',
                        color: "black",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        role !== "Buyer" && history.push("/dashboard/" + role);
                      }}
                      onClick={handleClick}
                    >
                      {/* {role !== 'Buyer' && ( */}
                      <Avatar
                        alt={
                          user?.firstName?.toUpperCase() ||
                          user?.companyName?.toUpperCase()
                        }
                        src={
                          user?.profileImage
                            ? localStorage.imagePath + user.profileImage
                            : // : user?.imageProfile
                              // ? 'localStorage.imagePath' +
                              //   user.imageProfile
                              "/static/images/avatar/1.jpg"
                        }
                        style={{ width: "2.3rem", height: "2.3rem" }}
                      />
                      {/* )} */}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        {/* <Typography style={{ fontSize: '0.8rem' }}>
                            {user?.firstName}
                            {user?.companyName}
                          </Typography> */}
                        {/* {role === 'Buyer' && user?.lastName} */}
                        {/* <div
                          style={{
                            marginTop: "0px",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Box width="20px" ml="-0.5px" height="20px">
                            <img
                              src={`/assets/images/SVG/${role}.svg`}
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <Typography
                            style={{ marginLeft: "2px", fontSize: "0.7rem" }}
                            color="secondary"
                          >
                            {role}
                          </Typography>
                        </div> */}
                      </div>
                    </Box>
                    <div className={classes.mobileDropdown}>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        // anchorOrigin={{
                        //   vertical: 'bottom',
                        //   horizontal: 'center',
                        // }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        anchorPosition={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        // style={{ background: 'red' }}
                      >
                        <MenuItem
                          onClick={() => history.push("/dashboard/" + role)}
                        >
                          {/* <Box
                          variant="outlined"
                          color="primary"
                          // style={{ marginRight: '10px' }}
                          width="100%"
                        > */}
                          <div
                            style={{
                              marginTop: "0px",
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box width="20px" ml="-0.5px" height="20px">
                              {/* <img
                                  src={`/assets/images/SVG/${role}.svg`}
                                  width="100%"
                                  height="100%"
                                /> */}
                              <img
                                src={`/assets/images/SVG/${role}_BW.svg`}
                                width="100%"
                                height="100%"
                                style={{ color: "black" }}
                              />
                            </Box>
                          </div>
                          <div
                            style={{ margin: "0 6px" }}
                            // className={currentLanguageCode==='en'?classes.iconMarginLeft:classes.iconMarginRight}
                          >
                            {user?.firstName || user?.companyName}{" "}
                            {user?.lastName}
                          </div>
                          {/* </Box> */}
                        </MenuItem>
                        {/* {home && ( */}
                        <MenuItem onClick={() => history.push("/help")}>
                          <HelpOutlineIcon />
                          <span
                            className={
                              currentLanguageCode === "en"
                                ? classes.iconMarginLeft
                                : classes.iconMarginRight
                            }
                          >
                            {t("sideMenu.help")}
                          </span>
                        </MenuItem>
                        {/* )} */}
                        <MenuItem onClick={() => handleLogout()}>
                          <ExitToAppIcon />
                          <span
                            className={
                              currentLanguageCode === "en"
                                ? classes.iconMarginLeft
                                : classes.iconMarginRight
                            }
                          >
                            {t("buttons.logout")}
                          </span>
                        </MenuItem>
                      </Menu>
                    </div>
                  </>
                ) : (
                  <Box
                    // align="right"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.navItemsContainer}
                    // onClick={() => {
                    //   role !== 'Buyer' && history.push('/dashboard/' + role)
                    // }}
                  >
                    <Box>
                      <SwitchLanguage />
                    </Box>
                    <IconButton
                      style={{ margin: "0 5px", marginTop: "2px" }}
                      onClick={() => history.push("/buyer/cartPage")}
                    >
                      <ShoppingCartIcon color="primary" />
                      {+items > 0 ? (
                        <div className={classes.card}>{items}</div>
                      ) : (
                        ""
                      )}
                    </IconButton>
                    {/* {role !== 'Buyer' && ( */}
                    <Avatar
                      alt={
                        user?.firstName?.toUpperCase() ||
                        user?.companyName?.toUpperCase()
                      }
                      src={
                        user?.profileImage
                          ? localStorage.imagePath + user.profileImage
                          : "/static/images/avatar/1.jpg"
                      }
                      style={{ width: "2.3rem", height: "2.3rem" }}
                      onClick={handleClick}
                    />
                    <div className={classes.mobileDropdown}>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        // anchorOrigin={{
                        //   vertical: 'bottom',
                        //   horizontal: 'center',
                        // }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        anchorPosition={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        // style={{ background: 'red' }}
                      >
                        <MenuItem
                          onClick={() => history.push("/buyer/profile")}
                        >
                          {/* <Box
                          variant="outlined"
                          color="primary"
                          // style={{ marginRight: '10px' }}
                          width="100%"
                        > */}
                          <div
                            style={{
                              marginTop: "0px",
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box width="20px" ml="-0.5px" height="20px">
                              <img
                                src={`/assets/images/SVG/${role}.svg`}
                                width="100%"
                                height="100%"
                              />
                            </Box>
                            {/* <Typography
                              style={{ marginLeft: '2px', fontSize: '0.7rem' }}
                              color="secondary"
                            >
                              {role}
                            </Typography> */}
                          </div>
                          <div
                            style={{ margin: "0 6px" }}
                            // className={currentLanguageCode==='en'?classes.iconMarginLeft:classes.iconMarginRight}
                          >
                            {user?.firstName + " " + user?.lastName}
                          </div>
                          {/* </Box> */}
                        </MenuItem>
                        <MenuItem onClick={() => history.push("/buyer/orders")}>
                          <LocalMallIcon />
                          <span
                            className={
                              currentLanguageCode === "en"
                                ? classes.iconMarginLeft
                                : classes.iconMarginRight
                            }
                          >
                            {t("order.orders")}
                          </span>
                        </MenuItem>
                        <MenuItem onClick={() => history.push("/help")}>
                          <HelpOutlineIcon />
                          <span
                            className={
                              currentLanguageCode === "en"
                                ? classes.iconMarginLeft
                                : classes.iconMarginRight
                            }
                          >
                            {t("sideMenu.help")}
                          </span>
                        </MenuItem>
                        <MenuItem onClick={() => handleLogout()}>
                          <ExitToAppIcon />
                          <span
                            className={
                              currentLanguageCode === "en"
                                ? classes.iconMarginLeft
                                : classes.iconMarginRight
                            }
                          >
                            {t("buttons.logout")}
                          </span>
                        </MenuItem>
                      </Menu>
                    </div>
                    {/* )} */}

                    {/* <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '10px',
                      }}
                    >
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {user?.firstName}
                      </Typography>
                    </div> */}
                  </Box>
                )}

                {/* {role === 'Buyer' && (
         
                )} */}
              </>
            )}
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      <Grid
        style={{
          // marginTop: "4rem",
          textAlign: "center",
          //  height: '25rem'
        }}
        item
        xs={12}
        className={cond ? "" : classes.dis}
      >
        <img
          className="landing"
          src={srcCover}
          alt="landing page"
          className={imgcover ? classes.imgCover : classes.imgover}
        />
      </Grid>
    </>
  );
};

export default withRouter(Header);
